import { useQuery } from "@tanstack/react-query";

export default function useGetQueryData({ queryKey = "", select = "" }) {
    const selectionPath = select.split(/\.|\?\./).join("?.");

    const query = useQuery({
        enabled: false,
        queryKey,
        select: function (data) {
            return eval(`data?.${selectionPath}`);
        },
    });

    return query;
}
