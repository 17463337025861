import { Box, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerOverlay, Grid, Image, Text } from "@chakra-ui/react";
import React from "react";
import CustomButton from "./CustomButton";
import group from "../assets/Group176.png";
import { useQueryParams } from "use-query-params";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { DEVICE_QUERY_PARAMS } from "../lib/queriesParams";
import { SEARCH_PARAMS } from "../constants";

const Step2ManageDevice = () => {
    const { space } = useParams();
    const [query, setQuery] = useQueryParams(DEVICE_QUERY_PARAMS);
    const isOpen = query[SEARCH_PARAMS.DEVICE.MANAGE_STATUS.key] === "success";

    const clearQuery = () => {
        setQuery({
            [SEARCH_PARAMS.DEVICE.MANAGE.key]: SEARCH_PARAMS.DEVICE.MANAGE.default_value,
            [SEARCH_PARAMS.DEVICE.MANAGE_STATUS.key]: SEARCH_PARAMS.DEVICE.MANAGE_STATUS.default_value,
        });
    };

    const { data: rooms = { data: [] } } = useQuery({
        queryKey: ["fetchRooms", space],
        enabled: false,
    });

    const { data: device } = useQuery({
        queryKey: ["fetchDevices", space],
        enabled: false,
        select: function (data) {
            const device = data?.data?.find((item) => item?.id === query?.[SEARCH_PARAMS.DEVICE.MANAGE.key]);
            return device;
        },
    });

    return (
        <Drawer placement="right" isOpen={isOpen} onClose={clearQuery}>
            <DrawerOverlay />
            <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                <DrawerBody p={0} m={0} align="center" position="relative">
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="10px"
                        minWidth="310px"
                        bg="#F8FBEE"
                        flexGrow={1}
                        pb="60px"
                    >
                        <Grid templateColumns={{ base: "1fr", md: "1fr " }} gap={4} alignItems="center" justifyItems="center">
                            <Box
                                w="300px"
                                h="117px"
                                ml="20px"
                                mr="20px"
                                mt="42px"
                                fontFamily="fontFamily"
                                fontSize="19px"
                                fontWeight="600"
                                lineHeight="34px"
                                letterSpacing="-0.01em"
                                color="#11250E"
                            >
                                <Text textAlign="center">
                                    Congratulations,
                                    <Text as="span" color="#57BA47">
                                        Device {device?.name}
                                    </Text>{" "}
                                    has been managed to your{" "}
                                    <Text as="span" color="#57BA47">
                                        Room {rooms?.data?.find((item) => item?.id === device?.room)?.name}.
                                    </Text>
                                </Text>
                            </Box>

                            <Image src={group} alt="Placeholder Image" w={{ lg: "140px" }} h={{ lg: "100px" }} mt={"2rem"} mb="2rem" />
                        </Grid>
                    </Box>
                </DrawerBody>

                <DrawerFooter>
                    <CustomButton
                        width="100%"
                        h="40px"
                        bg="#57BA47"
                        sx={{
                            _hover: { bg: "#57BA47" },
                            fontFamily: "fontFamily",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#FFFFFF",
                        }}
                        marginInline="auto"
                        onClick={clearQuery}
                    >
                        Done
                    </CustomButton>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};

export default Step2ManageDevice;
