import React from "react";
import { VStack, Text, Image, GridItem, Grid, Box } from "@chakra-ui/react";
import CustomButton from "../components/CustomButton";
import userIcon from "../assets/user.png";
import downArrow from "../assets/arrow-down.svg";
import Calculator from "../assets/calculator.svg";
import logo from "../assets/logo.svg";
import Graph from "../assets/Graph.svg";
import { useDispatch } from "react-redux";
import { Toggle_Step7Function } from "../redux/action";
import { Reveal } from "../common/Reveal";
import { useNavigate } from "react-router-dom";

export const Onboarding7 = ({ yourname, designation, roomId, space, room }) => {
    const dispatch = useDispatch();
    const handlePrevpage = () => {
        dispatch(Toggle_Step7Function());
    };
    const navigate = useNavigate();

    const ButtonTextStyle1 = {
        bg: "#FFF",
        border: "1px solid",
        borderColor: "#57BA47",
        p: "8px 24px 8px 24px",
        _hover: {
            bg: "#FFF",
        },
        color: "#57BA47",
        fontFamily: "fontFamily",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };

    const ButtonTextStyle2 = {
        bg: "#FFFFFF",
        p: "8px 24px 8px 24px",
        _hover: { bg: "#FFFFFF" },
        color: "#11250E",
        fontFamily: "fontFamily",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };

    const ButtonTextStyle3 = {
        p: "8px 24px 8px 24px",
        bg: "#57BA47",
        _hover: {
            bg: "#57BA47",
        },
        color: "#FFF",
        fontFamily: "fontFamily",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };

    const handleGettingStarted = () => {
        navigate("/setupdevice", { state: { roomId: roomId } });
    };
    function skipToDashboard() {
        window.location.href = "/dashboard";
    }

    return (
        <>
            <Grid bg="#F2F2F266" w="100%" h="100vh" gridTemplateColumns="repeat(2,1fr)">
                <VStack display="flex" flexDirection="column" h="100vh">
                    <Box w="80%" display="flex" flexDirection="column" h="75vh" gap="1.5rem" mt="4rem" ml="2rem">
                        <VStack align="left">
                            <Box alignItems="left">
                                <Image alignItems="left" src={Calculator} alt="cube" w="56px" h="56px" />
                            </Box>
                            <VStack display="flex" align="left">
                                <Text
                                    fontFamily="fontFamily"
                                    fontSize="32px"
                                    fontWeight="700"
                                    lineHeight="40px"
                                    letterSpacing="-0.01em"
                                    textAlign="left"
                                >
                                    Setup your device in the room
                                </Text>
                                <Text
                                    color="#818681"
                                    fontFamily="fontFamily"
                                    fontSize="14px"
                                    fontWeight="500"
                                    lineHeight="20px"
                                    letterSpacing="-0.01em"
                                    textAlign="left"
                                >
                                    Your room is now added. Let’s continue by adding your first device to complete the onboarding process.
                                </Text>
                            </VStack>
                        </VStack>
                        <Box w="100%" h="46px" padding="8px 16px 8px 16px" borderRadius="4px 4px 4px 4px" bg="rgba(221, 241, 218, 1)">
                            <Text
                                fontFamily="fontFamily"
                                fontSize="16px"
                                fontWeight="400"
                                lineHeight="30px"
                                letterSpacing="-0.01em"
                                color="rgba(17, 37, 14, 1)"
                            >
                                Setting up this device in{" "}
                                <Text
                                    as="span"
                                    fontFamily="fontFamily"
                                    fontSize="16px"
                                    fontWeight="600"
                                    lineHeight="30px"
                                    letterSpacing="-0.01em"
                                    color="rgba(17, 37, 14, 1)"
                                >
                                    {room ? room : "Kitchen 01"}
                                </Text>
                            </Text>
                        </Box>

                        <Box display="flex" justifyContent={"space-between"} w="100%" mt="4rem">
                            <CustomButton w="13.9%" sx={ButtonTextStyle1} onClick={handlePrevpage}>
                                Previous
                            </CustomButton>

                            <Box display="flex" justifyContent="space-between" w="35%">
                                <CustomButton w="35%" sx={ButtonTextStyle2} onClick={skipToDashboard}>
                                    Skip
                                </CustomButton>

                                <CustomButton w="55%" sx={ButtonTextStyle3} onClick={handleGettingStarted}>
                                    Set Up
                                </CustomButton>
                            </Box>
                        </Box>
                    </Box>
                </VStack>
                <VStack display="flex" alignItems="start">
                    <Grid
                        w="80%"
                        h="40vh"
                        ml="3rem"
                        borderRadius="16px 16px 0px 0px"
                        background="#F2F2F2"
                        pt="2rem"
                        pl="2rem"
                        pr="2rem"
                        gridTemplateColumns="30% auto"
                        mt="8rem"
                    >
                        <GridItem background="#11250E" h="35.5vh" borderRadius="8px 0px 0px 0px" align="center">
                            <Image src={logo} alt="" w="19px" h="15px" mt="10%" />
                            <Box
                                borderRadius="50%"
                                overflow="hidden"
                                width="50px"
                                height="50px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                background="#F2F2F2"
                                mt="15%"
                            >
                                <Image src={userIcon} alt="useIcon" />
                            </Box>
                            <Text
                                mt="0.5rem"
                                color="rgba(251, 251, 251, 1)"
                                fontFamily="fontFamily"
                                fontSize="12px"
                                fontWeight="600"
                                lineHeight="16px"
                                letterSpacing="-0.01em"
                            >
                                {yourname}
                            </Text>
                            <Text
                                color="rgba(242, 242, 242, 0.50)"
                                fontFamily="fontFamily"
                                fontSize="10px"
                                fontWeight="600"
                                lineHeight="16px"
                                letterSpacing="-0.01em"
                            >
                                {designation}
                            </Text>
                            <Box
                                border="1px solid"
                                borderColor="#FBFBFB"
                                w="90%"
                                h="24px"
                                mt="1rem"
                                borderRadius="4px 4px 4px 4px"
                                padding="4px 10px 4px 10px"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Text color="#FBFBFB" fontFamily="fontFamily" fontSize="10px" fontStyle="normal" fontWeight="600" lineHeight="16px">
                                    {space ? `${space}` : "Your space"}
                                </Text>
                                <Image src={downArrow} alt="arrow" />
                            </Box>
                        </GridItem>
                        <GridItem borderRadius="0px 8px 0px 0px" bg="#FFFFFF" h="35.7vh">
                            <Box bg="#F8FBEE" h="7vh" display="flex" alignItems="center">
                                <Text
                                    ml="2rem"
                                    color="#11250E"
                                    fontFamily="fontFamily"
                                    fontSize="12px"
                                    fontWeight="600"
                                    lineHeight="16px"
                                    letterSpacing="-0.01em"
                                    textAlign="left"
                                >
                                    {space ? `${space}` : "Your space"}
                                </Text>
                            </Box>

                            <Grid gridTemplateColumns="100%" w="100%" h="79.6%" display="flex" justifyContent="center" alignItems="center">
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    borderRadius="8px 8px 0px 0px"
                                    w="90%"
                                    h="90%"
                                    mt="1.8rem"
                                    bg="#F2F2F2"
                                >
                                    <Reveal hidden={{ opacity: 0, y: -60 }} visible={{ opacity: 1, y: 0 }} delay={0.1}>
                                        <Image src={Graph} alt=" " w="90%" h="60%" mt="0.5rem" />
                                    </Reveal>
                                </Box>
                            </Grid>

                            <Box
                                position="relative"
                                top="-63%"
                                left="13%"
                                color="#11250E"
                                fontFamily="fontFamily"
                                fontSize="12px"
                                fontWeight="600"
                                lineHeight="16px"
                                letterSpacing="-0.01em"
                                textAlign="left"
                            >
                                <Text>Sensor 01</Text>
                            </Box>
                        </GridItem>
                    </Grid>
                </VStack>
            </Grid>
        </>
    );
};
