import { withDefault } from "use-query-params";
import { SEARCH_PARAMS } from "../constants";

export const MEMBER_QUERY_PARAMS = {
    [SEARCH_PARAMS.MEMBER.ADD.key]: withDefault(SEARCH_PARAMS.MEMBER.ADD.type, SEARCH_PARAMS.MEMBER.ADD.default_value),
    [SEARCH_PARAMS.MEMBER.ADD_STATUS.key]: withDefault(SEARCH_PARAMS.MEMBER.ADD_STATUS.type, SEARCH_PARAMS.MEMBER.ADD_STATUS.default_value),
    [SEARCH_PARAMS.MEMBER.EDIT.key]: withDefault(SEARCH_PARAMS.MEMBER.EDIT.type, SEARCH_PARAMS.MEMBER.EDIT.default_value),
    [SEARCH_PARAMS.MEMBER.EDIT_STATUS.key]: withDefault(SEARCH_PARAMS.MEMBER.EDIT_STATUS.type, SEARCH_PARAMS.MEMBER.EDIT_STATUS.default_value),
    [SEARCH_PARAMS.MEMBER.DELETE.key]: withDefault(SEARCH_PARAMS.MEMBER.DELETE.type, SEARCH_PARAMS.MEMBER.DELETE.default_value),
    [SEARCH_PARAMS.MEMBER.DELETE_STATUS.key]: withDefault(SEARCH_PARAMS.MEMBER.DELETE_STATUS.type, SEARCH_PARAMS.MEMBER.DELETE_STATUS.default_value),
};

export const ROOM_QUERY_PARAMS = {
    [SEARCH_PARAMS.ROOM.ADD.key]: withDefault(SEARCH_PARAMS.ROOM.ADD.type, SEARCH_PARAMS.ROOM.ADD.default_value),
    [SEARCH_PARAMS.ROOM.ADD_STATUS.key]: withDefault(SEARCH_PARAMS.ROOM.ADD_STATUS.type, SEARCH_PARAMS.ROOM.ADD_STATUS.default_value),
    [SEARCH_PARAMS.ROOM.EDIT.key]: withDefault(SEARCH_PARAMS.ROOM.EDIT.type, SEARCH_PARAMS.ROOM.EDIT.default_value),
    [SEARCH_PARAMS.ROOM.EDIT_STATUS.key]: withDefault(SEARCH_PARAMS.ROOM.EDIT_STATUS.type, SEARCH_PARAMS.ROOM.EDIT_STATUS.default_value),
    [SEARCH_PARAMS.ROOM.DELETE.key]: withDefault(SEARCH_PARAMS.ROOM.DELETE.type, SEARCH_PARAMS.ROOM.DELETE.default_value),
    [SEARCH_PARAMS.ROOM.DELETE_STATUS.key]: withDefault(SEARCH_PARAMS.ROOM.DELETE_STATUS.type, SEARCH_PARAMS.ROOM.DELETE_STATUS.default_value),
};

export const DEVICE_QUERY_PARAMS = {
    [SEARCH_PARAMS.DEVICE.ADD.key]: withDefault(SEARCH_PARAMS.DEVICE.ADD.type, SEARCH_PARAMS.DEVICE.ADD.default_value),
    [SEARCH_PARAMS.DEVICE.ADD_STATUS.key]: withDefault(SEARCH_PARAMS.DEVICE.ADD_STATUS.type, SEARCH_PARAMS.DEVICE.ADD_STATUS.default_value),
    [SEARCH_PARAMS.DEVICE.EDIT.key]: withDefault(SEARCH_PARAMS.DEVICE.EDIT.type, SEARCH_PARAMS.DEVICE.EDIT.default_value),
    [SEARCH_PARAMS.DEVICE.EDIT_STATUS.key]: withDefault(SEARCH_PARAMS.DEVICE.EDIT_STATUS.type, SEARCH_PARAMS.DEVICE.EDIT_STATUS.default_value),
    [SEARCH_PARAMS.DEVICE.DELETE.key]: withDefault(SEARCH_PARAMS.DEVICE.DELETE.type, SEARCH_PARAMS.DEVICE.DELETE.default_value),
    [SEARCH_PARAMS.DEVICE.DELETE_STATUS.key]: withDefault(SEARCH_PARAMS.DEVICE.DELETE_STATUS.type, SEARCH_PARAMS.DEVICE.DELETE_STATUS.default_value),
    [SEARCH_PARAMS.DEVICE.MANAGE.key]: withDefault(SEARCH_PARAMS.DEVICE.MANAGE.type, SEARCH_PARAMS.DEVICE.MANAGE.default_value),
    [SEARCH_PARAMS.DEVICE.MANAGE_STATUS.key]: withDefault(SEARCH_PARAMS.DEVICE.MANAGE_STATUS.type, SEARCH_PARAMS.DEVICE.MANAGE_STATUS.default_value),
};
