import React, { useState } from "react";
import { Box, Button, CircularProgress, Image, Input, InputGroup, InputLeftElement, Stack, Text, useDisclosure, VStack } from "@chakra-ui/react";
import logo from "../assets/logo.svg";
import PasswordIcon from "../assets/lock.svg";
import { useParams } from "react-router-dom";
import axios from "axios";
import PasswordResetSuccess from "../components/passwordResetSuccess";

const PasswordSaveScreen = () => {
    const { token } = useParams();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const { isOpen: passwordSaveIsOpen, onOpen: passwordSaveOnOpen, onClose: passwordSaveOnClose } = useDisclosure();

    const handleSubmit = async () => {
        setLoading(true);
        setError("");

        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(
                `/auth/password-reset/confirm`,
                { password },
                {
                    headers: { Authorization: `Bearer ${token}` },
                },
            );

            if (response.status === 200) {
                passwordSaveOnOpen();
            } else {
                setError(response.data?.error || "Password save failed. Please try again.");
            }
        } catch (err) {
            console.error(err);
            setError(err.response?.data?.error || "An unexpected error occurred.");
        } finally {
            setLoading(false);
        }
    };

    const inputStyles = {
        borderRadius: "4px",
        border: "1px solid #818681",
        background: "#FFF",
        _placeholder: {
            color: "#11250E",
            fontFamily: "fontFamily",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "20px",
            letterSpacing: "-0.14px",
        },
        _hover: {
            border: "1px solid #818681",
            background: "#FFF",
        },
        _focus: {
            border: "1px solid #818681",
            background: "#FFF",
        },
    };

    const labelStyles = {
        color: "#11250E",
        fontFamily: "fontFamily",
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "30px",
        letterSpacing: "-0.16px",
    };

    return (
        <>
            <VStack
                width={{ base: "80%", sm: "80%", md: "40%", lg: "40%" }}
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
            >
                <Box w="100%" mb="20px" textAlign="center">
                    <Image src={logo} alt="logo" />
                    <Text
                        w="100%"
                        sx={{
                            color: "#11250E",
                            fontFamily: "fontFamily",
                            fontSize: "28px",
                            fontWeight: "700",
                            lineHeight: "42px",
                        }}
                    >
                        Reset Password
                    </Text>
                </Box>
                <Stack w="100%" spacing={4}>
                    <Box w="100%">
                        <Text sx={labelStyles}>Password</Text>
                        <InputGroup size="sm" w="100%">
                            <InputLeftElement>
                                <Image src={PasswordIcon} alt="password icon" />
                            </InputLeftElement>
                            <Input
                                placeholder="*******"
                                sx={inputStyles}
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                        </InputGroup>
                    </Box>

                    <Box w="100%">
                        <Text sx={labelStyles}>Confirm Password</Text>
                        <InputGroup size="sm" w="100%">
                            <InputLeftElement>
                                <Image src={PasswordIcon} alt="password icon" />
                            </InputLeftElement>
                            <Input
                                placeholder="*******"
                                sx={inputStyles}
                                type="password"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                value={confirmPassword}
                            />
                        </InputGroup>
                    </Box>

                    {error && (
                        <Text color="red.500" display="flex" justifyContent="center">
                            {error}
                        </Text>
                    )}

                    <Button
                        isLoading={loading}
                        onClick={handleSubmit}
                        sx={{
                            _hover: { bg: "#57BA47" },
                            fontFamily: "fontFamily",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#FFFFFF",
                            bg: "#57BA47",
                        }}
                    >
                        {loading ? <CircularProgress isIndeterminate color="#FFFFFF" /> : "Save Password"}
                    </Button>
                </Stack>
            </VStack>
            <PasswordResetSuccess passwordSaveIsOpen={passwordSaveIsOpen} passwordSaveOnClose={passwordSaveOnClose} />
        </>
    );
};

export default PasswordSaveScreen;
