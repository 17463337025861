import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Image,
    Input,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import CustomButton from "./CustomButton";

const CustomChartFilter = ({ startDate, endDate, setStartDate, setEndDate, isOpen, onClose }) => {
    const formik = useFormik({
        initialValues: {
            startDateTime: startDate || "",
            endDateTime: endDate || "",
        },
        validationSchema: Yup.object({
            startDateTime: Yup.date().when("snoozeCustom", {
                is: true,
                then: () => Yup.date().required("Start date is required"),
                otherwise: () => Yup.date().nullable(),
            }),
            endDateTime: Yup.date().when("snoozeCustom", {
                is: true,
                then: () => Yup.date().required("End date is required").min(Yup.ref("startDateTime"), "End date must be after start date"),
                otherwise: () => Yup.date().nullable(),
            }),
        }),
        onSubmit: (values) => {
            setStartDate(values.startDateTime);
            setEndDate(values.endDateTime);
            onClose();
        },
        enableReinitialize: true,
    });

    function reset() {
        setStartDate(null);
        setEndDate(null);
    }

    return (
        <>
            <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <Box mt={{ base: "0px", md: "5px", lg: "10px" }} mr="10px">
                        <DrawerCloseButton sx={{ color: "#11250E", p: "8px", bg: "#F6F6F6" }} />
                        <DrawerHeader
                            fontFamily="fontFamily"
                            fontSize="28px"
                            fontWeight="800"
                            lineHeight="42px"
                            letterSpacing="-0.04em"
                            textAlign="left"
                            color="#11250E"
                        >
                            Custom Range
                        </DrawerHeader>
                    </Box>

                    <DrawerBody p={0} m={0} align="center" position="relative">
                        <Box mb={4} p={5} bg="#F8F8F8">
                            <Text
                                fontFamily="fontFamily"
                                align="left"
                                color="#818681"
                                letterSpacing="-0.01em"
                                fontSize="14px"
                                fontWeight="800"
                                alignItems="left"
                            >
                                Select a custom date range to see the logs.
                            </Text>
                        </Box>

                        <Box m={4}>
                            <HStack spacing="2" justify="space-between" width="100%">
                                <VStack align="flex-start" spacing={0} width="100%">
                                    <FormControl isInvalid={formik.errors.startDateTime && formik.touched.startDateTime} mt={4}>
                                        <FormLabel htmlFor="startDate">Start Date & Time</FormLabel>
                                        <Input
                                            id="startDate"
                                            type="datetime-local"
                                            value={formik.values.startDateTime}
                                            onChange={(e) => formik.setFieldValue("startDateTime", e.target.value)}
                                        />
                                        <FormErrorMessage>{formik.errors.startDateTime}</FormErrorMessage>
                                    </FormControl>
                                </VStack>
                            </HStack>
                        </Box>

                        <Box m={4}>
                            <HStack spacing="2" justify="space-between" width="100%">
                                <VStack align="flex-start" spacing={0} width="100%">
                                    <FormControl isInvalid={formik.errors.endDateTime && formik.touched.endDateTime} mt={4}>
                                        <FormLabel htmlFor="endDate">End Date & Time</FormLabel>
                                        <Input
                                            id="endDate"
                                            type="datetime-local"
                                            value={formik.values.endDateTime}
                                            onChange={(e) => formik.setFieldValue("endDateTime", e.target.value)}
                                        />
                                        <FormErrorMessage>{formik.errors.endDateTime}</FormErrorMessage>
                                    </FormControl>
                                </VStack>
                            </HStack>
                        </Box>
                    </DrawerBody>

                    <DrawerFooter>
                        <Flex direction="column" justifyContent="center" gap={2} width="100%">
                            <CustomButton
                                width="100%"
                                h="40px"
                                bg="#EA5D5D"
                                sx={{
                                    _hover: { color: "#FFFFFF" },
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                }}
                                onClick={reset}
                                disabled={!formik.values.startDateTime && !formik.values.endDateTime}
                            >
                                Clear Range
                            </CustomButton>
                            <CustomButton
                                width="100%"
                                h="40px"
                                bg="#57BA47"
                                sx={{
                                    _hover: { bg: "#57BA47" },
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                }}
                                onClick={formik.handleSubmit}
                                disabled={!formik.isValid}
                            >
                                Search Logs
                            </CustomButton>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default CustomChartFilter;
