import React from "react";
import { Box, HStack, Image, Text } from "@chakra-ui/react";
import CustomButton from "../components/CustomButton";
import logo from "../assets/logo.svg";
import Premium from "../assets/Premium.svg";
import bell from "../assets/bell.svg";
import { useSelector } from "react-redux";

export const TopBar = () => {
    const Step4 = useSelector((store) => store.ConditionalRenderingReducer.step4);
    const Step9 = useSelector((store) => store.ConditionalRenderingReducer.step9);
    //     border='2px solid' borderColor='red.500'
    return (
        <>
            {!Step9 ? (
                <HStack
                    w={{ base: "", sm: "", md: "", lg: "100%", xl: "100%", "2xl": "100%" }}
                    h={{ base: "0", sm: "0", md: "110px", lg: "110px", xl: "110px", "2xl": "110px" }}
                    bg="#F8FBEE"
                    justifyContent="space-between"
                    pl="56px"
                    pr="56px"
                >
                    <HStack
                        justifyContent="space-between"
                        w={{ base: "", sm: "", md: "", lg: "93%", xl: "93%" }}
                        ml="3rem"
                        h={{ base: "0", sm: "0", md: "110px", lg: "110px", xl: "110px" }}
                    >
                        <Image src={logo} alt="" w="51px" h="41px" />
                        <Box display="flex" align="center" justifyContent="space-between">
                            {" "}
                            {Step4 ? (
                                <>
                                    <Image src={Premium} alt="" />
                                    <CustomButton
                                        border="1px solid"
                                        fontFamily="fontFamily"
                                        fontSize="12px"
                                        fontWeight="500"
                                        lineheight="16px"
                                        letterSpacing="-0.01em"
                                        b
                                        borderColor="rgba(87, 186, 71, 1)"
                                        bg="#F8FBEE"
                                        _hover={{ bg: "#F8FBEE" }}
                                        color="rgba(30, 146, 12, 1)"
                                        padding="0px, 16px, 0px, 16px"
                                        w="58px"
                                        h="24px"
                                    >
                                        Help
                                    </CustomButton>
                                </>
                            ) : (
                                <CustomButton
                                    border="1px solid"
                                    fontFamily="fontFamily"
                                    fontSize="12px"
                                    fontWeight="500"
                                    lineheight="16px"
                                    letterSpacing="-0.01em"
                                    b
                                    borderColor="rgba(87, 186, 71, 1)"
                                    bg="#F8FBEE"
                                    _hover={{ bg: "#F8FBEE" }}
                                    color="rgba(30, 146, 12, 1)"
                                    padding="0px, 16px, 0px, 16px"
                                    w="58px"
                                    h="24px"
                                >
                                    Help
                                </CustomButton>
                            )}
                        </Box>
                    </HStack>
                </HStack>
            ) : (
                <HStack
                    w={{ base: "", sm: "", md: "75%", lg: "75%", xl: "75%" }}
                    h={{ base: "0", sm: "0", md: "110px", lg: "110px", xl: "110px" }}
                    bg="#F8FBEE"
                    justifyContent="space-between"
                    position="fixed"
                    Top="0px"
                    Bottom="0px"
                    zIndex="1000"
                    boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
                >
                    <HStack
                        justifyContent="space-between"
                        w={{ base: "", sm: "", md: "", lg: "92%", xl: "92%" }}
                        ml="3rem"
                        h={{ base: "0", sm: "0", md: "110px", lg: "110px", xl: "110px" }}
                    >
                        <Text
                            fontFamily="fontFamily"
                            fontSize="28px"
                            fontWeight="700"
                            lineHeight="42px"
                            letterSpacing="-0.01em"
                            textAlign="left"
                            color="rgba(17, 37, 14, 1)"
                        >
                            My Space 01 / Overview
                        </Text>
                        <Image src={bell} alt="" w="51px" h="41px" />
                    </HStack>
                </HStack>
            )}
        </>
    );
};
