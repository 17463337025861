import { HStack } from '@chakra-ui/react';
import React from 'react';
import SideScreen from './SideScreen';
import ResetPasswordScreen from './resetPasswordScreen';

const ResetScreen = () => {
  return (
    <HStack
      m={0}
      p={0}
      w={{ base: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }}
    >
      <SideScreen />
      <ResetPasswordScreen />
    </HStack>
  );
};
export default ResetScreen;
