import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    FormLabel,
    Input,
    Text,
    FormErrorMessage,
    FormControl,
    Image,
} from "@chakra-ui/react";
import React from "react";
import CustomButton from "../components/CustomButton";
import plusIcon from "../assets/Plus_Icon.png";
import Step2EditDetail from "./Step2EditDetail";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import Axios from "axios";

const ButtonTextStyle = {
    _hover: {
        bg: "#57BA47",
    },
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    color: "#FFFFFF",
};
const labelStyle = {
    fontFamily: "fontFamily",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "-0.01em",
    marginBottom: "4px",
    color: "#11250E",
};
const inputStyle = {
    borderRadius: "4px",
    border: "1px solid #81868180",
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    color: "#000000",
    _focus: {
        border: "1.5px solid #57BA47",
        boxShadow: "none",
    },
    "::placeholder": {
        color: "#818681",
        opacity: 0.4,
    },
};

const EditPassword = ({
    passwordIsOpen,
    passwordOnClose,
    step2passwordIsOpen,
    step2passwordOnClose,
    step2passwordOnOpen,
    prevDetail,
    userProfileQuery,
}) => {
    const validation = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            password: Yup.string().min(6, "Password must be at least 6 characters.").required("Password is required."),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwords must match.")
                .required("Confirm Password is required."),
        }),
        onSubmit: function (values) {
            editPasswordMutation.mutate({ password: values.password });
        },
        enableReinitialize: true,
    });

    const editPasswordMutation = useMutation({
        mutationFn: async function ({ password }) {
            const response = await Axios.post(`/auth/password-reset/confirm`, { password });
            return response;
        },
        onSuccess: function (response) {
            validation.resetForm();
            passwordOnClose();
            step2passwordOnOpen();
            userProfileQuery.refetch();
        },
        onError: function (error) {
            // Handle error appropriately
            console.error("Error updating password:", error);
        },
    });

    return (
        <>
            <Drawer isOpen={passwordIsOpen} placement="right" onClose={passwordOnClose} overflowY="auto" size="sm">
                <DrawerOverlay />

                <DrawerContent borderRadius="10px" m="12px" minWidth="395px">
                    <DrawerCloseButton mt="35px" bg="#F6F6F6" color="#11250E" h="1.2rem" sx={{ mr: "1rem", pt: "1rem", pb: "1rem" }} />
                    <DrawerHeader mt="22px" fontFamily="fontFamily" fontWeight="700" fontSize="24px" lineHeight="36px" letterSpacing="-0.01em">
                        Edit Password
                    </DrawerHeader>

                    <DrawerBody overflowY="auto">
                        <Box mb="27px">
                            <FormControl isInvalid={validation.errors.password && validation.touched.password} isRequired>
                                <FormLabel htmlFor="password" sx={labelStyle}>
                                    New Password
                                </FormLabel>
                                <Input
                                    id="password"
                                    placeholder="********"
                                    type="password"
                                    sx={inputStyle}
                                    name="password"
                                    value={validation.values.password}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.password}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb="27px">
                            <FormControl isInvalid={validation.errors.confirmPassword && validation.touched.confirmPassword} isRequired>
                                <FormLabel htmlFor="confirmPassword" sx={labelStyle}>
                                    Confirm Password
                                </FormLabel>
                                <Input
                                    id="confirmPassword"
                                    placeholder="********"
                                    type="password"
                                    sx={inputStyle}
                                    name="confirmPassword"
                                    value={validation.values.confirmPassword}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.confirmPassword}</FormErrorMessage>
                            </FormControl>
                        </Box>
                    </DrawerBody>

                    <DrawerFooter display="flex" flexDirection="column">
                        <CustomButton
                            mt="1rem"
                            w="100%"
                            h="2.5rem"
                            bg="#57BA47"
                            sx={ButtonTextStyle}
                            onClick={validation.handleSubmit}
                            disabled={!validation.isValid || editPasswordMutation.isPending}
                        >
                            {editPasswordMutation.isLoading ? (
                                <Text>Updating...</Text>
                            ) : (
                                <>
                                    <Image src={plusIcon} alt="Edit password" border="1.2px" h="16px" w="16px" color="#FFFFFF" mr="3px" />
                                    Edit Password
                                </>
                            )}
                        </CustomButton>
                        <Text
                            fontFamily="fontFamily"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="16px"
                            letterSpacing="-0.01em"
                            color="#818681"
                            mt="0.5rem"
                        >
                            NOTE: Fields marked with * are compulsory.
                        </Text>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <Step2EditDetail step2DetailIsOpen={step2passwordIsOpen} step2DetailOnClose={step2passwordOnClose} prevDetail={prevDetail} />
        </>
    );
};

export default EditPassword;
