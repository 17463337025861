import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Image,
    Input,
    Select,
    Text,
    Link,
    Grid,
    GridItem,
    useDisclosure,
    Spinner,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import add_device_1 from "../assets/add_device_1.svg";
import add_device_2 from "../assets/add_device_2.svg";

import CustomButton from "./CustomButton";
import plusIcon from "../assets/Plus_Icon.png";
import addDeviceWifiConnect from "../assets/addDeviceWifiConnect.svg";
import Step2AddDevice from "./step2AddDevice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SEARCH_PARAMS } from "../constants";
import { DEVICE_QUERY_PARAMS } from "../lib/queriesParams";
import { useQueryParams } from "use-query-params";
import connectionLoder from "../../src/assets/connectivity_loder.svg";
import { Step3AddDevice } from "./Step3AddDevice";
const inputStyle = {
    borderRadius: "4px",
    border: "1px solid #81868180",
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    color: "#000000",
    _focus: {
        border: "1.5px solid #57BA47",
        boxShadow: "none",
    },
    "::placeholder": {
        color: "#818681",
        opacity: 0.4,
    },
};

const labelStyle = {
    fontFamily: "fontFamily",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "-0.01em",
    marginBottom: "4px",
    color: "#11250E",
};

const secondStyle = {
    color: "#000000",
    fontFamily: "fontFamily",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "15px",
    letterSpacing: "0.1%",
};

const AddDevice = () => {
    const queryClient = useQueryClient();
    const { space, room } = useParams();
    const [deviceId, setDeviceId] = useState(null);
    const [query, setQuery] = useQueryParams(DEVICE_QUERY_PARAMS);
    const isOpen = query[SEARCH_PARAMS.DEVICE.ADD.key] !== "";
    const [connect, setConnect] = useState(false);
    const [connectionValue, setConnectionValue] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const { isOpen: step2IsOpen, onOpen: step2onOpen, onClose: step2onClose } = useDisclosure();

    const { data: rooms = { data: [] } } = useQuery({
        queryKey: ["fetchRooms", space],
        enabled: false,
    });

    const clearQuery = () => {
        setQuery({
            [SEARCH_PARAMS.DEVICE.ADD.key]: SEARCH_PARAMS.DEVICE.ADD.default_value,
            [SEARCH_PARAMS.DEVICE.ADD_STATUS.key]: SEARCH_PARAMS.DEVICE.ADD_STATUS.default_value,
        });
    };

    const validation = useFormik({
        initialValues: {
            name: "",
            serial_number: "",
            connection_key: "",
            pairing_key: "",
            room: room || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().min(3, "Name is too short.").required("Name is required."),
            serial_number: Yup.string().min(3, "Serial Number is too short.").required("Serial Number is required."),
            connection_key: Yup.string().min(3, "Connection Key is too short.").required("Connection Key is required."),
            // pairing_key: Yup.string().min(3, "Pairing Key is too short.").required("Pairing Key is required."),
            room: Yup.string().required("Room selection is required."),
        }),
        onSubmit: (values) => {
            newDeviceMutation.mutate(values);
        },
        enableReinitialize: true,
    });

    const newDeviceMutation = useMutation({
        mutationFn: async ({ name, room, serial_number, connection_key, pairing_key }) => {
            setIsDisabled(true);
            setLoading(true);
            const response = await axios.post(`/device`, {
                name,
                room,
                serial_number,
                connection_key,
                pairing_key,
            });

            return response;
        },
        onSuccess: (response) => {
            setLoading(false);
            validation.resetForm();
            queryClient.refetchQueries({
                queryKey: ["fetchDevices", space],
                exact: true
            });
            setDeviceId(response?.data?.data?.id);
            setConnectionValue("Connected");
            setConnect(true);

            setTimeout(() => {
                setIsDisabled(true);
                setCountdown(null);
            }, 30000);
            // setQuery({ [SEARCH_PARAMS.DEVICE.ADD.key]: response?.data?.data?.id });
            // setQuery({ [SEARCH_PARAMS.DEVICE.ADD_STATUS.key]: "success" });
        },
        onError: (error) => {
            setLoading(false);
            console.error(error?.response?.data || error);
            setConnectionValue("Disconnected");
            setConnect(false);
            setIsDisabled(true);
            setTimeout(() => {
                setIsDisabled(false);
                setCountdown(null);
                setConnectionValue(null);
            }, 30000);

            setCountdown(30);
            const countdownInterval = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown <= 1) {
                        clearInterval(countdownInterval);
                        setIsDisabled(false);
                        setCountdown(null);
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
            return false;
        },
    });
    const handleNextSetLimit = () => {
        step2onOpen();
        clearQuery();
    };

    return (
        <>
            <Drawer placement="right" isOpen={isOpen} onClose={clearQuery}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <Box mt={{ base: "0px", sm: "0px", md: "5px", lg: "10px" }} mr="10px">
                        <DrawerCloseButton sx={{ color: "#11250E", p: "8px", bg: "#F6F6F6" }} />
                        <DrawerHeader
                            fontFamily="fontFamily"
                            fontSize="28px"
                            fontWeight="800"
                            lineHeight="42px"
                            letterSpacing="-0.04em"
                            textAlign="left"
                            color="#11250E"
                        >
                            Add Device
                        </DrawerHeader>
                    </Box>

                    <DrawerBody>
                        <Box mb={4}>
                            <Text sx={{ fontWeight: "800", fontFamily: "fontFamily", fontSize: "14px", lineHeight: "20px" }}>Step 1:</Text>

                            <Text as="span" sx={{ fontWeight: "500", fontFamily: "fontFamily", fontSize: "14px", color: "#818681" }}>
                                Insert 2 x AA batteries. The instrument will display 'StArt uP' followed by 'nEEd conn'.
                            </Text>
                        </Box>

                        {!room && (
                            <Box mb={4}>
                                <FormControl isInvalid={validation.errors.room && validation.touched.room} isRequired>
                                    <FormLabel htmlFor="room" sx={labelStyle}>
                                        Select Room
                                    </FormLabel>
                                    <Select
                                        id="room"
                                        placeholder="Select room"
                                        sx={inputStyle}
                                        value={validation.values.room}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                    >
                                        {rooms.data.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage>{validation.errors.room}</FormErrorMessage>
                                </FormControl>
                            </Box>
                        )}

                        <Box mb={4}>
                            <FormControl isInvalid={validation.errors.name && validation.touched.name} isRequired>
                                <FormLabel htmlFor="name" sx={labelStyle}>
                                    Device Name
                                </FormLabel>
                                <Input
                                    id="name"
                                    placeholder="ex: Device 01"
                                    type="text"
                                    sx={inputStyle}
                                    value={validation.values.name}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.name}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb={4}>
                            <FormControl isInvalid={validation.errors.serial_number && validation.touched.serial_number} isRequired>
                                <FormLabel htmlFor="serial_number" sx={labelStyle}>
                                    Serial Number
                                </FormLabel>
                                <Input
                                    id="serial_number"
                                    placeholder="ex: Device 01"
                                    sx={inputStyle}
                                    value={validation.values.serial_number}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.serial_number}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb={4}>
                            <FormControl isInvalid={validation.errors.connection_key && validation.touched.connection_key} isRequired>
                                <FormLabel htmlFor="connection_key" sx={labelStyle}>
                                    Connection Key
                                </FormLabel>
                                <Input
                                    id="connection_key"
                                    placeholder="ex: Device 01"
                                    sx={inputStyle}
                                    value={validation.values.connection_key}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.connection_key}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb={4}>
                            <Text sx={{ fontWeight: "800", fontFamily: "fontFamily", fontSize: "14px", lineHeight: "20px" }}>Step 2:</Text>

                            <ul style={{ paddingLeft: "20px" }}>
                                <li>
                                    {" "}
                                    <Text as="span" sx={{ fontWeight: "500", fontFamily: "fontFamily", fontSize: "14px", color: "#818681" }}>
                                        When your device screen shows "Need Connection," open the Wi-Fi settings on your host device, such as a laptop
                                        or mobile phone.
                                    </Text>
                                </li>
                                <li>
                                    {" "}
                                    <Text as="span" sx={{ fontWeight: "500", fontFamily: "fontFamily", fontSize: "14px", color: "#818681" }}>
                                        In the Wi-Fi settings, look for the Wi-Fi SSID that says 'Thermadata {"\u003C"}your device serial number
                                        {"\u003E"}'.
                                    </Text>
                                </li>
                                <li>
                                    {" "}
                                    <Text as="span" sx={{ fontWeight: "500", fontFamily: "fontFamily", fontSize: "14px", color: "#818681" }}>
                                        Select and connect to that Wi-Fi network (You can refer to the screenshot for guidance).
                                    </Text>
                                </li>
                            </ul>
                        </Box>

                        <Box
                            mb={4}
                            border="2px solid #D4D4D4"
                            borderRadius="6px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                        >
                            <Image src={add_device_1} alt="deviceAdd" />
                        </Box>

                        <Box mb={4}>
                            <Text sx={{ fontWeight: "800", fontFamily: "fontFamily", fontSize: "14px", lineHeight: "20px" }}>Step 3:</Text>

                            <ul style={{ paddingLeft: "20px" }}>
                                <li>
                                    <Text as="span" sx={{ fontWeight: "500", fontFamily: "fontFamily", fontSize: "14px", color: "#818681" }}>
                                        After completing the SSID setup from the previous steps.
                                    </Text>
                                </li>
                                <li>
                                    <Text as="span" sx={{ fontWeight: "500", fontFamily: "fontFamily", fontSize: "14px", color: "#818681" }}>
                                        Click here or visit this link:
                                        <Link
                                            href="http://192.168.1.1/"
                                            isExternal
                                            textDecoration="underline"
                                            fontFamily="fontFamily"
                                            color="rgba(84, 137, 236, 1)"
                                        >
                                            {" "}
                                            http://192.168.1.1.
                                        </Link>
                                    </Text>
                                </li>

                                <Box
                                    mb={4}
                                    mt={3}
                                    border="2px solid #D4D4D4"
                                    borderRadius="6px"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="100%"
                                    py={8}
                                >
                                    <Image src={addDeviceWifiConnect} h="100px" alt="deviceAdd" />
                                </Box>

                                <li>
                                    {" "}
                                    <Text as="span" sx={{ fontWeight: "500", fontFamily: "fontFamily", fontSize: "14px", color: "#818681" }}>
                                        A page similar to the one shown in the screenshot will appear.
                                    </Text>
                                </li>
                                <li>
                                    <Text as="span" sx={{ fontWeight: "500", fontFamily: "fontFamily", fontSize: "14px", color: "#818681" }}>
                                        From the list of available Wi-Fi networks, select the Wi-Fi you want to connect your Thermadata device to.
                                    </Text>
                                </li>
                                <li>
                                    <Text as="span" sx={{ fontWeight: "500", fontFamily: "fontFamily", fontSize: "14px", color: "#818681" }}>
                                        Enter your Wi-Fi password in the designated field.
                                    </Text>
                                </li>
                                <li>
                                    <Text as="span" sx={{ fontWeight: "500", fontFamily: "fontFamily", fontSize: "14px", color: "#818681" }}>
                                        Step 6: Finally, click on the "Save Configuration" button to complete the setup.
                                    </Text>
                                </li>
                            </ul>
                        </Box>

                        <Box mb={4}>
                            <Text sx={{ fontWeight: "800", fontFamily: "fontFamily", fontSize: "14px", lineHeight: "20px" }}>Step 4:</Text>

                            <ul style={{ paddingLeft: "20px" }}>
                                <li>
                                    <Text as="span" sx={{ fontWeight: "500", fontFamily: "fontFamily", fontSize: "14px", color: "#818681" }}>
                                        Ensure the symbols are visible on your device as shown in Illustration 1.
                                    </Text>
                                </li>
                                {/* <li>
                                    <Text as="span" sx={{ fontWeight: "500", fontFamily: "fontFamily", fontSize: "14px", color: "#818681" }}>
                                        Press the Start button 5 times in a row.
                                    </Text>
                                </li>

                                <li>
                                    {" "}
                                    <Text as="span" sx={{ fontWeight: "500", fontFamily: "fontFamily", fontSize: "14px", color: "#818681" }}>
                                        Enter the key displayed on your device screen into the input field below.
                                    </Text>
                                </li> */}
                            </ul>
                        </Box>

                        <Box
                            mb={4}
                            border="2px solid #D4D4D4"
                            borderRadius="6px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                            py={8}
                        >
                            <Image src={add_device_2} alt="deviceAdd" />
                        </Box>

                        <Grid w="100%" gridTemplateColumns="repeat(2,1fr)" justifyContent="space-between">
                            <GridItem align="start" gridTemplateColumns="60 40">
                                <Flex gap={3} align="center">
                                    <CustomButton
                                        h="30px"
                                        align="center"
                                        justifyContent="center"
                                        fontFamily="fontFamily"
                                        fontWeight="500"
                                        fontSize="12px"
                                        lineHeight="42px"
                                        color="rgba(0, 0, 0, 1)"
                                        letterSpacing="-0.01%"
                                        bg="none"
                                        disabled={isDisabled}
                                        border="1px solid rgba(173, 173, 173, 1)"
                                        onClick={validation.handleSubmit}
                                    >
                                        Check Connectivity
                                        <Image src={connectionLoder} alt="connectionLoder" h="16px" w="16px" ml="3px" />
                                    </CustomButton>{" "}
                                    {loading && <Spinner color="#FF9B3E" thickness="2px" size="md" />}
                                    {connectionValue === "Disconnected" && countdown !== null && (
                                        <Text w="30%" sx={secondStyle}>
                                            {countdown} sec
                                        </Text>
                                    )}
                                </Flex>
                            </GridItem>
                            <GridItem align="end">
                                <Text
                                    as="span"
                                    h="30px"
                                    fontFamily="fontFamily"
                                    fontWeight="600"
                                    fontSize="16px"
                                    lineHeight="28px"
                                    disabled={isDisabled}
                                    color={!connect ? "rgba(148, 20, 36, 1)" : "rgba(87, 186, 71, 1)"}
                                    letterSpacing="-0.01%"
                                    bg="none"
                                    border="none"
                                    align="end"
                                >
                                    {console.log("Connection Value:", connectionValue)}
                                    {connectionValue}
                                </Text>
                            </GridItem>
                        </Grid>

                        {/* <Box mb={4}>
                                <FormControl isInvalid={validation.errors.pairing_key && validation.touched.pairing_key} isRequired>
                                    <FormLabel htmlFor="pairing_key" sx={labelStyle}>
                                        Enter Pairing Key
                                    </FormLabel>
                                    <Input
                                        id="pairing_key"
                                        placeholder="ex: Device 01"
                                        sx={inputStyle}
                                        value={validation.values.pairing_key}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                    />
                                    <FormErrorMessage>{validation.errors.pairing_key}</FormErrorMessage>
                                </FormControl>
                            </Box> */}
                    </DrawerBody>

                    <DrawerFooter>
                        <Flex direction="column" justifyContent="center" gap={2} width="100%">
                            <CustomButton
                                width="100%"
                                h="40px"
                                bg="#57BA47"
                                sx={{
                                    _hover: { bg: "#57BA47" },
                                    fontFamily: "fontFamily",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                }}
                                onClick={handleNextSetLimit}
                                disabled={!connect}
                            >
                                <Image src={plusIcon} alt="Add Device" h="16px" w="16px" mr="3px" />
                                Add Device
                            </CustomButton>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

            <Step3AddDevice deviceId={deviceId} step2IsOpen={step2IsOpen} step2onClose={step2onClose} />
        </>
    );
};

export default AddDevice;
