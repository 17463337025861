import React, { useState } from "react";
import { Box, VStack, Text, HStack } from "@chakra-ui/react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { DashboardCount } from "../dashboardCount";
import { useParams } from "react-router-dom";
import { DashboardNoDevice } from "../dashboardNoDevice";
import { DashboardDevice } from "../dashboardDevice";
import notification from "../../assets/Notification.svg";
import { Notification } from "../notification";

const fetchDevices = async (space) => {
    const res = await axios.get(`/device`, {
        params: { space_id: space },
    });
    console.log(res?.data);
    return res?.data;
};

const DashboardScreen = () => {
    const { space } = useParams();

    const { data: devices = { data: [] }, ...devicesQuery } = useQuery({
        queryKey: ["fetchDevices", space],
        queryFn: () => fetchDevices(space),
        enabled: !!space,
    });

    console.log(devices);
    const { data: spaces, ...spacesQuery } = useQuery({
        queryKey: "fetchSpaces",
        enabled: false,
        select: function (data) {
            return data?.data?.filter((item) => item.id === space) || [];
        },
    });

    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setDrawerOpen((prev) => !prev);
    };

    return (
        <Box bg="#FFFFFF" margin={0} width="100%" height="100vh" overflow="hidden">
            <HStack w="80%" h="110px" bg="#F8FBEE" justifyContent="space-between" position="fixed" top="0" zIndex="1000" paddingX="3rem">
                <Text
                    fontFamily="fontFamily"
                    fontSize="28px"
                    fontWeight="800"
                    lineHeight="42px"
                    letterSpacing="-0.04em"
                    textAlign="left"
                    color="#11250E"
                >
                    {spaces?.[0]?.name} / Overview
                </Text>

                <img src={notification} alt="notification" width="40px" height="40px" style={{ cursor: "pointer" }} onClick={toggleDrawer} />
                {isDrawerOpen && <Notification />}
            </HStack>

            <VStack marginX="3.5%" marginTop="130px" height="calc(100vh - 130px)" overflowY="auto">
                <DashboardCount space={space} />
                {devices?.data?.length > 0 ? <DashboardDevice devices={devices} /> : <DashboardNoDevice />}
            </VStack>
        </Box>
    );
};

export default DashboardScreen;
