import React, { useState } from "react";
import {
    Flex,
    Text,
    HStack,
    IconButton,
    Box,
    Image,
    VStack,
    Grid,
    GridItem,
    Menu,
    MenuButton,
    Icon,
    MenuList,
    MenuItem,
    Button,
    keyframes,
    Divider,
} from "@chakra-ui/react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import temp from "../../assets/temsvg.svg";
import humidity from "../../assets/drop.svg";
import Battery from "../../assets/fullBattery.svg";
import battery_75_100 from "../../assets/75-100.svg";
import battery_50_75 from "../../assets/50-75.svg";
import battery_25_50 from "../../assets/25-50.svg";
import battery_0_25 from "../../assets/0-25.svg";
import { BsThreeDotsVertical } from "react-icons/bs";

import editIcon from "../../assets/editIcon.svg";
import trash from "../../assets/trash.svg";
import { LuChevronLeft } from "react-icons/lu";
import filter from "../../assets/filter.svg";
import AddDevice from "../AddDevice.jsx";
import EditDevice from "../EditDevice";
import CustomButton from "../../components/CustomButton";
import plusIcon from "../../assets/Plus_Icon.png";
import gridView from "../../assets/Grid.png";
import listView from "../../assets/List.png";
import bellIcon from "../../assets/bell.svg";
import offlineIcon from "../../assets/offlineIcon.png";
import onlineIcon from "../../assets/onlineIcon.png";
import { formatUnitForSensorType } from "../../utils/sensorUnitUtils.js";
import { useQueryParams } from "use-query-params";
import { SEARCH_PARAMS } from "../../constants";
import { DEVICE_QUERY_PARAMS } from "../../lib/queriesParams.jsx";
import DeleteDevice from "../DeleteDevice.jsx";
import ManageDevice from "../manageDevice.jsx";
import { CgSortAz } from "react-icons/cg";
import { isCompanysDecisionMaker } from "../../utils/roleUtils.js";
import useGetQueryData from "../../hooks/useGetQueryData.js";
import fallback from "../../assets/fallback.svg";

const HumidityHumidity = undefined;

const breatheAnimation = keyframes`
  0% { box-shadow: 0 0 0 2px red; }
  50% { box-shadow: 0 0 0 4px red; }
  100% { box-shadow: 0 0 0 2px red; }
`;

const GridView = () => {
    return <Image src={gridView} alt="GridIcon" />;
};

const ListView = () => {
    return <Image src={listView} alt="ListView" />;
};

const BellIcon = () => {
    return <Image src={bellIcon} alt="onlineIcon" w="20px" h="20px" />;
};

const EditIcon = () => {
    return <Image src={editIcon} alt="eidt" />;
};
const DeleteIcon = () => {
    return <Image src={trash} alt="eidt" />;
};

const OnlineIcon = () => {
    return <Image src={onlineIcon} alt="onlineIcon" w="13px" h="13px" />;
};

const OfflineIcon = () => {
    return <Image src={offlineIcon} alt="offlineIcon" w="13px" h="13px" />;
};

const Location = ({ roomName }) => {
    const { space } = useParams();
    const navigate = useNavigate();

    return (
        <Flex w="100%" mt="32px" display="flex" alignItems="center">
            <HStack>
                <LuChevronLeft size={24} onClick={() => navigate(`/dashboard/${space}/rooms`)} cursor="pointer" />
                <Text fontFamily="fontFamily" fontWeight="700" fontSize="28px" letterSpacing="-0.01em">
                    {roomName}
                </Text>
            </HStack>
        </Flex>
    );
};

const fetchDevices = async (room) => {
    const res = await axios.get(`/device`, {
        params: { room_id: room },
    });
    return res?.data;
};

const fetchRooms = async (space) => {
    const res = await axios.get(`/room`, {
        params: { space_id: space },
    });
    return res?.data;
};

const FilterSensors = ({
    view,
    setView,
    filterState,
    setFilterState,
    alphabeticallySorted,
    setAlphabeticallySorted,
    lowBattery,
    setLowBattery,
    setQuery,
}) => {
    const { data: userRole } = useGetQueryData({ queryKey: "fetchProfile", select: "data.company.company_access.company_role" });

    const ButtonTextStyle = {
        _hover: {
            bg: "#57BA47",
        },
        fontFamily: "fontFamily",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
        letterSpacing: "-0.01em",
        color: "#FFFFFF",
    };

    const handleTabClick = (view) => {
        setView(view);
    };

    const handleToggle = () => {
        setLowBattery((prev) => !prev);
    };

    const [hoveredOption, setHoveredOption] = React.useState(false);
    const colorScheme = {
        border: "1.5px solid",
        borderColor: lowBattery || hoveredOption ? "rgba(87, 186, 71, 1)" : "rgba(129, 134, 129, 0.5)",
    };
    const handleAlphabeticalToggle = () => {
        setAlphabeticallySorted((prev) => !prev);
    };

    return (
        <Flex w={{ xl: "94.7%", "2xl": "96%" }} ml="2rem" h="42px" mt="32px" display="flex" justifyContent="space-between" alignItems="center">
            <HStack spacing="3%" w="72%">
                <HStack>
                    <Button
                        borderRadius="4px"
                        justifyContent="space-between"
                        padding={2}
                        width="180px"
                        minWidth="180px"
                        cursor="pointer"
                        color="#11250E"
                        backgroundColor="#FFFFFF"
                        border={colorScheme.border}
                        borderColor={colorScheme.borderColor}
                        onClick={handleToggle}
                        onMouseEnter={() => setHoveredOption(true)}
                        onMouseLeave={() => setHoveredOption(false)}
                    >
                        <Flex justify="center" align="center" width="100%">
                            <Image src={filter} h="13px" w="13px" marginRight="8px" />
                            <Text fontSize="12px" fontWeight="500" fontFamily="fontFamily" lineHeight="16px" letterSpacing="0.01em">
                                Filter: By Low Battery
                            </Text>
                        </Flex>
                    </Button>

                    <Box
                        h="40px"
                        w="238px"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        borderRadius="4px"
                        position="relative"
                        background="#FFFFFF"
                        paddingX="4px"
                        marginLeft={4}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            padding="2px 8px"
                            bgColor={filterState === "all" ? "#e9ffdb" : "transparent"}
                            border={filterState === "all" ? "2px solid #6CC35E" : ""}
                            color={filterState === "all" ? "#11250E" : "#818681"}
                            borderRadius={4}
                            cursor="pointer"
                            sx={{
                                "&::after": {
                                    content: '""',
                                    position: "absolute",
                                    right: "-4",
                                    height: "4vh",
                                    width: "1px",
                                    backgroundColor: "#E0E5E0",
                                },
                            }}
                            onClick={() => {
                                setFilterState("all");
                            }}
                        >
                            <Text fontFamily="fontFamily" fontWeight="600" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#11250E">
                                All
                            </Text>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            padding="2px 8px"
                            gap="1"
                            bgColor={filterState === "active" ? "#e9ffdb" : "transparent"}
                            border={filterState === "active" ? "2px solid #6CC35E" : ""}
                            color={filterState === "active" ? "#11250E" : "#818681"}
                            borderRadius={4}
                            cursor="pointer"
                            onClick={() => {
                                setFilterState("active");
                            }}
                        >
                            <OnlineIcon />
                            <Text fontFamily="fontFamily" fontWeight="600" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#11250E">
                                Online
                            </Text>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            padding="2px 8px"
                            gap="1"
                            bgColor={filterState === "inactive" ? "#e9ffdb" : "transparent"}
                            border={filterState === "inactive" ? "2px solid #6CC35E" : ""}
                            color={filterState === "inactive" ? "#11250E" : "#818681"}
                            borderRadius={4}
                            cursor="pointer"
                            onClick={() => {
                                setFilterState("inactive");
                            }}
                        >
                            <OfflineIcon />
                            <Text fontFamily="fontFamily" fontWeight="600" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#11250E">
                                Offline
                            </Text>
                        </Box>
                    </Box>
                </HStack>

                <Flex p="2" gap={1} w="111px" h="32px" position="relative" bg="#FFFFFF" align="center">
                    <IconButton
                        onClick={() => handleTabClick("gridView")}
                        colorScheme={view === "gridView" ? "#57BA47" : "white"}
                        borderRadius="4px"
                        bg={view === "gridView" ? "#DDF1DA" : ""}
                        border={view === "gridView" ? "1px solid #57BA47" : ""}
                        w={view === "gridView" ? "48px" : "46px"}
                        h={view === "gridView" ? "24px" : "24px"}
                        icon={<GridView />}
                        _focus={{ background: "#DDF1DA", border: "1px solid #57BA47" }}
                    />

                    <IconButton
                        onClick={() => handleTabClick("listView")}
                        colorScheme={view === "listView" ? "#57BA47" : "white"}
                        borderRadius="4px"
                        bg={view === "listView" ? "#DDF1DA" : ""}
                        border={view === "listView" ? "1px solid #57BA47" : ""}
                        w={view === "listView" ? "48px" : "46px"}
                        h={view === "listView" ? "24px" : "24px"}
                        icon={<ListView />}
                        _focus={{ background: "#DDF1DA", border: "1px solid #57BA47" }}
                    />
                </Flex>
                <Box
                    borderRadius="4px"
                    background="#FFFFFF"
                    cursor="pointer"
                    color="#11250E"
                    padding={2}
                    width="180px"
                    minWidth="180px"
                    border={alphabeticallySorted ? "1.5px solid rgba(87, 186, 71, 1)" : "1.5px solid #81868180"}
                    onClick={handleAlphabeticalToggle}
                >
                    <Flex justify="center" align="center" width="100%">
                        <Icon as={CgSortAz} h="21px" w="24px" marginRight="8px" />
                        <Text as="span">Sort: Alphabetical</Text>
                    </Flex>
                </Box>
            </HStack>

            {isCompanysDecisionMaker(userRole) && (
                <HStack>
                    <CustomButton
                        w="192px"
                        h="40px"
                        bg="#57BA47"
                        sx={ButtonTextStyle}
                        aria-label="Add Device"
                        onClick={() => setQuery({ [SEARCH_PARAMS.DEVICE.ADD.key]: "success" })}
                    >
                        <Image src={plusIcon} alt="add" h="16px" w="16px" mr="3px" />
                        Add Device
                    </CustomButton>
                    <AddDevice />
                </HStack>
            )}
        </Flex>
    );
};

const pulseAnimation = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
`;

const RoomsListType = () => {
    const navigate = useNavigate();
    const { space, room, room_name } = useParams();
    const [filterState, setFilterState] = React.useState("all");
    const [lowBattery, setLowBattery] = React.useState(false);
    const [alphabeticallySorted, setAlphabeticallySorted] = useState(false);
    const [query, setQuery] = useQueryParams(DEVICE_QUERY_PARAMS);
    const { data: userRole } = useGetQueryData({ queryKey: "fetchProfile", select: "data.company.company_access.company_role" });

    const { data: roomDevices = { data: [] }, ...roomDevicesQuery } = useQuery({
        queryKey: ["fetchDevices", room],
        queryFn: () => fetchDevices(room),
        enabled: !!room,
    });

    const { data: rooms = { data: [] }, ...roomsQuery } = useQuery({
        queryKey: ["fetchRooms", space],
        queryFn: () => fetchRooms(space),
        enabled: !!space,
    });

    // Fetch notifications for devices in the current room
    const { data: notifications = { data: [] }, refetch: refetchNotifications } = useQuery({
        queryKey: ["fetchNotifications"],
        queryFn: () => axios.get("/notifications").then((res) => res.data),
        enabled: !!room,
    });

    // Compute devices with unread notifications
    const devicesWithUnreadNotifications = React.useMemo(() => {
        if (!roomDevices.data || !notifications.data) {
            return new Set();
        }
        const deviceIdsInRoom = roomDevices.data.map((device) => device.id);
        const notificationsForRoom = notifications.data.filter((notification) => deviceIdsInRoom.includes(notification.device));
        const unreadNotificationsForRoom = notificationsForRoom.filter((notification) => !notification.is_read);
        return new Set(unreadNotificationsForRoom.map((notification) => notification.device));
    }, [roomDevices.data, notifications.data]);

    const filteredDevices = roomDevices?.data
        ?.filter((device) => {
            const isActive = device?.active;
            const isLowBattery = device?.battery < 30;

            const baseCondition = lowBattery ? isLowBattery : true;

            if (filterState === "all") {
                return baseCondition;
            }

            if (filterState === "active") {
                return baseCondition && isActive;
            }

            if (filterState === "inactive") {
                return baseCondition && !isActive;
            }

            return false;
        })
        ?.sort((a, b) => {
            if (alphabeticallySorted) {
                const nameA = a?.name?.toLowerCase() || "";
                const nameB = b?.name?.toLowerCase() || "";
                return nameA.localeCompare(nameB);
            } else {
                const nameA = a?.name?.toLowerCase() || "";
                const nameB = b?.name?.toLowerCase() || "";
                return nameB.localeCompare(nameA);
            }
        });
    const renderMenuItems = (sensor) => {
        const menuItems = [
            {
                label: "Manage Alerts",
                icon: <BellIcon />,
                action: () => {
                    setQuery({ [SEARCH_PARAMS.DEVICE.MANAGE.key]: sensor?.id });
                },
            },
            {
                label: "Manage Device",
                icon: <EditIcon />,
                action: () => {
                    setQuery({ [SEARCH_PARAMS.DEVICE.EDIT.key]: sensor?.id });
                },
            },
            {
                label: "Delete Device",
                icon: <DeleteIcon />,
                action: () => {
                    setQuery({ [SEARCH_PARAMS.DEVICE.DELETE.key]: sensor?.id });
                },
            },
        ];

        return menuItems.map((item, idx) => (
            <MenuItem
                key={idx}
                icon={item.icon}
                onClick={item.action}
                fontFamily="fontFamily"
                fontSize="14px"
                fontWeight="600"
                lineHeight="20px"
                letterSpacing="-0.01em"
                color="rgba(17, 37, 14, 1)"
                bg="rgba(255, 255, 255, 1)"
                _hover={{ bg: "none" }}
            >
                {item.label}
            </MenuItem>
        ));
    };

    const [view, setView] = useState("gridView");

    const getBatteryIcon = (battery) => {
        if (battery >= 75) {
            return battery_75_100;
        } else if (battery >= 50) {
            return battery_50_75;
        } else if (battery >= 25) {
            return battery_25_50;
        } else {
            return battery_0_25;
        }
    };

    return (
        <VStack w="100%" bg="#F9F9F9" minH="100vh" margin={0} padding={0}>
            <VStack w="100%" gap={0}>
                <Box w="100%" paddingLeft="2rem" bg="#F6F6F6">
                    <Location roomName={room_name} />
                </Box>

                <Box w={{ xl: "100%", "2xl": "100%" }} bg="#F6F6F6" pb="1.5rem">
                    <FilterSensors
                        view={view}
                        setView={setView}
                        filterState={filterState}
                        setFilterState={setFilterState}
                        lowBattery={lowBattery}
                        setLowBattery={setLowBattery}
                        setQuery={setQuery}
                        alphabeticallySorted={alphabeticallySorted}
                        setAlphabeticallySorted={setAlphabeticallySorted}
                    />
                </Box>
            </VStack>

            {filteredDevices.length < 1 ? (
                <Box w={{ base: "94%", sm: "94%", md: "94%", lg: "94%", xl: "95%", "2xl": "96%" }}>
                    {/* <DashboardNoDevice marginTop="10px" height={{ lg: "calc(72vh - 10px)", xl: "calc(72vh - 10px)", "2xl": "calc(72vh - 10px)" }} /> */}
                    <Box display="flex" h="100%" flexDir="column" justifyContent="center" alignItems="center">
                        <Image src={fallback} p={10} />
                        <Divider w="25%" borderColor="#DBDBDB" />
                        <Text p={2} fontFamily="fontFamily" fontSize="28px" fontWeight={700} lineHeight="42px" letterSpacing="-1%">
                            No Devices To Show!
                        </Text>
                    </Box>
                </Box>
            ) : view === "gridView" ? (
                <Grid
                    templateColumns={{
                        base: "repeat(2, 1fr)",
                        sm: "repeat(2, 1fr)",
                        md: "repeat(2, 1fr)",
                        lg: "repeat(2, 1fr)",
                        xl: "repeat(3, 1fr)",
                    }}
                    columnGap="0px"
                    rowGap="32px"
                    w={{ xl: "94%", "2xl": "97%" }}
                    mt="24px"
                >
                    {filteredDevices?.map((sensor, index) => (
                        <GridItem
                            w={{ base: "", sm: "", md: "100%", lg: "85%", xl: "85%" }}
                            border={"#81868180"}
                            p="24px"
                            borderRadius="8px"
                            _hover={{ border: "1px solid #81868180", cursor: "pointer" }}
                            sx={{
                                animation: devicesWithUnreadNotifications.has(sensor?.id) ? `${breatheAnimation} 2s infinite ease-in-out` : "none",
                            }}
                            align="stretch"
                            bg="rgba(255, 255, 255, 1)"
                        >
                            <HStack
                                alignItems="center"
                                justifyContent="space-between"
                                borderBottom="2px solid #E0E5E0"
                                borderColor="#E0E5E0"
                                mb="16px"
                                pb="16px"
                            >
                                <Text
                                    fontFamily="fontFamily"
                                    fontSize="16px"
                                    fontWeight="800"
                                    lineHeight="24px"
                                    letterSpacing="-0.02em"
                                    textAlign="start"
                                    color="#11250E"
                                    h="24px"
                                    w="162px"
                                >
                                    {sensor.name}
                                </Text>
                                <HStack
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={3}
                                    w="82px"
                                    h="24px"
                                    border="1px solid rgba(212, 212, 212, 1)"
                                    borderRadius="40px"
                                    bg="#FFFFFF"
                                >
                                    {sensor.active ? <OnlineIcon /> : <OfflineIcon />}
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="12px"
                                        fontWeight="500"
                                        lineHeight="14.52px"
                                        letterSpacing="-0.01em"
                                        textAlign="start"
                                        color="#11250E"
                                    >
                                        {sensor?.active ? "Online" : "Offline"}
                                    </Text>
                                </HStack>
                            </HStack>

                            <HStack marginY={2}>
                                <Text
                                    fontFamily="fontFamily"
                                    fontSize="14px"
                                    fontWeight="500"
                                    lineheight="20px"
                                    letterSpacing="0.01em"
                                    textAlign="start"
                                    color="#818681"
                                >
                                    Last Recorded reading:
                                </Text>
                                <Text
                                    fontFamily="fontFamily"
                                    fontSize="14px"
                                    fontWeight="600"
                                    lineheight="20px"
                                    letterSpacing="-0.01em"
                                    textAlign="start"
                                    color="#11250E"
                                >
                                    {new Date(sensor?.last_recorded).toLocaleTimeString("en-US", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                    })}
                                </Text>
                            </HStack>

                            <HStack mb="24px" justifyContent="space-between" mt="24px" w="95%">
                                <VStack spacing={0}>
                                    <Image
                                        src={sensor?.sensor_one?.sensor_type === HumidityHumidity ? humidity : temp}
                                        alt=""
                                        w="21.31x"
                                        h="21.46px"
                                    />
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="24px"
                                        fontWeight="600"
                                        lineHeight="36px"
                                        letterSpacing="-0.04em"
                                        textAlign="center"
                                        color="#11250E"
                                    >
                                        {formatUnitForSensorType({
                                            sensorType: sensor?.sensor_one?.sensor_type,
                                            value: sensor?.sensor_one?.last_value,
                                        })}
                                    </Text>
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="12px"
                                        fontWeight="500"
                                        lineHeight="16px"
                                        letterSpacing="-0.01em"
                                        textAlign="center"
                                        color="#818681"
                                        p={0}
                                        m={0}
                                        w="100%"
                                    >
                                        Sensor 1
                                    </Text>
                                </VStack>

                                <VStack spacing={0}>
                                    <Image
                                        src={sensor?.sensor_two?.sensor_type === HumidityHumidity ? humidity : temp}
                                        alt=""
                                        w="21.31x"
                                        h="21.46px"
                                    />
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="24px"
                                        fontWeight="600"
                                        lineHeight="36px"
                                        letterSpacing="-0.04em"
                                        textAlign="center"
                                        color="#11250E"
                                    >
                                        {formatUnitForSensorType({
                                            sensorType: sensor?.sensor_two?.sensor_type,
                                            value: sensor?.sensor_two?.last_value,
                                        })}
                                    </Text>
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="12px"
                                        fontWeight="500"
                                        lineHeight="16px"
                                        letterSpacing="-0.01em"
                                        textAlign="center"
                                        color="#818681"
                                        p={0}
                                        m={0}
                                        w="100%"
                                    >
                                        Sensor 2
                                    </Text>
                                </VStack>

                                <VStack spacing={0}>
                                    <Box animation={sensor?.battery < 25 ? `${pulseAnimation} 1.5s infinite` : "none"}>
                                        <Image src={getBatteryIcon(sensor?.battery || 0)} alt="" w="24px" h="24px" />
                                    </Box>
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="24px"
                                        fontWeight="600"
                                        lineHeight="36px"
                                        letterSpacing="-0.04em"
                                        textAlign="center"
                                        color="#11250E"
                                    >
                                        {sensor?.battery}
                                    </Text>
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="12px"
                                        fontWeight="500"
                                        lineHeight="16px"
                                        letterSpacing="-0.01em"
                                        align="center"
                                        color="#818681"
                                        p={0}
                                        m={0}
                                        w="100%"
                                    >
                                        Battery
                                    </Text>
                                </VStack>
                            </HStack>

                            <HStack justifyContent="space-between" mt="24px" w="100%">
                                <Text
                                    fontFamily="fontFamily"
                                    textDecoration="underline"
                                    fontSize="14px"
                                    fontWeight="600"
                                    lineHeight="20px"
                                    letterSpacing="-0.01em"
                                    textAlign="center"
                                    color="#57BA47"
                                    _hover={{ cursor: "pointer", textDecoration: "underline" }}
                                    onClick={() => navigate(`/dashboard/${space}/devices/${sensor?.id}`)}
                                >
                                    <Link textDecoration="underline">View more Details</Link>
                                </Text>
                                {isCompanysDecisionMaker(userRole) && (
                                    <Menu>
                                        <MenuButton
                                            w="36px"
                                            h="36px"
                                            sx={{ _hover: { bg: "rgba(251, 251, 251, 1)" } }}
                                            borderRadius="4px"
                                            display="flex"
                                            align="center"
                                            justifyContent="center"
                                        >
                                            <Icon as={BsThreeDotsVertical} w="20px" h="20px" />
                                        </MenuButton>
                                        <MenuList>{renderMenuItems(sensor)}</MenuList>
                                    </Menu>
                                )}
                            </HStack>
                        </GridItem>
                    ))}
                </Grid>
            ) : (
                <Box w={{ xl: "94%", "2xl": "97%" }} mt="24px" borderRadius="8px">
                    <VStack spacing={4} mb="2%" borderRadius="8px">
                        {filteredDevices?.map((sensor, index) => (
                            <HStack
                                bg="#FFFFFF"
                                key={index}
                                w={{ base: "", sm: "", md: "", lg: "", xl: "100%" }}
                                h={{ base: "", sm: "", md: "", lg: "", xl: "64px" }}
                                borderRadius="8px"
                                sx={{
                                    animation: devicesWithUnreadNotifications.has(sensor?.id)
                                        ? `${breatheAnimation} 2s infinite ease-in-out`
                                        : "none",
                                }}
                                spacing={{ base: "", sm: "", md: "", lg: "", xl: "16px" }}
                                align="stretch"
                            >
                                <Grid
                                    templateColumns="repeat(7, 1fr)"
                                    bg="#FFFFFF"
                                    h={{ base: "", sm: "", md: "", lg: "100%", xl: "100%" }}
                                    w={{ base: "", sm: "", md: "", lg: "", xl: "100%" }}
                                    justifyContent="space-between"
                                    borderRadius="8px"
                                >
                                    <GridItem align="center" justifyContent="center">
                                        <VStack align="left" borderRadius="8px">
                                            <Box
                                                display="flex"
                                                borderRadius="8px"
                                                w={{ base: "", sm: "", md: "", lg: "", xl: "110px" }}
                                                h={{ base: "", sm: "", md: "", lg: "", xl: "60px" }}
                                                bg="#FFFFFF"
                                                alignItems="center"
                                                justifyContent="center"
                                                borderRight="1px solid #DDE2DD"
                                            >
                                                <HStack align="center">
                                                    <Text fontFamily="fontFamily" fontSize="14px" fontWeight="600" lineHeight="24px">
                                                        {sensor.name}
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        </VStack>
                                    </GridItem>
                                    <GridItem display="flex" flexDirection="column" alignItems="start" justifyContent="center">
                                        <VStack h="32px" spacing={0} align="left">
                                            <Text
                                                fontFamily="fontFamily"
                                                fontSize="12px"
                                                fontWeight="700"
                                                lineHeight="16px"
                                                color="#818681"
                                                align="left"
                                            >
                                                Time
                                            </Text>
                                            <HStack alignItems="center">
                                                <Text fontFamily="fontFamily" fontSize="12px" fontWeight="700" lineHeight="16px">
                                                    {new Date(sensor?.last_recorded).toLocaleTimeString("en-US", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                        hour12: true,
                                                    })}
                                                </Text>
                                            </HStack>
                                        </VStack>
                                    </GridItem>
                                    <GridItem display="flex" flexDirection="column" alignItems="start" justifyContent="center">
                                        <VStack h="32px" spacing={0} align="left">
                                            <Text fontFamily="fontFamily" fontSize="12px" fontWeight="700" color="#818681" align="left">
                                                Sensor 1
                                            </Text>
                                            <HStack align="left" alignItems="center" spacing={1}>
                                                <Text fontFamily="fontFamily" fontSize="12px" fontWeight="700" lineHeight="16px">
                                                    {formatUnitForSensorType({
                                                        sensorType: sensor?.sensor_one?.sensor_type,
                                                        value: sensor?.sensor_one?.last_value,
                                                    })}
                                                </Text>
                                                <Image
                                                    src={sensor?.sensor_one?.sensor_type === HumidityHumidity ? humidity : temp}
                                                    alt="sensorTemperature"
                                                    w="10px"
                                                    h="10px"
                                                />
                                            </HStack>
                                        </VStack>
                                    </GridItem>
                                    <GridItem display="flex" flexDirection="column" alignItems="start" justifyContent="center">
                                        <VStack h="32px" spacing={0} align="left">
                                            <Text fontFamily="fontFamily" fontSize="12px" fontWeight="700" color="#818681" align="left">
                                                Sensor 2
                                            </Text>
                                            <HStack align="left" alignItems="center" spacing={1}>
                                                <Text fontFamily="fontFamily" fontSize="12px" fontWeight="700" lineHeight="16px">
                                                    {formatUnitForSensorType({
                                                        sensorType: sensor?.sensor_two?.sensor_type,
                                                        value: sensor?.sensor_two?.last_value,
                                                    })}
                                                </Text>
                                                <Image
                                                    src={sensor?.sensor_two?.sensor_type === HumidityHumidity ? humidity : temp}
                                                    alt="sensorTemperature"
                                                    w="10px"
                                                    h="10px"
                                                />
                                            </HStack>
                                        </VStack>
                                    </GridItem>
                                    <GridItem display="flex" flexDirection="column" alignItems="start" justifyContent="center">
                                        <VStack h="32px" spacing={0} align="left">
                                            <Text
                                                fontFamily="fontFamily"
                                                fontSize="12px"
                                                lineHeight="16px"
                                                fontWeight="700"
                                                color="#818681"
                                                align="left"
                                            >
                                                Status
                                            </Text>
                                            <HStack align="left" alignItems="center" spacing={1}>
                                                <Text fontFamily="fontFamily" fontSize="12px" lineHeight="16px" fontWeight="700">
                                                    {sensor?.active ? "Online" : "Offline"}
                                                </Text>
                                                {sensor.active ? <OnlineIcon /> : <OfflineIcon />}
                                            </HStack>
                                        </VStack>
                                    </GridItem>
                                    <GridItem display="flex" flexDirection="column" alignItems="start" justifyContent="center">
                                        <VStack h="32px" spacing={0} align="left">
                                            <Text fontFamily="fontFamily" fontSize="12px" fontWeight="700" color="#818681" align="left">
                                                Battery Life
                                            </Text>
                                            <HStack alignItems="center" spacing={1}>
                                                <Text fontFamily="fontFamily" fontSize="12px" fontWeight="500">
                                                    {sensor?.battery}
                                                </Text>
                                                <Box animation={sensor?.battery < 25 ? `${pulseAnimation} 1.5s infinite` : "none"}>
                                                    <Image src={getBatteryIcon(sensor?.battery || 0)} alt="" w="20px" h="20px" />
                                                </Box>
                                            </HStack>
                                        </VStack>
                                    </GridItem>
                                    <GridItem display="flex" flexDirection="column" alignItems="start" justifyContent="center">
                                        <Flex
                                            h="32px"
                                            w={{ base: "", sm: "", md: "", lg: "", xl: "150px" }}
                                            gap={{ base: "", sm: "", md: "", lg: "", xl: "27px" }}
                                            align="center"
                                        >
                                            <Text
                                                cursor="pointer"
                                                textDecoration="underline"
                                                fontFamily="fontFamily"
                                                fontSize="14px"
                                                fontWeight="600"
                                                lineHeight="20px"
                                                letterSpacing="0.04em"
                                                textAlign="center"
                                                color="#57BA47"
                                                _hover={{ cursor: "pointer", textDecoration: "underline" }}
                                                onClick={() => navigate(`/dashboard/${space}/devices/${sensor?.id}`)}
                                            >
                                                View Details
                                            </Text>
                                            {isCompanysDecisionMaker(userRole) && (
                                                <Menu>
                                                    <MenuButton>
                                                        <Icon as={BsThreeDotsVertical} w="20px" h="20px" />
                                                    </MenuButton>
                                                    <MenuList>{renderMenuItems(sensor)}</MenuList>
                                                </Menu>
                                            )}
                                        </Flex>
                                    </GridItem>
                                </Grid>
                            </HStack>
                        ))}
                    </VStack>
                </Box>
            )}

            <EditDevice />
            <ManageDevice />
            <DeleteDevice />
        </VStack>
    );
};

export default RoomsListType;
