import { Box, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import LoginGroup from "../assets/LoginGroup.svg";

// border='2px solid' borderColor='gray.300'

const SideScreen = () => {
    const TextStyle = {
        fontFamily: "fontFamily",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.01em",
    };
    return (
        <VStack
            w={{ base: "60%", sm: "60%", md: "60%", lg: "60%", xl: "60%", "2xl": "60%" }}
            h={{ base: "100vh", sm: "100vh", md: "100vh", lg: "100vh", xl: "100vh", "2xl": "100vh" }}
        >
            <VStack
                bg="#DDF1DA"
                borderRadius={{ base: "16px", sm: "16px", md: "16px", lg: "16px", xl: "16px", "2xl": "16px" }}
                w={{ base: "", sm: "", md: "", lg: "97%", xl: "97%", "2xl": "97%" }}
                mt={{ base: "", sm: "", md: "", lg: "24px", xl: "24px", "2xl": "24px" }}
                ml={{ base: "", sm: "", md: "", lg: "24px", xl: "24px", "2xl": "24px" }}
                mb={{ base: "", sm: "", md: "", lg: "24px", xl: "24px", "2xl": "24px" }}
                h={{
                    base: "100vh",
                    sm: "100vh",
                    md: "100vh",
                    lg: "100vh",
                    xl: "100vh",
                    "2xl": "100vh",
                }}
            >
                <VStack
                    display="flex"
                    flexDirection="column"
                    margin="auto"
                    w={{ base: "", sm: "", md: "", lg: "77%", xl: "77%", "2xl": "77%" }}
                    spacing={{ lg: 3, xl: 3, "2xl": 10 }}
                >
                    <Image
                        src={LoginGroup}
                        alt="loginGroup"
                        w={{ base: "", sm: "", md: "", lg: "347.5px", xl: "347.5px", "2xl": "349px" }}
                        h={{ base: "", sm: "", md: "", lg: "251.3px", xl: "251.3px", "2xl": "255px" }}
                    />

                    <Box
                        bg="#FBFBFB66"
                        borderRadius={{ base: "", sm: "", md: "60px", lg: "60px", xl: "60px" }}
                        w={{ base: "", sm: "", md: "100%", lg: "100%", xl: "100%" }}
                        h={{ base: "", sm: "", md: "50px", lg: "50px", xl: "50px" }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Text sx={TextStyle}>Customizable Alarms and Notifications</Text>
                    </Box>
                    <Box
                        bg="#FBFBFB66"
                        borderRadius={{ base: "", sm: "", md: "60px", lg: "60px", xl: "60px" }}
                        w={{ base: "", sm: "", md: "100%", lg: "100%", xl: "100%" }}
                        h={{ base: "", sm: "", md: "50px", lg: "50px", xl: "50px" }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Text sx={TextStyle}>All-in-One Platform</Text>
                    </Box>
                    <Box
                        bg="#FBFBFB66"
                        borderRadius={{ base: "", sm: "", md: "60px", lg: "60px", xl: "60px" }}
                        w={{ base: "", sm: "", md: "100%", lg: "100%", xl: "100%" }}
                        h={{ base: "", sm: "", md: "50px", lg: "50p", xl: "50px" }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Text sx={TextStyle}>Seamless Space Management</Text>
                    </Box>
                </VStack>

                <Box></Box>
            </VStack>
        </VStack>
    );
};

export default SideScreen;
