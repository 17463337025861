import React from "react";
import { Box, Flex, Grid, GridItem, Image, Text, VStack } from "@chakra-ui/react";
import CustomButton from "../components/CustomButton";
import phase2_add_device from "../../src/assets/phase2_add_device.svg";
import addGif from "../../src/assets/addGifDevice.gif";
import { useNavigate } from "react-router-dom";

export const SetUpDevice6 = () => {
    const navigate = useNavigate();
    const GettingStarted = () => {
        navigate("/progressbar");
    };
    return (
        <Grid gridTemplateColumns="45% auto" mt="1rem">
            <VStack ml="110px" align="start">
                <VStack align="start">
                    <Box w="100%" pb="2rem" display="flex" flexDirection="column" align="start" mt="2.5rem">
                        <Box>
                            <Image src={phase2_add_device} alt="phase2_add_device" w="27px" h="30px" mb="1rem" />
                        </Box>
                        <Flex align="start">
                            <Text
                                fontFamily="fontFamily"
                                fontSize="32px"
                                fontWeight="700"
                                lineHeight="40px"
                                letterSpacing=" -0.01em"
                                align="start"
                                color="#11250E"
                            >
                                Your Device is ready
                            </Text>
                        </Flex>
                        <Flex alignItems="start">
                            <Text
                                fontFamily="fontFamily"
                                fontSize="14px"
                                fontWeight="500"
                                lineHeight="20px"
                                letterSpacing="-0.01em"
                                align="start"
                                color="rgba(129, 134, 129, 1)"
                                as="span"
                            >
                                Your device is now successfully paired and ready for use.
                            </Text>
                        </Flex>
                    </Box>
                </VStack>
                <GridItem display="flex" justifyContent="space-between" w="75%">
                    <Box display="flex" justifyContent="flex" alignItems="center" w="90%" gap="1rem">
                        <CustomButton
                            color="rgba(255, 255, 255, 1)"
                            w="27%"
                            h="40px"
                            bg="rgba(87, 186, 71, 1)"
                            sx={"buttonText"}
                            onClick={GettingStarted}
                            
                        >
                            Next
                        </CustomButton>
                    </Box>
                </GridItem>
            </VStack>

            <GridItem display="flex" justifyContent="center" alignItems="center">
                <Box mb="10rem">
                    {/* <Image src={onboarding_connect_wifi} alt="deviceAdd" /> */}

                    <Image src={addGif} alt="Placeholder Image" h="300px" />
                </Box>
            </GridItem>
        </Grid>
    );
};
