import React from "react";
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Input,
    Box,
    FormLabel,
    Text,
    HStack,
    useDisclosure,
    FormControl,
    FormErrorMessage,
    Flex,
} from "@chakra-ui/react";
import CustomButton from "./CustomButton";
import ReadingDeliveredToRegisterEmail from "./Step2ExportReading";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import moment from "moment-timezone";

const inputStyle = {
    borderRadius: "4px",
    border: "1px solid #81868180",
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    color: "#000000",
    _focus: {
        border: "1.5px solid #57BA47",
        boxShadow: "none",
    },
    "::placeholder": {
        color: "#818681",
        opacity: 0.4,
    },
};

export const ExportReading = ({ exportonClose, exportisOpen, device, startDate, endDate }) => {
    const { isOpen: emailSendisOpen, onOpen: emailSendonOpen, onClose: emailSendonClose } = useDisclosure();

    const validation = useFormik({
        initialValues: {
            startDateTime: moment(startDate).isValid() ? moment(startDate).format("YYYY-MM-DDTHH:mm") : "",
            endDateTime: moment(endDate).isValid() ? moment(endDate).format("YYYY-MM-DDTHH:mm") : "",
        },
        validationSchema: Yup.object({
            startDateTime: Yup.date().required("Start Date is required."),
            endDateTime: Yup.date().required("End Date is required."),
        }),
        onSubmit: (values) => {
            exportLogsMutation.mutate(values);
        },
        enableReinitialize: true,
    });

    const exportLogsMutation = useMutation({
        mutationFn: async ({ startDateTime, endDateTime }) => {
            const response = await axios.get(`/log/export`, {
                params: {
                    device_id: device?.id,
                    startDateTime: new Date(startDateTime),
                    endDateTime: new Date(endDateTime),
                },
            });
            return response.data;
        },
        onSuccess: () => {
            validation.resetForm();
            exportonClose();
            emailSendonOpen();
        },
        onError: (error) => {
            if (error?.response?.data) {
                console.error(error.response.data);
            }
        },
    });

    return (
        <>
            <Drawer isOpen={exportisOpen} placement="right" onClose={exportonClose}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <Box mt={{ base: "0px", sm: "0px", md: "5px", lg: "10px" }} mr="10px">
                        <DrawerCloseButton sx={{ color: "#11250E", p: "8px", bg: "#F6F6F6" }} />
                        <DrawerHeader
                            fontFamily="fontFamily"
                            fontSize="28px"
                            fontWeight="800"
                            lineHeight="42px"
                            letterSpacing="-0.04em"
                            textAlign="left"
                            color="#11250E"
                        >
                            Export Readings
                        </DrawerHeader>
                    </Box>

                    <DrawerBody mt="0.5rem">
                        <Box mb="1rem">
                            <HStack justify="space-between">
                                <FormLabel fontFamily="fontFamily" fontSize="16px" fontWeight="700">
                                    Device Name
                                </FormLabel>
                                <Text fontFamily="fontFamily" fontSize="16px" fontWeight="500">
                                    {device?.name}
                                </Text>
                            </HStack>
                            <HStack justify="space-between">
                                <FormLabel fontFamily="fontFamily" fontSize="16px" fontWeight="700">
                                    Room Name
                                </FormLabel>
                                <Text fontFamily="fontFamily" fontSize="16px" fontWeight="500">
                                    {device?.roomDevice?.name}
                                </Text>
                            </HStack>
                        </Box>

                        <Box mb="1rem">
                            <FormLabel fontFamily="fontFamily" fontSize="16px" fontWeight="700">
                                Select Range
                            </FormLabel>
                        </Box>

                        <FormControl isInvalid={validation.errors.startDateTime && validation.touched.startDateTime} isRequired>
                            <FormLabel htmlFor="startDate" fontSize="14px" fontWeight="500" color="#C0C0C0" mb={0}>
                                Start Date & Time
                            </FormLabel>
                            <Input
                                id="startDate"
                                type="datetime-local"
                                sx={inputStyle}
                                value={validation.values.startDateTime}
                                onChange={(e) => {
                                    validation.setFieldValue("startDateTime", e.target.value);
                                }}
                                onBlur={validation.handleBlur}
                            />
                            <FormErrorMessage>{validation.errors.startDateTime}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={validation.errors.endDateTime && validation.touched.endDateTime} isRequired mt="1rem">
                            <FormLabel htmlFor="endDate" fontSize="14px" fontWeight="500" color="#C0C0C0" mb={0}>
                                End Date & Time
                            </FormLabel>
                            <Input
                                id="endDate"
                                type="datetime-local"
                                sx={inputStyle}
                                value={validation.values.endDateTime}
                                onChange={(e) => {
                                    validation.setFieldValue("endDateTime", e.target.value);
                                }}
                                onBlur={validation.handleBlur}
                            />
                            <FormErrorMessage>{validation.errors.endDateTime}</FormErrorMessage>
                        </FormControl>
                    </DrawerBody>

                    <DrawerFooter>
                        <Flex direction="column" justifyContent="center" gap={2} width="100%">
                            <CustomButton
                                width="100%"
                                h="40px"
                                bg="#57BA47"
                                sx={{
                                    _hover: { bg: "#57BA47" },
                                    fontFamily: "fontFamily",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                }}
                                marginInline="auto"
                                onClick={validation.handleSubmit}
                                disabled={!validation.isValid || exportLogsMutation.isPending}
                            >
                                Export Data
                            </CustomButton>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

            <ReadingDeliveredToRegisterEmail emailSendonClose={emailSendonClose} emailSendisOpen={emailSendisOpen} />
        </>
    );
};
