import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Grid,
    Image,
    Text,
} from "@chakra-ui/react";
import React from "react";
import CustomButton from "./CustomButton";
import group from "../assets/openEmail.svg";

const EmailSended = ({ emailSendIsOpen, emailSendOnClose, email }) => {
    // console.log(email, "email");
    const [placement] = React.useState("right");
    // console.log(placement, "placement");

    const handleDone = () => {
        emailSendOnClose();
    };

    return (
        <>
            <Drawer placement={placement} onClose={emailSendOnClose} isOpen={emailSendIsOpen}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <DrawerHeader
                        fontFamily="fontFamily"
                        fontSize="28px"
                        fontWeight="700"
                        lineHeight="42px"
                        letterSpacing="-0.01em"
                        textAlign="left"
                        color="#11250E"
                        mt={{ base: "0px", sm: "0px", md: "5px", lg: "10px" }}
                        mr="10px"
                    >
                        <Flex align="center" justify="space-between">
                            <Text>Reset Password</Text>
                            <DrawerCloseButton sx={{ color: "#11250E", p: "8px", bg: "#F6F6F6" }} />
                        </Flex>
                    </DrawerHeader>

                    <DrawerBody p={0} m={0} align="center" position="relative">
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="10px"
                            minWidth="310px"
                            bgColor="#F8F8F8"
                            fontFamily="fontFamily"
                            fontSize="19px"
                            fontWeight="600"
                            lineHeight="34px"
                            letterSpacing="-0.01em"
                            color="#11250E"
                        >
                            <Text textAlign="center" m="20px">
                                A link has been sent on your email Id{" "}
                                <Text as="span" color="#57BA47">
                                    {email}
                                </Text>
                            </Text>
                        </Box>

                        <Image src={group} alt="Placeholder Image" w={{ lg: "180px" }} h={{ lg: "215px" }} marginY="50px" />

                        <Text marginTop="100px" marginX="20px">
                            Forgot your password? Check your email for the reset link to update your password.
                        </Text>
                    </DrawerBody>

                    <DrawerFooter>
                        <CustomButton
                            width="100%"
                            h="40px"
                            bg="#57BA47"
                            sx={{
                                _hover: { bg: "#57BA47" },
                                fontFamily: "fontFamily",
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#FFFFFF",
                            }}
                            marginInline="auto"
                            onClick={handleDone}
                        >
                            Done
                        </CustomButton>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default EmailSended;
