import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    breakpoints: {
        base: "0em",
        sm: "30em",
        md: "48em",
        lg: "62em",
        xl: "80em",
        "2xl": "96em",
    },
    fonts: {
        fontFamily: "Manrope",
        fontWeight: {
            thin: 200,
            400: 400,
            500: 500,
            semibold: 600,
            bold: 700,
            extrabold: 800,
        },
    },

    colors: {
        green: {
            500: "#11250e", // You can define different shades if needed
        },
    },
});

export default theme;
