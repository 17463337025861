import React, { useState } from "react";
import axios from "axios";
import { Box, Link, Grid, GridItem, Image, Text, VStack, Flex, Spinner } from "@chakra-ui/react";

import CustomButton from "../components/CustomButton";
import onboarding_open_wifi from "../assets/onboarding_open_wifi.svg";
import { useDispatch, useSelector } from "react-redux";

import { Add_Device3_Toggle_Stepper_Function, Add_Device4_Toggle_Stepper_Function } from "../redux/action";
import { useNavigate } from "react-router-dom";
import connectionLoder from "../../src/assets/connectivity_loder.svg";
import connected_sensor from "../../src/assets/connected_sensor.svg";
import disconnectSensor from "../../src/assets/disconnect_sensor.svg";
import { SetUpDevice5 } from "./SetUpDevice5";
export const SetUpDevice4 = ({ serialno, deviceName, connectionKey, roomId }) => {
    // console.log(serialno, deviceName, connectionKey, roomId);
    const Add_Device4_Stepper = useSelector((store) => store.ConditionalRenderingReducer.Add_Device4_Stepper);
    console.log(Add_Device4_Stepper);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [connect, setConnect] = useState(false);
    const [image, setImage] = useState(onboarding_open_wifi);
    const [connectionValue, setConnectionValue] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [deviceId, setDeviceId] = useState(null);
    console.log(deviceId, "deviceId");

    const Heading = {
        color: "#11250E",
        fontFamily: "fontFamily",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: "800",
        lineHeight: "42px",
        letterSpacing: "-0.01em",
    };

    const buttonText = {
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.16%",
    };
    const noteStyle = {
        color: "#818681",
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "0.1%",
    };

    const secondStyle = {
        color: "#000000",
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "13px",
        fontWeight: "500",
        lineHeight: "15px",
        letterSpacing: "0.1%",
    };
    const handle_link_Open_Wifi = async () => {
        setLoading(true);
        setIsDisabled(true);
        try {
            const response = await axios.post(`/device`, {
                room: roomId,
                serial_number: serialno,
                connection_key: connectionKey,
                pairing_key: "ABC000",
                name: deviceName,
            });

            if (response.status === 200) {
                setImage(connected_sensor);
                setLoading(false);
                setConnectionValue("Connected");
                setDeviceId(response?.data?.data?.id);
                setConnect(true);

                setTimeout(() => {
                    setIsDisabled(true);
                }, 30000);

                return true;
            } else {
                console.error(`Error in Device Creation: ${response?.data?.error}`);
                setImage(disconnectSensor);
                setConnectionValue("Disconnected");
                setConnect(false);
                setLoading(false);
                setTimeout(() => {
                    setIsDisabled(false);
                    setConnectionValue(null);
                    setImage(onboarding_open_wifi);
                }, 30000);

                setCountdown(30);
                const countdownInterval = setInterval(() => {
                    setCountdown((prevCountdown) => {
                        if (prevCountdown <= 1) {
                            clearInterval(countdownInterval);
                            return 0;
                        }
                        return prevCountdown - 1;
                    });
                }, 1000);
                return false;
            }
        } catch (error) {
            console.error("Error in Device Creation:", error);
            setImage(disconnectSensor);
            setConnectionValue("Disconnected");
            setConnect(false);
            setLoading(false);
            setTimeout(() => {
                setIsDisabled(false);
                setConnectionValue(null);
                setImage(onboarding_open_wifi);
            }, 30000);
            setCountdown(30);
            const countdownInterval = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown <= 1) {
                        clearInterval(countdownInterval);
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);

            return false;
        } finally {
            setLoading(false);
        }
    };
    const Previous_handle_link_Open_Wifi = () => [dispatch(Add_Device3_Toggle_Stepper_Function())];

    const skipToTheDashboardThree = () => {
        window.location.href = "/dashboard";
    };

    const handleNextpage = async () => {
        dispatch(Add_Device4_Toggle_Stepper_Function());
        // navigate("/progressbar");
    };

    return (
        <>
            {Add_Device4_Stepper ? (
                <SetUpDevice5  deviceId={deviceId}/>
            ) : (
                <Grid gridTemplateColumns="45% auto" mt="1rem">
                    <VStack align="start" ml="6.9rem">
                        <VStack align="start" h="50vh">
                            <GridItem w="100%" h="5vh" mb="1rem">
                                <Text sx={Heading}>Click on the link to open Wifi </Text>
                            </GridItem>
                            <GridItem w="75%">
                                <Text sx={noteStyle}>Step 1: After completing the SSID setup from the previous steps.</Text>
                            </GridItem>
                            <GridItem w="75%">
                                <Text mb="0.5rem" sx={noteStyle}>
                                    Step 2: Click here or visit this link:
                                    <Link
                                        href="http://192.168.1.1/"
                                        isExternal
                                        textDecoration="underline"
                                        fontFamily="fontFamily"
                                        color="rgba(84, 137, 236, 1)"
                                    >
                                        {" "}
                                        http://192.168.1.1.
                                    </Link>
                                </Text>
                            </GridItem>

                            <GridItem w="75%">
                                <Text sx={noteStyle}>Step 3: A page similar to the one shown in the screenshot will appear.</Text>
                            </GridItem>
                            <GridItem w="75%">
                                <Text sx={noteStyle}>
                                    Step 4: From the list of available Wi-Fi networks, select the Wi-Fi you want to connect your Thermadata device to.
                                </Text>
                            </GridItem>
                            <GridItem w="75%">
                                <Text sx={noteStyle}>Step 5: Enter your Wi-Fi password in the designated field.</Text>
                            </GridItem>

                            <GridItem w="75%" mb="2rem">
                                <Text sx={noteStyle}>Step 6: Finally, click on the "Save Configuration" button to complete the setup.</Text>
                            </GridItem>

                            <Grid w="75%" gridTemplateColumns="repeat(2,1fr)" justifyContent="space-between">
                                <GridItem align="start">
                                    <Flex gap={3} align="center">
                                        <CustomButton
                                            h="30px"
                                            align="center"
                                            justifyContent="center"
                                            fontFamily="fontFamily"
                                            fontWeight="500"
                                            fontSize="12px"
                                            lineHeight="42px"
                                            color="rgba(0, 0, 0, 1)"
                                            letterSpacing="-0.01%"
                                            bg="none"
                                            disabled={isDisabled}
                                            border="1px solid rgba(173, 173, 173, 1)"
                                            onClick={handle_link_Open_Wifi}
                                        >
                                            Check Connectivity
                                            <Image src={connectionLoder} alt="connectionLoder" h="16px" w="16px" ml="3.5px" />
                                        </CustomButton>{" "}
                                        {loading && <Spinner color="#FF9B3E" thickness="2px" size="md" />}
                                        {connectionValue === "Disconnected" && <Text sx={secondStyle}>{countdown} sec</Text>}
                                    </Flex>
                                </GridItem>
                                <GridItem align="end">
                                    <Text
                                        as="span"
                                        h="30px"
                                        fontFamily="fontFamily"
                                        fontWeight="600"
                                        fontSize="16px"
                                        lineHeight="28px"
                                        color={!connect ? "rgba(148, 20, 36, 1)" : "rgba(87, 186, 71, 1)"}
                                        letterSpacing="-0.01%"
                                        bg="none"
                                        border="none"
                                        align="end"
                                    >
                                        {connectionValue}
                                    </Text>
                                </GridItem>
                            </Grid>
                        </VStack>

                        <GridItem display="flex" justifyContent="space-between" w="75%">
                            <Box w="30%" display="flex">
                                <CustomButton
                                    bg="rgba(255, 255, 255, 1)"
                                    color="rgba(87, 186, 71, 1)"
                                    border="2px solid"
                                    borderColor="rgba(87, 186, 71, 1)"
                                    onClick={Previous_handle_link_Open_Wifi}
                                >
                                    Back
                                </CustomButton>
                            </Box>
                            <Box display="flex" justifyContent="flex-end" alignItems="center" w="90%" gap="1rem">
                                <CustomButton
                                    color="rgba(0, 0, 0, 1)"
                                    w="30%"
                                    bg="rgba(255, 255, 255, 1)"
                                    sx={buttonText}
                                    onClick={skipToTheDashboardThree}
                                >
                                    Cancel
                                </CustomButton>
                                <CustomButton
                                    color="rgba(255, 255, 255, 1)"
                                    w="27%"
                                    h="40px"
                                    bg="rgba(87, 186, 71, 1)"
                                    disabled={!connect}
                                    sx={buttonText}
                                    onClick={handleNextpage}
                                >
                                    Next
                                </CustomButton>
                            </Box>
                        </GridItem>
                    </VStack>

                    <GridItem display="flex" justifyContent="center" alignItems="center">
                        <Box mb="4rem">
                            <Image src={image} alt="deviceAdd" />
                        </Box>
                    </GridItem>
                </Grid>
            )}
        </>
    );
};
