import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { Button, Flex, Grid, GridItem, HStack, Modal, ModalBody, ModalContent, ModalOverlay, Text, Box, Image } from "@chakra-ui/react";
import basicCheck from "../assets/BasicListCheck.png";
import CustomButton from "./CustomButton";
import crown from "../assets/subCrown.svg";
import premiumCheck from "../assets/PremiumListCheck.png";
import { ModalSubNextStep } from "./ModalSubNextStep";
import { useDispatch, useSelector } from "react-redux";
import { Toggle_Step4Function } from "../redux/action";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkout";
import axios from "axios";

const REACT_APP_STRIPE_CLIENT_ID = process.env.REACT_APP_STRIPE_CLIENT_ID;

// Fetch subscription plans
const fetchPlans = async () => {
    const res = await axios.get(`/plan`, {
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (res.status !== 200) {
        throw new Error("Network response was not ok");
    }

    return res.data;
};

export const ModalSubscription = ({ finalRef, isFirstModalOpen, onFirstCloseModalOpen }) => {
    const dispatch = useDispatch();
    const { data, ...dataQuery } = useQuery({
        queryKey: "fetchPlans",
        queryFn: fetchPlans,
    });

    const monthlyPlan = data?.data?.filter((item) => item?.interval?.toLowerCase().includes("month"));
    const yearlyPlan = data?.data?.filter((item) => item?.interval?.toLowerCase().includes("year"));

    const [activeTab, setActiveTab] = useState("tab1");
    const Step4 = useSelector((store) => store.ConditionalRenderingReducer.step4);
    const [clientSecret, setClientSecret] = useState("");
    const [stripePromise, setStripePromise] = useState(null);
    const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
    const [paymentDone, setPaymentDone] = useState(false);

    // Load Stripe instance once
    useEffect(() => {
        setStripePromise(loadStripe(REACT_APP_STRIPE_CLIENT_ID));
    }, []);

    useEffect(() => {
        if (paymentDone) {
            dispatch(Toggle_Step4Function());
            setIsCheckoutModalOpen(false);
        }
    }, [paymentDone, dispatch]);

    const handleSubscriptionDone = async (id) => {
        try {
            const res = await axios.post(`/subscription`, {
                plan_id: id,
            });

            if (res.status === 200) {
                setClientSecret(res?.data?.data?.clientSecret);
                setIsCheckoutModalOpen(true);
            } else {
                console.error(`Error in subscription: ${res?.data?.error}`);
            }
        } catch (error) {
            console.error("Subscription error:", error);
        }
    };

    const renderPlans = (plans) => {
        return (
            plans &&
            plans.map((plan) => (
                <GridItem
                    key={plan.id}
                    w="100%"
                    h="440px"
                    borderRadius="16px"
                    padding="32px"
                    bg={
                        plan?.title?.toLowerCase().includes("premium")
                            ? "linear-gradient(325.55deg, #57BA47 -40.69%, #11250E 116.9%)"
                            : "rgba(255, 255, 255, 1)"
                    }
                    boxShadow="0px 4px 25px 0px rgba(0, 0, 0, 0.1)"
                >
                    <Box display="flex" flexDirection="column" gap="0.3rem">
                        <Box display="flex" alignItems="center" gap="0.5rem">
                            {plan?.title?.toLowerCase().includes("premium") && <Image src={crown} alt="crown" w="32px" h="32px" />}
                            <Text
                                color={plan?.title?.toLowerCase().includes("premium") ? "rgba(255, 255, 255, 1)" : "#11250E"}
                                fontSize="24px"
                                fontWeight="700"
                                lineHeight="32px"
                                textAlign="left"
                            >
                                {plan?.title}
                            </Text>
                        </Box>
                        <Text
                            color={plan?.title?.toLowerCase().includes("premium") ? "rgba(255, 255, 255, 0.6)" : "#818681"}
                            fontSize="14px"
                            fontWeight="500"
                            lineHeight="20px"
                            textAlign="left"
                        >
                            {plan?.description}
                        </Text>
                    </Box>

                    <Box>
                        <Text
                            color={plan?.title?.toLowerCase().includes("premium") ? "rgba(255, 255, 255, 1)" : "#11250E"}
                            fontSize="48px"
                            fontWeight="700"
                            lineHeight="72px"
                        >
                            ${plan?.amount}
                            <Text as="span" color="#818681" fontSize="24px" fontWeight="800" lineHeight="72px">
                                {activeTab === "tab1" ? "/mo" : "/yr"}
                            </Text>
                        </Text>
                        <CustomButton
                            border="1px solid"
                            borderColor={plan?.title?.toLowerCase().includes("premium") ? "rgba(255, 255, 255, 1)" : "rgba(129, 134, 129, 1)"}
                            w="100%"
                            fontFamily="fontFamily"
                            fontSize="14px"
                            fontWeight="700"
                            lineHeight="20px"
                            boxShadow="rgba(0, 0, 0, 0.1)"
                            bg="#FFFFFF"
                            onClick={() => handleSubscriptionDone(plan?.id)}
                        >
                            Subscribe
                        </CustomButton>
                    </Box>

                    <Box mt="1rem" display="flex" flexDirection="column" gap="0.5rem">
                        <Text
                            color={plan?.title?.toLowerCase().includes("premium") ? "rgba(255, 255, 255, 1)" : "#11250E"}
                            fontFamily="fontFamily"
                            fontSize="16px"
                            fontWeight="500"
                            lineHeight="24px"
                            letterSpacing="-0.01em"
                        >
                            Features:
                        </Text>
                        {plan?.feature &&
                            plan?.feature?.map((feature, index) => (
                                <Box display="flex" alignItems="center" gap="0.5rem" key={index}>
                                    <Image
                                        src={plan?.title?.toLowerCase().includes("premium") ? premiumCheck : basicCheck}
                                        alt="check"
                                        w="16px"
                                        h="16px"
                                    />
                                    <Text
                                        color={plan?.title?.toLowerCase().includes("premium") ? "rgba(255, 255, 255, 0.6)" : "#818681"}
                                        fontSize="14px"
                                        fontWeight="500"
                                        lineHeight="20px"
                                        letterSpacing="-0.01em"
                                    >
                                        {feature}
                                    </Text>
                                </Box>
                            ))}
                    </Box>
                </GridItem>
            ))
        );
    };

    return (
        <>
            {Step4 ? (
                <ModalSubNextStep />
            ) : (
                <>
                    <Modal finalFocusRef={finalRef} isOpen={isFirstModalOpen} onClose={onFirstCloseModalOpen}>
                        <ModalOverlay />
                        <ModalContent bg="rgba(255, 255, 255, 1)" width="80%" height="610px" maxWidth="none" borderRadius="32px" alignItems="center">
                            <ModalBody width="60%">
                                <Flex
                                    bg="white"
                                    w="100%"
                                    p="4px"
                                    maxWidth="fit-content"
                                    borderRadius="4px"
                                    mt="72px"
                                    boxShadow="0px 4px 30px 0px #0000001A"
                                    mb="1rem"
                                >
                                    <HStack spacing="7px">
                                        <Button
                                            onClick={() => setActiveTab("tab1")}
                                            fontFamily="fontFamily"
                                            fontSize="16px"
                                            fontWeight="600"
                                            lineHeight="24px"
                                            letterSpacing="-0.01em"
                                            bg={activeTab === "tab1" ? "#57BA47" : "white"}
                                            color={activeTab === "tab1" ? "white" : "rgba(17, 37, 14, 1)"}
                                            w={activeTab === "tab1" ? "95px" : "80px"}
                                            transition="all 0.3s ease"
                                            h="32px"
                                            _hover={{
                                                bg: activeTab === "tab1" ? "#57BA47" : "white", // Ensures no change on hover
                                                color: activeTab === "tab1" ? "white" : "rgba(17, 37, 14, 1)", // Ensures no change on hover
                                            }}
                                        >
                                            Monthly
                                        </Button>
                                        <Button
                                            onClick={() => setActiveTab("tab2")}
                                            fontFamily="fontFamily"
                                            fontSize="16px"
                                            fontWeight="600"
                                            lineHeight="24px"
                                            letterSpacing="-0.01em"
                                            bg={activeTab === "tab2" ? "#57BA47" : "white"}
                                            color={activeTab === "tab2" ? "white" : "rgba(17, 37, 14, 1)"}
                                            w={activeTab === "tab2" ? "95px" : "80px"}
                                            transition="all 0.3s ease"
                                            h="32px"
                                            _hover={{
                                                bg: activeTab === "tab2" ? "#57BA47" : "white", // Ensures no change on hover
                                                color: activeTab === "tab2" ? "white" : "rgba(17, 37, 14, 1)", // Ensures no change on hover
                                            }}
                                        >
                                            Yearly
                                        </Button>
                                    </HStack>
                                </Flex>

                                <Grid templateColumns="repeat(2, 1fr)" templateRows="repeat(1, 1fr)" gap={5} width="100%">
                                    {activeTab === "tab1" ? renderPlans(monthlyPlan) : renderPlans(yearlyPlan)}
                                </Grid>
                            </ModalBody>
                        </ModalContent>
                    </Modal>

                    <Modal isOpen={isCheckoutModalOpen} onClose={() => setIsCheckoutModalOpen(false)}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalBody>
                                {clientSecret && stripePromise && (
                                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                                        <CheckoutForm clientSecret={clientSecret} setPaymentDone={setPaymentDone} />
                                    </Elements>
                                )}
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </>
            )}
        </>
    );
};
