import React, { useEffect } from "react";
import Axios from "axios";
import { Routing } from "./routing/Routing";
import ImpersonationOverlay from "./components/impersonation/ImpersonationOverlay";
import { ImpersonationProvider, useImpersonation } from "./contexts/useImpersonationContext";

const token = localStorage.getItem("token");

Axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

Axios.defaults.headers.common.Authorization = `Bearer ${token}`;

Axios.interceptors.request.use();

Axios.interceptors.request.use(
    (config) => {
        // are we impersonating an user
        const isImpersonating = sessionStorage.getItem("impersonating");
        const impersonationToken = sessionStorage.getItem("impersonation_token");

        if (isImpersonating && impersonationToken) {
            config.headers["Impersonation-Token"] = `Bearer ${impersonationToken}`;
            config.headers["impersonating"] = "true";
        }

        return config;
    },
    (error) => {
        // Handle errors here, such as logging or showing a notification
        return Promise.reject(error);
    },
);

Axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response) {
            const { status } = error.response;

            if (status === 401 || status === 403) {
                window.location.href = `${window.location.origin}/login`;
            } else if ((status === 422 || status === 500 || status === 400) && error.response?.data?.error) {
                window.dispatchEvent(
                    new CustomEvent("validation-toast", {
                        detail: {
                            message: error?.response?.data?.error,
                        },
                    }),
                );
            }
        }

        return Promise.reject(error);
    },
);

function App() {
    const { isImpersonating } = useImpersonation();

    return (
        <>
            <Routing />
            {isImpersonating && <ImpersonationOverlay />}
        </>
    );
}

export default App;
