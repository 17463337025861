import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerFooter,
    FormLabel,
    Image,
    Input,
    Text,
    useDisclosure,
    Select,
    FormErrorMessage,
    FormControl,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomButton from "../../components/CustomButton";
import plusIcon from "../../assets/Plus_Icon.png";
import Step2AddClient from "./Step2AddClient";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import Axios from "axios";

const ROLES = ["MANAGER", "OWNER", "EMPLOYEE", "FARMER", "CARETAKER", "CHEF"];
const COMPANY_SIZE = ["Less than 10", "10 - 50", "50 - 100", "100 - 200", "200 - 500", "500 - 1000", "More than 1000"];

const AddClient = ({ deviceIsOpen, deviceOnClose }) => {
    const { isOpen: step2IsOpen, onOpen: step2onOpen, onClose: step2onClose } = useDisclosure();

    const newClientMutation = useMutation({
        mutationFn: async function ({ name, designation, email, company_name, company_size }) {
            const response = await Axios.post("/auth/signup", { name, designation, email, company_name, company_size });
            return response;
        },
        onSuccess: function (response) {
            deviceOnClose();
            // step2onOpen();
        },
    });

    const validation = useFormik({
        initialValues: {
            name: "",
            designation: "",
            email: "",
            company_name: "",
            company_size: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().min(3, "Name is too short.").required("Name is required."),
            designation: Yup.string().oneOf(ROLES, "Invalid designation.").required("Designation is required."),
            email: Yup.string().email("Enter a valid email.").required("Email is required."),
            company_name: Yup.string().min(3, "Company name is too short.").required("Company name is required."),
            company_size: Yup.string().oneOf(COMPANY_SIZE, "Invalid company size.").required("Company size is required."),
        }),
        onSubmit: function (values) {
            newClientMutation.mutate(values);
        },
        enableReinitialize: true,
    });
    // console.log(validation);
    const btnRef = React.useRef();
    const ButtonTextStyle = {
        _hover: {
            bg: "#57BA47",
        },
        fontFamily: "fontFamily",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
        letterSpacing: "-0.01em",
        color: "#FFFFFF",
    };
    const lableStyle = {
        fontFamily: "fontFamily",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "30px",
        letterSpacing: "-0.01em",
    };
    const inputStyle = {
        borderRadius: "4px",
        border: "1px solid #81868180",
        fontFamily: "var(--chakra-font-fontFamily)",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "-0.01em",
        color: "#818681",
        _focus: {
            border: "1.5px solid #57BA47",
            boxShadow: "none",
        },
        "::placeholder": {
            color: "#818681",
            opacity: 0.4,
        },
    };

    const handleCompanySizeClick = (size) => {
        validation.setFieldValue("company_size", size);
    };
    return (
        <>
            <Drawer isOpen={deviceIsOpen} placement="right" onClose={deviceOnClose} finalFocusRef={btnRef} overflowY="auto" size="sm">
                <DrawerOverlay />

                <DrawerContent borderRadius="10px" m="12px" minWidth="395px">
                    <DrawerCloseButton mt="35px" bg="#F6F6F6" color="#11250E" h="1.2rem" sx={{ mr: "1rem", pt: "1rem", pb: "1rem" }} />
                    <DrawerHeader mt="22px" fontFamily="fontFamily" fontWeight="700" fontSize="24px" lineHeight="36px" letterSpacing="-0.01em">
                        Add Member
                    </DrawerHeader>

                    <DrawerBody overflowY="auto">
                        <Box mb="27px">
                            <FormControl isInvalid={validation.errors.name && validation.touched.name} isRequired>
                                <FormLabel htmlFor="desc" sx={lableStyle}>
                                    Enter Name
                                </FormLabel>
                                <Input
                                    isInvalid={validation.errors.name && validation.touched.name}
                                    placeholder="Full name"
                                    type="text"
                                    sx={inputStyle}
                                    name="name"
                                    value={validation.values.name}
                                    onChange={validation.handleChange}
                                    onFocus={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.name}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb="27px">
                            <FormControl isInvalid={validation.errors.designation && validation.touched.designation} isRequired>
                                <FormLabel htmlFor="email" sx={lableStyle}>
                                    Enter Designation
                                </FormLabel>
                                <Select
                                    id="designation"
                                    value={validation.values.designation}
                                    name="designation"
                                    isInvalid={validation.errors.designation && validation.touched.designation}
                                    onChange={validation.handleChange}
                                    onFocus={validation.handleBlur}
                                    style={inputStyle}
                                >
                                    <option value="" disabled style={{ fontFamily: "fontFamily" }}>
                                        Select designation
                                    </option>
                                    <option value="MANAGER">Manager</option>
                                    <option value="OWNER">Owner</option>
                                    <option value="EMPLOYEE">Employee</option>
                                    <option value="FARMER">Farmer</option>
                                    <option value="CARETAKER">Caretaker</option>
                                    <option value="CHEF">Chef</option>
                                </Select>
                                <FormErrorMessage>{validation.errors.name}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb="27px">
                            <FormControl isInvalid={validation.errors.email && validation.touched.email} isRequired>
                                <FormLabel htmlFor="desc" sx={lableStyle}>
                                    Enter Email
                                </FormLabel>
                                <Input
                                    isInvalid={validation.errors.email && validation.touched.email}
                                    placeholder="Email"
                                    type="email"
                                    sx={inputStyle}
                                    name="email"
                                    value={validation.values.email}
                                    onChange={validation.handleChange}
                                    onFocus={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.email}</FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box mb="27px">
                            <FormControl isInvalid={validation.errors.company_name && validation.touched.company_name} isRequired>
                                <FormLabel htmlFor="desc" sx={lableStyle}>
                                    Enter Organisation Name
                                </FormLabel>
                                <Input
                                    isInvalid={validation.errors.company_name && validation.touched.company_name}
                                    placeholder="Company Name"
                                    type="text"
                                    sx={inputStyle}
                                    name="company_name"
                                    value={validation.values.company_name}
                                    onChange={validation.handleChange}
                                    onFocus={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.company_name}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb="27px">
                            <FormControl isInvalid={validation.errors.company_size && validation.touched.company_size} isRequired>
                                <FormLabel htmlFor="desc" sx={lableStyle}>
                                    Enter Organisation Size
                                </FormLabel>

                                <Box display="flex" flexDirection="column" rowGap="16px">
                                    <Box display="flex" gap="16px">
                                        {["Less than 10", "10 - 50", "50 - 100"].map((size) => (
                                            <Box
                                                as="span"
                                                key={size}
                                                border="1px solid"
                                                borderColor="#DDE2DD"
                                                padding="1rem"
                                                h="5vh"
                                                borderRadius="24px"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                cursor="pointer"
                                                bg={validation.values.company_size === size ? "#DDF1DA" : "none"}
                                                _hover={{
                                                    borderColor: validation.values.company_size === size ? "none" : "#11250E",
                                                }}
                                                onClick={() => handleCompanySizeClick(size)}
                                            >
                                                <Text
                                                    color="#11250E"
                                                    fontFamily="fontFamily"
                                                    fontSize="14px"
                                                    fontWeight="500"
                                                    lineHeight="20px"
                                                    letterSpacing="-0.14px"
                                                >
                                                    {size}
                                                </Text>
                                            </Box>
                                        ))}
                                    </Box>

                                    <Box display="flex" gap="16px">
                                        {["100 - 200", "200 - 500", "500 - 1000"].map((size) => (
                                            <Box
                                                as="span"
                                                key={size}
                                                border="1px solid"
                                                borderColor={"#DDE2DD"}
                                                padding="1rem"
                                                h="5vh"
                                                borderRadius="24px"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                cursor="pointer"
                                                bg={validation.values.company_size === size ? "#DDF1DA" : "none"}
                                                _hover={{
                                                    borderColor: validation.values.company_size === size ? "none" : "#11250E",
                                                }}
                                                onClick={() => handleCompanySizeClick(size)}
                                            >
                                                <Text
                                                    color="#11250E"
                                                    fontFamily="fontFamily"
                                                    fontSize="14px"
                                                    fontWeight="500"
                                                    lineHeight="20px"
                                                    letterSpacing="-0.14px"
                                                >
                                                    {size}
                                                </Text>
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box display="flex" gap="16px">
                                        {["More than 1000"].map((size) => (
                                            <Box
                                                as="span"
                                                key={size}
                                                border="1px solid"
                                                borderColor="#DDE2DD"
                                                padding="1rem"
                                                h="5vh"
                                                borderRadius="24px"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                cursor="pointer"
                                                bg={validation.values.company_size === size ? "#DDF1DA" : "none"}
                                                _hover={{
                                                    borderColor: validation.values.company_size === size ? "none" : "#11250E",
                                                }}
                                                onClick={() => handleCompanySizeClick(size)}
                                            >
                                                <Text
                                                    color="#11250E"
                                                    fontFamily="fontFamily"
                                                    fontSize="14px"
                                                    fontWeight="500"
                                                    lineHeight="20px"
                                                    letterSpacing="-0.14px"
                                                >
                                                    {size}
                                                </Text>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                                <FormErrorMessage>{validation.errors.company_size}</FormErrorMessage>
                            </FormControl>
                        </Box>
                    </DrawerBody>
                    <DrawerFooter display="flex" flexDirection="column">
                        <Text fontFamily="fontFamily" fontWeight="400" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#818681">
                            NOTE: Fields marked with * are compulsory.
                        </Text>

                        <CustomButton
                            mt="1rem"
                            w="100%"
                            h="2.5rem"
                            bg="#57BA47"
                            sx={ButtonTextStyle}
                            onClick={validation.handleSubmit}
                            disabled={!validation.isValid || newClientMutation.isPending}
                        >
                            <Image src={plusIcon} alt="sortIcon" border="1.2px" h="16px" w="16px" color="#FFFFFF" mr="3px" />
                            Add Member
                        </CustomButton>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <Step2AddClient step2IsOpen={step2IsOpen} step2onClose={step2onClose} />
        </>
    );
};

export default AddClient;
