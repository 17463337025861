import React, { useEffect, useRef, useState } from "react";
import { Box, Image, Input, Text, Progress } from "@chakra-ui/react";
import add_image from "../assets/add_image.png";
import upload_success from "../assets/upload_success.svg";
import upload_error from "../assets/upload_error.svg";
import upload_trash from "../assets/upload_trash.svg";

const ErrorBox = ({ displayText, onUploadAgain }) => (
    <Box
        h="full"
        w="full"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        borderRadius="8px"
        border="2px dashed #861321"
        bg="transparent"
    >
        <Box display="flex" flexDirection="column" alignItems="center">
            <Image src={upload_error} alt="Error" />
            <Text
                fontFamily="fontFamily"
                fontSize="20px"
                fontWeight="700"
                lineHeight="30px"
                letterSpacing="-0.01em"
                textAlign="left"
                color="rgba(134, 19, 33, 1)"
                mb="1rem"
            >
                {displayText}
            </Text>
            <Text
                bg="transparent"
                border="1px solid #1E920C"
                color="#1E920C"
                borderRadius="4px"
                fontFamily="fontFamily"
                fontSize="12px"
                fontWeight="600"
                lineHeight="16px"
                letterSpacing="-0.01em"
                cursor="pointer"
                onClick={onUploadAgain}
                py={1}
                px={2}
            >
                Upload Again
            </Text>
        </Box>
    </Box>
);

const ImagePreview = ({ displayImage, alt, onChangePicture, onDelete }) => {
    const inputRef = useRef();

    return (
        <Box
            h="full"
            w="full"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderRadius="16px"
            bg="transparent"
            position="relative"
            overflow="hidden"
        >
            <Image src={displayImage} alt={alt} h="full" w="full" objectFit="cover" />
            <Box position="absolute" bottom="2" left="2" right="2" display="flex" justifyContent="space-between" p="4px">
                <Text
                    bg="#DDF1DA"
                    borderRadius="4px"
                    fontFamily="fontFamily"
                    fontSize="12px"
                    fontWeight="600"
                    lineHeight="16px"
                    letterSpacing="-0.01em"
                    cursor="pointer"
                    onClick={() => inputRef.current.click()}
                    py={1}
                    px={2}
                >
                    Change Picture
                </Text>
                <input ref={inputRef} onChange={onChangePicture} type="file" id="file1" style={{ display: "none" }} />
                <Image src={upload_trash} alt="Delete" cursor="pointer" onClick={onDelete} />
            </Box>
        </Box>
    );
};

const UploadSuccessBox = ({ displayText, progress, uploadedSuccessfully, alt }) => (
    <Box
        h="full"
        w="full"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        borderRadius="8px"
        border="2px dashed"
        borderColor="#57BA47"
        bg="#DDF1DA"
    >
        {uploadedSuccessfully && <Image src={upload_success} alt={alt} />}
        <Text
            fontFamily="fontFamily"
            fontSize="20px"
            fontWeight="700"
            lineHeight="30px"
            letterSpacing="-0.01em"
            textAlign="left"
            color="rgba(17, 37, 14, 1)"
            mb="1rem"
        >
            {displayText}
        </Text>
        <Progress
            pl="0.5rem"
            pr="0.5rem"
            mt={0}
            mb={0}
            value={progress}
            w="90%"
            h="17px"
            colorScheme="green"
            borderRadius="16px"
            sx={{
                display: "flex",
                alignItems: "center",
                "& > div": {
                    height: "4px",
                },
            }}
        />
    </Box>
);

const UploadPrompt = ({ label, onChange }) => (
    <Box
        h="full"
        w="full"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        borderRadius="8px"
        border="2px dashed"
        borderColor="#57BA47"
        bg="#DDF1DA"
    >
        <Image src={add_image} alt="add_image" h="42px" w="42px" />
        <Input type="file" display="none" id="file-input" onChange={onChange} />
        <Text
            as="label"
            htmlFor="file-input"
            textDecoration="underline"
            cursor="pointer"
            fontFamily="fontFamily"
            fontSize="14px"
            fontWeight="600"
            lineHeight="20px"
            letterSpacing="-0.01em"
            color="#57BA47"
        >
            {label}
        </Text>
        <Text marginTop="2" fontSize="12px" fontWeight="500" lineHeight="16px" letterSpacing="-0.01em" color="#11250E">
            Drag & Drop jpeg & png not more than 10MB.
        </Text>
    </Box>
);

const ImageUpload = ({ setImage, src, label, alt, ...props }) => {
    const [progress, setProgress] = useState(0);
    const [displayImage, setDisplayImage] = useState(src);
    const [imageValue, setImageValue] = useState(false);
    const [displayText, setDisplayText] = useState("Uploading...");
    const [uploadedSuccessfully, setUploadedSuccessfully] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        let interval;
        if (progress > 0 && progress < 100) {
            interval = setInterval(() => {
                setProgress((prev) => {
                    const newProgress = Math.min(prev + 35, 100);

                    if (newProgress === 100) {
                        setDisplayText("Upload Successful!");
                        setUploadedSuccessfully(true);

                        setTimeout(() => {
                            setImageValue(true);
                        }, 1000);
                    }

                    return newProgress;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [progress]);

    const resetUploadState = () => {
        setDisplayImage(null);
        setImageValue(false);
        setUploadedSuccessfully(false);
        setProgress(0);
        setDisplayText("Uploading...");
        setError(false);
    };

    const handleDelete = () => {
        resetUploadState();
        setImage(null);
    };

    const handleImageChange = (event) => {
        resetUploadState();
        const file = event.target.files?.[0];
        if (file) {
            // console.log("Uploaded image file:", file);
            const fileType = file.type;
            const fileSize = file.size / 1024 / 1024;

            if (!["image/jpeg", "image/png"].includes(fileType)) {
                setError(true);
                setDisplayText("Please upload a JPEG or PNG image.");
                return;
            }
            if (fileSize > 10) {
                setError(true);
                setDisplayText("File size must be less than 10MB.");
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setDisplayImage(reader.result);
                setImage(file);
                setProgress(1);
                setUploadedSuccessfully(false);
                setDisplayText("Uploading...");
                setError(false);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUploadAgain = () => {
        resetUploadState();
        setImage(null);
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" w="full" h={140} {...props}>
            {(function () {
                if (error) return <ErrorBox displayText={displayText} onUploadAgain={handleUploadAgain} />;

                if (!displayImage) return <UploadPrompt label={label} onChange={handleImageChange} />;

                return imageValue ? (
                    <ImagePreview displayImage={displayImage} alt={alt} onChangePicture={handleImageChange} onDelete={handleDelete} />
                ) : (
                    <UploadSuccessBox displayText={displayText} progress={progress} uploadedSuccessfully={uploadedSuccessfully} alt={alt} />
                );
            })()}
        </Box>
    );
};

export default ImageUpload;
