import React from "react";
import { Box, VStack, Text, Image, Button } from "@chakra-ui/react";

import CentralSensorImage from "../assets/CentralSensorImage.png";
import plusIcon from "../assets/Plus_Icon.png";
import AddDevice from "./AddDevice";
import { useQueryParams } from "use-query-params";
import { SEARCH_PARAMS } from "../constants";
import { DEVICE_QUERY_PARAMS } from "../lib/queriesParams.jsx";
import useGetQueryData from "../hooks/useGetQueryData.js";
import { isCompanysDecisionMaker } from "../utils/roleUtils.js";

export const DashboardNoDevice = () => {
    const [query, setQuery] = useQueryParams(DEVICE_QUERY_PARAMS);
    const { data: userRole } = useGetQueryData({ queryKey: "fetchProfile", select: "data.company.company_access.company_role" });

    const ButtonTextStyle = {
        _hover: {
            bg: "#57BA47",
        },
        fontFamily: "fontFamily",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
        letterSpacing: "-0.01em",
        color: "#FFFFFF",
    };

    return (
        <>
            <Box w="full" h="72vh" marginTop="16px" borderRadius="8px" bg="white" border="2px solid #EDEDEDCC" overflow="hidden">
                <VStack h="full" align="center" justify="center">
                    <VStack position="relative" display="flex" alignItems="center" justifyContent="center" spacing={4}>
                        <Image src={CentralSensorImage} width="198px" height="198px" />

                        <Text fontFamily="fontFamily" fontSize="20px" lineHeight="30px" letterSpacing="-0.0.01em" fontWeight="700" textAlign="center">
                            No Devices to show. Add Device to view details
                        </Text>
                    </VStack>

                    {isCompanysDecisionMaker(userRole) && (
                        <Box paddingTop="20px">
                            <Button
                                w="192px"
                                h="40px"
                                bg="#57BA47"
                                fontWeight="1000"
                                sx={ButtonTextStyle}
                                aria-label="Add Room"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                onClick={() => setQuery({ [SEARCH_PARAMS.DEVICE.ADD.key]: "success" })}
                            >
                                <Image src={plusIcon} alt="add" h="16px" w="16px" mr="3px" />
                                Add Device
                            </Button>
                        </Box>
                    )}
                </VStack>
            </Box>

            <AddDevice />
        </>
    );
};
