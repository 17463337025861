import { Box, Flex, HStack, Image, space, Text, VStack } from "@chakra-ui/react";
import axios from "axios";
import { LuChevronLeft } from "react-icons/lu";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import notification from "../assets/error-notification.svg";
import moment from "moment-timezone";

export const AllNotification = () => {
    const { space } = useParams();
    const navigate = useNavigate();
    const [selectedNotificationType, setSelectedNotificationType] = useState("all");

    const { data: allNotifications = { data: [] }, refetch } = useQuery({
        queryKey: ["fetchAllNotifications"],
        enabled: true,
    });

    const { data: userId } = useQuery({
        queryKey: "fetchProfile",
        enabled: false,
        select: function (data) {
            return data?.data?.user?.id;
        },
    });

    const filteredNotifications = allNotifications.data.filter((notification) => {
        if (selectedNotificationType === "all") return true;
        return selectedNotificationType === "Unread" ? !notification.is_read : false;
    });

    const updateNotification = async (id, device_id, log_id, breached_sensor) => {
        try {
            const response = await axios.patch(`/notification`, { notification_id: id });
            if (response.status === 200) {
                refetch();
                // navigate(`/dashboard/${space}/devices/${device_id}`);
                navigate(`/dashboard/${space}/devices/${device_id}?logId=${log_id}&breachedSensor=${breached_sensor}`);
            }
        } catch (error) {
            console.error("Error in notification update:", error);
        }
    };

    const markAllAsRead = async (user_id) => {
        try {
            const response = await axios.patch(`/notification/all`, { user_id });
            if (response.status === 200) {
                refetch();
            }
        } catch (error) {
            console.error("Error marking all notifs as read:", error);
        }
    };

    return (
        <Box>
            <HStack
                display="flex"
                w="100%"
                h="110px"
                bg="#F8FBEE"
                position="fixed"
                top="0px"
                bottom="0px"
                zIndex="1000"
                boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
            >
                <Box display="flex" gap={4} alignItems="center" justifyContent="center" ml="3rem">
                    <LuChevronLeft size={24} onClick={() => navigate(-1)} cursor="pointer" />
                    <Text
                        fontFamily="fontFamily"
                        fontSize="28px"
                        fontWeight="700"
                        lineHeight="42px"
                        letterSpacing="-0.01em"
                        textAlign="left"
                        color="rgba(17, 37, 14, 1)"
                    >
                        Notification
                    </Text>
                </Box>
            </HStack>

            {/*  */}
            <Box display="flex" justifyContent="center" height="100vh">
                <Box mt="10rem" bg="#FFFFFF" border="1px solid #11250E1A" borderRadius="8px" width="92%" overflow="hidden">
                    <Flex paddingY={4} paddingX={4} borderBottom="1px solid #E0E5E0">
                        <VStack align="start" spacing={6} width="100%">
                            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                <HStack>
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="14px"
                                        fontWeight="600"
                                        borderRadius="24px"
                                        padding="8px 16px"
                                        cursor="pointer"
                                        bgColor={selectedNotificationType === "all" ? "#6CC35E" : "#F8F8F8"}
                                        color={selectedNotificationType === "all" ? "#FFFFFF" : "#818681"}
                                        onClick={() => setSelectedNotificationType("all")}
                                    >
                                        All
                                    </Text>
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="14px"
                                        fontWeight="600"
                                        borderRadius="24px"
                                        padding="8px 16px"
                                        cursor="pointer"
                                        bgColor={selectedNotificationType === "Unread" ? "#6CC35E" : "#F8F8F8"}
                                        color={selectedNotificationType === "Unread" ? "#FFFFFF" : "#818681"}
                                        onClick={() => setSelectedNotificationType("Unread")}
                                    >
                                        Unread
                                    </Text>
                                </HStack>
                                <Text
                                    color="rgba(150, 152, 153, 1)"
                                    textDecoration="underline"
                                    fontFamily="fontFamily"
                                    fontSize="14px"
                                    fontWeight="500"
                                    lineHeight="-0.01%"
                                    borderRadius="20px"
                                    cursor="pointer"
                                    onClick={() => markAllAsRead(userId)}
                                >
                                    Mark all as read
                                </Text>
                            </Box>
                        </VStack>
                    </Flex>

                    <Box
                        overflowX="hidden"
                        // flex="1"
                        height="calc(100% - 100px)"
                        overflowY="auto"
                        sx={{
                            "&::-webkit-scrollbar": {
                                width: "0px",
                            },
                            "&:hover::-webkit-scrollbar": {
                                width: "8px",
                            },
                            "&::-webkit-scrollbar-track": {
                                background: "transparent",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                background: "transparent",
                                borderRadius: "10px",
                            },
                            "&:hover::-webkit-scrollbar-thumb": {
                                background: "rgba(104, 110, 103, 0.3)",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                background: "rgba(104, 110, 103, 0.3)",
                            },
                        }}
                    >
                        <Box align="start" width="100%" spacing={0}>
                            {filteredNotifications.map((item) => (
                                <Box
                                    display="flex"
                                    key={item.id}
                                    paddingY={4}
                                    paddingX={2}
                                    borderLeft={item.is_read ? "transparent" : "3.5px solid rgba(197, 16, 16, 1)"}
                                    borderBottom="1px solid #E0E5E0"
                                    width="100%"
                                    cursor="pointer"
                                    spacing={0}
                                    onClick={() => updateNotification(item.id, item?.device, item?.log_id, item?.breached_sensor)}
                                    _hover={{
                                        boxShadow: "0px 4px 12px rgba(0,0,0,0.15)",
                                        transition: "box-shadow 0.2s ease",
                                    }}
                                >
                                    <Box>
                                        <Image src={notification} mx={5} w="30px" h="30px" />
                                    </Box>
                                    <VStack align="start" spacing={0}>
                                        <Text
                                            fontFamily="fontFamily"
                                            fontWeight="500"
                                            fontSize="16px"
                                            lineHeight="24px"
                                            letterSpacing="-0.01em"
                                            color="#65696A"
                                        >
                                            <Text as="span" fontWeight="800">
                                                {item?.deviceNotification?.name}
                                            </Text>{" "}
                                            {item.text_content}
                                        </Text>
                                        <Box mt="1rem">
                                            <Text
                                                fontFamily="fontFamily"
                                                fontWeight="600"
                                                fontSize="12px"
                                                lineHeight="16px"
                                                letterSpacing="-0.01em"
                                                color="#969899"
                                            >
                                                {moment(item.created_at).format("DD-MM-YYYY HH:mm A")}
                                            </Text>
                                        </Box>
                                    </VStack>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
