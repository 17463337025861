import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Box } from "@chakra-ui/react";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TemperatureChart = ({ data, selectedGraph, selectedType, deviceData }) => {
    const sortedItems = data?.sort((a, b) => new Date(a.fetch_time) - new Date(b.fetch_time));
    const filteredData = [...new Map(sortedItems?.map((item) => [item.fetch_time, item])).values()].slice(-10);

    const Max = deviceData?.[selectedGraph === "sensor1" ? "sensor_one" : "sensor_two"]?.max_value;
    const Min = deviceData?.[selectedGraph === "sensor1" ? "sensor_one" : "sensor_two"]?.min_value;

    const chartData = {
        labels: filteredData.map((point) => new Date(point.fetch_time).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false })),
        datasets: [
            {
                label: "Max",
                data: new Array(filteredData?.length).fill(Max),
                borderDash: [10, 5], // Dashed line for upper range
                borderWidth: 2,
                pointRadius: 0,
                borderColor: "#EA5D5D77",
                type: "line",
            },
            {
                label: selectedType === "humidity" ? "Humidity" : "Temperature",
                data: filteredData.map((point) => (selectedGraph === "sensor1" ? point.sensor_one : point.sensor_two)),
                backgroundColor: selectedType === "humidity" ? "#99E9E9" : "#FFCC00",
                borderColor: selectedType === "humidity" ? "#99E9E9" : "#FFCC00",
                borderWidth: 1,
                barThickness: 24,
                borderRadius: 4,
            },
            {
                label: "Min",
                data: new Array(filteredData?.length).fill(Min),
                borderDash: [10, 5], // Dashed line for lower range
                pointRadius: 0,
                borderWidth: 2,
                borderColor: "#EA5D5D77",
                type: "line",
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Time",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                title: {
                    display: true,
                    text: selectedType === "humidity" ? "Humidity" : "Temperature",
                },
                // min: 0,
                // max:
                //     Math.ceil(
                //         (Math.max(...filteredData.map((point) => (selectedGraph === "sensor1" ? point.sensor_one : point.sensor_two))) + 10) / 5,
                //     ) * 5,
                ticks: {
                    stepSize: 1,
                },
            },
        },
        plugins: {
            legend: false,
            tooltip: {
                enabled: true,
                mode: "index",
                intersect: false,
            },
        },
    };

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column">
            <Box flex="1" display="flex">
                <Bar data={chartData} options={options} style={{ width: "100%", height: "200px" }} />
            </Box>
        </Box>
    );
};

export default TemperatureChart;
