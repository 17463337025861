import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormLabel,
    Input,
    Text,
    HStack,
    Select,
    InputGroup,
    InputRightElement,
    FormErrorMessage,
    FormControl,
    Image,
} from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CustomButton from "./CustomButton";
import plusIcon from "../assets/Plus_Icon.png";
import Step2EditDevice from "./Step2EditDevice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useQueryParams } from "use-query-params";
import { DEVICE_QUERY_PARAMS } from "../lib/queriesParams";
import { SEARCH_PARAMS } from "../constants";
const HumidityHumidity = undefined;
const inputStyle = {
    borderRadius: "4px",
    border: "1px solid #81868180",
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    color: "#000000",
    _focus: {
        border: "1.5px solid #57BA47",
        boxShadow: "none",
    },
    "::placeholder": {
        color: "#818681",
        opacity: 0.4,
    },
};

const labelStyle = {
    fontFamily: "fontFamily",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "-0.01em",
    marginBottom: "4px",
    color: "#11250E",
};

const fetchDevicesBySpace = async (space) => {
    const response = await axios.get(`/device`, { params: { space_id: space } });
    return response.data;
};

const EditDevice = () => {
    const queryClient = useQueryClient();
    const { space, room } = useParams();
    const [query, setQuery] = useQueryParams(DEVICE_QUERY_PARAMS);
    const isOpen = query[SEARCH_PARAMS.DEVICE.EDIT.key] !== "";

    const { data: rooms, ...roomsQuery } = useQuery({
        queryKey: ["fetchRooms", space],
        enabled: false,
    });

    const { data: prevDevice } = useQuery({
        queryKey: ["fetchDevices", space],
        queryFn: () => fetchDevicesBySpace(space),
        enabled: !!space,
        select: (data) => {
            return data.data?.find((item) => item.id === query[SEARCH_PARAMS.DEVICE.EDIT.key]);
        },
    });

    const clearQuery = () => {
        setQuery({
            [SEARCH_PARAMS.DEVICE.EDIT.key]: SEARCH_PARAMS.DEVICE.EDIT.default_value,
            [SEARCH_PARAMS.DEVICE.EDIT_STATUS.key]: SEARCH_PARAMS.DEVICE.EDIT_STATUS.default_value,
        });
    };

    const validation = useFormik({
        initialValues: {
            name: prevDevice?.name || "",
            sensor_one_min_value: prevDevice?.sensor_one?.min_value || prevDevice?.sensor_one?.min_limit || "",
            sensor_one_max_value: prevDevice?.sensor_one?.max_value || prevDevice?.sensor_one?.max_limit || "",
            sensor_two_min_value: prevDevice?.sensor_two?.min_value || prevDevice?.sensor_two?.min_limit || "",
            sensor_two_max_value: prevDevice?.sensor_two?.max_value || prevDevice?.sensor_two?.max_limit || "",
            room: prevDevice?.room || room || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().min(3, "Name is too short.").required("Name is required."),
            sensor_one_min_value: Yup.number().required("Min value is required."),
            sensor_one_max_value: Yup.number().required("Max value is required."),
            sensor_two_min_value: Yup.number().required("Min value is required."),
            sensor_two_max_value: Yup.number().required("Max value is required."),
            room: Yup.string().required("Room selection is required."),
        }),
        onSubmit: (values) => {
            updateDeviceMutation.mutate(values);
        },
        enableReinitialize: true,
    });

    const updateDeviceMutation = useMutation({
        mutationFn: async (values) => {
            const response = await axios.patch(
                `/device`,
                {
                    name: values.name,
                    room: values.room,
                    sensor_one_data: { min_value: values.sensor_one_min_value, max_value: values.sensor_one_max_value },
                    sensor_two_data: { min_value: values.sensor_two_min_value, max_value: values.sensor_two_max_value },
                },
                {
                    params: { device_id: prevDevice.id },
                },
            );
            return response;
        },
        onSuccess: (response) => {
            validation.resetForm();
            queryClient.refetchQueries(["fetchDevices", space]);
            setQuery({ [SEARCH_PARAMS.DEVICE.EDIT_STATUS.key]: "success" });
        },
        onError: (error) => {
            console.error(error?.response?.data || error);
        },
    });

    return (
        <>
            <Drawer placement="right" isOpen={isOpen} onClose={clearQuery}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <Box mt={{ base: "0px", sm: "0px", md: "5px", lg: "10px" }} mr="10px">
                        <DrawerCloseButton sx={{ color: "#11250E", p: "8px", bg: "#F6F6F6" }} />
                        <DrawerHeader
                            fontFamily="fontFamily"
                            fontSize="28px"
                            fontWeight="800"
                            lineHeight="42px"
                            letterSpacing="-0.04em"
                            textAlign="left"
                            color="#11250E"
                        >
                            Edit Device
                        </DrawerHeader>
                    </Box>

                    <DrawerBody>
                        <Box mb={4} p={5} bg="#F8F8F8">
                            <Text fontFamily="fontFamily" align="left" color="#818681" letterSpacing="-0.01em" fontSize="14px" fontWeight="800">
                                You are setting up this device in{" "}
                                <Box as="span" color="#11250E">
                                    {prevDevice?.roomDevice?.name}.
                                </Box>
                            </Text>
                            <Text align="left" color="#818681" fontSize="14px" fontWeight="500">
                                The device will monitor {(prevDevice?.sensor_one?.sensor_type === HumidityHumidity && "Humidity") || "Temperature"}{" "}
                                and {(prevDevice?.sensor_two?.sensor_type === HumidityHumidity && "Humidity") || "Temperature"} inside the room.
                            </Text>
                        </Box>
                        <Box mb={4}>
                            <FormControl isInvalid={validation.errors.room && validation.touched.room} isRequired>
                                <FormLabel htmlFor="room" sx={labelStyle}>
                                    Select Room
                                </FormLabel>
                                <Select
                                    id="room"
                                    placeholder="Select room"
                                    sx={inputStyle}
                                    value={validation.values.room}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                >
                                    {rooms?.data?.length > 0 &&
                                        rooms.data.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                </Select>
                                <FormErrorMessage>{validation.errors.room}</FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box mb={4}>
                            <FormControl isInvalid={validation.errors.name && validation.touched.name} isRequired>
                                <FormLabel htmlFor="name" sx={labelStyle}>
                                    Enter Device Name
                                </FormLabel>
                                <Input
                                    id="name"
                                    placeholder="ex: Device 01"
                                    type="text"
                                    sx={inputStyle}
                                    value={validation.values.name}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.name}</FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box mb={4}>
                            <Text sx={labelStyle}>Set Range for this device</Text>
                            <Text fontSize="13px" fontWeight="400" color="#818681">
                                Configure the range for temperature and humidity.
                            </Text>
                        </Box>
                        {["sensor_one", "sensor_two"].map((sensor, index) => (
                            <Box mb={4} key={sensor}>
                                <FormLabel fontWeight="600" fontSize="14px" color="#11250E">
                                    {(sensor === "sensor_one" && "Sensor One ") || (sensor === "sensor_two" && "Sensor Two ")}{" "}
                                    {prevDevice?.[sensor]?.sensor_type === HumidityHumidity ? "Humidity Range" : "Temperature Range"}
                                </FormLabel>
                                <HStack spacing="1">
                                    <InputGroup>
                                        <Input
                                            id={`${sensor}_min_value`}
                                            placeholder="Min. Value..."
                                            sx={inputStyle}
                                            value={validation.values[`${sensor}_min_value`]}
                                            onChange={validation.handleChange}
                                        />
                                        <InputRightElement pointerEvents="none">
                                            {prevDevice?.[sensor]?.sensor_type === HumidityHumidity ? "%" : "°C"}
                                        </InputRightElement>
                                    </InputGroup>
                                    <InputGroup>
                                        <Input
                                            id={`${sensor}_max_value`}
                                            placeholder="Max. Value..."
                                            sx={inputStyle}
                                            value={validation.values[`${sensor}_max_value`]}
                                            onChange={validation.handleChange}
                                        />
                                        <InputRightElement pointerEvents="none">
                                            {prevDevice?.[sensor]?.sensor_type === HumidityHumidity ? "%" : "°C"}
                                        </InputRightElement>
                                    </InputGroup>
                                </HStack>
                                <FormErrorMessage>{validation.errors[`${sensor}_min_value`]}</FormErrorMessage>
                                <FormErrorMessage>{validation.errors[`${sensor}_max_value`]}</FormErrorMessage>
                            </Box>
                        ))}
                    </DrawerBody>

                    <DrawerFooter>
                        <Flex direction="column" justifyContent="center" gap={2} width="100%">
                            <CustomButton
                                width="100%"
                                h="40px"
                                bg="#57BA47"
                                sx={{
                                    _hover: { bg: "#57BA47" },
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                }}
                                onClick={validation.handleSubmit}
                                disabled={!validation.isValid || updateDeviceMutation.isLoading}
                            >
                                Done
                            </CustomButton>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <Step2EditDevice />
        </>
    );
};

export default EditDevice;
