import * as React from "react";
import {
    Flex,
    Select,
    Box,
    VStack,
    Text,
    HStack,
    Button,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Image,
    Td,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Icon,
    useDisclosure,
} from "@chakra-ui/react";

import { BsThreeDotsVertical } from "react-icons/bs";
import selectIcon from "../../assets/selectIcon.png";
import { InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { CheckCircleIcon } from "@chakra-ui/icons";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import EditDevice from "../EditDevice";
import temp from "../../assets/temsvg.svg";
import humidity from "../../assets/drop.svg";
import Battery from "../../assets/fullBattery.svg";
import DeleteDevice from "../DeleteDevice";
import ManageDevice from "../manageDevice";
import notification from "../../assets/Notification.svg";
import offlineIcon from "../../assets/offlineIcon.png";
import onlineIcon from "../../assets/onlineIcon.png";
import bellIcon from "../../assets/bell.svg";
import deleteIcon from "../../assets/delete.svg";
import editIcon from "../../assets/edit.svg";
import { formatUnitForSensorType } from "../../utils/sensorUnitUtils";
import { Notification } from "../notification";
import { useQueryParams } from "use-query-params";
import { SEARCH_PARAMS } from "../../constants";
import { DEVICE_QUERY_PARAMS } from "../../lib/queriesParams";
import useGetQueryData from "../../hooks/useGetQueryData";
import { isCompanysDecisionMaker } from "../../utils/roleUtils";

const HumidityHumidity = undefined;

const SelectIcon = () => {
    return <img src={selectIcon} alt="menu" width="15.8px" height="7px" />;
};

const OnlineIcon = () => {
    return <Image src={onlineIcon} alt="onlineIcon" w="13px" h="13px" />;
};

const OfflineIcon = () => {
    return <Image src={offlineIcon} alt="offlineIcon" w="13px" h="13px" />;
};

const BellIcon = () => {
    return <Image src={bellIcon} alt="onlineIcon" w="20px" h="20px" />;
};

const DeleteIcon = () => {
    return <Image src={deleteIcon} alt="onlineIcon" w="20px" h="20px" />;
};

const EditIcon = () => {
    return <Image src={editIcon} alt="onlineIcon" w="20px" h="20px" />;
};

const fetchRooms = async (space) => {
    const res = await axios.get(`/room`, {
        params: { space_id: space },
    });
    return res?.data;
};

const FilterBox = ({ space, filterState, setFilterState, selectedSearchText, setSelectedSearchText, lowBattery, setLowBattery }) => {
    const { data: rooms = { data: [] } } = useQuery({
        queryKey: ["fetchRooms", space],
        queryFn: () => fetchRooms(space),
        enabled: !!space,
    });

    const [search, setSearch] = React.useState("");

    const filteredRooms = rooms?.data?.filter((room) => room.name.toLowerCase().includes(search.toLowerCase()));

    const handleToggle = () => {
        setLowBattery((prev) => !prev);
    };

    const [hoveredOption, setHoveredOption] = React.useState(false);
    const colorScheme = {
        border: "1.5px solid",
        borderColor: lowBattery || hoveredOption ? "rgba(87, 186, 71, 1)" : "rgba(129, 134, 129, 0.5)",
    };

    return (
        <Flex w="95%" h="42px" mt="32px" display="flex" justifyContent="space-between" alignItems="center">
            <HStack>
                <Menu>
                    <MenuButton
                        p="8px 16px"
                        w="383px"
                        h="40px"
                        backgroundColor="#FFFFFF"
                        fontFamily="fontFamily"
                        fontSize="12px"
                        fontWeight="400"
                        cursor="pointer"
                        position="relative"
                        transition="all 0.2s"
                        border="1px solid rgba(129, 134, 129, 0.5)"
                        borderRadius="4px"
                        _focus={{ boxShadow: "none", border: "1.5px solid", borderColor: "rgba(87, 186, 71, 1)" }}
                        _hover={{ boxShadow: "none", border: "1.5px solid", borderColor: "rgba(87, 186, 71, 1)" }}
                    >
                        <HStack w="full" justifyContent="space-between">
                            <HStack>
                                <Text
                                    fontSize="12px"
                                    fontWeight="500"
                                    fontFamily="fontFamily"
                                    lineHeight="16px"
                                    letterSpacing="0.01em"
                                    color="#818681"
                                >
                                    Area:
                                </Text>
                                <Text
                                    fontSize="12px"
                                    fontWeight="600"
                                    fontFamily="fontFamily"
                                    lineHeight="16px"
                                    letterSpacing="0.01em"
                                    color="#11250E"
                                >
                                    {selectedSearchText || "All"}
                                </Text>
                            </HStack>
                            <SelectIcon />
                        </HStack>
                    </MenuButton>
                    <MenuList w="383px" boxShadow="3px 15px 30px 0px #0000000D" border="none">
                        <Box w="85%" align="center" mt="5%" mb="5%" ml={{ base: "", sm: "", md: "", lg: "6%", xl: "6%" }}>
                            <Text fontFamily="fontFamily" fontWeight="bold" align="left" mb="2%">
                                List of areas
                            </Text>
                            <InputGroup align="left" sx={{ borderColor: "#81868180", borderRadius: "4px" }}>
                                <InputLeftElement pointerEvents="none">
                                    <SearchIcon color="#81868180" />
                                </InputLeftElement>
                                <Input
                                    type="text"
                                    placeholder="Search area by name"
                                    w="300px"
                                    h="40px"
                                    border="1px solid #81868180"
                                    _focus={{ boxShadow: "none", border: "1.5px solid", borderColor: "rgba(87, 186, 71, 1)" }}
                                    _hover={{ boxShadow: "none", border: "1.5px solid", borderColor: "rgba(87, 186, 71, 1)", cursor: "pointer" }}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </InputGroup>
                        </Box>
                        <Box
                            h="150px"
                            overflowY="auto"
                            mb="5%"
                            css={{
                                "&::-webkit-scrollbar": {
                                    width: "8px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    background: "#bec3c9",
                                    borderRadius: "24px",
                                },
                            }}
                        >
                            {filteredRooms.map((item) => (
                                <MenuItem key={item.id} minH="48px" onClick={() => setSelectedSearchText(item.name)}>
                                    <Flex gap={4} ml="2%">
                                        {item?.name === selectedSearchText ? (
                                            <Icon as={CheckCircleIcon} color="#57BA47" w="25px" h="25px" />
                                        ) : (
                                            <div style={{ width: "16px", height: "16px", marginRight: "3px" }} />
                                        )}

                                        <Text fontFamily="fontFamily" fontWeight="600">
                                            {item.name}
                                        </Text>
                                    </Flex>
                                </MenuItem>
                            ))}
                        </Box>
                    </MenuList>
                </Menu>

                <Button
                    borderRadius="4px"
                    justifyContent="space-between"
                    padding={2}
                    width="180px"
                    minWidth="180px"
                    cursor="pointer"
                    color="#11250E"
                    backgroundColor="#FFFFFF"
                    border={colorScheme.border}
                    borderColor={colorScheme.borderColor}
                    onClick={handleToggle}
                    onMouseEnter={() => setHoveredOption(true)}
                    onMouseLeave={() => setHoveredOption(false)}
                >
                    <Flex justify="center" align="center" width="100%">
                        <Text fontSize="12px" fontWeight="500" fontFamily="fontFamily" lineHeight="16px" letterSpacing="0.01em">
                            Filter By Low Battery
                        </Text>
                    </Flex>
                </Button>
            </HStack>

            <HStack
                spacing={2}
                h="40px"
                display="flex"
                alignItems="center"
                borderRadius="4px"
                position="relative"
                background="#FFFFFF"
                padding="4px"
                marginLeft={4}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    padding="2px 8px"
                    gap="1"
                    bgColor={filterState === "all" ? "#e9ffdb" : "transparent"}
                    border={filterState === "all" ? "2px solid #6CC35E" : ""}
                    color={filterState === "all" ? "#11250E" : "#818681"}
                    borderRadius={4}
                    onClick={() => {
                        setFilterState("all");
                    }}
                >
                    <Text fontFamily="fontFamily" fontWeight="600" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#11250E">
                        All
                    </Text>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    padding="2px 8px"
                    gap="1"
                    bgColor={filterState === "active" ? "#e9ffdb" : "transparent"}
                    border={filterState === "active" ? "2px solid #6CC35E" : ""}
                    color={filterState === "active" ? "#11250E" : "#818681"}
                    borderRadius={4}
                    onClick={() => {
                        setFilterState("active");
                    }}
                >
                    <OnlineIcon />
                    <Text fontFamily="fontFamily" fontWeight="600" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#11250E">
                        Online
                    </Text>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    padding="2px 8px"
                    gap="1"
                    bgColor={filterState === "inactive" ? "#e9ffdb" : "transparent"}
                    border={filterState === "inactive" ? "2px solid #6CC35E" : ""}
                    color={filterState === "inactive" ? "#11250E" : "#818681"}
                    borderRadius={4}
                    onClick={() => {
                        setFilterState("inactive");
                    }}
                >
                    <OfflineIcon />
                    <Text fontFamily="fontFamily" fontWeight="600" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#11250E">
                        Offline
                    </Text>
                </Box>
            </HStack>
        </Flex>
    );
};

const fetchDevices = async (space) => {
    const res = await axios.get(`/device`, {
        params: { space_id: space },
    });
    return res?.data;
};

const AllDevices = () => {
    const navigate = useNavigate();
    const { space } = useParams();
    const location = useLocation();
    // console.log(location);

    const [filterState, setFilterState] = React.useState("all");
    const [selectedSearchText, setSelectedSearchText] = React.useState("");
    const [lowBattery, setLowBattery] = React.useState(false);
    const [query, setQuery] = useQueryParams(DEVICE_QUERY_PARAMS);
    const { data: userRole } = useGetQueryData({ queryKey: "fetchProfile", select: "data.company.company_access.company_role" });

    const { data: spaces, ...spacesQuery } = useQuery({
        queryKey: "fetchSpaces",
        enabled: false,
        select: function (data) {
            return data?.data?.filter((item) => item.id === space) || [];
        },
    });

    const { data: devices = { data: [] }, ...devicesQuery } = useQuery({
        queryKey: ["fetchDevices", space],
        queryFn: () => fetchDevices(space),
        enabled: !!space,
    });

    React.useEffect(() => {
        const params = new URLSearchParams(location.search); // Read query parameters

        const filterParam = params.get("filter");

        if (filterParam) {
            setFilterState(filterParam); // Set filterState based on query parameter
        }
    }, [location]);
    const filteredDevices = devices?.data?.filter((device) => {
        const isActive = device?.active;
        const isLowBattery = device?.battery < 30;
        const roomDeviceName = device?.roomDevice?.name?.toLowerCase() || "";
        const searchText = selectedSearchText.toLowerCase();
        const matchesSearchText = roomDeviceName.includes(searchText);

        const baseCondition = lowBattery ? isLowBattery : true;

        if (filterState === "all") {
            return baseCondition && matchesSearchText;
        }

        if (filterState === "active") {
            return baseCondition && isActive && matchesSearchText;
        }

        if (filterState === "inactive") {
            return baseCondition && !isActive && matchesSearchText;
        }

        return false;
    });

    const renderMenuItems = (sensor) => {
        const menuItems = [
            {
                label: "Manage Device",
                icon: <BellIcon />,
                action: () => {
                    setQuery({ [SEARCH_PARAMS.DEVICE.MANAGE.key]: sensor?.id });
                },
            },
            {
                label: "Edit Device",
                icon: <EditIcon />,
                action: () => {
                    setQuery({ [SEARCH_PARAMS.DEVICE.EDIT.key]: sensor?.id });
                },
            },
            {
                label: "Delete Device",
                icon: <DeleteIcon />,
                action: () => {
                    setQuery({ [SEARCH_PARAMS.DEVICE.DELETE.key]: sensor?.id });
                },
            },
        ];

        return menuItems.map((item, idx) => (
            <MenuItem
                key={idx}
                icon={item.icon}
                onClick={item.action}
                fontFamily="fontFamily"
                fontSize="14px"
                fontWeight="600"
                lineHeight="26px"
                letterSpacing="-0.01em"
                color="rgba(17, 37, 14, 1)"
                bg="rgba(255, 255, 255, 1)"
                _hover={{ bg: "none" }}
                iconSpacing="14px"
                paddingLeft="18px"
            >
                {item.label}
            </MenuItem>
        ));
    };

    const [isDrawerOpen, setDrawerOpen] = React.useState(false);
    const toggleDrawer = () => {
        setDrawerOpen((prev) => !prev);
    };

    return (
        <Box bg="#F9F9F9" margin={0} width="100%" overflow="hidden">
            <HStack w="80%" h="110px" bg="#F8FBEE" justifyContent="space-between" position="fixed" top="0" zIndex="1000" paddingX="3rem">
                <Text
                    fontFamily="fontFamily"
                    fontSize="28px"
                    fontWeight="800"
                    lineHeight="42px"
                    letterSpacing="-0.04em"
                    textAlign="left"
                    color="#11250E"
                >
                    {spaces?.[0]?.name} / All Devices
                </Text>

                <img src={notification} alt="notification" width="40px" height="40px" style={{ cursor: "pointer" }} onClick={toggleDrawer} />
                {isDrawerOpen && <Notification />}
            </HStack>

            <Box w="100%" h="100vh" mt="7rem">
                <Box bg="#FBFBFB" h="full" w="full">
                    <VStack spacing="24px">
                        <Box w="100%" paddingLeft="3rem">
                            <FilterBox
                                space={space}
                                filterState={filterState}
                                setFilterState={setFilterState}
                                selectedSearchText={selectedSearchText}
                                setSelectedSearchText={setSelectedSearchText}
                                lowBattery={lowBattery}
                                setLowBattery={setLowBattery}
                            />
                        </Box>

                        <Text
                            w="100%"
                            align="start"
                            fontFamily="fontFamily"
                            fontSize="14px"
                            fontWeight="800"
                            lineHeight="20x"
                            letterSpacing="-0.01em"
                            ml="100px"
                        >
                            Total Devices - {devices.data.length}
                        </Text>
                        <TableContainer w="100%">
                            <Table>
                                <Thead fontSize="12px" backgroundColor="#FFFFFF" borderBottom="4px solid #FFFFFF" borderTop="4px solid #FFFFFF">
                                    <Tr>
                                        <Th
                                            key="Name"
                                            textTransform="capitalize"
                                            color="#818681"
                                            fontWeight="600"
                                            textAlign="left"
                                            fontFamily="fontFamily"
                                            letterSpacing="-0.01em"
                                            pl="52px"
                                        >
                                            Name
                                        </Th>
                                        <Th
                                            key="Location"
                                            textTransform="capitalize"
                                            color="#818681"
                                            fontWeight="600"
                                            textAlign="center"
                                            fontFamily="fontFamily"
                                            letterSpacing="0em"
                                        >
                                            Location
                                        </Th>
                                        <Th
                                            key="RecordedOn"
                                            textTransform="capitalize"
                                            color="#818681"
                                            fontWeight="600"
                                            textAlign="center"
                                            fontFamily="fontFamily"
                                            letterSpacing="0em"
                                        >
                                            Recorded On
                                        </Th>
                                        <Th
                                            key="Sensor 1"
                                            textTransform="capitalize"
                                            color="#818681"
                                            fontWeight="600"
                                            textAlign="center"
                                            fontFamily="fontFamily"
                                            letterSpacing="0em"
                                        >
                                            Sensor 1
                                        </Th>
                                        <Th
                                            key="Sensor 2"
                                            textTransform="capitalize"
                                            color="#818681"
                                            fontWeight="600"
                                            textAlign="center"
                                            fontFamily="fontFamily"
                                            letterSpacing="0em"
                                        >
                                            Sensor 2
                                        </Th>
                                        <Th
                                            key="Status"
                                            textTransform="capitalize"
                                            color="#818681"
                                            fontWeight="600"
                                            textAlign="center"
                                            fontFamily="fontFamily"
                                            letterSpacing="0em"
                                        >
                                            Status
                                        </Th>
                                        <Th
                                            key="Battery"
                                            textTransform="capitalize"
                                            color="#818681"
                                            fontWeight="600"
                                            textAlign="center"
                                            fontFamily="fontFamily"
                                            letterSpacing="0em"
                                        >
                                            Battery
                                        </Th>
                                        {isCompanysDecisionMaker(userRole) && (
                                            <Th
                                                key="Actions"
                                                textTransform="capitalize"
                                                color="#818681"
                                                fontWeight="600"
                                                textAlign="center"
                                                fontFamily="fontFamily"
                                                letterSpacing="0em"
                                            >
                                                Actions
                                            </Th>
                                        )}
                                    </Tr>
                                </Thead>
                                <Tbody
                                    variant="striped"
                                    sx={{
                                        "tr:nth-of-type(odd)": { backgroundColor: "#F6F6F6", borderBottom: "1px solid #E0E5E0" },
                                        "tr:nth-of-type(even)": { backgroundColor: "#FFFFFF", borderBottom: "1px solid #E0E5E0" },
                                    }}
                                    fontSize="13px"
                                    mt={0}
                                    pt={0}
                                >
                                    {filteredDevices?.map((sensor, index) => (
                                        <Tr key={index}>
                                            <Td
                                                fontWeight="600"
                                                textAlign="left"
                                                pl="52px"
                                                color="#57BA47"
                                                textDecoration="underline"
                                                cursor="pointer"
                                                onClick={() => navigate(`/dashboard/${space}/devices/${sensor?.id}`)}
                                            >
                                                {sensor?.name}
                                            </Td>
                                            <Td
                                                fontFamily="fontFamily"
                                                fontWeight="600"
                                                fontSize="12px"
                                                lineHeight="16px"
                                                letterSpacing="0.01em"
                                                textAlign="center"
                                            >
                                                {sensor?.roomDevice?.name || "N/A"}
                                            </Td>
                                            <Td
                                                fontFamily="fontFamily"
                                                fontWeight="600"
                                                fontSize="12px"
                                                lineHeight="16px"
                                                letterSpacing="0.01em"
                                                textAlign="center"
                                            >
                                                {new Date(sensor?.last_fetched).toLocaleString()}
                                            </Td>
                                            <Td fontWeight="500" textAlign="center">
                                                {sensor?.sensor_one?.last_value ? (
                                                    <HStack justify="center" align="center" textAlign="center">
                                                        <Image
                                                            src={sensor?.sensor_one?.sensor_type === HumidityHumidity ? humidity : temp}
                                                            alt=""
                                                            w="20px"
                                                            h="20px"
                                                        />
                                                        <Text
                                                            fontFamily="fontFamily"
                                                            fontWeight="600"
                                                            fontSize="12px"
                                                            lineHeight="16px"
                                                            letterSpacing="0.01em"
                                                        >
                                                            {formatUnitForSensorType({
                                                                sensorType: sensor?.sensor_one?.sensor_type,
                                                                value: sensor?.sensor_one?.last_value,
                                                            })}
                                                        </Text>
                                                    </HStack>
                                                ) : (
                                                    ""
                                                )}
                                            </Td>
                                            <Td fontWeight="500" textAlign="center">
                                                {sensor?.sensor_two?.last_value ? (
                                                    <HStack justify="center" align="center" textAlign="center">
                                                        <Image
                                                            src={sensor?.sensor_two?.sensor_type === HumidityHumidity ? humidity : temp}
                                                            alt=""
                                                            w="20px"
                                                            h="20px"
                                                        />
                                                        <Text
                                                            fontFamily="fontFamily"
                                                            fontWeight="600"
                                                            fontSize="12px"
                                                            lineHeight="16px"
                                                            letterSpacing="0.01em"
                                                        >
                                                            {formatUnitForSensorType({
                                                                sensorType: sensor?.sensor_two?.sensor_type,
                                                                value: sensor?.sensor_two?.last_value,
                                                            })}
                                                        </Text>
                                                    </HStack>
                                                ) : (
                                                    ""
                                                )}
                                            </Td>
                                            <Td fontWeight="500" textAlign="center">
                                                <HStack justify="center" align="center" textAlign="center">
                                                    {sensor.active ? <OnlineIcon /> : <OfflineIcon />}
                                                    <Text
                                                        fontFamily="fontFamily"
                                                        fontWeight="600"
                                                        fontSize="12px"
                                                        lineHeight="16px"
                                                        letterSpacing="0.01em"
                                                    >
                                                        {sensor?.active ? "Online" : "Offline"}
                                                    </Text>
                                                </HStack>
                                            </Td>
                                            <Td fontWeight="500" textAlign="center">
                                                <HStack justify="center" align="center" textAlign="center">
                                                    <Text
                                                        fontFamily="fontFamily"
                                                        fontWeight="600"
                                                        fontSize="12px"
                                                        lineHeight="16px"
                                                        letterSpacing="0.01em"
                                                    >
                                                        {sensor?.battery || 0}
                                                    </Text>
                                                    <Image src={Battery} alt="" w="20px" h="20px" />
                                                </HStack>
                                            </Td>
                                            {isCompanysDecisionMaker(userRole) && (
                                                <Td fontWeight="500" textAlign="center" fontSize="16px">
                                                    <Menu>
                                                        <MenuButton>
                                                            <Icon as={BsThreeDotsVertical} w="20px" h="20px" />
                                                        </MenuButton>
                                                        <MenuList>{renderMenuItems(sensor)}</MenuList>
                                                    </Menu>
                                                </Td>
                                            )}
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </VStack>
                </Box>
            </Box>

            <EditDevice />
            <ManageDevice />
            <DeleteDevice />
        </Box>
    );
};

export default AllDevices;
