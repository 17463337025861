import { useNavigate, useParams } from "react-router-dom";
import { useQueries, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Box,
    Card,
    HStack,
    Icon,
    Image,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Grid,
    Text,
    VStack,
    InputGroup,
    InputLeftElement,
    Flex,
    keyframes,
    Divider,
} from "@chakra-ui/react";
import { CgSortAz } from "react-icons/cg";
import CustomButton from "../../components/CustomButton";
import kitchenIcon from "../../assets/kitchen01.png";
import plusIcon from "../../assets/Plus_Icon.png";
import AddRoom from "../../components/AddRoom";
import { BsThreeDotsVertical } from "react-icons/bs";
import EditRoom from "../EditRoom";
import DeleteRoom from "../DeleteRoom";
import notification from "../../assets/Notification.svg";
import { IoIosSearch } from "react-icons/io";
import deleteIcon from "../../assets/delete.svg";
import editIcon from "../../assets/edit.svg";
import { Notification } from "../notification";
import { useQueryParams } from "use-query-params";
import { SEARCH_PARAMS } from "../../constants";
import { ROOM_QUERY_PARAMS } from "../../lib/queriesParams";
import { isCompanysDecisionMaker } from "../../utils/roleUtils";
import useGetQueryData from "../../hooks/useGetQueryData";
import fallback from "../../assets/fallback.svg";

const breatheAnimation = keyframes`
  0% { box-shadow: 0 0 0 2px red; }
  50% { box-shadow: 0 0 0 4px red; }
  100% { box-shadow: 0 0 0 2px red; }
`;

const DeleteIcon = () => {
    return <Image src={deleteIcon} alt="onlineIcon" w="20px" h="20px" />;
};

const EditIcon = () => {
    return <Image src={editIcon} alt="onlineIcon" w="20px" h="20px" />;
};

const fetchRooms = async (space) => {
    const res = await axios.get(`/room`, {
        params: { space_id: space },
    });
    return res?.data;
};

const fetchRoomsForSpace = async (spaceId) => {
    const res = await axios.get(`/room`, {
        params: { space_id: spaceId },
    });
    return res?.data;
};

const fetchDevicesForRoom = async (roomId) => {
    const res = await axios.get(`/device`, {
        params: { room_id: roomId },
    });
    return res?.data;
};

const FilterBox = ({ lastAdded, setLastAdded, setAlphabeticallySorted, alphabeticallySorted, searchText, setSearchText, setQuery }) => {
    const { data: userRole } = useGetQueryData({ queryKey: "fetchProfile", select: "data.company.company_access.company_role" });

    const ButtonTextStyle = {
        _hover: {
            bg: "#57BA47",
        },
        fontFamily: "fontFamily",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
        letterSpacing: "-0.01em",
        color: "#FFFFFF",
    };

    const handleToggle = () => {
        setLastAdded((prev) => !prev);
    };
    const handleAlphabeticalToggle = () => {
        setAlphabeticallySorted((prev) => !prev);
    };

    const [hoveredOption, setHoveredOption] = React.useState(false);

    return (
        <Flex w="95%" h="42px" mt="32px" display="flex" justifyContent="space-between" alignItems="center">
            <HStack>
                <InputGroup>
                    <InputLeftElement pointerEvents="none">
                        <IoIosSearch fontSize={24} color="#81868180" />
                    </InputLeftElement>
                    <Input
                        type="text"
                        placeholder="Start typing room name"
                        w="300px"
                        h="40px"
                        background="#FFFFFF"
                        border="1px solid rgba(192, 195, 192, 0.5)"
                        _focus={{ boxShadow: "none", border: "1.5px solid", borderColor: "rgba(87, 186, 71, 1)" }}
                        _hover={{ boxShadow: "none", border: "1.5px solid", borderColor: "rgba(87, 186, 71, 1)", cursor: "pointer" }}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </InputGroup>

                <Box
                    borderRadius="4px"
                    position="relative"
                    background="#FFFFFF"
                    cursor="pointer"
                    color="#11250E"
                    justifyContent="space-between"
                    padding={2}
                    width="180px"
                    minWidth="180px"
                    backgroundColor="#FFFFFF"
                    border={lastAdded ? "1.5px solid rgba(87, 186, 71, 1)" : "1.5px solid #81868180"}
                    onClick={handleToggle}
                    onMouseEnter={() => setHoveredOption(true)}
                    onMouseLeave={() => setHoveredOption(false)}
                >
                    <Flex justify="center" align="center" width="100%">
                        <Icon as={CgSortAz} h="21px" w="24px" marginRight="8px" />
                        <Box>
                            <Text as="span" marginRight={2}>
                                Sort:
                            </Text>
                            <Text as="span">{!lastAdded ? "Last added" : "First added"}</Text>
                        </Box>
                    </Flex>
                </Box>
                <Box
                    borderRadius="4px"
                    background="#FFFFFF"
                    cursor="pointer"
                    color="#11250E"
                    padding={2}
                    width="180px"
                    minWidth="180px"
                    border={alphabeticallySorted ? "1.5px solid rgba(87, 186, 71, 1)" : "1.5px solid #81868180"}
                    onClick={handleAlphabeticalToggle}
                >
                    <Flex justify="center" align="center" width="100%">
                        <Icon as={CgSortAz} h="21px" w="24px" marginRight="8px" />
                        <Text as="span">Sort: Alphabetical</Text>
                    </Flex>
                </Box>
            </HStack>

            {isCompanysDecisionMaker(userRole) && (
                <HStack>
                    <CustomButton
                        w="192px"
                        h="40px"
                        bg="#57BA47"
                        sx={ButtonTextStyle}
                        aria-label="Add Room"
                        onClick={() => setQuery({ [SEARCH_PARAMS.ROOM.ADD.key]: "success" })}
                    >
                        <Image src={plusIcon} alt="add" h="16px" w="16px" mr="3px" />
                        Add Room
                    </CustomButton>
                    <AddRoom />
                </HStack>
            )}
        </Flex>
    );
};

const Rooms = () => {
    const navigate = useNavigate();
    const { space } = useParams();
    const [lastAdded, setLastAdded] = useState(true);
    const [alphabeticallySorted, setAlphabeticallySorted] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [query, setQuery] = useQueryParams(ROOM_QUERY_PARAMS);
    const { data: userRole } = useGetQueryData({ queryKey: "fetchProfile", select: "data.company.company_access.company_role" });

    const { data: spaces } = useQuery({
        queryKey: "fetchSpaces",
        enabled: false,
        select: function (data) {
            return data?.data?.filter((item) => item.id === space) || [];
        },
    });

    const { data: rooms = { data: [] } } = useQuery({
        queryKey: ["fetchRooms", space],
        queryFn: () => fetchRooms(space),
        enabled: !!space,
    });

    let filteredRooms = rooms?.data?.filter((room) => {
        return room?.name?.toLowerCase()?.includes(searchText.toLowerCase());
    });

    const { data: notifications = { data: [] }, refetch } = useQuery({
        queryKey: ["fetchNotifications"],
        enabled: true,
    });

    if (alphabeticallySorted) {
        filteredRooms = [...filteredRooms].sort((a, b) => a.name.localeCompare(b.name));
    } else if (lastAdded) {
        filteredRooms = [...filteredRooms].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }

    const hoverColor = {
        _hover: {
            bg: "#DDF1DA",
            border: "0.01px solid #11250E",
        },
        fontFamily: "fontFamily",
        fontWeight: "600",
        fontSize: { base: "12px", md: "14px" },
        lineHeight: "20px",
        letterSpacing: "-0.01em",
        color: "#11250E",
    };

    const renderMenuItems = (sensor) => {
        const menuItems = [
            {
                label: "Edit Room",
                icon: <EditIcon />,
                action: () => {
                    setQuery({ [SEARCH_PARAMS.ROOM.EDIT.key]: sensor?.id });
                },
            },
            {
                label: "Delete Room",
                icon: <DeleteIcon />,
                action: () => {
                    setQuery({ [SEARCH_PARAMS.ROOM.DELETE.key]: sensor?.id });
                },
            },
        ];

        return menuItems.map((item, idx) => (
            <MenuItem
                key={idx}
                icon={item.icon}
                onClick={item.action}
                fontFamily="fontFamily"
                fontSize="14px"
                fontWeight="600"
                lineHeight="26px"
                letterSpacing="-0.01em"
                color="rgba(17, 37, 14, 1)"
                bg="rgba(255, 255, 255, 1)"
                _hover={{ bg: "none" }}
                iconSpacing="14px"
                paddingLeft="18px"
            >
                {item.label}
            </MenuItem>
        ));
    };

    // Fetch rooms for the selected space
    const { data: roomsForSelectedSpace = { data: [] } } = useQuery({
        queryKey: ["fetchRoomsForSpace", space],
        queryFn: () => fetchRoomsForSpace(space),
        enabled: !!space,
    });

    // Fetch devices for each room in the selected space using useQueries
    const devicesQueries = useQueries({
        queries:
            roomsForSelectedSpace?.data?.map((room) => ({
                queryKey: ["fetchDevicesForRoom", room.id],
                queryFn: () => fetchDevicesForRoom(room.id),
                enabled: !!room.id,
            })) || [],
    });

    // Combine all devices from all rooms
    const devicesForSelectedSpace = devicesQueries.flatMap((query) => query.data?.data || []);

    // Function to filter notifications by devices in the current space
    const filterNotificationsBySpace = () => {
        const deviceIds = devicesForSelectedSpace.map((device) => device.id);
        return notifications.data.filter((notification) => deviceIds.includes(notification.device));
    };

    const filteredNotifications = filterNotificationsBySpace(space);
    const unreadNotifications = filteredNotifications.filter((notification) => !notification.is_read);
    const unreadCount = filteredNotifications.filter((notification) => !notification.is_read).length;

    // Create a set of room IDs that have devices with unread notifications
    const roomsWithUnreadNotifications = new Set();
    unreadNotifications.forEach((notification) => {
        const device = devicesForSelectedSpace.find((dev) => dev.id === notification.device);
        if (device && device.room) {
            roomsWithUnreadNotifications.add(device.room);
        }
    });

    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setDrawerOpen((prev) => !prev);
    };

    useEffect(() => {}, [notifications]);

    return (
        <Box bg="#F9F9F9" margin={0} paddingBottom={4} w="100%" overflow="hidden" minH="100vh">
            <HStack w="80%" h="110px" bg="#F8FBEE" justifyContent="space-between" position="fixed" top="0" zIndex="1000" paddingX="3rem">
                <Text
                    fontFamily="fontFamily"
                    fontSize="28px"
                    fontWeight="800"
                    lineHeight="42px"
                    letterSpacing="-0.04em"
                    textAlign="left"
                    color="#11250E"
                >
                    {spaces?.[0]?.name} / Rooms
                </Text>

                <HStack position="relative" onClick={toggleDrawer} cursor="pointer">
                    <img src={notification} alt="notification" width="48px" height="48px" />
                    {unreadCount > 0 && (
                        <Box
                            position="absolute"
                            top="0"
                            right="0"
                            bg="red.500"
                            color="white"
                            borderRadius="full"
                            width="24px"
                            height="24px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            fontSize="12px"
                        >
                            {unreadCount}
                        </Box>
                    )}
                </HStack>
                {isDrawerOpen && <Notification notifications={filteredNotifications} refetch={refetch} />}
            </HStack>

            <Box w="100%" mt="7rem">
                <Box w="full">
                    <VStack>
                        <Box w="full" paddingX="3rem" bg="#F6F6F6" pb="1.5rem">
                            <FilterBox
                                lastAdded={lastAdded}
                                setLastAdded={setLastAdded}
                                alphabeticallySorted={alphabeticallySorted}
                                setAlphabeticallySorted={setAlphabeticallySorted}
                                searchText={searchText}
                                setSearchText={setSearchText}
                                setQuery={setQuery}
                            />
                        </Box>

                        {filteredRooms?.length > 0 ? (
                            <Grid
                                paddingX="3rem"
                                templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
                                rowGap="2rem"
                                mt={10}
                                w="100%"
                                bg="#F9F9F9"
                            >
                                {filteredRooms?.map((room) => (
                                    <Card
                                        key={room.id}
                                        w="85%"
                                        h="320px"
                                        borderRadius={8}
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="space-between"
                                        // border={roomsWithUnreadNotifications.has(room.id) ? "2px solid #FF102D" : "none"}
                                        sx={{
                                            animation: roomsWithUnreadNotifications.has(room.id)
                                                ? `${breatheAnimation} 2s infinite ease-in-out`
                                                : "none",
                                        }}
                                        _hover={{
                                            transform: "translateX(1px)",
                                            transition: "transform 0.3s ease",
                                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                            zIndex: 1,
                                        }}
                                    >
                                        <Image
                                            src={room?.image?.length > 0 ? room?.image : kitchenIcon}
                                            alt="room"
                                            w="100%"
                                            h="169px"
                                            borderTopRadius={8}
                                            objectFit="cover"
                                        />
                                        <Box marginX={4} flexGrow={1}>
                                            <Box w="100%" h="48px" mt="24px">
                                                <Box display="flex" justifyContent="space-between" align="center">
                                                    <Text fontFamily="fontFamily" fontWeight="750" fontSize="16px" letterSpacing="-0.01em">
                                                        {room?.name}
                                                    </Text>
                                                    <Text
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        w="82px"
                                                        h="24px"
                                                        fontFamily="fontFamily"
                                                        fontSize="14px"
                                                        borderRadius="40px"
                                                        bg="rgba(255, 255, 255, 1)"
                                                        border="1px solid"
                                                        borderColor="rgba(202, 202, 202, 1)"
                                                        fontWeight="600"
                                                        lineHeight="16.94px"
                                                        text-align="left"
                                                    >
                                                        {`${room?.devices} devices`}
                                                    </Text>
                                                </Box>
                                                <Text
                                                    mt="8px"
                                                    fontFamily="fontFamily"
                                                    fontWeight="400"
                                                    fontSize="12px"
                                                    color="#818681"
                                                    letterSpacing="0.01em"
                                                >
                                                    {room?.description?.split("").slice(0, 45).join("")}..
                                                </Text>
                                            </Box>
                                        </Box>
                                        <Box
                                            w="100%"
                                            h="48px"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            paddingX={4}
                                            mb="0.7rem"
                                            pb="0.5rem"
                                        >
                                            <CustomButton
                                                w="106px"
                                                h="40px"
                                                bg="#DDF1DA"
                                                sx={hoverColor}
                                                borderRadius="4px"
                                                onClick={() => navigate(`${room?.name}/${room?.id}`)}
                                                aria-label="View Room Sensors"
                                            >
                                                View
                                            </CustomButton>
                                            {isCompanysDecisionMaker(userRole) && (
                                                <Menu>
                                                    <MenuButton>
                                                        <Icon as={BsThreeDotsVertical} w="20px" h="20px" />
                                                    </MenuButton>
                                                    <MenuList>{renderMenuItems(room)}</MenuList>
                                                </Menu>
                                            )}
                                        </Box>
                                    </Card>
                                ))}
                            </Grid>
                        ) : (
                            <Box display="flex" h="100%" flexDir="column" justifyContent="center" alignItems="center">
                                <Image src={fallback} p={10} />
                                <Divider borderColor="#DBDBDB" />
                                <Text p={2} fontFamily="fontFamily" fontSize="28px" fontWeight={700} lineHeight="42px" letterSpacing="-1%">
                                    No Rooms To Show!
                                </Text>
                            </Box>
                        )}
                    </VStack>
                </Box>
            </Box>

            <EditRoom />
            <DeleteRoom />
        </Box>
    );
};

export default Rooms;
