import {
    Add_Device2_Toggle_Stepper,
    Add_Device3_Toggle_Stepper,
    Add_Device4_Toggle_Stepper,
    Add_Device_Toggle_Stepper,
    OnboardDevice_Toggle_Stepper,
    Toggle_iFrstCircleCheck,
    Toggle_Step2,
    Toggle_Step3,
    Toggle_Step4,
    Toggle_Step5,
    Toggle_Step6,
    Toggle_Step7,
    Toggle_Step8,
    Toggle_Step9,
    Toggle_StepTopBarSettings,
} from "./actionType";

const initialState = {
    firstCircleCheck: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
    step7: false,
    onboardDevice: false,
    step8: false,
    step9: false,
    ToggleTopBarSettings: false,
    Device_Add1_Stepper: false,
    Add_Device2_Stepper: false,
    Add_Device3_Stepper: false,
    Add_Device4_Stepper: false,
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case Toggle_iFrstCircleCheck:
            // console.log("Reducer - firstCircleCheck:", state.firstCircleCheck); // Log current state value
            return { ...state, firstCircleCheck: !state.firstCircleCheck };
        case Toggle_Step2:
            // console.log("Reducer - Step2:", state.step2); // Log current state value
            return { ...state, step2: !state.step2 };
        case Toggle_Step3:
            // console.log("Reducer - Step3:", state.step3); // Log current state value
            return { ...state, step3: !state.step3 };
        case Toggle_Step4:
            // console.log("Reducer - Step4:", state.step4); // Log current state value
            return { ...state, step4: !state.step4 };
        case Toggle_Step5:
            // console.log("Reducer - Step4:", state.step5); // Log current state value
            return { ...state, step5: !state.step5 };
        case Toggle_Step6:
            // console.log("Reducer - Step4:", state.step6); // Log current state value
            return { ...state, step6: !state.step6 };
        case Toggle_Step7:
            // console.log("Reducer - Step4:", state.step7); // Log current state value
            return { ...state, step7: !state.step7 };
        case Toggle_Step8:
            // console.log("Reducer - Step4:", state.step8); // Log current state value
            return { ...state, step8: !state.step8 };
        case Toggle_Step9:
            // console.log("Reducer - Step4:", state.step9); // Log current state value
            return { ...state, step9: true };
        case Toggle_StepTopBarSettings:
            // console.log("Reducer - Step4:", state.ToggleTopBarSettings); // Log current state value
            return { ...state, ToggleTopBarSettings: !state.ToggleTopBarSettings };
        case Add_Device_Toggle_Stepper:
            // console.log("Reducer - Device_Add1_Stepper", state.Device_Add1_Stepper);
            return { ...state, Device_Add1_Stepper: !state.Device_Add1_Stepper };
        case Add_Device2_Toggle_Stepper:
            // console.log("Reducer - Device_Add2_Stepper", state.Add_Device2_Stepper);
            return { ...state, Add_Device2_Stepper: !state.Add_Device2_Stepper };
        case Add_Device3_Toggle_Stepper:
            // console.log("Reducer - Device_Add3_Stepper", state.Add_Device3_Stepper);
            return { ...state, Add_Device3_Stepper: !state.Add_Device3_Stepper };
        case Add_Device4_Toggle_Stepper:
            // console.log("Reducer - Device_Add3_Stepper", state.Add_Device4_Stepper);
            return { ...state, Add_Device4_Stepper: !state.Add_Device4_Stepper };
        case OnboardDevice_Toggle_Stepper:
            // console.log("Reducer - Device_Add3_Stepper", state.onboardDevice);
            return { ...state, onboardDevice: !state.onboardDevice };
        default:
            return state;
    }
};
