import { useImpersonation } from "../../contexts/useImpersonationContext";

export default function ImpersonationOverlay() {
    const { stopImpersonation } = useImpersonation();
    return (
        <div
            style={{
                minHeight: "100dvh",
                minWidth: "100dvw",
                position: "fixed",
                zIndex: 9999999,
                margin: 0,
                padding: 0,
                top: 0,
                left: 0,
                border: "8px solid black",
                pointerEvents: "none",
            }}
        >
            <div
                style={{
                    backgroundColor: "black",
                    position: "absolute",
                    color: "white",
                    left: "50%",
                    translate: "-50% 0",
                    padding: "0.25rem 0.5rem 0.5rem 0.5rem",
                    borderRadius: "0 0 10px 10px",
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                }}
            >
                <span>Impersonating User</span>
                <button
                    style={{
                        padding: "0.125rem 0.5rem",
                        backgroundColor: "white",
                        color: "black",
                        borderRadius: 5,
                        fontWeight: 500,
                        pointerEvents: "all",
                    }}
                    onClick={stopImpersonation}
                >
                    Stop
                </button>
            </div>
        </div>
    );
}
