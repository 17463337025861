import React, { useState } from "react";
import { Box, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import notification from "../assets/error-notification.svg";
import moment from "moment-timezone";

export const Notification = () => {
    const { space } = useParams();
    const navigate = useNavigate();

    const { data: notifications = { data: [] }, refetch } = useQuery({
        queryKey: ["fetchNotifications"],
        enabled: false,
    });

    const [selectedNotificationType, setSelectedNotificationType] = useState("all");

    const filteredNotifications = notifications.data.filter((notification) => {
        if (selectedNotificationType === "all") return true;
        return selectedNotificationType === "Unread" ? !notification.is_read : false;
    });

    const updateNotification = async (id, device_id) => {
        try {
            const response = await axios.patch(`/notification`, { notification_id: id });
            if (response.status === 200) {
                refetch();
                navigate(`/dashboard/${space}/devices/${device_id}`);
            }
        } catch (error) {
            console.error("Error in notification update:", error);
        }
    };

    return (
        <Box
            position="absolute"
            top="80px"
            right="50px"
            bg="#FFFFFF"
            border="1px solid #11250E1A"
            borderRadius="8px"
            zIndex="2000"
            width="475px"
            height="550px"
            overflow="hidden"
        >
            <Flex paddingY={4} paddingX={4} borderBottom="1px solid #E0E5E0">
                <VStack align="start" spacing={6}>
                    <HStack>
                        <Text fontFamily="fontFamily" fontSize="20px" fontWeight="900" letterSpacing="-0.04em" color="#11250E">
                            Notifications
                        </Text>
                    </HStack>
                    <HStack>
                        <Text
                            fontFamily="fontFamily"
                            fontSize="14px"
                            fontWeight="600"
                            borderRadius="24px"
                            padding="8px 16px"
                            cursor="pointer"
                            bgColor={selectedNotificationType === "all" ? "#6CC35E" : "#F8F8F8"}
                            color={selectedNotificationType === "all" ? "#FFFFFF" : "#818681"}
                            onClick={() => setSelectedNotificationType("all")}
                        >
                            All
                        </Text>
                        <Text
                            fontFamily="fontFamily"
                            fontSize="14px"
                            fontWeight="600"
                            borderRadius="24px"
                            padding="8px 16px"
                            cursor="pointer"
                            bgColor={selectedNotificationType === "Unread" ? "#6CC35E" : "#F8F8F8"}
                            color={selectedNotificationType === "Unread" ? "#FFFFFF" : "#818681"}
                            onClick={() => setSelectedNotificationType("Unread")}
                        >
                            Unread
                        </Text>
                    </HStack>
                </VStack>
            </Flex>

            <Box
                height="calc(100% - 100px)"
                overflowY="auto"
                sx={{
                    "&::-webkit-scrollbar": {
                        width: "0px",
                    },
                    "&:hover::-webkit-scrollbar": {
                        width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        background: "transparent",
                        borderRadius: "10px",
                    },
                    "&:hover::-webkit-scrollbar-thumb": {
                        background: "rgba(104, 110, 103, 0.3)",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "rgba(104, 110, 103, 0.3)",
                    },
                }}
            >
                <VStack align="start" width="100%" spacing={0}>
                    {filteredNotifications.map((item) => (
                        <HStack
                            key={item.id}
                            paddingY={4}
                            paddingX={2}
                            backgroundColor={item.is_read ? "transparent" : "rgba(216, 100, 100, 0.05)"}
                            borderBottom="1px solid #E0E5E0"
                            width="100%"
                            cursor="pointer"
                            spacing={0}
                            onClick={() => updateNotification(item.id, item?.device)}
                            _hover={{
                                transform: "scale(1.05)",
                                transition: "transform 0.2s ease",
                            }}
                        >
                            <Image src={notification} mx={4} />
                            <VStack align="start" spacing={0}>
                                <Text
                                    fontFamily="fontFamily"
                                    fontWeight="500"
                                    fontSize="16px"
                                    lineHeight="24px"
                                    letterSpacing="-0.01em"
                                    color="#65696A"
                                >
                                    <Text as="span" fontWeight="800">
                                        {item?.deviceNotification?.name}
                                    </Text>{" "}
                                    {item.text_content}
                                </Text>
                                <Text
                                    fontFamily="fontFamily"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="16px"
                                    letterSpacing="-0.01em"
                                    color="#969899"
                                >
                                    {moment(item.created_at).format("MM-DD-YYYY HH:mm")}
                                </Text>
                            </VStack>
                        </HStack>
                    ))}
                </VStack>
            </Box>
        </Box>
    );
};
