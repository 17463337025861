import React from "react";
import { Box, VStack, Text, HStack, Image } from "@chakra-ui/react";
import rooms from "../assets/rooms.png";
import devices from "../assets/devices.png";
import offlineIcon from "../assets/offlineIcon.png";
import onlineIcon from "../assets/onlineIcon.png";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { pointer } from "@testing-library/user-event/dist/cjs/pointer/index.js";
import { useNavigate } from "react-router-dom";

const fetchOverview = async (space) => {
    const res = await axios.get(`/space/overview-count`, {
        params: { space_id: space },
    });
    // console.log(res.data);

    return res?.data;
};

export const DashboardCount = ({ space }) => {
    // console.log(space, "space");

    const navigate = useNavigate();
    const { data: overview, ...overviewQuery } = useQuery({
        queryKey: ["fetchOverview", space],
        queryFn: () => fetchOverview(space),
        enabled: !!space,
    });

    //console.log(overview, "data");

    const RedirectToRoom = () => {
        navigate(`/dashboard/${space}/rooms`);
    };

    const RedirectToDevice = () => {
        navigate(`/dashboard/${space}/devices`);
    };

    const handleActive = () => {
        navigate(`/dashboard/${space}/devices?filter=active`);
    };

    const handleInactive = () => {
        navigate(`/dashboard/${space}/devices?filter=inactive`);
    };
    return (
        <Box w="100%">
            <HStack columnGap="2%" spacing={0} flexDirection={{ base: "column", md: "row" }} height={{ base: "auto", md: "136px" }}>
                <Box
                    bg="white"
                    w={{ base: "100%", md: "46vw" }}
                    h="136px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="8px"
                    p="16px 24px"
                    mb={{ base: "16px", md: 0 }}
                    border="2px solid #EDEDEDCC"
                    onClick={RedirectToRoom}
                >
                    <VStack h="full" w="full" cursor="pointer" onClick={RedirectToRoom}>
                        <Text fontFamily="fontFamily" fontSize={{ base: "32px", md: "48px" }} fontWeight="600" align="left" w="full" color="#11250E">
                            {overview?.data?.room || 0}
                        </Text>
                        <Text fontFamily="fontFamily" fontSize={{ base: "14px", md: "16px" }} fontWeight="500" align="left" w="full" color="#818681">
                            Number of Rooms
                        </Text>
                    </VStack>
                    <Image src={rooms} alt="" w="72px" h="72px" align="center" justifyContent="center" cursor="pointer" />
                </Box>

                <Box
                    bg="white"
                    w={{ base: "100%", md: "46vw" }}
                    h="136px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="8px"
                    p="16px 24px"
                    mb={{ base: "16px", md: 0 }}
                    border="2px solid #EDEDEDCC"
                    onClick={RedirectToDevice}
                >
                    <VStack h="full" w="full" cursor="pointer">
                        <Text fontFamily="fontFamily" fontSize={{ base: "32px", md: "48px" }} fontWeight="600" align="left" w="full" color="#11250E">
                            {overview?.data?.device || 0}
                        </Text>
                        <Text fontFamily="fontFamily" fontSize={{ base: "14px", md: "16px" }} fontWeight="500" align="left" w="full" color="#818681">
                            Number of Devices
                        </Text>
                    </VStack>
                    <Image src={devices} alt="" w="72px" h="72px" align="center" justifyContent="center" cursor="pointer" />
                </Box>

                <Box
                    w={{ base: "100%", md: "60vw" }}
                    minW={{ base: "auto", md: "286px" }}
                    h="136px"
                    align="center"
                    justifyContent="center"
                    borderRadius="8px"
                    bg="white"
                    border="2px solid #EDEDEDCC"
                >
                    <HStack h="full" w="full" align="center" justifyContent="center">
                        <Box
                            w={{ base: "100%", md: "28vw" }}
                            h="full"
                            borderRight={{ base: "none", md: "1px solid #DEE3DE" }}
                            align="center"
                            justifyContent="center"
                            p="16px 24px"
                        >
                            <VStack cursor="pointer" onClick={handleActive}>
                                <HStack w="full" align="center" justifyContent="space-between" px={2}>
                                    <Text fontFamily="fontFamily" fontSize={{ base: "32px", md: "48px" }} fontWeight="600" color="#11250E">
                                        {overview?.data?.active || 0}
                                    </Text>
                                    <Image src={onlineIcon} w="20px" h="20px" />
                                </HStack>
                                <Text
                                    color="#818681"
                                    w="full"
                                    textAlign="left"
                                    fontFamily="fontFamily"
                                    fontSize={{ base: "14px", md: "16px" }}
                                    fontWeight="500"
                                >
                                    Devices Online
                                </Text>
                            </VStack>
                        </Box>

                        <Box w={{ base: "100%", md: "28vw" }} h="full" align="center" justifyContent="center" p="16px 24px" onClick={handleInactive}>
                            <VStack cursor="pointer">
                                <HStack w="full" align="center" justifyContent="space-between" px={2}>
                                    <Text fontFamily="fontFamily" fontSize={{ base: "32px", md: "48px" }} fontWeight="600" color="#11250E">
                                        {overview?.data?.inactive || 0}
                                    </Text>
                                    <Image src={offlineIcon} w="20px" h="20px" />
                                </HStack>
                                <Text
                                    color="#818681"
                                    w="full"
                                    textAlign="left"
                                    fontFamily="fontFamily"
                                    fontSize={{ base: "14px", md: "16px" }}
                                    fontWeight="500"
                                >
                                    Devices Offline
                                </Text>
                            </VStack>
                        </Box>
                    </HStack>
                </Box>
            </HStack>
        </Box>
    );
};
