import React from "react";
import { Box, Grid, GridItem, Image, Text, VStack } from "@chakra-ui/react";

import CustomButton from "../components/CustomButton";
import onboarding_setup_device from "../assets/onboarding_setup_device.svg";
import { useDispatch, useSelector } from "react-redux";
import { Add_Device2_Toggle_Stepper_Function, Device_Add_Toggle_Stepper_Function } from "../redux/action";
import { SetUpDevice3 } from "./SetUpDevice3";

export const SetUpDevice2 = ({ serialno, deviceName, connectionKey, roomId }) => {
    const dispatch = useDispatch();
    const Add_Device2_Stepper = useSelector((store) => store.ConditionalRenderingReducer.Add_Device2_Stepper);
    const Heading = {
        color: "#11250E",
        fontFamily: "fontFamily",
        fontSize: "32px",

        fontWeight: "800",
        lineHeight: "42px",
        letterSpacing: "-0.01em",
    };
    const noteStyle = {
        color: "#818681",
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "-0.14%",
    };

    const buttonText = {
        fontFamily: "fontFamily",

        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.17%",
    };
    const Handle_Previous_Add_Device_Page = () => {
        dispatch(Device_Add_Toggle_Stepper_Function());
    };
    const Set_Up_Device = () => {
        // console.log("Okey!");
        dispatch(Add_Device2_Toggle_Stepper_Function());
    };

    const skipToTheDashboardOne = () => {
        window.location.href = "/dashboard";
    };
    return (
        <>
            {Add_Device2_Stepper ? (
                <SetUpDevice3 serialno={serialno} deviceName={deviceName} connectionKey={connectionKey} roomId={roomId} />
            ) : (
                <Grid gridTemplateColumns="45% auto" mt="1rem">
                    <VStack ml="110px">
                        <VStack h="50vh" align="start">
                            <GridItem w="75%" h="5vh" mb="1rem">
                                <Text sx={Heading}>Set up your device</Text>
                            </GridItem>
                            <GridItem w="93%">
                                <Text
                                    color="#000D00"
                                    fontFamily="fontFamily"
                                    fontStyle="normal"
                                    fontSize="16px"
                                    fontWeight="500"
                                    lineHeight="30px"
                                    letterSpacing="-0.16%"
                                >
                                    Please follow the step to set up your device
                                </Text>
                            </GridItem>
                            <GridItem w="99%">
                                <Text mb="0.5rem" sx={noteStyle}>
                                    Insert 2 x AA batteries. The instrument will display 'StArt uP' followed by 'nEEd conn'.
                                </Text>
                            </GridItem>
                        </VStack>
                        <GridItem display="flex" justifyContent="space-between" w="95%">
                            <Box w="30%" display="flex">
                                <CustomButton
                                    bg="rgba(255, 255, 255, 1)"
                                    color="rgba(87, 186, 71, 1)"
                                    border="2px solid"
                                    borderColor="rgba(87, 186, 71, 1)"
                                    onClick={Handle_Previous_Add_Device_Page}
                                >
                                    Back
                                </CustomButton>
                            </Box>
                            <Box display="flex" justifyContent="flex-end" alignItems="center" w="90%" gap="1rem">
                                <CustomButton
                                    color="rgba(0, 0, 0, 1)"
                                    w="30%"
                                    bg="rgba(255, 255, 255, 1)"
                                    sx={buttonText}
                                    onClick={skipToTheDashboardOne}
                                >
                                    Cancel
                                </CustomButton>
                                <CustomButton
                                    color="rgba(255, 255, 255, 1)"
                                    w="27%"
                                    h="40px"
                                    bg="rgba(87, 186, 71, 1)"
                                    sx={buttonText}
                                    onClick={Set_Up_Device}
                                >
                                    Next
                                </CustomButton>
                            </Box>
                        </GridItem>
                    </VStack>

                    <GridItem display="flex" justifyContent="center" alignItems="normal" mt="5rem">
                        <Box w="60%" h="300px" display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="2rem">
                            <Image src={onboarding_setup_device} alt="" />
                        </Box>
                    </GridItem>
                </Grid>
            )}
        </>
    );
};
