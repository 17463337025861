import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import axios from "axios";
import {
    Box,
    Card,
    HStack,
    Icon,
    Image,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Grid,
    Text,
    useDisclosure,
    VStack,
    InputGroup,
    InputLeftElement,
    Flex,
    Button,
} from "@chakra-ui/react";
import { CgSortAz } from "react-icons/cg";
import CustomButton from "../../components/CustomButton";
import kitchenIcon from "../../assets/kitchen01.png";
import plusIcon from "../../assets/Plus_Icon.png";
import AddRoom from "../../components/AddRoom";
import { BsThreeDotsVertical } from "react-icons/bs";
import EditRoom from "../EditRoom";
import DeleteRoom from "../DeleteRoom";
import notification from "../../assets/Notification.svg";
import { IoIosSearch } from "react-icons/io";
import deleteIcon from "../../assets/delete.svg";
import editIcon from "../../assets/edit.svg";
import { Notification } from "../notification";
import { useQueryParams } from "use-query-params";
import { SEARCH_PARAMS } from "../../constants";
import { ROOM_QUERY_PARAMS } from "../../lib/queriesParams";
import { isCompanysDecisionMaker } from "../../utils/roleUtils";
import useGetQueryData from "../../hooks/useGetQueryData";

const DeleteIcon = () => {
    return <Image src={deleteIcon} alt="onlineIcon" w="20px" h="20px" />;
};

const EditIcon = () => {
    return <Image src={editIcon} alt="onlineIcon" w="20px" h="20px" />;
};

const fetchRooms = async (space) => {
    const res = await axios.get(`/room`, {
        params: { space_id: space },
    });
    return res?.data;
};

const FilterBox = ({ lastAdded, setLastAdded, searchText, setSearchText, setQuery }) => {
    const { data: userRole } = useGetQueryData({ queryKey: "fetchProfile", select: "data.company.company_access.company_role" });

    const ButtonTextStyle = {
        _hover: {
            bg: "#57BA47",
        },
        fontFamily: "fontFamily",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
        letterSpacing: "-0.01em",
        color: "#FFFFFF",
    };

    const handleToggle = () => {
        setLastAdded((prev) => !prev);
    };

    const [hoveredOption, setHoveredOption] = React.useState(false);
    const colorScheme = {
        border: "1.5px solid",
        borderColor: lastAdded || hoveredOption ? "rgba(87, 186, 71, 1)" : "rgba(129, 134, 129, 0.5)",
    };

    return (
        <Flex w="95%" h="42px" mt="32px" display="flex" justifyContent="space-between" alignItems="center">
            <HStack>
                <InputGroup>
                    <InputLeftElement pointerEvents="none">
                        <IoIosSearch fontSize={24} color="#81868180" />
                    </InputLeftElement>
                    <Input
                        type="text"
                        placeholder="Start typing room name"
                        w="300px"
                        h="40px"
                        background="#FFFFFF"
                        border="1px solid rgba(192, 195, 192, 0.5)"
                        _focus={{ boxShadow: "none", border: "1.5px solid", borderColor: "rgba(87, 186, 71, 1)" }}
                        _hover={{ boxShadow: "none", border: "1.5px solid", borderColor: "rgba(87, 186, 71, 1)", cursor: "pointer" }}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </InputGroup>

                <Button
                    borderRadius="4px"
                    position="relative"
                    background="#FFFFFF"
                    cursor="pointer"
                    color="#11250E"
                    justifyContent="space-between"
                    padding={2}
                    width="180px"
                    minWidth="180px"
                    backgroundColor="#FFFFFF"
                    border={colorScheme.border}
                    borderColor={colorScheme.borderColor}
                    onClick={handleToggle}
                    onMouseEnter={() => setHoveredOption(true)}
                    onMouseLeave={() => setHoveredOption(false)}
                >
                    <Flex justify="center" align="center" width="100%">
                        <Icon as={CgSortAz} h="21px" w="24px" marginRight="8px" />
                        <Box>
                            <Text
                                as="span"
                                fontFamily="fontFamily"
                                fontSize="14px"
                                fontWeight="500"
                                lineHeight="20px"
                                letterSpacing="-0.01em"
                                textAlign="left"
                                color="rgba(129, 134, 129, 1)"
                                marginRight={2}
                            >
                                Sort:
                            </Text>
                            <Text as="span">Last added</Text>
                        </Box>
                    </Flex>
                </Button>
            </HStack>

            {isCompanysDecisionMaker(userRole) && (
                <HStack>
                    <CustomButton
                        w="192px"
                        h="40px"
                        bg="#57BA47"
                        sx={ButtonTextStyle}
                        aria-label="Add Room"
                        onClick={() => setQuery({ [SEARCH_PARAMS.ROOM.ADD.key]: "success" })}
                    >
                        <Image src={plusIcon} alt="add" h="16px" w="16px" mr="3px" />
                        Add Room
                    </CustomButton>
                    <AddRoom />
                </HStack>
            )}
        </Flex>
    );
};

const Rooms = () => {
    const navigate = useNavigate();
    const { space } = useParams();
    const [lastAdded, setLastAdded] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [query, setQuery] = useQueryParams(ROOM_QUERY_PARAMS);
    const { data: userRole } = useGetQueryData({ queryKey: "fetchProfile", select: "data.company.company_access.company_role" });

    const { data: spaces, ...spacesQuery } = useQuery({
        queryKey: "fetchSpaces",
        enabled: false,
        select: function (data) {
            return data?.data?.filter((item) => item.id === space) || [];
        },
    });

    const { data: rooms = { data: [] }, ...roomsQuery } = useQuery({
        queryKey: ["fetchRooms", space],
        queryFn: () => fetchRooms(space),
        enabled: !!space,
    });

    let filteredRooms = rooms?.data?.filter((room) => {
        return room?.name?.toLowerCase()?.includes(searchText.toLowerCase());
    });

    if (lastAdded) {
        filteredRooms = [...filteredRooms].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }

    const hoverColor = {
        _hover: {
            bg: "#DDF1DA",
            border: "0.01px solid #11250E",
        },
        fontFamily: "fontFamily",
        fontWeight: "600",
        fontSize: { base: "12px", md: "14px" },
        lineHeight: "20px",
        letterSpacing: "-0.01em",
        color: "#11250E",
    };

    const renderMenuItems = (sensor) => {
        const menuItems = [
            {
                label: "Edit Room",
                icon: <EditIcon />,
                action: () => {
                    setQuery({ [SEARCH_PARAMS.ROOM.EDIT.key]: sensor?.id });
                },
            },
            {
                label: "Delete Room",
                icon: <DeleteIcon />,
                action: () => {
                    setQuery({ [SEARCH_PARAMS.ROOM.DELETE.key]: sensor?.id });
                },
            },
        ];

        return menuItems.map((item, idx) => (
            <MenuItem
                key={idx}
                icon={item.icon}
                onClick={item.action}
                fontFamily="fontFamily"
                fontSize="14px"
                fontWeight="600"
                lineHeight="26px"
                letterSpacing="-0.01em"
                color="rgba(17, 37, 14, 1)"
                bg="rgba(255, 255, 255, 1)"
                _hover={{ bg: "none" }}
                iconSpacing="14px"
                paddingLeft="18px"
            >
                {item.label}
            </MenuItem>
        ));
    };

    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setDrawerOpen((prev) => !prev);
    };

    return (
        <Box bg="#F9F9F9" margin={0} paddingBottom={4} w="100%" overflow="hidden" minH="100vh">
            <HStack w="80%" h="110px" bg="#F8FBEE" justifyContent="space-between" position="fixed" top="0" zIndex="1000" paddingX="3rem">
                <Text
                    fontFamily="fontFamily"
                    fontSize="28px"
                    fontWeight="800"
                    lineHeight="42px"
                    letterSpacing="-0.04em"
                    textAlign="left"
                    color="#11250E"
                >
                    {spaces?.[0]?.name} / Rooms
                </Text>

                <img src={notification} alt="notification" width="40px" height="40px" style={{ cursor: "pointer" }} onClick={toggleDrawer} />
                {isDrawerOpen && <Notification />}
            </HStack>

            <Box w="100%" mt="7rem">
                <Box w="full">
                    <VStack>
                        <Box w="full" paddingX="3rem" bg="#F6F6F6" pb="1.5rem">
                            <FilterBox
                                lastAdded={lastAdded}
                                setLastAdded={setLastAdded}
                                searchText={searchText}
                                setSearchText={setSearchText}
                                setQuery={setQuery}
                            />
                        </Box>

                        <Grid
                            paddingX="3rem"
                            templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
                            rowGap="2rem"
                            mt={10}
                            w="100%"
                            bg="#F9F9F9"
                        >
                            {filteredRooms?.map((room) => (
                                <Card
                                    key={room.id}
                                    w="85%"
                                    h="320px"
                                    borderRadius={8}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="space-between"
                                    _hover={{
                                        transform: "translateX(1px)",
                                        transition: "transform 0.3s ease",
                                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                        zIndex: 1,
                                    }}
                                >
                                    <Image
                                        src={room?.image?.length > 0 ? room?.image : kitchenIcon}
                                        alt="room"
                                        w="100%"
                                        h="169px"
                                        borderTopRadius={8}
                                        objectFit="cover"
                                    />
                                    <Box marginX={4} flexGrow={1}>
                                        <Box w="100%" h="48px" mt="24px">
                                            <Box display="flex" justifyContent="space-between" align="center">
                                                <Text fontFamily="fontFamily" fontWeight="750" fontSize="16px" letterSpacing="-0.01em">
                                                    {room?.name}
                                                </Text>
                                                <Text
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    w="82px"
                                                    h="24px"
                                                    fontFamily="fontFamily"
                                                    fontSize="14px"
                                                    borderRadius="40px"
                                                    bg="rgba(255, 255, 255, 1)"
                                                    border="1px solid"
                                                    borderColor="rgba(202, 202, 202, 1)"
                                                    fontWeight="600"
                                                    lineHeight="16.94px"
                                                    text-align="left"

                                                    // boxShadow="0px 4px 30px 0px #0000001A"
                                                >
                                                    {`${room?.devices} devices`}
                                                </Text>
                                            </Box>
                                            <Text
                                                mt="8px"
                                                fontFamily="fontFamily"
                                                fontWeight="400"
                                                fontSize="12px"
                                                color="#818681"
                                                letterSpacing="0.01em"
                                            >
                                                {room?.description?.split("").slice(0, 45).join("")}..
                                            </Text>
                                        </Box>
                                    </Box>
                                    <Box
                                        w="100%"
                                        h="48px"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        paddingX={4}
                                        mb="0.7rem"
                                        pb="0.5rem"
                                    >
                                        <CustomButton
                                            w="106px"
                                            h="40px"
                                            bg="#DDF1DA"
                                            sx={hoverColor}
                                            borderRadius="4px"
                                            onClick={() => navigate(`${room?.name}/${room?.id}`)}
                                            aria-label="View Room Sensors"
                                        >
                                            View
                                        </CustomButton>
                                        {isCompanysDecisionMaker(userRole) && (
                                            <Menu>
                                                <MenuButton>
                                                    <Icon as={BsThreeDotsVertical} w="20px" h="20px" />
                                                </MenuButton>
                                                <MenuList>{renderMenuItems(room)}</MenuList>
                                            </Menu>
                                        )}
                                    </Box>
                                </Card>
                            ))}
                        </Grid>
                    </VStack>
                </Box>
            </Box>

            <EditRoom />
            <DeleteRoom />
        </Box>
    );
};

export default Rooms;
