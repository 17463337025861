import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Grid,
    Image,
    Text,
} from "@chakra-ui/react";
import React from "react";
import CustomButton from "../../components/CustomButton";
import deleteclient from "../assets/deleteclient.svg";

const DeleteClient = ({ step1IsOpen, step1onClose }) => {
    const btnRef = React.useRef();

    return (
        <>
            <Drawer isOpen={step1IsOpen} placement="right" onClose={step1onClose} finalFocusRef={btnRef}>
                <DrawerOverlay />

                <DrawerContent borderRadius="10px" m="12px" minWidth={"380px"}>
                    <DrawerCloseButton
                        sx={{ color: "#11250E", p: "1px", bg: "#F6F6F6" }}
                        mt={{ base: 0, sm: 0, md: 0, lg: "29px" }}
                        mr={{ base: 0, sm: 0, md: 0, lg: "10px" }}
                    />
                    <DrawerHeader
                        fontFamily="fontFamily"
                        fontSize="28px"
                        fontWeight="800"
                        lineHeight="42px"
                        letterSpacing="-0.01em"
                        textAlign="left"
                        color="#11250E"
                        mt={{ base: 0, sm: 0, md: 0, lg: "22px" }}
                        ml={{ base: 0, sm: 0, md: 0, lg: "2px" }}
                    >
                        Delete Client
                    </DrawerHeader>
                    <DrawerBody
                        borderRadius="10px"
                        overflowY="auto"
                        sx={{
                            // Hide scroll bar for WebKit browsers
                            "::-webkit-scrollbar": { display: "none" },
                            // Hide scroll bar for Firefox
                            scrollbarWidth: "none",
                        }}
                    >
                        <Box width="330px" h="40px" textAlign="start" ml={{ base: 0, sm: 0, md: 0, lg: "2px" }}>
                            <Text
                                fontFamily="fontFamily"
                                fontSize="16px"
                                fontWeight="500"
                                lineHeight="28px"
                                letterSpacing="-0.01em"
                                textAlign="left"
                                color="background: rgba(0, 0, 0, 1)
"
                            >
                                Are you sure you want to delete Mcdonalds?
                            </Text>
                        </Box>
                        <Box w="220.35px" h="20%" margin="auto" mt="6rem">
                            <Image src={deleteclient} alt="deleteclient" w="100%" />
                        </Box>

                        <Grid column={1} justifyContent="center" mt="7rem">
                            <Box w="379.7px" h="100px" mb="1.5rem" backgroundColor="#F3FAF2">
                                <Box ml="32px" mr="32px" mt="20px">
                                    <Box display="flex">
                                        <Text
                                            fontFamily="fontFamily"
                                            fontSize="16px"
                                            fontWeight="700"
                                            lineHeight="30px"
                                            letterSpacing=" -0.01em"
                                            color="#11250E"
                                        >
                                            Client Name-
                                        </Text>{" "}
                                        <Text
                                            fontFamily="fontFamily"
                                            fontSize="16px"
                                            fontWeight="700"
                                            ml="0.5rem"
                                            lineHeight="30px"
                                            letterSpacing=" -0.01em"
                                        >
                                            Mcdonalods
                                        </Text>
                                    </Box>
                                    {/* <Box display="flex"> */}
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="16px"
                                        fontWeight="400"
                                        lineHeight="30px"
                                        letterSpacing=" -0.01em"
                                        color="#000000"
                                    >
                                        Joined On 15 July 2022
                                    </Text>{" "}
                                    {/* </Box> */}
                                </Box>
                            </Box>
                            <CustomButton
                                w="20rem"
                                h="2.5rem"
                                bg="#57BA47"
                                ml="32.3px"
                                mt="1.5rem"
                                sx={{
                                    _hover: {
                                        bg: "#57BA47",
                                    },
                                }}
                                onClick={step1onClose}
                            >
                                <Text
                                    fontFamily="fontFamily"
                                    fontSize="14px"
                                    fontWeight="600"
                                    lineHeight="20px"
                                    letterSpacing=" -0.01em"
                                    color="#FFFFFF"
                                >
                                    Delete Client
                                </Text>
                            </CustomButton>
                        </Grid>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default DeleteClient;
