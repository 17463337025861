import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, VStack, Text, Input, Image, GridItem } from "@chakra-ui/react";
import CustomButton from "../components/CustomButton";
import logo from "../assets/logo.svg";
import userIcon from "../assets/user.png";

import { Reveal } from "../common/Reveal";
import { Onboarding4 } from "../onboarding/Onboarding4";
import { useDispatch, useSelector } from "react-redux";
import { Toggle_Step2Function, Toggle_Step3Function } from "../redux/action";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export const Onboarding3 = ({ designation, yourname }) => {
    const [companyname, setCompanyName] = useState("");
    const [selectedCompanySize, setSelectedCompanySize] = useState("");
    const [company_id, setCompanyId] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const { data: companyData, ...companyQuery } = useQuery({
        queryKey: "fetchProfile",
        enabled: false,
        select: function (data) {
            return data?.data?.company;
        },
    });

    useEffect(() => {
        if (companyData) {
            setCompanyName(companyData?.name);
            setSelectedCompanySize(companyData?.size);
            setCompanyId(companyData?.id);
        }
    }, [companyData]);

    const Step3 = useSelector((store) => store.ConditionalRenderingReducer.step3);

    const handleType = (e) => {
        setCompanyName(e.target.value);
    };

    const handleCompanySizeClick = (size) => {
        setSelectedCompanySize(size);
    };

    const handleNextPage = async () => {
        // Validate input before making the API call
        if (!yourname || !designation || !companyname || !selectedCompanySize) {
            console.error("Please fill in all the required fields.");
            return false;
        }

        setLoading(true);
        try {
            const response = await axios.patch(`/auth/update`, {
                company_id: company_id,
                name: yourname,
                role: designation.toUpperCase(),
                company: {
                    name: companyname,
                    size: selectedCompanySize,
                },
            });
            if (response.status === 200) {
                dispatch(Toggle_Step3Function());
                return true;
            } else {
                console.error(`Error updating user details: ${response?.data?.error}`);
                return false;
            }
        } catch (error) {
            console.error("Error in submitUserDetail:", error);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const handlePreviousPage2 = () => {
        dispatch(Toggle_Step2Function());
    };

    const ButtonTextStyle1 = {
        bg: "#FFF",
        border: "1px solid",
        borderColor: "#57BA47",

        _hover: {
            bg: "#FFF",
        },
        color: "#57BA47",
        fontFamily: "fontFamily",
        fontSize: "16px",

        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };

    const ButtonTextStyle2 = {
        bg: "#57BA47",
        _hover: {
            bg: "#57BA47",
        },
        color: "#FFF",
        fontFamily: "fontFamily",
        fontSize: "16px",

        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };

    return (
        <>
            {Step3 ? (
                <Onboarding4 designation={designation} yourname={yourname} />
            ) : (
                <Grid w="100%" bg="#F2F2F266" h="100vh" m={0} p={0} gridTemplateColumns="repeat(2,1fr)">
                    <VStack m={0} p={0} display="flex" flexDirection="column">
                        <Box w="79%" display="flex" flexDirection="column" gap="1.5rem" mt="4rem">
                            <Box>
                                <Text color="#11250E" fontFamily="fontFamily" fontSize="28px" fontWeight="700" lineHeight="42px" ml="1rem">
                                    What is the name of your organisation?
                                </Text>
                            </Box>
                            <Box>
                                <Input
                                    placeholder="type company name here"
                                    onChange={handleType}
                                    value={companyname}
                                    type="text"
                                    border="none"
                                    size="lg"
                                    color="rgba(129, 134, 129,1)"
                                    fontFamily="fontFamily"
                                    fontSize="40px"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    lineHeight="60px"
                                    letterSpacing="-0.4px"
                                    textAlign="left"
                                    _focus={{ border: "none", boxShadow: "none" }}
                                    _placeholder={{
                                        color: "rgba(129, 134, 129, 0.50)",
                                        fontFamily: "fontFamily",
                                        fontSize: "40px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "60px",
                                        letterSpacing: "-0.4px",
                                        textAlign: "left",
                                    }}
                                />
                            </Box>
                            <Box ml="1rem">
                                <Text color="#11250E" fontFamily="fontFamily" fontSize="28px" fontWeight="700" lineHeight="42px">
                                    What is the size of your organisation?
                                </Text>
                            </Box>
                            <Reveal hidden={{ opacity: 0, y: 40 }} visible={{ opacity: 1, y: 0 }} delay={0.1}>
                                <Box display="flex" flexDirection="column" rowGap="0.5rem">
                                    <Box display="flex" gap="12px" flexWrap="wrap" ml="1rem">
                                        {["Less than 10", "10 - 50", "50 - 100", "100 - 200", "200 - 500", "500 - 1000", "More than 1000"].map(
                                            (size) => (
                                                <Box
                                                    as="span"
                                                    key={size}
                                                    border="1px solid"
                                                    borderColor="#DDE2DD"
                                                    paddingInline="1rem"
                                                    paddingBlock="0.5rem"
                                                    borderRadius="24px"
                                                    display="grid"
                                                    placeItems="center"
                                                    cursor="pointer"
                                                    bg={selectedCompanySize === size ? "#DDF1DA" : "none"}
                                                    _hover={{
                                                        borderColor: selectedCompanySize === size ? "none" : "#11250E",
                                                    }}
                                                    onClick={() => handleCompanySizeClick(size)}
                                                >
                                                    <Text
                                                        color="#11250E"
                                                        fontFamily="fontFamily"
                                                        fontSize="14px"
                                                        fontWeight="600"
                                                        lineHeight="20px"
                                                        letterSpacing="-0.14px"
                                                        sx={{
                                                            textWrap: "nowrap",
                                                        }}
                                                    >
                                                        {size}
                                                    </Text>
                                                </Box>
                                            ),
                                        )}
                                    </Box>
                                </Box>
                            </Reveal>
                            <Box display="flex" justifyContent={"space-between"} ml="1rem" w="90%">
                                <CustomButton w="15%" sx={ButtonTextStyle1} padding="8px 24px 8px 24px" onClick={handlePreviousPage2}>
                                    Previous
                                </CustomButton>

                                <CustomButton
                                    w="15%"
                                    sx={ButtonTextStyle2}
                                    onClick={handleNextPage}
                                    padding="8px 24px 8px 24px"
                                    disabled={!companyname || !selectedCompanySize || loading}
                                >
                                    Next
                                </CustomButton>
                            </Box>
                        </Box>
                    </VStack>
                    <VStack display="flex" alignItems="start">
                        <Grid
                            w="80%"
                            h="44vh"
                            ml="3rem"
                            borderRadius="16px 16px 0px 0px"
                            background="#F2F2F2"
                            pt="2rem"
                            pl="2rem"
                            pr="2rem"
                            gridTemplateColumns="30% auto"
                            mt="4rem"
                        >
                            <GridItem background="#11250E" borderRadius="8px 0px 0px 0px" align="center">
                                <Image src={logo} alt="" w="19px" h="15px" mt="15%" />
                                <Box
                                    borderRadius="50%"
                                    overflow="hidden"
                                    width="50px"
                                    height="50px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    background="#F2F2F2"
                                    mt="29%"
                                    mb="0.5rem"
                                >
                                    <Image src={userIcon} alt="" />
                                </Box>
                                <Text
                                    color="#FBFBFB"
                                    fontFamily="fontFamily"
                                    fontSize="12px"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    lineHeight="16px"
                                    letterSpacing="-0.14px"
                                >
                                    {yourname}
                                </Text>
                                <Text
                                    mt="0.3rem"
                                    color="#F2F2F280"
                                    fontFamily="fontFamily"
                                    fontSize="10px"
                                    fontStyle="normal"
                                    fontWeight="600"
                                    lineHeight="16px"
                                    letterSpacing="-0.14px"
                                >
                                    {designation}
                                </Text>
                            </GridItem>
                            <GridItem borderRadius="0px 8px 0px 0px" bg="#FFFFFF" align="center" justifyContent="center">
                                <Reveal hidden={{ opacity: 0, x: -40 }} visible={{ opacity: 1, x: 0 }} delay={0.1}>
                                    <VStack
                                        w="75%"
                                        mt="20px"
                                        h="37vh"
                                        borderRadius="4px 4px 0px 0px"
                                        background="rgba(242, 242, 242, 0.40)"
                                        align="start"
                                    >
                                        <Text
                                            color="#11250E"
                                            fontFamily="fontFamily"
                                            fontSize="16px"
                                            fontStyle="normal"
                                            fontWeight="600"
                                            lineHeight="24px"
                                            letterSpacing="-0.16px"
                                            align="start"
                                            opacity="0.5"
                                            mt="12%"
                                            ml="8%"
                                            mb="0.01em"
                                        >
                                            Profile
                                        </Text>
                                        <Box borderColor="#DDE2DD" borderWidth="1px" w="100%"></Box>
                                        <Box
                                            borderRadius="50%"
                                            overflow="hidden"
                                            width="40px"
                                            height="40px"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            background="#F2F2F2"
                                            mt="0.01em"
                                            ml="8%"
                                        >
                                            <Image src={userIcon} alt="" />
                                        </Box>

                                        <Text
                                            color="#11250E"
                                            fontFamily="fontFamily"
                                            fontSize="12px"
                                            fontStyle="normal"
                                            fontWeight="600"
                                            lineHeight="16px"
                                            letterSpacing="-0.12px"
                                            opacity="0.5"
                                            ml="8%"
                                        >
                                            {yourname}
                                        </Text>
                                        <Text
                                            color="#828C80"
                                            fontFamily="fontFamily"
                                            fontSize="10px"
                                            fontStyle="normal"
                                            fontWeight="500"
                                            lineHeight="12px"
                                            letterSpacing="-0.12px"
                                            opacity="0.5"
                                            ml="8%"
                                        >
                                            {designation}
                                        </Text>
                                        <Divider borderColor="#DDE2DD" borderWidth="1px" w="80%" ml="10%" mt="2%" />

                                        <Text
                                            color="#11250E"
                                            fontFamily="fontFamily"
                                            fontSize="12px"
                                            fontStyle="normal"
                                            fontWeight="600"
                                            lineHeight="16px"
                                            letterSpacing="-0.12px"
                                            ml="8%"
                                            mt="4%"
                                            mb="0.01em"
                                        >
                                            {companyname ? companyname : "Company Name Here"}
                                        </Text>
                                        <Text
                                            color="#11250E"
                                            fontFamily="fontFamily"
                                            fontSize="12px"
                                            fontStyle="normal"
                                            fontWeight="600"
                                            lineHeight="16px"
                                            letterSpacing="-0.12px"
                                            ml="8%"
                                        >
                                            {selectedCompanySize ? `${selectedCompanySize}` : "Select your company size"}
                                        </Text>
                                    </VStack>
                                </Reveal>
                            </GridItem>
                        </Grid>
                    </VStack>
                </Grid>
            )}
        </>
    );
};
