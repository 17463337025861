import React, { useEffect, useState } from "react";
import { VStack, Text, Image, GridItem, Grid, Box, Input } from "@chakra-ui/react";
import CustomButton from "../components/CustomButton";
import userIcon from "../assets/user.png";
import downArrow from "../assets/arrow-down.svg";
import yellowLocation from "../assets/yelllowLocation.svg";
import logo from "../assets/logo.svg";
import { Onboarding6 } from "./Onboarding6";
import { Reveal } from "../common/Reveal";
import { Toggle_Step3Function, Toggle_Step6Function } from "../redux/action";
import { useDispatch, useSelector } from "react-redux";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const Onboarding5 = ({ yourname, designation }) => {
    const dispatch = useDispatch();
    const Step6 = useSelector((store) => store.ConditionalRenderingReducer.step6);
    const [spaceId, setSpaceId] = useState("");
    const [space, setSapace] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [company_id, setCompanyId] = useState("");
    const [loading, setLoading] = useState(false);

    const ButtonTextStyle1 = {
        bg: "#FFF",
        border: "1px solid",
        borderColor: "#57BA47",
        _hover: {
            bg: "#FFF",
        },
        color: "#57BA47",
        fontFamily: "fontFamily",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };

    const ButtonTextStyle2 = {
        bg: "#FFFFFF",
        _hover: { bg: "#FFFFFF" },
        color: "#11250E",
        fontFamily: "fontFamily",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };

    const ButtonTextStyle3 = {
        bg: "#57BA47",
        _hover: {
            bg: "#57BA47",
        },
        color: "#FFF",
        fontFamily: "fontFamily",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };

    const { data: companyData, ...companyQuery } = useQuery({
        queryKey: "fetchProfile",
        enabled: false,
        select: function (data) {
            return data?.data?.company;
        },
    });

    useEffect(() => {
        if (companyData) {
            setCompanyId(companyData?.id);
        }
    }, [companyData]);

    const handleSetSpace = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`/space`, {
                name: space,
                company_id: company_id,
                location: {
                    country: country,
                    state: state,
                    city: city,
                    address: address,
                },
            });

            if (response.status === 200) {
                setSpaceId(response?.data?.data?.id);
                // console.log("User Space creation successfull.");
                dispatch(Toggle_Step6Function());
                return true;
            } else {
                console.error(`Error in Space Creation: ${response?.data?.error}`);
                return false;
            }
        } catch (error) {
            console.error("Error in Space Creation:", error);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const handlePreviousPage4 = () => {
        dispatch(Toggle_Step3Function());
    };

    function skipToDashboard() {
        window.location.href = "/dashboard";
    }

    return (
        <>
            {Step6 ? (
                <Onboarding6 spaceId={spaceId} yourname={yourname} designation={designation} space={space} />
            ) : (
                <Grid bg="#F2F2F266" w="100%" h="100vh" gridTemplateColumns="repeat(2,1fr)">
                    <VStack display="flex" flexDirection="column" h="100vh">
                        <Box w="80%" display="flex" flexDirection="column" h="69vh" gap="1rem" mt="0.5rem" ml="2rem">
                            <VStack align="start">
                                <Reveal hidden={{ opacity: 0, y: 40 }} visible={{ opacity: 1, y: 0 }} delay={0.1}>
                                    <Box display="flex" align="start">
                                        <Image src={yellowLocation} alt="" />
                                    </Box>
                                </Reveal>
                                <VStack display="flex" align="left" gap="0.5rem">
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="32px"
                                        fontWeight="800"
                                        lineHeight="40px"
                                        letterSpacing="-0.01em"
                                        textAlign="left"
                                    >
                                        Great !!! Now, let’s set up your space
                                    </Text>
                                    <Text
                                        color="#818681"
                                        fontFamily="fontFamily"
                                        fontSize="14px"
                                        fontWeight="600"
                                        lineHeight="20px"
                                        letterSpacing="-0.01em"
                                        textAlign="left"
                                    >
                                        Fill in the details of your space to begin exploring our InstantEmp features and customize your experience
                                        today.
                                    </Text>
                                </VStack>
                            </VStack>
                            <Grid gridTemplateColumns="1fr" gridColumnGap="1rem">
                                <GridItem display="flex" flexDirection="column" gap="0.5rem" gridColumn="span 1">
                                    <Text
                                        fontFamily="fontFamily"
                                        color="rgba(17, 37, 14, 1)"
                                        fontSize="16px"
                                        fontWeight="750"
                                        lineHeight="30px"
                                        letterSpacing="-0.01em"
                                    >
                                        Space Name *
                                    </Text>
                                    <Input
                                        type="text"
                                        _placeholder={{
                                            fontFamily: "fontFamily",
                                            color: "rgba(129, 134, 129, 1)",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "20px",
                                            letterSpacing: "-0.01em",
                                            alignContent: "start",
                                        }}
                                        placeholder="ex: Space 01"
                                        _hover={{
                                            border: "2px solid",
                                            borderColor: "#57BA47",
                                            boxShadow: "none",
                                        }}
                                        _focus={{
                                            border: "2px solid",
                                            borderColor: "#57BA47",
                                            boxShadow: "none",
                                        }}
                                        value={space}
                                        onChange={(e) => setSapace(e.target.value)}
                                    />
                                </GridItem>

                                <Grid
                                    gridTemplateColumns={{
                                        base: "repeat(1, 1fr)",
                                        md: "repeat(3, 1fr)",
                                    }}
                                    gridColumnGap="1rem"
                                    gridColumn="span 1"
                                >
                                    <GridItem display="flex" flexDirection="column" gap="0.5rem">
                                        <Text
                                            mt="0.5rem"
                                            fontFamily="fontFamily"
                                            color="rgba(17, 37, 14, 1)"
                                            fontSize="16px"
                                            fontWeight="750"
                                            lineHeight="30px"
                                            letterSpacing="-0.01em"
                                        >
                                            Country *
                                        </Text>
                                        <Input
                                            type="text"
                                            _placeholder={{
                                                fontFamily: "fontFamily",
                                                color: "rgba(129, 134, 129, 1)",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "20px",
                                                letterSpacing: "-0.01em",
                                                alignContent: "start",
                                            }}
                                            placeholder="ex: Space 01"
                                            _hover={{
                                                border: "2px solid",
                                                borderColor: "#57BA47",
                                                boxShadow: "none",
                                            }}
                                            _focus={{
                                                border: "2px solid",
                                                borderColor: "#57BA47",
                                                boxShadow: "none",
                                            }}
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                        />
                                    </GridItem>
                                    <GridItem display="flex" flexDirection="column" gap="0.5rem">
                                        <Text
                                            mt="0.5rem"
                                            fontFamily="fontFamily"
                                            color="rgba(17, 37, 14, 1)"
                                            fontSize="16px"
                                            fontWeight="750"
                                            lineHeight="30px"
                                            letterSpacing="-0.01em"
                                        >
                                            State *
                                        </Text>
                                        <Input
                                            type="text"
                                            placeholder="ex: Bridgeton 65/12, North campus 20223 , Australia"
                                            _placeholder={{
                                                fontFamily: "fontFamily",
                                                color: "rgba(129, 134, 129, 1)",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "20px",
                                                letterSpacing: "-0.01em",
                                                alignContent: "start",
                                            }}
                                            _hover={{
                                                border: "2px solid",
                                                borderColor: "#57BA47",
                                                boxShadow: "none",
                                            }}
                                            _focus={{
                                                border: "2px solid",
                                                borderColor: "#57BA47",
                                                boxShadow: "none",
                                            }}
                                            value={state}
                                            onChange={(e) => setState(e.target.value)}
                                        />
                                    </GridItem>
                                    <GridItem display="flex" flexDirection="column" gap="0.5rem">
                                        <Text
                                            mt="0.5rem"
                                            fontFamily="fontFamily"
                                            color="rgba(17, 37, 14, 1)"
                                            fontSize="16px"
                                            fontStyle="normal"
                                            fontWeight="750"
                                            lineHeight="30px"
                                            letterSpacing="-0.01em"
                                        >
                                            City *
                                        </Text>
                                        <Input
                                            type="text"
                                            placeholder="ex: Bridgeton 65/12, North campus 20223 , Australia"
                                            _placeholder={{
                                                fontFamily: "fontFamily",
                                                color: "rgba(129, 134, 129, 1)",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "20px",
                                                letterSpacing: "-0.01em",
                                                alignContent: "start",
                                            }}
                                            _hover={{
                                                border: "2px solid",
                                                borderColor: "#57BA47",
                                                boxShadow: "none",
                                            }}
                                            _focus={{
                                                border: "2px solid",
                                                borderColor: "#57BA47",
                                                boxShadow: "none",
                                            }}
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                        />
                                    </GridItem>
                                </Grid>

                                <GridItem display="flex" flexDirection="column" gap="0.5rem" gridColumn="span 1">
                                    <Text
                                        mt="0.5rem"
                                        fontFamily="fontFamily"
                                        color="rgba(17, 37, 14, 1)"
                                        fontSize="16px"
                                        fontStyle="normal"
                                        fontWeight="750"
                                        lineHeight="30px"
                                        letterSpacing="-0.01em"
                                    >
                                        Street *
                                    </Text>
                                    <Input
                                        type="text"
                                        placeholder="ex: Bridgeton 65/12, North campus 20223 , Australia"
                                        _placeholder={{
                                            fontFamily: "fontFamily",
                                            color: "rgba(129, 134, 129, 1)",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "20px",
                                            letterSpacing: "-0.01em",
                                            alignContent: "start",
                                        }}
                                        _hover={{
                                            border: "2px solid",
                                            borderColor: "#57BA47",
                                            boxShadow: "none",
                                        }}
                                        _focus={{
                                            border: "2px solid",
                                            borderColor: "#57BA47",
                                            boxShadow: "none",
                                        }}
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </GridItem>
                            </Grid>

                            <Box display="flex" justifyContent={"space-between"} w="100%">
                                <CustomButton w="15%" sx={ButtonTextStyle1} onClick={handlePreviousPage4}>
                                    Previous
                                </CustomButton>

                                <Box display="flex" justifyContent="space-between" w="35%">
                                    <CustomButton w="45%" sx={ButtonTextStyle2} onClick={skipToDashboard}>
                                        Skip
                                    </CustomButton>

                                    <CustomButton
                                        w="45%"
                                        sx={ButtonTextStyle3}
                                        disabled={!space || !country || !city || !state || !address || loading}
                                        onClick={handleSetSpace}
                                    >
                                        Next
                                    </CustomButton>
                                </Box>
                            </Box>
                        </Box>
                    </VStack>
                    <VStack display="flex" alignItems="start">
                        <Grid
                            w="80%"
                            h="44vh"
                            ml="3rem"
                            borderRadius="16px 16px 0px 0px"
                            background="#F2F2F2"
                            pt="2rem"
                            pl="2rem"
                            pr="2rem"
                            gridTemplateColumns="30% auto"
                            mt="5rem"
                        >
                            <GridItem background="#11250E" h="39.7vh" borderRadius="8px 0px 0px 0px" align="center">
                                <Image src={logo} alt="" w="19px" h="15px" mt="10%" />
                                <Box
                                    borderRadius="50%"
                                    overflow="hidden"
                                    width="50px"
                                    height="50px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    background="#F2F2F2"
                                    mt="15%"
                                >
                                    <Image src={userIcon} alt="useIcon" />
                                </Box>
                                <Text color="white">{yourname}</Text>
                                <Text color="rgba(242, 242, 242, 0.50)">{designation}</Text>
                                <Box
                                    border="1px solid"
                                    borderColor="#FBFBFB"
                                    w="90%"
                                    h="24px"
                                    mt="1rem"
                                    borderRadius="4px 4px 4px 4px"
                                    padding="4px 10px 4px 10px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Text
                                        color="#FBFBFB"
                                        fontFamily="fontFamily"
                                        fontSize="10px"
                                        fontStyle="normal"
                                        fontWeight="600"
                                        lineHeight="16px"
                                    >
                                        Your Space
                                    </Text>
                                    <Image src={downArrow} alt="arrow" />
                                </Box>
                                <Reveal hidden={{ opacity: 0, y: -20 }} visible={{ opacity: 1, y: 0 }} delay={0.005}>
                                    <Box
                                        w="90%"
                                        h="144px"
                                        bg="rgba(255, 255, 255, 1)"
                                        mt="11px"
                                        borderRadius="4px 4px 4px 4px"
                                        boxShadow="0px 4px 30px 0px rgba(0, 0, 0, 0.05)"
                                    >
                                        <Text
                                            color="rgba(17, 37, 14, 1)"
                                            fontFamily="fontFamily"
                                            fontSize="12px"
                                            fontWeight="400"
                                            lineHeight="16px"
                                            pt="1.5rem"
                                            ml="0.9rem"
                                            align="left"
                                        >
                                            {!space ? " Your Space" : `${space}`}
                                        </Text>
                                    </Box>
                                </Reveal>
                            </GridItem>
                            <GridItem borderRadius="0px 8px 0px 0px" bg="#FFFFFF">
                                <Box bg="#F8FBEE" h="7vh" display="flex" alignItems="center">
                                    <Text
                                        color="#11250E"
                                        ml="2rem"
                                        fontFamily="fontFamily"
                                        fontSize="12px"
                                        fontWeight="600"
                                        lineHeight="16px"
                                        letterSpacing="-0.01em"
                                        textAlign="left"
                                    >
                                        {!space ? " Your Space" : `${space}`}
                                    </Text>
                                </Box>
                            </GridItem>
                        </Grid>
                    </VStack>
                </Grid>
            )}
        </>
    );
};
