import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import blackCrown from "../assets/subCrown.svg";
import basicCheck from "../assets/BasicListCheck.png";
import CustomButton from "./CustomButton";
import { Reveal } from "../common/Reveal";
import { useDispatch, useSelector } from "react-redux";
import { Toggle_Step5Function } from "../redux/action";

export const ModalSubNextStep = () => {
    const Step4 = useSelector((store) => store.ConditionalRenderingReducer.step4);
    const dispatch = useDispatch();
    const handleSpace = () => {
        dispatch(Toggle_Step5Function());
        // console.log(Step4,'ook');;
    };

    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100%"
            bg="rgba(0, 0, 0, 0.5)" // Adjust overlay background as needed
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex="1000" // Ensure the modal appears above other content
        >
            <Box
                bg="rgba(255, 255, 255, 1)"
                width="80%"
                height="610px"
                maxWidth="none"
                borderRadius="32px"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Box width="70%" display="flex" justifyContent="center" alignItems="center">
                    <Box w="100%">
                        <Reveal hidden={{ opacity: 0, y: -60 }} visible={{ opacity: 1, y: 0 }} delay={0.1}>
                            <Box display="flex" alignItems="center" justifyContent="center" w="100%" gap="0.5rem" mb="1.5rem">
                                <Image src={blackCrown} alt="crown" w="56px" h="56px" />
                                <Text
                                    color="rgba(199, 136, 22, 1)"
                                    fontFamily="fontFamily"
                                    fontSize="40px"
                                    fontWeight="700"
                                    lineHeight="24px"
                                    letterSpacing="-0.01em"
                                >
                                    You are now a Premium member
                                </Text>
                            </Box>
                        </Reveal>
                        {[
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ",
                        ].map((ele, index) => (
                            <Box key={index} display="flex" alignItems="center" justifyContent="center" gap="1rem" mb="0.5rem">
                                <Image src={basicCheck} alt="check" w="16px" h="16px" />
                                <Text
                                    fontFamily="fontFamily"
                                    color="rgba(129, 134, 129, 1)"
                                    fontSize="14px"
                                    fontWeight="500"
                                    lineHeight="20px"
                                    letterSpacing="-0.01em"
                                >
                                    {ele}
                                </Text>
                            </Box>
                        ))}
                        <Box display="flex" alignItems="center" justifyContent="center" mt="3rem">
                            <CustomButton
                                color="rgba(255, 255, 255, 1)"
                                bg="rgba(87, 186, 71, 1)"
                                w="17%"
                                _hover={{ bg: "rgba(87, 186, 71, 1)" }}
                                fontFamily="fontFamily"
                                fontSize="16px"
                                fontWeight="600"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                onClick={handleSpace}
                            >
                                Continue
                            </CustomButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
