import React, { memo, useEffect, useRef, useState } from "react";
import {
    Box,
    VStack,
    Text,
    Image,
    HStack,
    Flex,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    useDisclosure,
    keyframes,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Tooltip,
    Spinner,
} from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import temp from "../../assets/temsvg.svg";
import humidity from "../../assets/drop.svg";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import TemperatureLineChart from "../chart2";
import { LuChevronLeft } from "react-icons/lu";
import { RiRadioButtonLine } from "react-icons/ri";
import CustomButton from "../CustomButton";
import { ExportReading } from "../ExportReading";
import ChartFilter from "../CustomChartFilter";
import moment from "moment-timezone";
import { formatUnitForSensorType } from "../../utils/sensorUnitUtils";
import Moment from "moment";
import { ChevronDownIcon, ChevronUpIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import AddReport from "../AddReport";

const HumidityHumidity = undefined;

const fetchLogs = async ({ device, startDate, endDate, readingType }) => {
    const res = await axios.get(`/log`, {
        params: { device_id: device, start_date: startDate, end_date: endDate, reading_type: readingType },
    });
    return res?.data;
};

const fetchDevices = async (device) => {
    const res = await axios.get(`/device`, {
        params: { device_id: device },
    });
    return res?.data?.data;
};

const blinkKeyframes = keyframes`
    0%, 49% {
        background-color: #FFE3E3; /* Highlight color */
    }
    50%, 100% {
        background-color: transparent;
    }
`;

export const blinkAnimation = `${blinkKeyframes} 1s ease-in-out infinite`;

const SensorDevice = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const logRefs = useRef(new Map());
    const queryParams = new URLSearchParams(location.search);
    const logId = queryParams.get("logId");
    const breachedSensor = queryParams.get("breachedSensor");

    const MemoizedTemperatureLineChart = memo(TemperatureLineChart);

    const { device } = useParams();

    const { isOpen: isOpenTab1, onOpen: onOpenTab1, onClose: onCloseTab1 } = useDisclosure();
    const { isOpen: isOpenTab2, onOpen: onOpenTab2, onClose: onCloseTab2 } = useDisclosure();

    const [selectedSensor, setSelectedSensor] = useState("sensor1");
    const [selectedGraphType, setSelectedGraphType] = useState("temperature");
    const [selectedOverview, setSeletedOverview] = useState("MINUTES");
    const { isOpen: exportisOpen, onOpen: exportonOpen, onClose: exportonClose } = useDisclosure();
    const { isOpen: filterIsOpen, onOpen: filterOnOpen, onClose: filterOnClose } = useDisclosure();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const customDateTimeEnabled = !!startDate && !!endDate;
    const [isBreachFilter, setIsBreachFilter] = useState(false);

    const toggleBreachFilter = () => {
        setIsBreachFilter(!isBreachFilter);
    };

    useEffect(() => {
        if (breachedSensor) {
            setSelectedSensor(breachedSensor);
        }
    }, [breachedSensor]);

    const {
        data: logs,
        isLoading: isLogsLoading,
        refetch: refetchLogs,
    } = useQuery({
        queryKey: ["fetchLogs", { device, start_date: startDate, end_date: endDate, reading_type: selectedOverview }],
        queryFn: () => fetchLogs({ device, startDate, endDate, readingType: selectedOverview }),
        enabled: !!device,
    });

    const { data: deviceData } = useQuery({
        queryKey: ["fetchDevices", device],
        queryFn: () => fetchDevices(device),
        enabled: !!device,
    });

    const MaxOne = deviceData?.sensor_one?.max_value;
    const MinOne = deviceData?.sensor_one?.min_value;

    const MaxTwo = deviceData?.sensor_two?.max_value;
    const MinTwo = deviceData?.sensor_two?.min_value;

    const handleOpen = () => {
        exportonOpen();
    };

    const [expandedRowTab1, setExpandedRowTab1] = useState(null);
    const [expandedRowTab2, setExpandedRowTab2] = useState(null);
    const [expandedLogId, setExpandedLogId] = useState(null);
    const [reportData, setReportData] = useState({});

    const handleExpandTab1 = (index, item) => {
        setExpandedRowTab1(expandedRowTab1 === index ? null : index);
        setExpandedLogId(item.id);
    };

    const handleExpandTab2 = (index, item) => {
        setExpandedRowTab2(expandedRowTab2 === index ? null : index);
        setExpandedLogId(item.id);
    };

    useEffect(() => {
        if (deviceData) {
            const sensorType = deviceData?.sensor_one?.sensor_type === HumidityHumidity ? "humidity" : "temperature";
            setSelectedGraphType(sensorType);
        }
    }, [deviceData, logs, logRefs]);

    useEffect(() => {
        if (logId && selectedSensor && deviceData && logs && !isLogsLoading) {
            // const targetRowId = `log-${selectedSensor}-${logId}`;
            // const targetRow = document.getElementById(targetRowId);
            const targetRow = logRefs.current.get(logId);

            if (targetRow) {
                targetRow.scrollIntoView({ behavior: "smooth", block: "center" });
                targetRow.style.animation = blinkAnimation;

                const handleClick = () => {
                    targetRow.style.animation = "none";
                    navigate(location.pathname, { replace: true });
                };

                targetRow.addEventListener("click", handleClick);

                return () => {
                    if (targetRow) {
                        targetRow.removeEventListener("click", handleClick);
                        targetRow.style.animation = "none";
                    }
                };
            } else {
                console.error("No matching row found for logId in query params.");
                refetchLogs();
            }
        }
    }, [logId, selectedSensor, deviceData, isLogsLoading]);

    const isAccordionOpen1 = (logId) => expandedRowTab1 === logId;
    const isAccordionOpen2 = (logId) => expandedRowTab2 === logId;

    const ChevronIcon = memo(({ isOpen, hasReported, isAlert }) => {
        return (
            <span>
                {isAlert &&
                    (isOpen ? (
                        <ChevronUpIcon boxSize="2em" p={1} borderRadius="50%" bg={hasReported ? "#FFCC00" : "#D42929"} color="white" />
                    ) : (
                        <ChevronDownIcon boxSize="2em" p={1} borderRadius="50%" bg={hasReported ? "#FFCC00" : "#D42929"} color="white" />
                    ))}
            </span>
        );
    });

    const {
        data: IncidentData,
        refetch,
        isLoading,
    } = useQuery({
        queryKey: ["getIncident", expandedLogId],
        enabled: !!expandedLogId,
        queryFn: async () => {
            const response = await axios.get(`/log/${expandedLogId}/incident`);
            return response.data;
        },
    });

    // set incident data in the log row
    useEffect(() => {
        if (expandedLogId) {
            refetch();
            refetchLogs();
            // console.log("Manually refetching for log ID:", expandedLogId);
            // console.log("query data: ", IncidentData);
            setReportData(IncidentData?.data);
        }
    }, [expandedLogId, refetch, IncidentData, refetchLogs]);

    // reset expanded row tabs on changing selected sensor
    useEffect(() => {
        setExpandedRowTab1(null);
        setExpandedRowTab2(null);
    }, [selectedSensor]);

    return (
        <Box bg="#F9F9F9" margin={0} width="100%" overflow="hidden">
            <Box marginX={12} marginY={10}>
                <HStack width="100%" justifyContent="space-between">
                    <HStack display="flex" alignItems="center" justifyContent="center" spacing={2}>
                        <LuChevronLeft size={24} onClick={() => navigate(-1)} cursor="pointer" />
                        <Text color="#11250E" fontFamily="fontFamily" fontWeight="bold" fontSize="24px">
                            {deviceData?.name}
                        </Text>
                    </HStack>

                    <HStack display="flex" alignItems="center" justifyContent="center" spacing={2} w="82px" h="24px" borderRadius="40px" bg="#FFFFFF">
                        <RiRadioButtonLine color={deviceData?.active ? "#00b300" : "#b30000"} />
                        <Text
                            fontFamily="fontFamily"
                            fontSize="12px"
                            fontWeight="400"
                            lineHeight="14.52px"
                            letterSpacing="-0.01em"
                            textAlign="start"
                            color="#11250E"
                        >
                            {deviceData?.active ? "Online" : "Offline"}
                        </Text>
                    </HStack>
                </HStack>
            </Box>
            <Box h="full" borderRadius="8px 8px 0px 0px" marginX={12}>
                <Tabs
                    isLazy
                    variant="enclosed"
                    align="start"
                    index={selectedSensor === "sensor1" ? 0 : 1}
                    onChange={(index) => {
                        setSelectedSensor(index === 0 ? "sensor1" : "sensor2");
                        setExpandedRowTab1(null);
                        setExpandedRowTab2(null);
                    }}
                >
                    <TabList>
                        <Tab
                            isDisabled={!deviceData?.sensor_one_enabled || false}
                            color="#11250E"
                            fontFamily="fontFamily"
                            fontSize="12px"
                            fontWeight="600"
                            letterSpacing="-0.01em"
                            onClick={() => setSelectedSensor("sensor1")}
                            border="none"
                            bg={selectedSensor === "sensor1" ? "#FFF" : "transparent"}
                        >
                            <Image src={deviceData?.sensor_one?.sensor_type === HumidityHumidity ? humidity : temp} alt="" w="12px" h="12px" />
                            Sensor 1
                        </Tab>
                        <Tab
                            isDisabled={!deviceData?.sensor_two_enabled || false}
                            color="#11250E"
                            fontFamily="fontFamily"
                            fontSize="12px"
                            fontWeight="600"
                            letterSpacing="-0.01em"
                            onClick={() => setSelectedSensor("sensor2")}
                            border="none"
                            bg={selectedSensor === "sensor2" ? "#FFF" : "transparent"}
                        >
                            <Image src={deviceData?.sensor_two?.sensor_type === HumidityHumidity ? humidity : temp} alt="" w="12px" h="12px" />
                            Sensor 2
                        </Tab>
                    </TabList>
                    {/* seperate stats */}
                    <TabPanels>
                        <TabPanel key={selectedSensor} bg={selectedSensor === "sensor1" ? "#FFF" : "transparent"}>
                            <Box p={6}>
                                <HStack spacing={0} marginBottom={4} justifyContent="space-between" alignItems="center" width="100%">
                                    <VStack gap="0px" justifyContent="start" alignItems="start">
                                        <Text color="#818681" fontFamily="fontFamily" fontWeight="500" fontSize="12px" lineHeight="20px">
                                            LOCATION
                                        </Text>
                                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="16px" lineHeight="30px">
                                            {deviceData?.roomDevice?.name}
                                        </Text>
                                    </VStack>
                                    <VStack gap="0px" justifyContent="start" alignItems="start">
                                        <Text color="#818681" fontFamily="fontFamily" fontWeight="500" fontSize="12px" lineHeight="20px">
                                            DATE
                                        </Text>
                                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="16px" lineHeight="30px">
                                            {moment(deviceData?.last_recorded).format("DD-MM-YYYY")}
                                        </Text>
                                    </VStack>
                                    <VStack gap="0px" justifyContent="start" alignItems="start">
                                        <Text color="#818681" fontFamily="fontFamily" fontWeight="500" fontSize="12px" lineHeight="20px">
                                            TIME
                                        </Text>
                                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="16px" lineHeight="30px">
                                            {moment(deviceData?.last_recorded).format("hh:mm A")}
                                        </Text>
                                    </VStack>
                                    <VStack gap="0px" justifyContent="start" alignItems="start">
                                        <Text color="#818681" fontFamily="fontFamily" fontWeight="500" fontSize="12px" lineHeight="20px">
                                            LAST READING
                                        </Text>
                                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="16px" lineHeight="30px">
                                            {formatUnitForSensorType({
                                                value: deviceData?.sensor_one?.last_value?.toFixed(1),
                                                sensorType: deviceData?.sensor_one?.sensor_type,
                                            })}
                                        </Text>
                                    </VStack>
                                    <VStack gap="0px" justifyContent="start" alignItems="start">
                                        <Text color="#818681" fontFamily="fontFamily" fontWeight="500" fontSize="12px" lineHeight="20px">
                                            MAX
                                        </Text>
                                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="16px" lineHeight="30px">
                                            {MaxOne}
                                            {deviceData?.sensor_one?.sensor_type === HumidityHumidity ? "%" : "°C"}
                                        </Text>
                                    </VStack>
                                    <VStack gap="0px" justifyContent="start" alignItems="start">
                                        <Text color="#818681" fontFamily="fontFamily" fontWeight="500" fontSize="12px" lineHeight="20px">
                                            MIN
                                        </Text>
                                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="16px" lineHeight="30px">
                                            {MinOne}
                                            {deviceData?.sensor_one?.sensor_type === HumidityHumidity ? "%" : "°C"}
                                        </Text>
                                    </VStack>
                                    <VStack gap="0px" justifyContent="start" alignItems="start">
                                        <Text color="#818681" fontFamily="fontFamily" fontWeight="500" fontSize="12px" lineHeight="20px">
                                            BATTERY
                                        </Text>
                                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="16px" lineHeight="30px">
                                            {formatUnitForSensorType({ value: deviceData?.battery?.toFixed(1), sensorType: "Battery" })}
                                        </Text>
                                    </VStack>
                                </HStack>

                                <HStack spacing={0} justifyContent="space-between" width="100%">
                                    <HStack spacing={4} align="flex-start" marginTop={3} marginRight={4} marginBottom={4}>
                                        <Text
                                            fontFamily="fontFamily"
                                            fontSize="12px"
                                            borderRadius="24px"
                                            padding="8px 16px"
                                            bgColor={customDateTimeEnabled ? "#57BA47" : "#F8F8F8"}
                                            color={customDateTimeEnabled ? "#FFFFFF" : "#818681"}
                                            cursor="pointer"
                                            onClick={() => {
                                                filterOnOpen();
                                            }}
                                        >
                                            Custom Date & Time
                                        </Text>
                                        <Text
                                            fontFamily="fontFamily"
                                            fontSize="12px"
                                            borderRadius="24px"
                                            padding="8px 16px"
                                            bgColor={selectedOverview === "DAILY" ? "#57BA47" : "#F8F8F8"}
                                            cursor="pointer"
                                            color={selectedOverview === "DAILY" ? "#FFFFFF" : "#818681"}
                                            onClick={() => setSeletedOverview("DAILY")}
                                        >
                                            Daily
                                        </Text>
                                        <Text
                                            fontFamily="fontFamily"
                                            fontSize="12px"
                                            borderRadius="24px"
                                            padding="8px 16px"
                                            bgColor={selectedOverview === "HOURLY" ? "#57BA47" : "#F8F8F8"}
                                            color={selectedOverview === "HOURLY" ? "#FFFFFF" : "#818681"}
                                            cursor="pointer"
                                            onClick={() => setSeletedOverview("HOURLY")}
                                        >
                                            Hourly
                                        </Text>
                                        <Text
                                            fontFamily="fontFamily"
                                            fontSize="12px"
                                            borderRadius="24px"
                                            padding="8px 16px"
                                            bgColor={selectedOverview === "MINUTES" ? "#57BA47" : "#F8F8F8"}
                                            color={selectedOverview === "MINUTES" ? "#FFFFFF" : "#818681"}
                                            cursor="pointer"
                                            onClick={() => setSeletedOverview("MINUTES")}
                                        >
                                            Minutes
                                        </Text>
                                    </HStack>
                                </HStack>

                                <Flex w="full">
                                    <MemoizedTemperatureLineChart
                                        data={logs?.data}
                                        selectedGraph={selectedSensor}
                                        selectedType={selectedGraphType}
                                        deviceData={deviceData}
                                        overview={selectedOverview}
                                    />
                                </Flex>
                            </Box>
                            <Box p={8}>
                                <VStack>
                                    <HStack mb="20px" width="100%" justifyContent="space-between">
                                        <HStack gap={2} alignItems="center">
                                            <Text
                                                color="#11250E"
                                                fontFamily="fontFamily"
                                                fontSize="20px"
                                                fontWeight="bold"
                                                lineHeight="30px"
                                                letterSpacing="-0.01em"
                                            >
                                                Detailed Statistics
                                            </Text>
                                            <Tooltip
                                                placement="top"
                                                padding={2}
                                                bg="gray"
                                                hasArrow
                                                label={
                                                    <Box display="flex" flexDirection="column">
                                                        <Text>Evaluate the breakdown of incidents</Text>
                                                        <Flex alignItems="center" mb={1}>
                                                            <Box bg="#FFCC00" borderRadius="9999px" w="12px" h="12px" mr={2} />
                                                            Reported
                                                        </Flex>
                                                        <Flex alignItems="center">
                                                            <Box bg="#D42929" borderRadius="9999px" w="12px" h="12px" mr={2} />
                                                            Unreported
                                                        </Flex>
                                                    </Box>
                                                }
                                            >
                                                <InfoOutlineIcon color="#57BA47" />
                                            </Tooltip>
                                        </HStack>
                                        <HStack gap={2} alignItems="center">
                                            <CustomButton
                                                px="1.5rem"
                                                fontFamily="fontFamily"
                                                fontSize="14px"
                                                fontWeight="700"
                                                lineHeight="20px"
                                                letterSpacing="-0.01em"
                                                textAlign="left"
                                                color="#11250E"
                                                borderRadius="4px"
                                                border={isBreachFilter ? "2px solid #6CC35E" : "1px solid #11250E"}
                                                bg="transparent"
                                                onClick={toggleBreachFilter}
                                            >
                                                <InfoOutlineIcon color="#11250E" mr={2} />
                                                Breach
                                            </CustomButton>

                                            <CustomButton
                                                px="2.5rem"
                                                bg="#57BA47"
                                                fontFamily="fontFamily"
                                                fontSize="14px"
                                                fontWeight="700"
                                                lineHeight="20px"
                                                letterSpacing="-0.01em"
                                                textAlign="left"
                                                color="#FFFFFF"
                                                onClick={handleOpen}
                                            >
                                                Export
                                            </CustomButton>
                                        </HStack>
                                    </HStack>
                                    <TableContainer w="100%">
                                        <Table
                                            variant="contained"
                                            sx={{ "tr:nth-of-type(even)": { backgroundColor: "#F6F6F6" }, tableLayout: "fixed" }}
                                            colorScheme="#F6F6F6"
                                            fontSize="12px"
                                        >
                                            <Thead bg="#F6F6F6" h="48px">
                                                <Tr>
                                                    <Th
                                                        textTransform="capitalize"
                                                        fontWeight="bold"
                                                        textAlign="center"
                                                        fontFamily="fontFamily"
                                                        fontSize="16px"
                                                        lineHeight="30px"
                                                        letterSpacing="-0.01em"
                                                        alignContent="center"
                                                        paddingBlock="18px"
                                                    >
                                                        Date
                                                    </Th>
                                                    <Th
                                                        textTransform="capitalize"
                                                        fontWeight="bold"
                                                        textAlign="center"
                                                        fontFamily="fontFamily"
                                                        fontSize="16px"
                                                        lineHeight="30px"
                                                        letterSpacing="-0.01em"
                                                        alignContent="center"
                                                        paddingBlock="18px"
                                                    >
                                                        Reading Time
                                                    </Th>
                                                    <Th
                                                        textTransform="capitalize"
                                                        fontWeight="bold"
                                                        textAlign="center"
                                                        fontFamily="fontFamily"
                                                        fontSize="16px"
                                                        lineHeight="30px"
                                                        letterSpacing="-0.01em"
                                                        alignContent="center"
                                                        paddingBlock="18px"
                                                    >
                                                        Readings
                                                    </Th>
                                                    <Th
                                                        textTransform="capitalize"
                                                        fontWeight="bold"
                                                        textAlign="center"
                                                        fontFamily="fontFamily"
                                                        fontSize="16px"
                                                        lineHeight="30px"
                                                        letterSpacing="-0.01em"
                                                        alignContent="center"
                                                        paddingBlock="18px"
                                                    >
                                                        Min/Max
                                                    </Th>
                                                    <Th
                                                        textTransform="capitalize"
                                                        fontWeight="bold"
                                                        textAlign="center"
                                                        fontFamily="fontFamily"
                                                        fontSize="16px"
                                                        lineHeight="30px"
                                                        letterSpacing="-0.01em"
                                                        alignContent="center"
                                                        paddingBlock="18px"
                                                    >
                                                        Status
                                                    </Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {logs &&
                                                    logs?.data
                                                        ?.filter((item) => !isBreachFilter || item.is_alert)
                                                        ?.map((item, index) => {
                                                            const rowColor = index % 2 === 0 ? "#FFFFFF" : "#F6F6F6";
                                                            const isOpen = selectedSensor === "sensor1" ? isOpenTab1 : isOpenTab2;
                                                            const onOpen = selectedSensor === "sensor1" ? onOpenTab1 : onOpenTab2;
                                                            const onClose = selectedSensor === "sensor1" ? onCloseTab1 : onCloseTab2;
                                                            return (
                                                                <React.Fragment key={`log-${selectedSensor}-${item.id}`}>
                                                                    <Tr
                                                                        id={`log-${selectedSensor}-${item.id}`}
                                                                        data-log-id={item.id}
                                                                        data-sensor-type={selectedSensor}
                                                                        ref={(el) => {
                                                                            if (el && item.id) {
                                                                                logRefs.current.set(item.id, el);
                                                                            }
                                                                        }}
                                                                        sx={{
                                                                            cursor: "pointer",
                                                                            animation: item.id === logId ? blinkAnimation : "none",
                                                                        }}
                                                                        onClick={() => handleExpandTab1(index, item)}
                                                                    >
                                                                        <Td
                                                                            fontFamily="fontFamily"
                                                                            fontSize="14px"
                                                                            fontWeight="bold"
                                                                            lineHeight="30px"
                                                                            letterSpacing="-0.01em"
                                                                            textAlign="center"
                                                                        >
                                                                            {Moment(item?.fetch_time).format("DD-MM-YYYY")}
                                                                        </Td>
                                                                        <Td
                                                                            fontFamily="fontFamily"
                                                                            fontSize="14px"
                                                                            lineHeight="30px"
                                                                            fontWeight="bold"
                                                                            letterSpacing="-0.01em"
                                                                            textAlign="center"
                                                                        >
                                                                            {Moment(item?.fetch_time).format("hh:mm A")}
                                                                        </Td>
                                                                        <Td
                                                                            fontFamily="fontFamily"
                                                                            fontSize="14px"
                                                                            lineHeight="30px"
                                                                            fontWeight="bold"
                                                                            letterSpacing="-0.01em"
                                                                            textAlign="center"
                                                                        >
                                                                            {typeof item?.sensor_one === "number"
                                                                                ? item?.sensor_one.toFixed(1)
                                                                                : item?.sensor_one}
                                                                            {deviceData?.sensor_one?.sensor_type === HumidityHumidity ? "%" : "°C"}
                                                                        </Td>
                                                                        <Td
                                                                            fontFamily="fontFamily"
                                                                            fontSize="14px"
                                                                            lineHeight="30px"
                                                                            fontWeight="bold"
                                                                            letterSpacing="-0.01em"
                                                                            textAlign="center"
                                                                        >
                                                                            {MinOne +
                                                                                (deviceData?.sensor_one?.sensor_type === HumidityHumidity
                                                                                    ? "%"
                                                                                    : "°C") +
                                                                                "/" +
                                                                                MaxOne +
                                                                                (deviceData?.sensor_one?.sensor_type === HumidityHumidity
                                                                                    ? "%"
                                                                                    : "°C")}
                                                                        </Td>
                                                                        <Td
                                                                            fontFamily="fontFamily"
                                                                            fontSize="14px"
                                                                            lineHeight="30px"
                                                                            fontWeight="bold"
                                                                            letterSpacing="-0.01em"
                                                                            textAlign="center"
                                                                        >
                                                                            <ChevronIcon
                                                                                isOpen={isAccordionOpen1(index)}
                                                                                hasReported={item.has_report}
                                                                                isAlert={item.is_alert}
                                                                            />
                                                                        </Td>
                                                                    </Tr>
                                                                    {/* Accordion Content */}
                                                                    {expandedRowTab1 === index && item.is_alert && (
                                                                        <Tr>
                                                                            <Td colSpan={5} bg={rowColor} padding={8}>
                                                                                <Box bg={rowColor} borderRadius="8px" padding="16px 32px">
                                                                                    <Box
                                                                                        mt={2}
                                                                                        h="100px"
                                                                                        borderRadius="8px"
                                                                                        display="flex"
                                                                                        flexDirection="column"
                                                                                        alignItems="center"
                                                                                        gap="12px"
                                                                                        justifyContent="center"
                                                                                    >
                                                                                        {expandedLogId === item.id && item.has_report ? (
                                                                                            <Box alignSelf="stretch">
                                                                                                {isLoading ? (
                                                                                                    <Spinner />
                                                                                                ) : (
                                                                                                    <TableContainer>
                                                                                                        <Table
                                                                                                            variant="unstyled"
                                                                                                            sx={{
                                                                                                                "tr:nth-of-type(odd)": {
                                                                                                                    backgroundColor: "transparent",
                                                                                                                    borderBottom:
                                                                                                                        "1px solid transparent",
                                                                                                                },
                                                                                                                "tr:nth-of-type(even)": {
                                                                                                                    backgroundColor: "transparent",
                                                                                                                    borderBottom:
                                                                                                                        "1px solid transparent",
                                                                                                                },
                                                                                                                tableLayout: "fixed",
                                                                                                            }}
                                                                                                            size="sm"
                                                                                                        >
                                                                                                            <Tbody>
                                                                                                                <Tr>
                                                                                                                    <Td>
                                                                                                                        <Text fontWeight="600">
                                                                                                                            Incident Reason:
                                                                                                                        </Text>
                                                                                                                    </Td>
                                                                                                                    <Td>
                                                                                                                        {reportData?.incident_reason}
                                                                                                                    </Td>
                                                                                                                </Tr>
                                                                                                                <Tr>
                                                                                                                    <Td>
                                                                                                                        <Text fontWeight="600">
                                                                                                                            Corrective Actions:
                                                                                                                        </Text>
                                                                                                                    </Td>
                                                                                                                    <Td>
                                                                                                                        {
                                                                                                                            reportData?.corrective_actions
                                                                                                                        }
                                                                                                                    </Td>
                                                                                                                </Tr>
                                                                                                                <Tr>
                                                                                                                    <Td>
                                                                                                                        <Text fontWeight="600">
                                                                                                                            Product Loss:
                                                                                                                        </Text>
                                                                                                                    </Td>
                                                                                                                    <Td>
                                                                                                                        {reportData?.product_loss}
                                                                                                                    </Td>
                                                                                                                </Tr>
                                                                                                                <Tr>
                                                                                                                    <Td>
                                                                                                                        <Text fontWeight="600">
                                                                                                                            Safety Status:
                                                                                                                        </Text>
                                                                                                                    </Td>
                                                                                                                    <Td>
                                                                                                                        {reportData?.safety_status}
                                                                                                                    </Td>
                                                                                                                </Tr>
                                                                                                                <Tr>
                                                                                                                    <Td>
                                                                                                                        <Text fontWeight="600">
                                                                                                                            Reported By:
                                                                                                                        </Text>
                                                                                                                    </Td>
                                                                                                                    <Td>
                                                                                                                        {
                                                                                                                            reportData?.reportedByUser
                                                                                                                                ?.name
                                                                                                                        }
                                                                                                                    </Td>
                                                                                                                </Tr>
                                                                                                            </Tbody>
                                                                                                        </Table>
                                                                                                    </TableContainer>
                                                                                                )}
                                                                                            </Box>
                                                                                        ) : (
                                                                                            <>
                                                                                                <CustomButton
                                                                                                    bg="#D42929"
                                                                                                    color="#FFFFFF"
                                                                                                    px="36px"
                                                                                                    py="5px"
                                                                                                    onClick={onOpen}
                                                                                                >
                                                                                                    Add Report
                                                                                                </CustomButton>
                                                                                                <Text
                                                                                                    as="p"
                                                                                                    fontSize="10px"
                                                                                                    lineHeight="12px"
                                                                                                    letterSpacing="-1%"
                                                                                                    fontStyle="italic"
                                                                                                >
                                                                                                    *Help us improve safety by submitting a report.
                                                                                                </Text>
                                                                                                <AddReport
                                                                                                    isOpen={isOpen}
                                                                                                    onReportSuccess={() => {
                                                                                                        refetch();
                                                                                                        onClose();
                                                                                                    }}
                                                                                                    onClose={onClose}
                                                                                                    deviceId={device}
                                                                                                    logId={item.id}
                                                                                                    occuredAt={item.fetch_time}
                                                                                                    sensorType={selectedSensor}
                                                                                                />
                                                                                            </>
                                                                                        )}
                                                                                    </Box>
                                                                                </Box>
                                                                            </Td>
                                                                        </Tr>
                                                                    )}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </VStack>
                            </Box>
                        </TabPanel>
                        <TabPanel key={selectedSensor} bg={selectedSensor === "sensor2" ? "#FFF" : "transparent"}>
                            <Box p={6}>
                                <HStack spacing={0} marginBottom={4} justifyContent="space-between" alignItems="center" width="100%">
                                    <VStack gap="0px" justifyContent="start" alignItems="start">
                                        <Text color="#818681" fontFamily="fontFamily" fontWeight="500" fontSize="12px" lineHeight="20px">
                                            LOCATION
                                        </Text>
                                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="16px" lineHeight="30px">
                                            {deviceData?.roomDevice?.name}
                                        </Text>
                                    </VStack>
                                    <VStack gap="0px" justifyContent="start" alignItems="start">
                                        <Text color="#818681" fontFamily="fontFamily" fontWeight="500" fontSize="12px" lineHeight="20px">
                                            DATE
                                        </Text>
                                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="16px" lineHeight="30px">
                                            {moment(deviceData?.last_recorded).format("DD-MM-YYYY")}
                                        </Text>
                                    </VStack>
                                    <VStack gap="0px" justifyContent="start" alignItems="start">
                                        <Text color="#818681" fontFamily="fontFamily" fontWeight="500" fontSize="12px" lineHeight="20px">
                                            TIME
                                        </Text>
                                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="16px" lineHeight="30px">
                                            {moment(deviceData?.last_recorded).format("hh:mm A")}
                                        </Text>
                                    </VStack>
                                    <VStack gap="0px" justifyContent="start" alignItems="start">
                                        <Text color="#818681" fontFamily="fontFamily" fontWeight="500" fontSize="12px" lineHeight="20px">
                                            LAST READING
                                        </Text>
                                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="16px" lineHeight="30px">
                                            {formatUnitForSensorType({
                                                value: deviceData?.sensor_two?.last_value?.toFixed(1),
                                                sensorType: deviceData?.sensor_two?.sensor_type,
                                            })}
                                        </Text>
                                    </VStack>
                                    <VStack gap="0px" justifyContent="start" alignItems="start">
                                        <Text color="#818681" fontFamily="fontFamily" fontWeight="500" fontSize="12px" lineHeight="20px">
                                            MAX
                                        </Text>
                                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="16px" lineHeight="30px">
                                            {MaxTwo}
                                            {deviceData?.sensor_two?.sensor_type === HumidityHumidity ? "%" : "°C"}
                                        </Text>
                                    </VStack>
                                    <VStack gap="0px" justifyContent="start" alignItems="start">
                                        <Text color="#818681" fontFamily="fontFamily" fontWeight="500" fontSize="12px" lineHeight="20px">
                                            MIN
                                        </Text>
                                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="16px" lineHeight="30px">
                                            {MinTwo}
                                            {deviceData?.sensor_two?.sensor_type === HumidityHumidity ? "%" : "°C"}
                                        </Text>
                                    </VStack>
                                    <VStack gap="0px" justifyContent="start" alignItems="start">
                                        <Text color="#818681" fontFamily="fontFamily" fontWeight="500" fontSize="12px" lineHeight="20px">
                                            BATTERY
                                        </Text>
                                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="16px" lineHeight="30px">
                                            {formatUnitForSensorType({ value: deviceData?.battery?.toFixed(1), sensorType: "Battery" })}
                                        </Text>
                                    </VStack>
                                </HStack>

                                <HStack spacing={0} justifyContent="space-between" width="100%">
                                    <HStack spacing={4} align="flex-start" marginTop={3} marginRight={4} marginBottom={4}>
                                        <Text
                                            fontFamily="fontFamily"
                                            fontSize="12px"
                                            borderRadius="24px"
                                            padding="8px 16px"
                                            bgColor={customDateTimeEnabled ? "#57BA47" : "#F8F8F8"}
                                            color={customDateTimeEnabled ? "#FFFFFF" : "#818681"}
                                            cursor="pointer"
                                            onClick={() => {
                                                filterOnOpen();
                                            }}
                                        >
                                            Custom Date & Time
                                        </Text>
                                        <Text
                                            fontFamily="fontFamily"
                                            fontSize="12px"
                                            borderRadius="24px"
                                            padding="8px 16px"
                                            bgColor={selectedOverview === "DAILY" ? "#57BA47" : "#F8F8F8"}
                                            cursor="pointer"
                                            color={selectedOverview === "DAILY" ? "#FFFFFF" : "#818681"}
                                            onClick={() => setSeletedOverview("DAILY")}
                                        >
                                            Daily
                                        </Text>
                                        <Text
                                            fontFamily="fontFamily"
                                            fontSize="12px"
                                            borderRadius="24px"
                                            padding="8px 16px"
                                            bgColor={selectedOverview === "HOURLY" ? "#57BA47" : "#F8F8F8"}
                                            color={selectedOverview === "HOURLY" ? "#FFFFFF" : "#818681"}
                                            cursor="pointer"
                                            onClick={() => setSeletedOverview("HOURLY")}
                                        >
                                            Hourly
                                        </Text>
                                        <Text
                                            fontFamily="fontFamily"
                                            fontSize="12px"
                                            borderRadius="24px"
                                            padding="8px 16px"
                                            bgColor={selectedOverview === "MINUTES" ? "#57BA47" : "transparent"}
                                            color={selectedOverview === "MINUTES" ? "#FFFFFF" : "#818681"}
                                            cursor="pointer"
                                            onClick={() => setSeletedOverview("MINUTES")}
                                        >
                                            Minutes
                                        </Text>
                                    </HStack>
                                </HStack>

                                <Flex w="full">
                                    <MemoizedTemperatureLineChart
                                        data={logs?.data}
                                        selectedGraph={"sensor_two"}
                                        selectedType="sensor2"
                                        deviceData={deviceData}
                                        overview={selectedOverview}
                                    />
                                </Flex>
                            </Box>
                            <Box p={8}>
                                <VStack>
                                    <HStack mb="20px" width="100%" justifyContent="space-between">
                                        <HStack gap={2} alignItems="center">
                                            <Text
                                                color="#11250E"
                                                fontFamily="fontFamily"
                                                fontSize="20px"
                                                fontWeight="bold"
                                                lineHeight="30px"
                                                letterSpacing="-0.01em"
                                            >
                                                Detailed Statistics
                                            </Text>
                                            <Tooltip
                                                placement="top"
                                                padding={2}
                                                bg="gray"
                                                hasArrow
                                                label={
                                                    <Box display="flex" flexDirection="column">
                                                        <Text>Evaluate the breakdown of incidents</Text>
                                                        <Flex alignItems="center" mb={1}>
                                                            <Box bg="#FFCC00" borderRadius="9999px" w="12px" h="12px" mr={2} />
                                                            Reported
                                                        </Flex>
                                                        <Flex alignItems="center">
                                                            <Box bg="#D42929" borderRadius="9999px" w="12px" h="12px" mr={2} />
                                                            Unreported
                                                        </Flex>
                                                    </Box>
                                                }
                                            >
                                                <InfoOutlineIcon color="#57BA47" />
                                            </Tooltip>
                                        </HStack>

                                        <HStack gap={2} alignItems="center">
                                            <CustomButton
                                                px="1.5rem"
                                                fontFamily="fontFamily"
                                                fontSize="14px"
                                                fontWeight="700"
                                                lineHeight="20px"
                                                letterSpacing="-0.01em"
                                                textAlign="left"
                                                color="#11250E"
                                                borderRadius="4px"
                                                border="1px solid #11250E"
                                                bg="transparent"
                                                onClick={toggleBreachFilter}
                                            >
                                                <InfoOutlineIcon color="#11250E" mr={2} />
                                                Breach
                                            </CustomButton>

                                            <CustomButton
                                                px="2.5rem"
                                                bg="#57BA47"
                                                fontFamily="fontFamily"
                                                fontSize="14px"
                                                fontWeight="700"
                                                lineHeight="20px"
                                                letterSpacing="-0.01em"
                                                textAlign="left"
                                                color="#FFFFFF"
                                                onClick={handleOpen}
                                            >
                                                Export
                                            </CustomButton>
                                        </HStack>
                                    </HStack>
                                    <TableContainer w="100%">
                                        <Table
                                            variant="contained"
                                            sx={{ "tr:nth-of-type(even)": { backgroundColor: "#F6F6F6" }, tableLayout: "fixed" }}
                                            colorScheme="#F6F6F6"
                                            fontSize="12px"
                                        >
                                            <Thead bg="#F6F6F6" h="48px">
                                                <Tr>
                                                    <Th
                                                        textTransform="capitalize"
                                                        fontWeight="bold"
                                                        textAlign="center"
                                                        fontFamily="fontFamily"
                                                        fontSize="16px"
                                                        lineHeight="30px"
                                                        letterSpacing="-0.01em"
                                                        alignContent="center"
                                                        paddingBlock="18px"
                                                    >
                                                        Date
                                                    </Th>
                                                    <Th
                                                        textTransform="capitalize"
                                                        fontWeight="bold"
                                                        textAlign="center"
                                                        fontFamily="fontFamily"
                                                        fontSize="16px"
                                                        lineHeight="30px"
                                                        letterSpacing="-0.01em"
                                                        alignContent="center"
                                                        paddingBlock="18px"
                                                    >
                                                        Reading Time
                                                    </Th>
                                                    <Th
                                                        textTransform="capitalize"
                                                        fontWeight="bold"
                                                        textAlign="center"
                                                        fontFamily="fontFamily"
                                                        fontSize="16px"
                                                        lineHeight="30px"
                                                        letterSpacing="-0.01em"
                                                        alignContent="center"
                                                        paddingBlock="18px"
                                                    >
                                                        Readings
                                                    </Th>
                                                    <Th
                                                        textTransform="capitalize"
                                                        fontWeight="bold"
                                                        textAlign="center"
                                                        fontFamily="fontFamily"
                                                        fontSize="16px"
                                                        lineHeight="30px"
                                                        letterSpacing="-0.01em"
                                                        alignContent="center"
                                                        paddingBlock="18px"
                                                    >
                                                        Min/Max
                                                    </Th>
                                                    <Th
                                                        textTransform="capitalize"
                                                        fontWeight="bold"
                                                        textAlign="center"
                                                        fontFamily="fontFamily"
                                                        fontSize="16px"
                                                        lineHeight="30px"
                                                        letterSpacing="-0.01em"
                                                        alignContent="center"
                                                        paddingBlock="18px"
                                                    >
                                                        Status
                                                    </Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {logs &&
                                                    logs?.data
                                                        ?.filter((item) => !isBreachFilter || item.is_alert)
                                                        ?.map((item, index) => {
                                                            const rowColor = index % 2 === 0 ? "#FFFFFF" : "#F6F6F6";
                                                            const isOpen = selectedSensor === "sensor2" ? isOpenTab1 : isOpenTab2;
                                                            const onOpen = selectedSensor === "sensor2" ? onOpenTab1 : onOpenTab2;
                                                            const onClose = selectedSensor === "sensor2" ? onCloseTab1 : onCloseTab2;
                                                            return (
                                                                <React.Fragment key={`log-${selectedSensor}-${item.id}`}>
                                                                    {/* Table Row */}
                                                                    <Tr
                                                                        id={`log-${selectedSensor}-${item.id}`}
                                                                        data-log-id={item.id}
                                                                        data-sensor-type={selectedSensor}
                                                                        ref={(el) => {
                                                                            if (el && item.id) {
                                                                                logRefs.current.set(item.id, el);
                                                                            }
                                                                        }}
                                                                        sx={{
                                                                            cursor: "pointer",
                                                                            animation: item.id === logId ? blinkAnimation : "none",
                                                                        }}
                                                                        onClick={() => handleExpandTab2(index, item)}
                                                                    >
                                                                        <Td
                                                                            fontFamily="fontFamily"
                                                                            fontSize="14px"
                                                                            fontWeight="bold"
                                                                            lineHeight="30px"
                                                                            letterSpacing="-0.01em"
                                                                            textAlign="center"
                                                                        >
                                                                            {Moment(item?.fetch_time).format("DD-MM-YYYY")}
                                                                        </Td>
                                                                        <Td
                                                                            fontFamily="fontFamily"
                                                                            fontSize="14px"
                                                                            lineHeight="30px"
                                                                            fontWeight="bold"
                                                                            letterSpacing="-0.01em"
                                                                            textAlign="center"
                                                                        >
                                                                            {Moment(item?.fetch_time).format("hh:mm A")}
                                                                        </Td>
                                                                        <Td
                                                                            fontFamily="fontFamily"
                                                                            fontSize="14px"
                                                                            lineHeight="30px"
                                                                            fontWeight="bold"
                                                                            letterSpacing="-0.01em"
                                                                            textAlign="center"
                                                                        >
                                                                            {typeof item?.sensor_two === "number"
                                                                                ? item?.sensor_two.toFixed(1)
                                                                                : item?.sensor_two}
                                                                            {deviceData?.sensor_two?.sensor_type === HumidityHumidity ? "%" : "°C"}
                                                                        </Td>
                                                                        <Td
                                                                            fontFamily="fontFamily"
                                                                            fontSize="14px"
                                                                            lineHeight="30px"
                                                                            fontWeight="bold"
                                                                            letterSpacing="-0.01em"
                                                                            textAlign="center"
                                                                        >
                                                                            {MinTwo +
                                                                                (deviceData?.sensor_two?.sensor_type === HumidityHumidity
                                                                                    ? "%"
                                                                                    : "°C") +
                                                                                "/" +
                                                                                MaxTwo +
                                                                                (deviceData?.sensor_two?.sensor_type === HumidityHumidity
                                                                                    ? "%"
                                                                                    : "°C")}
                                                                        </Td>
                                                                        <Td
                                                                            fontFamily="fontFamily"
                                                                            fontSize="14px"
                                                                            lineHeight="30px"
                                                                            fontWeight="bold"
                                                                            letterSpacing="-0.01em"
                                                                            textAlign="center"
                                                                        >
                                                                            <ChevronIcon
                                                                                isOpen={isAccordionOpen2(index)}
                                                                                hasReported={item.has_report}
                                                                                isAlert={item.is_alert}
                                                                            />
                                                                        </Td>
                                                                    </Tr>
                                                                    {/* Accordion Content */}
                                                                    {expandedRowTab2 === index && item.is_alert && (
                                                                        <Tr>
                                                                            <Td colSpan={5} bg={rowColor} padding={8}>
                                                                                <Box bg={rowColor} borderRadius="8px" padding="16px 32px">
                                                                                    <Box
                                                                                        mt={2}
                                                                                        h="100px"
                                                                                        borderRadius="8px"
                                                                                        display="flex"
                                                                                        flexDirection="column"
                                                                                        alignItems="center"
                                                                                        gap="12px"
                                                                                        justifyContent="center"
                                                                                    >
                                                                                        {expandedLogId === item.id && item.has_report ? (
                                                                                            <Box alignSelf="stretch">
                                                                                                {isLoading ? (
                                                                                                    <Spinner />
                                                                                                ) : (
                                                                                                    <TableContainer>
                                                                                                        <Table
                                                                                                            variant="unstyled"
                                                                                                            sx={{
                                                                                                                "tr:nth-of-type(odd)": {
                                                                                                                    backgroundColor: "transparent",
                                                                                                                    borderBottom:
                                                                                                                        "1px solid transparent",
                                                                                                                },
                                                                                                                "tr:nth-of-type(even)": {
                                                                                                                    backgroundColor: "transparent",
                                                                                                                    borderBottom:
                                                                                                                        "1px solid transparent",
                                                                                                                },
                                                                                                                tableLayout: "fixed",
                                                                                                            }}
                                                                                                            size="sm"
                                                                                                        >
                                                                                                            <Tbody>
                                                                                                                <Tr>
                                                                                                                    <Td>
                                                                                                                        <Text fontWeight="600">
                                                                                                                            Incident Reason:
                                                                                                                        </Text>
                                                                                                                    </Td>
                                                                                                                    <Td>
                                                                                                                        {reportData?.incident_reason}
                                                                                                                    </Td>
                                                                                                                </Tr>
                                                                                                                <Tr>
                                                                                                                    <Td>
                                                                                                                        <Text fontWeight="600">
                                                                                                                            Corrective Actions:
                                                                                                                        </Text>
                                                                                                                    </Td>
                                                                                                                    <Td>
                                                                                                                        {
                                                                                                                            reportData?.corrective_actions
                                                                                                                        }
                                                                                                                    </Td>
                                                                                                                </Tr>
                                                                                                                <Tr>
                                                                                                                    <Td>
                                                                                                                        <Text fontWeight="600">
                                                                                                                            Product Loss:
                                                                                                                        </Text>
                                                                                                                    </Td>
                                                                                                                    <Td>
                                                                                                                        {reportData?.product_loss}
                                                                                                                    </Td>
                                                                                                                </Tr>
                                                                                                                <Tr>
                                                                                                                    <Td>
                                                                                                                        <Text fontWeight="600">
                                                                                                                            Safety Status:
                                                                                                                        </Text>
                                                                                                                    </Td>
                                                                                                                    <Td>
                                                                                                                        {reportData?.safety_status}
                                                                                                                    </Td>
                                                                                                                </Tr>
                                                                                                                <Tr>
                                                                                                                    <Td>
                                                                                                                        <Text fontWeight="600">
                                                                                                                            Reported By:
                                                                                                                        </Text>
                                                                                                                    </Td>
                                                                                                                    <Td>
                                                                                                                        {
                                                                                                                            reportData?.reportedByUser
                                                                                                                                ?.name
                                                                                                                        }
                                                                                                                    </Td>
                                                                                                                </Tr>
                                                                                                            </Tbody>
                                                                                                        </Table>
                                                                                                    </TableContainer>
                                                                                                )}
                                                                                            </Box>
                                                                                        ) : (
                                                                                            <>
                                                                                                <CustomButton
                                                                                                    bg="#D42929"
                                                                                                    color="#FFFFFF"
                                                                                                    px="36px"
                                                                                                    py="5px"
                                                                                                    onClick={onOpen}
                                                                                                >
                                                                                                    Add Report
                                                                                                </CustomButton>
                                                                                                <Text
                                                                                                    as="p"
                                                                                                    fontSize="10px"
                                                                                                    lineHeight="12px"
                                                                                                    letterSpacing="-1%"
                                                                                                    fontStyle="italic"
                                                                                                >
                                                                                                    *Help us improve safety by submitting a report.
                                                                                                </Text>
                                                                                                <AddReport
                                                                                                    isOpen={isOpen}
                                                                                                    onClose={onClose}
                                                                                                    onReportSuccess={() => {
                                                                                                        refetch();
                                                                                                        onClose();
                                                                                                    }}
                                                                                                    deviceId={device}
                                                                                                    logId={item.id}
                                                                                                    occuredAt={item.fetch_time}
                                                                                                    sensorType={selectedSensor}
                                                                                                />
                                                                                            </>
                                                                                        )}
                                                                                    </Box>
                                                                                </Box>
                                                                            </Td>
                                                                        </Tr>
                                                                    )}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </VStack>
                            </Box>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
            {exportisOpen && (
                <ExportReading
                    exportisOpen={exportisOpen}
                    exportonClose={exportonClose}
                    device={deviceData}
                    endDate={endDate}
                    startDate={startDate}
                />
            )}
            {filterIsOpen && (
                <ChartFilter
                    endDate={endDate}
                    startDate={startDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    isOpen={filterIsOpen}
                    onClose={filterOnClose}
                />
            )}
        </Box>
    );
};

export default SensorDevice;
