import React from "react";
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Text, Image, Box } from "@chakra-ui/react";

import emailSend from "../assets/savePassword.svg";
import CustomButton from "./CustomButton";

const ReadingDeliveredToRegisterEmail = ({ emailSendonClose, emailSendisOpen }) => {
    const btnRef = React.useRef();
    const handleEmailSend = () => {
        emailSendonClose();
    };

    return (
        <>
            <Drawer isOpen={emailSendisOpen} placement="right" onClose={emailSendonClose} finalFocusRef={btnRef}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <DrawerCloseButton />

                    <DrawerBody mt="5rem">
                        <Box w="90%" display="flex" align="center" justifyContent="center" margin={"auto"} mb="0.5rem">
                            <Text fontFamily="fontFamily" fontSize="24px" fontWeight="600" lineheight="36px" letterSpacing="-0.01em">
                                Data has been sent to your registered email.
                            </Text>
                        </Box>
                        <Image src={emailSend} alt="email send" margin={"auto"} />
                    </DrawerBody>

                    <DrawerFooter margin={"auto"}>
                        <CustomButton
                            px="11rem"
                            color="#FFFFFF"
                            bg="#57BA47"
                            fontFamily="fontFamily"
                            fontWeight="600"
                            fontSize="16px"
                            lineHeight="20px"
                            onClick={handleEmailSend}
                            mb="1rem"
                        >
                            Done
                        </CustomButton>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default ReadingDeliveredToRegisterEmail;
