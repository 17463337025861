import React, { useState, useEffect } from "react";
import { Box, Grid, HStack, Image, Progress, Text, VStack } from "@chakra-ui/react";
import logo from "../assets/logo.svg";
import Premium from "../assets/Premium.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { Toggle_Step9Function } from "../redux/action";
import { Reveal } from "../common/Reveal";
import CustomButton from "../components/CustomButton";
const SetUpProgress = () => {
    const queryClient = useQueryClient();
    const [pageRedirect, setPageRedirect] = useState(false);
    const [progress, setProgress] = useState(0);
    const [displayText, setDisplayText] = useState("Space");
    const [completed, setIsCompleted] = useState(false);
    const Step4 = useSelector((store) => store.ConditionalRenderingReducer.step4);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    setIsCompleted(true);
                    setPageRedirect(true);
                    return 100;
                }

                const newProgress = prev + 35;

                if (newProgress >= 100) {
                    setIsCompleted(true);
                } else if (newProgress >= 70) {
                    setDisplayText("Device");
                } else if (newProgress >= 35) {
                    setDisplayText("Room");
                }

                return newProgress;
            });
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (pageRedirect) {
            dispatch(Toggle_Step9Function());
            localStorage.setItem("step9", JSON.stringify(true));
            queryClient.clear();
            return navigate("/dashboard");
        }
    }, [pageRedirect, dispatch, navigate]);

    return (
        <Grid h="100vh" bg="#F2F2F266" p={0} m={0}>
            <HStack
                w={{ base: "", sm: "", md: "", lg: "100%", xl: "100%", "2xl": "100%" }}
                h={{ base: "0", sm: "0", md: "110px", lg: "110px", xl: "110px", "2xl": "110px" }}
                bg="#F8FBEE"
                justifyContent="space-between"
                pl="56px"
                pr="56px"
            >
                <HStack
                    justifyContent="space-between"
                    w={{ base: "", sm: "", md: "", lg: "93%", xl: "93%" }}
                    ml="3rem"
                    h={{ base: "0", sm: "0", md: "110px", lg: "110px", xl: "110px" }}
                >
                    <Image src={logo} alt="" w="51px" h="41px" />
                    <Box display="flex" align="center" justifyContent="space-between">
                        {" "}
                        {Step4 ? (
                            <>
                                <Image src={Premium} alt="" />
                                <CustomButton
                                    border="1px solid"
                                    fontFamily="fontFamily"
                                    fontSize="12px"
                                    fontWeight="500"
                                    lineheight="16px"
                                    letterSpacing="-0.01em"
                                    b
                                    borderColor="rgba(87, 186, 71, 1)"
                                    bg="#F8FBEE"
                                    _hover={{ bg: "#F8FBEE" }}
                                    color="rgba(30, 146, 12, 1)"
                                    padding="0px, 16px, 0px, 16px"
                                    w="58px"
                                    h="24px"
                                >
                                    Help
                                </CustomButton>
                            </>
                        ) : (
                            <CustomButton
                                border="1px solid"
                                fontFamily="fontFamily"
                                fontSize="12px"
                                fontWeight="500"
                                lineheight="16px"
                                letterSpacing="-0.01em"
                                b
                                borderColor="rgba(87, 186, 71, 1)"
                                bg="#F8FBEE"
                                _hover={{ bg: "#F8FBEE" }}
                                color="rgba(30, 146, 12, 1)"
                                padding="0px, 16px, 0px, 16px"
                                w="58px"
                                h="24px"
                            >
                                Help
                            </CustomButton>
                        )}
                    </Box>
                </HStack>
            </HStack>
            {/*  */}
            <VStack position="relative" h="50vh" top="22%" gap="1rem">
                <Box display="flex" flexDirection="row" h="50px" m={0} p={0}>
                    {completed ? (
                        <Text
                            fontFamily="fontFamily"
                            fontSize="32px"
                            fontWeight="700"
                            lineHeight="40px"
                            letterSpacing="-0.01em"
                            textAlign="left"
                            color="rgba(17, 37, 14, 1)"
                        >
                            You are all set...
                        </Text>
                    ) : (
                        <>
                            <Text
                                fontFamily="fontFamily"
                                fontSize="32px"
                                fontWeight="700"
                                lineHeight="40px"
                                letterSpacing="-0.01em"
                                textAlign="left"
                                color="rgba(17, 37, 14, 1)"
                            >
                                Setting up your
                            </Text>
                            {displayText === "Space" ? (
                                <Text
                                    as="span"
                                    fontFamily="fontFamily"
                                    fontSize="32px"
                                    fontWeight="700"
                                    lineHeight="40px"
                                    letterSpacing="-0.01em"
                                    textAlign="left"
                                    color="rgba(87, 186, 71, 1)"
                                    ml="1rem"
                                >
                                    {displayText}
                                </Text>
                            ) : (
                                <Reveal hidden={{ opacity: 0, y: 50 }} visible={{ opacity: 1, y: 0 }} delay={0.1} key={displayText}>
                                    <Text
                                        as="span"
                                        fontFamily="fontFamily"
                                        fontSize="32px"
                                        fontWeight="700"
                                        lineHeight="40px"
                                        letterSpacing="-0.01em"
                                        textAlign="left"
                                        color="rgba(87, 186, 71, 1)"
                                        ml="1rem"
                                    >
                                        {displayText}
                                    </Text>
                                </Reveal>
                            )}
                        </>
                    )}
                </Box>
                <Progress
                    pl="0.5rem"
                    pr="0.5rem"
                    mt={0}
                    mb={0}
                    value={progress}
                    w="595px"
                    h="17px"
                    colorScheme="green"
                    borderRadius="16px 16px 16px 16px"
                    color="rgba(255, 255, 255, 1)"
                    sx={{
                        display: "flex",
                        alignItems: "center",

                        "& > div": {
                            height: "4px",
                        },
                    }}
                />
            </VStack>
        </Grid>
    );
};
export default SetUpProgress;
