import React from "react";
import { Box, Circle, Divider, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Image, Input, Text, VStack } from "@chakra-ui/react";
import CustomButton from "../components/CustomButton";
import { TopBar } from "./TopBar";
import { useDispatch, useSelector } from "react-redux";
import activeIcon from "../assets/onboardActiveicon.svg";
import StepperCheck from "../assets/stepper-check.svg";
import onboarding_add_device from "../assets/onboard_add_device.svg";
import { Device_Add_Toggle_Stepper_Function, Toggle_Step7Function, Toggle_Step9Function } from "../redux/action";
import { SetUpDevice2 } from "./SetUpDevice2";
import { useLocation, useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";

const SetUpDevice1 = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const Device_Add1_Stepper = useSelector((store) => store.ConditionalRenderingReducer.Device_Add1_Stepper);
    const Add_Device2_Stepper = useSelector((store) => store.ConditionalRenderingReducer.Add_Device2_Stepper);
    const Add_Device3_Stepper = useSelector((store) => store.ConditionalRenderingReducer.Add_Device3_Stepper);
    const Add_Device4_Stepper = useSelector((store) => store.ConditionalRenderingReducer.Add_Device4_Stepper);
    const roomId = location?.state?.roomId;
    const Heading = {
        color: "#11250E",
        fontFamily: "fontFamily",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: "800",
        lineHeight: "42px",
        letterSpacing: "-0.01em",
    };
    const noteStyle = {
        color: "#818681",
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "-0.14%",
    };
    const labelField = {
        color: "#11250E",
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "30px",
        letterSpacing: "-0.16%",
    };
    const InputField = {
        border: "1.5px solid",
        borderColor: "rgba(129, 134, 129, 1)",
        borderRadius: "4px 4px 4px 4px",
        _placeholder: {
            fontFamily: "fontFamily",
            color: "rgba(129, 134, 129, 1)",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "20px",
            letterSpacing: "-0.01em",
            alignContent: "start",
        },
        _focus: {
            border: "1.5px solid",
            borderColor: "rgba(87, 186, 71, 1)",
            boxShadow: "none",
        },
        _hover: {
            border: "1.5px solid",
            borderColor: "rgba(87, 186, 71, 1)",
            boxShadow: "none",
        },
    };
    const SteppweText = {
        color: "rgba(0, 0, 0, 1)",
        fontFamily: "fontFamily",
        fontSize: "12px",

        fontWeight: "800",
        lineHeight: "16px",
        letterSpacing: "-0.4px",
    };
    const buttonText = {
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.16%",
    };

    const validation = useFormik({
        initialValues: {
            deviceName: "",
            serialno: "",
            connectionKey: "",
        },
        validationSchema: Yup.object({
            deviceName: Yup.string().required("Device Name is required").max(50, "Device Name must be at most 50 characters"),
            serialno: Yup.string()
                .required("Serial Number is required")
                .matches(/^[A-Za-z0-9]+$/, "Serial Number can only contain letters and numbers"),
            connectionKey: Yup.string().required("Connection Key is required"),
        }),
        enableReinitialize: true,
    });

    // Extract values from validation
    const { deviceName, serialno, connectionKey } = validation.values;
    const SetUpDevice = async () => {
        dispatch(Device_Add_Toggle_Stepper_Function());
    };

    const goToPrevious = () => {
        window.history.back();
    };

    const skipToTheDashboard = () => {
        window.location.href = "/dashboard";
    };

    return (
        <Grid
            w={{
                base: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
                "2xl": "100%",
            }}
            bg="#F2F2F266"
            minH={{
                base: "100vh",
                sm: "100vh",
                md: "100vh",
                lg: "100vh",
                xl: "100vh",
                "2xl": "100vh",
            }}
            m={0}
            p={0}
            gap={0}
            gridTemplateRows="10vh 23.9vh auto"
        >
            <TopBar />
            <Box h="20vh" display="flex" alignItems="center" position="relative" top="16%" p={0}>
                {Device_Add1_Stepper ? (
                    <Box
                        position="absolute"
                        top="50%"
                        left="0"
                        width="100%"
                        height="1px"
                        background={Device_Add1_Stepper === true ? "#11250E" : "linear-gradient(270deg, #DDE2DD 0%, #11250E 100%)"}
                    />
                ) : (
                    <Divider borderColor="rgba(129, 134, 129, 0.80)" borderWidth="1px" w="100%" />
                )}

                {/* Circles for Stepper */}
                {!Device_Add1_Stepper ? (
                    <>
                        <Circle
                            size="40px"
                            bg="#DDF1DA"
                            position="absolute"
                            top="36%"
                            left="7%"
                            backgroundImage={activeIcon}
                            backgroundPosition="center"
                            backgroundRepeat="no-repeat"
                            cursor="pointer"
                        />

                        <Text position="absolute" sx={SteppweText} top="70%" left="7.5%" zIndex="1">
                            Add
                        </Text>
                    </>
                ) : (
                    <>
                        <Circle
                            size="40px"
                            position="absolute"
                            top="38%"
                            left="6.7%"
                            backgroundImage={StepperCheck}
                            backgroundPosition="center"
                            backgroundRepeat="no-repeat"
                            zIndex="1"
                            cursor="pointer"
                        />
                        <Text position="absolute" sx={SteppweText} top="70%" left="7.4%" zIndex="1">
                            Add
                        </Text>
                    </>
                )}

                {!Add_Device2_Stepper ? (
                    <>
                        <Circle
                            size="40px"
                            bg="#DDF1DA"
                            position="absolute"
                            top="36%"
                            backgroundImage={Device_Add1_Stepper === true ? activeIcon : "none"}
                            backgroundPosition="center"
                            backgroundRepeat="no-repeat"
                            left="35%"
                            zIndex="1"
                            cursor="pointer"
                        />
                        <Text
                            position="absolute"
                            sx={SteppweText}
                            top="70%"
                            left={{
                                base: "35.1%",
                                sm: "35.1%",
                                md: "35.1%",
                                lg: "35.1%",
                                xl: "35.1%",
                                "2xl": "35.1%",
                            }}
                            zIndex="1"
                        >
                            Set Up
                        </Text>
                    </>
                ) : (
                    <>
                        <Circle
                            size="40px"
                            position="absolute"
                            top="36.9%"
                            left="35%"
                            backgroundImage={StepperCheck}
                            backgroundPosition="center"
                            backgroundRepeat="no-repeat"
                            zIndex="1"
                            cursor="pointer"
                        />

                        <Text position="absolute" sx={SteppweText} top="95px" left="35.1%" zIndex="1">
                            Set Up
                        </Text>
                    </>
                )}
                {Add_Device3_Stepper ? (
                    <>
                        <Circle
                            size="40px"
                            position="absolute"
                            top="36.9%"
                            left="60%"
                            backgroundImage={StepperCheck}
                            backgroundPosition="center"
                            backgroundRepeat="no-repeat"
                            zIndex="1"
                            cursor="pointer"
                        />
                        <Text position="absolute" sx={SteppweText} top="70%" left="60.7%" zIndex="1">
                            Wifi
                        </Text>
                    </>
                ) : (
                    <>
                        <Circle
                            size="40px"
                            bg="#DDF1DA"
                            position="absolute"
                            backgroundImage={Add_Device2_Stepper === true ? activeIcon : "none"}
                            backgroundPosition="center"
                            backgroundRepeat="no-repeat"
                            top="36.9%"
                            left="60%"
                            zIndex="1"
                            cursor="pointer"
                        />
                        <Text position="absolute" sx={SteppweText} top="70%" left="60.7%" zIndex="1">
                            Wifi
                        </Text>
                    </>
                )}
                {/* {Add_Device4_Stepper ? (
                    <>
                        <Circle
                            size="40px"
                            position="absolute"
                            top="36.9%"
                            left="70%"
                            backgroundImage={StepperCheck}
                            backgroundPosition="center"
                            backgroundRepeat="no-repeat"
                            zIndex="1"
                            cursor="pointer"
                        />
                        <Text position="absolute" sx={SteppweText} top="70%" left="70.7%" zIndex="1">
                            Link
                        </Text>
                    </>
                ) : (
                    <>
                        <Circle
                            size="40px"
                            bg="#DDF1DA"
                            position="absolute"
                            backgroundImage={Add_Device3_Stepper === true ? activeIcon : "none"}
                            backgroundPosition="center"
                            backgroundRepeat="no-repeat"
                            top="36.9%"
                            left="70%"
                            zIndex="1"
                            cursor="pointer"
                        />
                        <Text position="absolute" sx={SteppweText} top="70%" left="70.7%" zIndex="1">
                            Link
                        </Text>
                    </>
                )} */}

                <Circle
                    size="40px"
                    bg="#DDF1DA"
                    position="absolute"
                    backgroundImage={Add_Device3_Stepper === true ? activeIcon : "none"}
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    top="36.9%"
                    left="90%"
                    zIndex="1"
                    cursor="pointer"
                />
                <Text position="absolute" sx={SteppweText} top="70%" left="90.7%" zIndex="1">
                    Link
                </Text>
            </Box>

            {Device_Add1_Stepper ? (
                <SetUpDevice2 serialno={serialno} deviceName={deviceName} connectionKey={connectionKey} roomId={roomId} />
            ) : (
                <Grid gridTemplateColumns="45% auto" mt="0.5rem" ml="2rem">
                    <VStack gap="1rem">
                        <GridItem w="75%">
                            <Text sx={Heading}>Add Device</Text>
                        </GridItem>
                        <GridItem w="75%">
                            <FormControl isInvalid={validation.errors.deviceName && validation.touched.deviceName} isRequired>
                                <FormLabel mb="0.5rem" sx={labelField}>
                                    Enter Device Name
                                </FormLabel>

                                <Input
                                    isInvalid={validation.errors.deviceName && validation.touched.deviceName}
                                    placeholder="Ex: Sensor 1"
                                    type="text"
                                    name="deviceName"
                                    sx={InputField}
                                    value={validation.values.deviceName}
                                    onChange={validation.handleChange}
                                    onFocus={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.deviceName}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem w="75%">
                            <FormControl isInvalid={validation.errors.serialno && validation.touched.serialno} isRequired>
                                <FormLabel mb="0.5rem" sx={labelField}>
                                    Enter Serial Number
                                </FormLabel>
                                <Input
                                    isInvalid={validation.errors.serialno && validation.touched.serialno}
                                    placeholder="Check at the back of your device"
                                    type="text"
                                    name="serialno"
                                    sx={InputField}
                                    value={validation.values.serialno}
                                    onChange={validation.handleChange}
                                    onFocus={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.serialno}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem w="75%">
                            <FormControl isInvalid={validation.errors.connectionKey && validation.touched.connectionKey} isRequired>
                                <FormLabel mb="0.5rem" sx={labelField}>
                                    Enter Connection Key
                                </FormLabel>
                                <Input
                                    placeholder="Check at the back of your device"
                                    type="text"
                                    sx={InputField}
                                    name="connectionKey"
                                    isInvalid={validation.errors.connectionKey && validation.touched.connectionKey}
                                    value={validation.values.connectionKey}
                                    onChange={validation.handleChange}
                                    onFocus={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.connectionKey}</FormErrorMessage>
                            </FormControl>
                        </GridItem>

                        <GridItem display="flex" justifyContent="space-between" w="75%" mt="3rem">
                            <Box w="30%" display="flex">
                                <CustomButton
                                    bg="rgba(255, 255, 255, 1)"
                                    color="rgba(87, 186, 71, 1)"
                                    border="2px solid"
                                    borderColor="rgba(87, 186, 71, 1)"
                                    onClick={goToPrevious}
                                >
                                    Back
                                </CustomButton>
                            </Box>
                            <Box display="flex" justifyContent="flex-end" alignItems="center" w="90%" gap="1rem">
                                <CustomButton
                                    color="rgba(0, 0, 0, 1)"
                                    w="30%"
                                    bg="rgba(255, 255, 255, 1)"
                                    sx={buttonText}
                                    onClick={skipToTheDashboard}
                                >
                                    Cancel
                                </CustomButton>
                                <CustomButton
                                    color="rgba(255, 255, 255, 1)"
                                    w="27%"
                                    h="40px"
                                    bg="rgba(87, 186, 71, 1)"
                                    sx={buttonText}
                                    onClick={SetUpDevice}
                                    disabled={!deviceName || !serialno || !connectionKey}
                                >
                                    Next
                                </CustomButton>
                            </Box>
                        </GridItem>
                    </VStack>

                    <GridItem display="flex" justifyContent="center" alignItems="center">
                        <Box w="60%" h="352px" display="flex" justifyContent="center" alignItems="center">
                            <Image src={onboarding_add_device} alt="onboarding_add_device" />
                        </Box>
                    </GridItem>
                </Grid>
            )}
        </Grid>
    );
};

export default SetUpDevice1;
