import React from "react";
import { VStack, Box, Image, GridItem, Grid, useDisclosure, Text } from "@chakra-ui/react";
import crown from "../assets/crown.svg";
import userIcon from "../assets/user.png";
import downArrow from "../assets/arrow-down.svg";
import location from "../assets/location.svg";
import logo from "../assets/logo.svg";
import { ModalSubscription } from "../components/ModalSubscription";
import { Onboarding5 } from "../onboarding/Onboarding5";
import { Reveal } from "../common/Reveal";
import CustomButton from "../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { Toggle_Step3Function, Toggle_Step5Function } from "../redux/action";
import { useNavigate } from "react-router-dom";

export const Onboarding4 = ({ designation, yourname }) => {
    const navigate = useNavigate();
    const { isOpen: isFirstModalOpen, onOpen: onFirstModalOpen, onClose: onFirstCloseModalOpen } = useDisclosure();

    const finalRef = React.useRef(null);

    const Step5 = useSelector((store) => store.ConditionalRenderingReducer.step5);
    const dispatch = useDispatch();

    const ButtonTextStyle1 = {
        bg: "#FFF",
        border: "1px solid",
        borderColor: "#57BA47",
        _hover: {
            bg: "#FFF",
        },
        color: "#57BA47",
        fontFamily: "fontFamily",
        fontSize: "16px",

        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };

    const ButtonTextStyle2 = {
        bg: "#FFFFFF",
        _hover: { bg: "#FFFFFF" },
        color: "#11250E",
        fontFamily: "fontFamily",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };

    const ButtonTextStyle4 = {
        color: "#FFF",
        _hover: { bg: "#57BA47" },

        bg: "#57BA47",
        fontFamily: "fontFamily",
        fontSize: "16px",

        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };

    const handleOpenPage = () => {
        onFirstModalOpen();
    };

    const handlePrevious3 = () => {
        dispatch(Toggle_Step3Function());
    };

    const skipToDashboard = () => {
        dispatch(Toggle_Step5Function());
    };

    return (
        <>
            {Step5 === true ? (
                <Onboarding5 yourname={yourname} designation={designation} />
            ) : (
                <Grid bg="#F2F2F266" w="100%" h="100vh" gridTemplateColumns="repeat(2,1fr)">
                    <VStack display="flex" flexDirection="column" h="100%" ml="1rem">
                        <Box w="79%" display="flex" flexDirection="column" h="69vh" gap="2rem" mt="4rem">
                            <VStack align="start">
                                {/* <Box display="flex" align="center" mb="0.7rem">
                                    <Reveal hidden={{ opacity: 0, y: 40 }} visible={{ opacity: 1, y: 0 }} delay={0.1}>
                                        <Image src={crown} alt="" />
                                    </Reveal>
                                </Box> */}
                                <VStack display="flex" align="left">
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="32px"
                                        fontWeight="800"
                                        lineHeight="40px"
                                        letterSpacing="-0.01em"
                                        textAlign="left"
                                    >
                                        Hey! You've Got an Invite from the Admin!
                                    </Text>
                                    <Text
                                        color="#818681"
                                        fontFamily="fontFamily"
                                        fontSize="14px"
                                        fontWeight="500"
                                        lineHeight="20px"
                                        letterSpacing="-0.01em"
                                        textAlign="left"
                                    >
                                        Your special invite is waiting! Click to join and be part of something amazing.
                                    </Text>
                                </VStack>
                            </VStack>

                            <Box display="flex" justifyContent={"space-between"} w="93.9%">
                                <CustomButton w="15%" sx={ButtonTextStyle1} onClick={handlePrevious3}>
                                    Previous
                                </CustomButton>

                                <Box display="flex" gap="1rem" w="40%">
                                    {/* <CustomButton w="45%" sx={ButtonTextStyle2} onClick={skipToDashboard}>
                                        Skip
                                    </CustomButton> */}
                                    {/* <CustomButton w="100%" sx={ButtonTextStyle4} onClick={handleOpenPage}>
                                        Subscribe
                                    </CustomButton> */}
                                    <CustomButton w="100%" sx={ButtonTextStyle4} onClick={skipToDashboard}>
                                        Continue
                                    </CustomButton>
                                </Box>
                            </Box>
                        </Box>
                    </VStack>
                    <VStack display="flex" alignItems="start">
                        <Grid
                            w="80%"
                            h="44vh"
                            ml="3rem"
                            borderRadius="16px 16px 0px 0px"
                            background="#F2F2F2"
                            pt="2rem"
                            pl="2rem"
                            pr="2rem"
                            gridTemplateColumns="30% auto"
                            mt="4rem"
                        >
                            <GridItem background="#11250E" borderRadius="8px 0px 0px 0px" align="center">
                                <Reveal hidden={{ opacity: 0, y: 40 }} visible={{ opacity: 1, y: 0 }} delay={0.1}>
                                    <Image src={logo} alt="" w="19px" h="15px" mt="15%" />
                                    <Box
                                        borderRadius="50%"
                                        overflow="hidden"
                                        width="50px"
                                        height="50px"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        background="#F2F2F2"
                                        mt="7%"
                                    >
                                        <Image src={userIcon} alt="useIcon" />
                                    </Box>
                                    <Text
                                        mt="0.5rem"
                                        color="rgba(251, 251, 251, 1)"
                                        fontFamily="fontFamily"
                                        fontSize="12px"
                                        fontWeight="600"
                                        lineHeight="16px"
                                        letterSpacing="-0.01em"
                                    >
                                        {yourname}
                                    </Text>
                                    <Text
                                        mt="0.3rem"
                                        color="rgba(242, 242, 242, 0.50)"
                                        fontFamily="fontFamily"
                                        fontSize="10px"
                                        fontWeight="600"
                                        lineHeight="16px"
                                        letterSpacing="-0.01em"
                                    >
                                        {designation}
                                    </Text>
                                    <Box
                                        border="1px solid"
                                        borderColor="#FBFBFB"
                                        w="90%"
                                        h="24px"
                                        mt="1rem"
                                        borderRadius="4px 4px 4px 4px"
                                        padding="4px 10px 4px 10px"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Text
                                            color="#FBFBFB"
                                            fontFamily="fontFamily"
                                            fontSize="10px"
                                            fontStyle="normal"
                                            fontWeight="600"
                                            lineHeight="16px"
                                        >
                                            Your Space
                                        </Text>
                                        <Image src={downArrow} alt="arrow" />
                                    </Box>
                                </Reveal>
                            </GridItem>

                            <GridItem borderRadius="0px 8px 0px 0px" bg="#FFFFFF">
                                <Box bg="#F8FBEE" h="6vh" display="flex" alignItems="center">
                                    <Reveal hidden={{ opacity: 0, y: 20 }} visible={{ opacity: 1, y: 0 }} delay={0.1}>
                                        <Text
                                            color="#11250E"
                                            ml="2rem"
                                            fontFamily="fontFamily"
                                            fontSize="12px"
                                            fontWeight="600"
                                            lineHeight="16px"
                                            letterSpacing="-0.01em"
                                            textAlign="left"
                                        >
                                            Your Space
                                        </Text>{" "}
                                    </Reveal>
                                    <Reveal hidden={{ opacity: 0, y: 40 }} visible={{ opacity: 1, y: 0 }} delay={0.1}>
                                        <Image src={location} mb="3rem" />{" "}
                                    </Reveal>
                                </Box>

                                <GridItem borderRadius="0px 8px 0px 0px" bg="#FFFFFF" pl="1.5rem" pt="1.5rem" pr="1.5rem">
                                    <Grid gridTemplateColumns="auto auto auto" columnGap="1rem" ml="0.5rem">
                                        <GridItem bg="rgba(242, 242, 242, 1)" h="44px" borderRadius="4px 4px 4px"></GridItem>
                                        <GridItem bg="rgba(242, 242, 242, 1)" h="44px" borderRadius="4px 4px 4px"></GridItem>
                                        <GridItem bg="rgba(242, 242, 242, 1)" h="44px" borderRadius="4px 4px 4px"></GridItem>
                                    </Grid>
                                    <Grid
                                        gridTemplateColumns="40% 60%"
                                        ml="0.5rem"
                                        bg="rgba(242, 242, 242, 1)"
                                        mt="1rem"
                                        borderRadius="8px 8px 0px 0px"
                                    >
                                        <GridItem h="20vh" pt="1rem">
                                            <VStack
                                                pl="0.5rem"
                                                pr="0.5rem"
                                                spacing="1rem" // Add this line for spacing between VStack items
                                            >
                                                <VStack w="90%" h="30px" bg="rgba(255, 255, 255, 1)" borderRadius="4px 4px 4px"></VStack>
                                                <VStack w="90%" h="30px" bg="rgba(255, 255, 255, 1)" borderRadius="4px 4px 4px"></VStack>
                                                <VStack w="90%" h="30px" bg="rgba(255, 255, 255, 1)" borderRadius="4px 4px 4px"></VStack>
                                            </VStack>
                                        </GridItem>
                                        <GridItem gridTemplateColumns="100%" h="22.4vh">
                                            <VStack w="90%" h="16.8vh" bg="rgba(255, 255, 255, 1)" borderRadius="4px 4px 4px" mt="1rem"></VStack>
                                        </GridItem>
                                    </Grid>
                                </GridItem>
                            </GridItem>
                        </Grid>
                    </VStack>
                    <ModalSubscription isFirstModalOpen={isFirstModalOpen} onFirstCloseModalOpen={onFirstCloseModalOpen} finalRef={finalRef} />
                </Grid>
            )}
        </>
    );
};
