import React from "react";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { Grid, GridItem } from "@chakra-ui/react";
import Onboarding1 from "../onboarding/Onboarding1";
import Screen from "../page/Screen";
import SetUpDevice1 from "../onboarding/SetUpDevice1";
import SetUpProgress from "../onboarding/SetUpProgress";
import SideBar from "../common/SideBar";
import DashboardScreen from "../components/parentdashboard/DashboardScreen";
import Rooms from "../components/parentdashboard/Rooms";
import RoomsListType from "../components/parentdashboard/RoomsListType";
import Members from "../components/parentdashboard/Members";
import Subscription from "../components/parentdashboard/Subscription";
import Settings from "../components/parentdashboard/Settings";
import Devices from "../components/parentdashboard/Devices";
import { AllClients } from "../admin/component/AllClients";
import AdminNav from "../admin/common/AdminNav";
import { ClientProfile } from "../admin/component/ClientProfile";
import { ViewHistory } from "../admin/component/ViewHistory";
import { AdminSettings } from "../admin/component/AdminSettings";
import SensorDevice from "../components/parentdashboard/sensorDetail";
import OauthRedirect from "../page/OauthRedirect";
import ResetScreen from "../page/resetScreen";
import PasswordSaveScreen from "../page/passwordSave";
import DashboardWrapper from "../components/parentdashboard/DashboardWrapper";
import { Onboarding6 } from "../onboarding/Onboarding6";
import { Onboarding5 } from "../onboarding/Onboarding5";
import { Onboarding7 } from "../onboarding/Onboarding7";

export const Routing = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/login" />} />

            <Route
                path="/login"
                element={
                    <Grid w="100%" minH="100vh">
                        <Screen />
                    </Grid>
                }
            />
            <Route
                path="/reset-password"
                element={
                    <Grid w="100%" minH="100vh">
                        <ResetScreen />
                    </Grid>
                }
            />
            <Route
                path="/update-password/:token"
                element={
                    <Grid w="100%" minH="100vh">
                        <PasswordSaveScreen />
                    </Grid>
                }
            />
            <Route
                path="/oauth-redirect"
                element={
                    <Grid w="100%" minH="100vh">
                        <OauthRedirect />
                    </Grid>
                }
            />
            <Route
                path="/onboarding"
                element={
                    <Grid w="100%" minH="100vh">
                        <Onboarding1 />
                    </Grid>
                }
            />
            <Route
                path="/setupdevice"
                element={
                    <Grid w="100%" minH="100vh">
                        <SetUpDevice1 />
                    </Grid>
                }
            />
            <Route
                path="/progressbar"
                element={
                    <Grid w="100%" minH="100vh">
                        <SetUpProgress />
                    </Grid>
                }
            />
            <Route
                path="/dashboard"
                element={
                    <DashboardWrapper>
                        <Grid templateColumns="20% 80%" h="100vh">
                            <GridItem>
                                <SideBar />
                            </GridItem>
                            <GridItem>
                                <Outlet />
                            </GridItem>
                        </Grid>
                    </DashboardWrapper>
                }
            >
                <Route path="" element={<DashboardScreen />} />
                {/* <Route path=":space" element={<DashboardScreen />} /> */}
                <Route path=":space/overview" element={<DashboardScreen />} />
                <Route path=":space/rooms" element={<Rooms />} />
                <Route path=":space/rooms/:room_name/:room" element={<RoomsListType />} />
                <Route path=":space/devices" element={<Devices />} />
                <Route path=":space/devices/:device" element={<SensorDevice />} />
                <Route path=":space/members" element={<Members />} />
                <Route path=":space/subscription" element={<Subscription />} />
                <Route path=":space/settings" element={<Settings />} />
            </Route>

            <Route
                path="/admin/dashboard"
                element={
                    <Grid templateColumns="20% 80%" h="100vh">
                        <GridItem>
                            <AdminNav />
                        </GridItem>
                        <GridItem>
                            <Outlet />
                        </GridItem>
                    </Grid>
                }
            >
                <Route path="" element={<AllClients />} />
                <Route path="/admin/dashboard/all-clients" element={<AllClients />} />
                <Route path="/admin/dashboard/client-profile/:client" element={<ClientProfile />} />
                <Route path="/admin/dashboard/client-profile/:client/viewhistory" element={<ViewHistory />} />
                <Route path="/admin/dashboard/settings" element={<AdminSettings />} />
            </Route>
        </Routes>
    );
};
