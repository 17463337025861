import { Box, Grid, GridItem, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { RiArrowLeftSLine } from "react-icons/ri";
import CustomButton from "../../components/CustomButton";
import { PiDownloadThin } from "react-icons/pi";
import { useNavigate, useParams } from "react-router-dom";

const ButtonTextStyle1 = {
    bg: "#FFF",
    border: "1px solid",
    borderColor: "rgba(216, 216, 216, 1)",
    padding: "8px 24px 8px 24px",
    borderRadius: "8px 8px 8px 8px",
    _hover: {
        bg: "#FFF",
    },
    color: "rgba(0, 0, 0, 1)",
    fontFamily: "fontFamily",
    fontSize: "16px",

    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "-0.16px",
};
const ButtonTextStyle2 = {
    bg: "#FFF",
    border: "1px solid",
    borderColor: "#57BA47",
    padding: "8px 24px 8px 24px",
    _hover: {
        bg: "#FFF",
    },
    color: "rgba(0, 0, 0, 1)",
    fontFamily: "fontFamily",
    fontSize: "12px",

    fontWeight: "400",
    lineHeight: "26.45px",
    letterSpacing: "-0.16px",
};
const DateStyle = {
    color: "rgba(0, 0, 0, 1)",
    fontFamily: "fontFamily",
    fontSize: "14px",

    fontWeight: "600",
    lineHeight: "26.45px",
};
const TimePeriodStyle = {
    color: "rgba(0, 0, 0, 1)",
    fontFamily: "fontFamily",
    fontSize: "12px",

    fontWeight: "400",
    lineHeight: "21px",
};
const PriceStyle = {
    color: "rgba(0, 0, 0, 1)",
    fontFamily: "fontFamily",
    fontSize: "20px",

    fontWeight: "600",
    lineHeight: "26.45px",
};

export const ViewHistory = () => {
    const navigate = useNavigate();
    const { client } = useParams();

    const userHistory = [
        {
            Date: "15 September 2024",
            "Time period": " 1-06-2024 to 1-07-2024",
            price: "$80",
        },
        {
            Date: "15 September 2024",
            "Time period": "1-06-2024 to 1-07-2024",
            price: "$80",
        },
        {
            Date: "15 September 2024",
            "Time period": "1-06-2024 to 1-07-2024",
            price: "$80",
        },
        {
            Date: "15 September 2024",
            "Time period": "1-06-2024 to 1-07-2024",
            price: "$80",
        },
        {
            Date: "15 September 2024",
            "Time period": "1-06-2024 to 1-07-2024",
            price: "$80",
        },
        {
            Date: "15 September 2024",
            "Time period": "1-06-2024 to 1-07-2024",
            price: "$80",
        },
        {
            Date: "15 September 2024",
            "Time period": "1-06-2024 to 1-07-2024",
            price: "$80",
        },
    ];
    const handleNavigateProfile = () => {
        navigate(`/admin/dashboard/client-profile/${client}`);
    };
    return (
        <>
            <HStack
                display="flex"
                justifyContent="space-between"
                w="80%"
                h="110px"
                bg="rgba(246, 246, 246, 1)"
                position="fixed"
                top="0px"
                bottom="0px"
                zIndex="1000"
                boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
            >
                <Box
                    display=" flex"
                    align="center"
                    alignItems="center"
                    gap="0.5rem"
                    ml={{ base: "3rem", sm: "3rem", md: "3rem", lg: "3rem", xl: "3rem" }}
                >
                    <RiArrowLeftSLine style={{ fontSize: "35px", cursor: "pointer" }} onClick={handleNavigateProfile} />
                    <Text
                        fontFamily="fontFamily"
                        fontSize="28px"
                        fontWeight="700"
                        lineHeight="42px"
                        letterSpacing="-0.01em"
                        textAlign="left"
                        color="rgba(17, 37, 14, 1)"
                    >
                        History
                    </Text>
                </Box>
                <CustomButton w="14%" h="6vh" mr="2rem" sx={ButtonTextStyle1}>
                    Download
                    <Box as={PiDownloadThin} boxSize="20px" ml="10px" color="rgba(76, 76, 76, 1)" />
                </CustomButton>
            </HStack>

            {/* Cllient History */}
            <Grid
                gridTemplateColumns="repeat(1,1fr)"
                justifyContent="center"
                mt={{ base: "7rem", sm: "10rem", lg: "10rem", xl: "10rem", "2xl": "10rem" }}
                minW="90%"
                ml="3.5rem"
            >
                {userHistory.map((element, index) => (
                    <GridItem justifyContent="space-between" key={index} bg={index % 2 === 0 ? "rgba(249, 249, 249, 1)" : "none"} p="2rem">
                        <HStack w="full" justifyContent="space-between">
                            <VStack ml="2rem" align="start">
                                <Text textAlign="start" sx={DateStyle}>
                                    {element.Date}
                                </Text>
                                <Box gap="0.5px">
                                    <Text textAlign="start" sx={TimePeriodStyle}>
                                        Membership for
                                    </Text>
                                    <Text textAlign="start" sx={TimePeriodStyle}>
                                        {element["Time period"]}
                                    </Text>
                                </Box>
                            </VStack>

                            {/*  */}
                            <VStack mr="2rem" w="20%" align="end">
                                <Text textAlign="end" sx={PriceStyle}>
                                    $80
                                </Text>
                                <CustomButton minW={{ md: "30%", lg: "30%", xl: "50%", "2xl": "50%" }} h="4vh" align="end" sx={ButtonTextStyle2}>
                                    View Invoice
                                </CustomButton>
                            </VStack>
                        </HStack>
                    </GridItem>
                ))}
            </Grid>
        </>
    );
};
