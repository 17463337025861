export const Toggle_iFrstCircleCheck = "Toggle_firstCircleCheck";
export const Toggle_Step2 = "Toggle_Step2";
export const Toggle_Step3 = "Toggle_Step3";
export const Toggle_Step4 = "Toggle_Step4";
export const Toggle_Step5 = "Toggle_Step5";
export const Toggle_Step6 = "Toggle_Step6";
export const Toggle_Step7 = "Toggle_Step7";
export const Toggle_Step8 = "Toggle_Step8";
export const Toggle_Step9 = "Toggle_Step9";
export const Toggle_StepTopBarSettings = "Toggle_Step10";
export const Add_Device_Toggle_Stepper = "Add_Device_Toggle_Stepper";
export const Add_Device2_Toggle_Stepper = "Add_Device2_Toggle_Stepper";
export const Add_Device3_Toggle_Stepper = "Add_Device3_Toggle_Stepper";
export const Add_Device4_Toggle_Stepper = "Add_Device4_Toggle_Stepper";
export const  OnboardDevice_Toggle_Stepper = " OnboardDevice_Toggle_Stepper";
