import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Box } from "@chakra-ui/react";
import Moment from "moment-timezone";
import { formatUnitForSensorType } from "../utils/sensorUnitUtils";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TemperatureChart = ({ data, selectedGraph, selectedType, deviceData }) => {
    const sortedItems = data?.sort((a, b) => new Date(a.fetch_time) - new Date(b.fetch_time));
    const filteredData = [...new Map(sortedItems?.map((item) => [item.fetch_time, item])).values()].slice(-10);

    const Max = deviceData?.[selectedGraph === "sensor1" ? "sensor_one" : "sensor_two"]?.max_value;
    const Min = deviceData?.[selectedGraph === "sensor1" ? "sensor_one" : "sensor_two"]?.min_value;

    const chartData = {
        // labels: filteredData.map((point) => new Date(point.fetch_time).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false })),
        labels: filteredData.map((point) => Moment(point.fetch_time).format("HH:mm A")),
        datasets: [
            {
                label: selectedType === "humidity" ? "Humidity" : "Temperature",
                data: filteredData.map((point) => (selectedGraph === "sensor1" ? point.sensor_one?.toFixed(1) : point.sensor_two?.toFixed(1))),
                backgroundColor: selectedType === "humidity" ? "#99E9E9" : "#FFCC00",
                borderColor: selectedType === "humidity" ? "#99E9E9" : "#FFCC00",
                borderWidth: 1,
                barThickness: 24,
                borderRadius: 4,
            },
            {
                label: "Max Value",
                data: new Array(filteredData?.length).fill(Max),
                borderDash: [10, 5], // Dashed line for upper range
                borderWidth: 2,
                borderColor: "#FF5733",
                pointRadius: 0,

                type: "line",
            },

            {
                label: "Min Value",
                data: new Array(filteredData?.length).fill(Min),
                borderDash: [10, 5], // Dashed line for lower range
                pointRadius: 0,
                borderWidth: 2,
                borderColor: "#3CB371",
                type: "line",
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Time",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                title: {
                    display: true,
                    text: selectedType === "humidity" ? "Humidity" : "Temperature",
                },
                // min: 0,
                // max:
                //     Math.ceil(
                //         (Math.max(...filteredData.map((point) => (selectedGraph === "sensor1" ? point.sensor_one : point.sensor_two))) + 10) / 5,
                //     ) * 5,
                ticks: {
                    stepSize: 1,
                },
            },
        },
        plugins: {
            legend: false,
            tooltip: {
                enabled: true,
                mode: "index",
                intersect: false,
                callbacks: {
                    // Customize the tooltip title to show the formatted date and time
                    title: (tooltipItems) => "",
                    label: (tooltipItem) => {
                        // Customize the label for each data point
                        const formattedValue = formatUnitForSensorType({
                            sensorType: selectedType === "humidity" ? "HumidityHumidity" : "HumidityTemperature",
                            value: tooltipItem.raw,
                        });
                        // for removing the tooltip legends of max and min limits
                        if (tooltipItem.dataset.label === "Max Value" || tooltipItem.dataset.label === "Min Value") return "";
                        return `${tooltipItem.dataset.label}: ${formattedValue}`;
                    },
                    footer: (tooltipItems) => {
                        // Use the first item to get the date and time
                        const dataIndex = tooltipItems[0].dataIndex;
                        const originalFetchTime = filteredData[dataIndex]?.fetch_time;
                        const date = Moment(originalFetchTime).format("DD-MM-YYYY");
                        const time = Moment(originalFetchTime).format("HH:mm A");

                        // Return an array to format the tooltip with separate lines
                        return ["Recorded at : ", `Date: ${date}`, `Time: ${time}`];
                    },
                },
            },
        },
    };

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column">
            <Box flex="1" display="flex">
                <Bar data={chartData} options={options} style={{ width: "100%", height: "200px" }} />
            </Box>
        </Box>
    );
};

export default TemperatureChart;
