import React from "react";
import { Avatar, Box, Grid, HStack, Link, Text, useDisclosure, VStack } from "@chakra-ui/react";
import userimg from "../../assets/Ellipse.png";
import EditPassword from "./EditPassword";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchUserProfile = async () => {
    const res = await axios.get(`/auth/user`);
    return res?.data?.data;
};

export const AdminSettings = () => {
    const { data: userProfile, ...userProfileQuery } = useQuery({
        queryKey: ["fetchUserProfile"],
        queryFn: () => fetchUserProfile(),
    });

    const { isOpen: editIsOpen, onOpen: editonOpen, onClose: editonClose } = useDisclosure();

    const handleEditPassword = () => {
        editonOpen();
    };
    return (
        <>
            <HStack
                w="100%"
                h="110px"
                bg="#F8FBEE"
                justifyContent="space-between"
                position="fixed"
                top="0px"
                bottom="0px"
                zIndex="1000"
                boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
            >
                <Text
                    ml="3rem"
                    fontFamily="fontFamily"
                    fontSize="28px"
                    fontWeight="700"
                    lineHeight="42px"
                    letterSpacing="-0.01em"
                    textAlign="left"
                    color="rgba(17, 37, 14, 1)"
                >
                    Settings
                </Text>
            </HStack>

            <Box display="flex" flexDirection="column" alignItems="center" minW="100%" mt="7rem" minH="100vh">
                <Grid gridTemplateColumns="repeat(1,1fr)" w="100%">
                    <VStack w="100%" display="flex" flexDirection="column" mt="3rem" mb="3rem">
                        <Avatar size="xl" name="John Doe" w="120px" h="120px" src={userimg} />
                        <Box gap="5px" spacing="0.5rem" align="center">
                            <Text color="#11250E" fontWeight="600" lineHeight="24px" letterSpacing="-0.01em" fontSize="20px" fontFamily=" fontFamily">
                                {userProfile?.user?.name}
                            </Text>
                            <Text color="#818681" fontWeight="500" lineHeight="20px" letterSpacing="-0.01em" fontSize="14px" fontFamily=" fontFamily">
                                Admin
                            </Text>
                        </Box>
                    </VStack>
                </Grid>

                <Box
                    position="relative"
                    top="-2%"
                    w={{ base: "90%", sm: "90%", md: "90%", lg: "90%", xl: "90%", "2xl": "90%" }}
                    borderRadius="16px 16px 16px 16px"
                    boxShadow="0px 4px 25px 0px #0000000D"
                    background="#FFFFFF"
                    mt="2rem"
                    pb="2rem"
                    mb="2rem"
                    pt="1.5rem"
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        borderBottom="1px solid #E0E5E0"
                        w={{ base: "92%", sm: "92%", md: "92%", lg: "92%", xl: "93%", "2xl": "96%" }}
                        ml="2rem"
                    >
                        <Text
                            color="#11250E"
                            fontWeight="600"
                            lineHeight="36px"
                            letterSpacing="-0.01em"
                            fontSize="24px"
                            fontFamily=" fontFamily"
                            mt="16px"
                            mr="40px"
                            align="left"
                        >
                            Login Details
                        </Text>
                        <Link
                            isExternal
                            textDecoration="underline"
                            fontFamily=" fontFamily"
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="20px"
                            color="#57BA47"
                            onClick={handleEditPassword}
                        >
                            Edit
                        </Link>
                    </Box>

                    <VStack spacing={4} mt="40px" align="start" ml="40px">
                        <Box display="flex" align="start" gap="3rem">
                            <Text
                                w="70%"
                                color="rgba(129, 134, 129, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontFamily=" fontFamily"
                            >
                                Email Address
                            </Text>
                            <Text
                                color="rgba(17, 37, 14, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontFamily=" fontFamily"
                            >
                                {userProfile?.user?.email}
                            </Text>
                        </Box>

                        <Box display="flex" align="start" gap="5rem">
                            <Text
                                w="50%"
                                color="rgba(129, 134, 129, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontFamily=" fontFamily"
                            >
                                Password
                            </Text>
                            <Text
                                color="rgba(17, 37, 14, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontFamily=" fontFamily"
                            >
                                ************
                            </Text>
                        </Box>
                    </VStack>
                </Box>
            </Box>
            <EditPassword editIsOpen={editIsOpen} editonOpen={editonOpen} editonClose={editonClose} />
        </>
    );
};
