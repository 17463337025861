import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerFooter,
    FormLabel,
    Select,
    Textarea,
    FormErrorMessage,
    FormControl,
    Image,
    Flex,
    Input,
    Button,
    Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "./CustomButton";
import SensorImg from "../assets/incident_sensor.png";
import CorrectImg from "../assets/correct.png";
import ExclaimImg from "../assets/incident_problem.png";
import { useMutation } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import Axios from "axios";

const AddReport = ({ isOpen, onClose, deviceId, logId, fetchTime, sensorType, onReportSuccess }) => {
    const [currentStep, setCurrentStep] = useState("form"); // "form" or "success"

    const { data: user } = useQuery({
        queryKey: "fetchProfile",
        enabled: false,
        select: function (data) {
            return {
                id: data?.data?.user?.id,
                name: data?.data?.user?.name,
            };
        },
    });

    const reportIncidentMutation = useMutation({
        mutationFn: async function ({ incident_reason, corrective_actions, product_loss, safety_status }) {
            const response = await Axios.post("/log/incident", {
                reported_by: user?.id,
                device_id: deviceId,
                log_id: logId,
                incident_reason,
                corrective_actions,
                product_loss,
                safety_status,
                occured_at: fetchTime,
                sensor_type: sensorType,
            });
            return response.data;
        },
        onSuccess: function (response) {
            console.log("Incident reported successfully:", response);
            setCurrentStep("success"); // Move to success step
        },
        onError: function (error) {
            console.error("Failed to report incident:", error.response?.data || error.message);
        },
    });

    const validation = useFormik({
        initialValues: {
            reported_by: user?.name,
            incident_reason: "",
            corrective_actions: "",
            product_loss: "",
            safety_status: "",
        },
        validationSchema: Yup.object({
            incident_reason: Yup.string().min(5, "Must be at least 5 characters.").required("Incident reason is required."),
            corrective_actions: Yup.string().min(5, "Must be at least 5 characters.").required("Corrective actions are required."),
            safety_status: Yup.string().oneOf(["safe", "not safe"], "Invalid choice.").required("Please select safety status."),
            product_loss: Yup.string().oneOf(["yes", "no"], "Invalid choice.").required("Please select if there was product loss."),
        }),
        onSubmit: (values) => {
            console.log("Submitted values:", values);
            reportIncidentMutation.mutate(values);
        },
    });

    const inputStyle = {
        borderRadius: "4px",
        border: "1px solid #81868180",
        fontFamily: "var(--chakra-font-fontFamily)",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "-0.01em",
        color: "#818681",
        _focus: {
            border: "1.5px solid #57BA47",
            boxShadow: "none",
        },
        "::placeholder": {
            color: "#818681",
            opacity: 0.4,
        },
    };

    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
            <DrawerOverlay />
            <DrawerContent borderRadius="10px" m="12px" minWidth="395px">
                <DrawerCloseButton mt="35px" bg="#F6F6F6" color="#11250E" h="1.2rem" sx={{ mr: "1rem", pt: "1rem", pb: "1rem" }} />
                <DrawerHeader mt="22px" fontFamily="fontFamily" fontWeight="700" fontSize="24px" lineHeight="36px" letterSpacing="-0.01em">
                    {currentStep === "form" ? "Report an Incident" : null}
                </DrawerHeader>
                <DrawerBody overflowY="auto" position="relative">
                    {currentStep === "form" ? (
                        <form onSubmit={validation.handleSubmit}>
                            <Flex justifyContent="center">
                                <Image src={SensorImg} />
                                <Image src={ExclaimImg} pos="absolute" top="0" left="60%" />
                            </Flex>
                            {/* Reported By */}
                            <FormControl mb="27px" isReadOnly>
                                <FormLabel>Reported By</FormLabel>
                                <Input name="reported_by" value={validation.values.reported_by} color="gray.500" isReadOnly bg="gray.100" />
                            </FormControl>
                            <FormControl mb="27px" isInvalid={validation.errors.incident_reason && validation.touched.incident_reason} isRequired>
                                <FormLabel>Incident Reason</FormLabel>
                                <Textarea
                                    placeholder="Brief description of the incident"
                                    name="incident_reason"
                                    value={validation.values.incident_reason}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    sx={inputStyle}
                                />
                                <FormErrorMessage>{validation.errors.incident_reason}</FormErrorMessage>
                            </FormControl>

                            <FormControl
                                mb="27px"
                                mt={4}
                                isInvalid={validation.errors.corrective_actions && validation.touched.corrective_actions}
                                isRequired
                            >
                                <FormLabel>Corrective Actions</FormLabel>
                                <Textarea
                                    placeholder="Steps taken to resolve or mitigate"
                                    name="corrective_actions"
                                    value={validation.values.corrective_actions}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    sx={inputStyle}
                                />
                                <FormErrorMessage>{validation.errors.corrective_actions}</FormErrorMessage>
                            </FormControl>

                            <FormControl mb="27px" mt={4} isInvalid={validation.errors.safety_status && validation.touched.safety_status} isRequired>
                                <FormLabel>Safety Status</FormLabel>
                                <Select
                                    placeholder="Select option"
                                    name="safety_status"
                                    value={validation.values.safety_status}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    sx={inputStyle}
                                >
                                    <option value="safe">Safe</option>
                                    <option value="not safe">Not Safe</option>
                                </Select>
                                <FormErrorMessage>{validation.errors.safety_status}</FormErrorMessage>
                            </FormControl>

                            <FormControl mb="27px" mt={4} isInvalid={validation.errors.product_loss && validation.touched.product_loss} isRequired>
                                <FormLabel>Product Loss</FormLabel>
                                <Select
                                    placeholder="Select option"
                                    name="product_loss"
                                    value={validation.values.product_loss}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    sx={inputStyle}
                                >
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </Select>
                                <FormErrorMessage>{validation.errors.product_loss}</FormErrorMessage>
                            </FormControl>
                        </form>
                    ) : (
                        <Flex direction="column" alignItems="center" justifyContent="space-around" h="100%">
                            <Text color="#11250E" fontFamily="fontFamily" fontWeight="700" fontSize="28px" lineHeight="42px" letterSpacing="-1%">
                                Incident Recorded
                            </Text>
                            <Image src={CorrectImg} mb="1rem" />
                            <Text
                                textAlign="center"
                                color="#11250E"
                                fontFamily="fontFamily"
                                fontWeight="600"
                                fontSize="16px"
                                lineHeight="36px"
                                letterSpacing="-1%"
                            >
                                Your incident report has been successfully submitted and is now being reviewed.
                            </Text>
                        </Flex>
                    )}
                </DrawerBody>
                <DrawerFooter>
                    {currentStep === "form" ? (
                        <CustomButton
                            type="submit"
                            mt="1rem"
                            w="100%"
                            h="2.5rem"
                            bg="#57BA47"
                            onClick={validation.handleSubmit}
                            disabled={!validation.isValid || validation.isSubmitting}
                        >
                            Submit Report
                        </CustomButton>
                    ) : (
                        <CustomButton type="submit" mt="1rem" w="100%" h="2.5rem" bg="#57BA47" onClick={() => onReportSuccess()}>
                            Done
                        </CustomButton>
                    )}
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};

export default AddReport;
