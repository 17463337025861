import React, { useState } from "react";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button, Text, Box, VStack, Heading, Grid, GridItem, Spinner, Center } from "@chakra-ui/react";

const CheckoutForm = ({ clientSecret, setPaymentDone }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [cardName, setCardName] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements || !clientSecret) {
            console.error("Stripe, Elements, or clientSecret is not loaded.");
            return;
        }

        const cardNumberElement = elements.getElement(CardNumberElement);
        setLoading(true);

        try {
            // Fetch the existing PaymentIntent first
            const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

            // Check if the payment has already succeeded
            if (paymentIntent && paymentIntent.status === "succeeded") {
                setSuccessMessage("Payment already succeeded!");
                setErrorMessage("");
                setLoading(false);
                setPaymentDone(true);
                return;
            }

            // If the payment has not succeeded, proceed with confirmation
            const { error, paymentIntent: confirmedPaymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardNumberElement,
                    billing_details: {
                        name: cardName,
                    },
                    confirmParams: {
                        return_url: window.location.href, // Or set to success page
                    },
                },
            });

            if (error) {
                if (error.code === "payment_intent_unexpected_state") {
                    setErrorMessage("The payment is in an unexpected state. Please try again.");
                } else {
                    setErrorMessage(`Payment failed: ${error.message}`);
                }
                setSuccessMessage("");
            } else if (confirmedPaymentIntent.status === "succeeded") {
                setSuccessMessage("Payment succeeded!");
                setErrorMessage("");
                setPaymentDone(true);
            } else {
                setSuccessMessage("");
                setErrorMessage(`Payment status: ${confirmedPaymentIntent.status}`);
            }
        } catch (e) {
            console.error("Unexpected error:", e);
            setErrorMessage(`Unexpected error: ${e.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box maxW="lg" mx="auto" p={6} borderWidth={1} borderRadius="lg" boxShadow="lg" bg="white" borderColor="#57BA47">
            <Heading as="h2" size="lg" mb={4} textAlign="center">
                Payment Details
            </Heading>
            <VStack spacing={6} align="stretch">
                <form onSubmit={handleSubmit}>
                    <Grid templateColumns="1fr" gap={4}>
                        <GridItem>
                            <Box p={4} borderWidth={1} borderRadius="md" borderColor="#57BA47" bg="gray.50">
                                <CardNumberElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: "16px",
                                                color: "#424770",
                                                "::placeholder": {
                                                    color: "#aab7c4",
                                                },
                                            },
                                            invalid: {
                                                color: "#9e2146",
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </GridItem>

                        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                            <GridItem>
                                <Box p={4} borderWidth={1} borderRadius="md" borderColor="#57BA47" bg="gray.50">
                                    <CardExpiryElement
                                        options={{
                                            style: {
                                                base: {
                                                    fontSize: "16px",
                                                    color: "#424770",
                                                    "::placeholder": {
                                                        color: "#aab7c4",
                                                    },
                                                },
                                                invalid: {
                                                    color: "#9e2146",
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                            </GridItem>
                            <GridItem>
                                <Box p={4} borderWidth={1} borderRadius="md" borderColor="#57BA47" bg="gray.50">
                                    <CardCvcElement
                                        options={{
                                            style: {
                                                base: {
                                                    fontSize: "16px",
                                                    color: "#424770",
                                                    "::placeholder": {
                                                        color: "#aab7c4",
                                                    },
                                                },
                                                invalid: {
                                                    color: "#9e2146",
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                            </GridItem>
                        </Grid>
                    </Grid>
                    <Box mt={4}>
                        <Text mb={2} fontSize="md">
                            Cardholder's Name
                        </Text>
                        <input
                            type="text"
                            value={cardName}
                            onChange={(e) => setCardName(e.target.value)}
                            placeholder="Name on card"
                            style={{
                                width: "100%",
                                padding: "8px",
                                border: "1px solid #57BA47",
                                borderRadius: "4px",
                                backgroundColor: "gray.50",
                                outline: "none",
                                transition: "border-color 0.3s ease-in-out",
                            }}
                            onFocus={(e) => (e.target.style.borderColor = "#57BA47")}
                            onBlur={(e) => (e.target.style.borderColor = "#57BA47")}
                        />
                    </Box>
                    <Button
                        mt={6}
                        type="submit"
                        backgroundColor="#57BA47"
                        color="#ffffff"
                        size="lg"
                        isDisabled={!stripe || !clientSecret || loading}
                        width="full"
                        _hover={{ bg: "#57BA47", color: "#ffffff" }}
                    >
                        {loading ? (
                            <Center>
                                <Spinner color="#ffffff" />
                            </Center>
                        ) : (
                            "Pay Now"
                        )}
                    </Button>
                </form>
                {errorMessage && (
                    <Text color="red.500" fontSize="md" textAlign="center">
                        {errorMessage}
                    </Text>
                )}
                {successMessage && (
                    <Text color="green.500" fontSize="md" textAlign="center">
                        {successMessage}
                    </Text>
                )}
            </VStack>
        </Box>
    );
};

export default CheckoutForm;
