import React, { useState } from "react";
import { Box, Button, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import notification from "../assets/error-notification.svg";
import moment from "moment-timezone";
import { useQuery } from "@tanstack/react-query";

export const Notification = ({ notifications, refetch }) => {
    const { space } = useParams();
    const navigate = useNavigate();

    const [selectedNotificationType, setSelectedNotificationType] = useState("all");

    const filteredNotifications = notifications.filter((notification) => {
        if (selectedNotificationType === "all") return true;
        return selectedNotificationType === "Unread" ? !notification.is_read : false;
    });

    const updateNotification = async (id, device_id, log_id, breached_sensor) => {
        try {
            const response = await axios.patch(`/notification`, { notification_id: id });
            if (response.status === 200) {
                refetch();
                navigate(`/dashboard/${space}/devices/${device_id}?logId=${log_id}&breachedSensor=${breached_sensor}`);
            }
        } catch (error) {
            console.error("Error in notification update:", error);
        }
    };

    const { data: userId } = useQuery({
        queryKey: "fetchProfile",
        enabled: false,
        select: function (data) {
            return data?.data?.user?.id;
        },
    });

    const markAllAsRead = async (user_id) => {
        try {
            const response = await axios.patch(`/notification/all`, { user_id });
            if (response.status === 200) {
                refetch();
            }
        } catch (error) {
            console.error("Error marking all notifs as read:", error);
        }
    };

    const handle_Show_All_Notifiocation = () => {
        navigate(`/dashboard/${space}/all_notif`);
    };

    return (
        <Box
            position="absolute"
            top="80px"
            right="50px"
            bg="#FFFFFF"
            border="1px solid #11250E1A"
            borderRadius="8px"
            zIndex="2000"
            width="475px"
            height="550px"
            display="flex"
            flexDirection="column"
        >
            <Flex paddingY={4} paddingX={4} borderBottom="1px solid #E0E5E0">
                <VStack align="start" spacing={6} justifyContent="space-between" w="100%">
                    <HStack justifyContent="space-between" w="100%">
                        <Text fontFamily="fontFamily" fontSize="20px" fontWeight="900" letterSpacing="-0.04em" color="#11250E">
                            Notifications
                        </Text>
                        <Text
                            color="rgba(150, 152, 153, 1)"
                            textDecoration="underline"
                            fontFamily="fontFamily"
                            fontSize="14px"
                            fontWeight="500"
                            lineHeight="-0.01%"
                            borderRadius="20px"
                            cursor="pointer"
                            onClick={() => markAllAsRead(userId)}
                        >
                            Mark all as read
                        </Text>
                    </HStack>
                    <HStack>
                        <Text
                            fontFamily="fontFamily"
                            fontSize="14px"
                            fontWeight="600"
                            borderRadius="24px"
                            padding="8px 16px"
                            cursor="pointer"
                            bgColor={selectedNotificationType === "all" ? "#6CC35E" : "#F8F8F8"}
                            color={selectedNotificationType === "all" ? "#FFFFFF" : "#818681"}
                            onClick={() => setSelectedNotificationType("all")}
                        >
                            All
                        </Text>
                        <Text
                            fontFamily="fontFamily"
                            fontSize="14px"
                            fontWeight="600"
                            borderRadius="24px"
                            padding="8px 16px"
                            cursor="pointer"
                            bgColor={selectedNotificationType === "Unread" ? "#6CC35E" : "#F8F8F8"}
                            color={selectedNotificationType === "Unread" ? "#FFFFFF" : "#818681"}
                            onClick={() => setSelectedNotificationType("Unread")}
                        >
                            Unread
                        </Text>
                    </HStack>
                </VStack>
            </Flex>

            <Box
                flex="1"
                overflowY="auto"
                sx={{
                    "&::-webkit-scrollbar": {
                        width: "0px",
                    },
                    "&:hover::-webkit-scrollbar": {
                        width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        background: "transparent",
                        borderRadius: "10px",
                    },
                    "&:hover::-webkit-scrollbar-thumb": {
                        background: "rgba(104, 110, 103, 0.3)",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "rgba(104, 110, 103, 0.3)",
                    },
                }}
            >
                <VStack align="start" width="100%" spacing={0}>
                    {filteredNotifications.map((item) => (
                        <Box
                            key={item.id}
                            paddingY={4}
                            paddingX={2}
                            borderLeft={item.is_read ? "transparent" : "3.5px solid rgba(197, 16, 16, 1)"}
                            borderBottom="1px solid #E0E5E0"
                            width="100%"
                            cursor="pointer"
                            onClick={() => updateNotification(item.id, item?.device, item?.log_id, item?.breached_sensor)}
                            _hover={{
                                boxShadow: "0px 4px 12px rgba(0,0,0,0.15)",
                                transition: "box-shadow 0.2s ease",
                            }}
                        >
                            <HStack align="start" spacing={0}>
                                <Image src={notification} w="30px" h="30px" mx={2} />
                                <Text
                                    fontFamily="fontFamily"
                                    fontWeight="500"
                                    fontSize="16px"
                                    lineHeight="24px"
                                    letterSpacing="-0.01em"
                                    color="#65696A"
                                >
                                    <Text as="span" fontWeight="800">
                                        {item?.deviceNotification?.name}
                                    </Text>{" "}
                                    {item.text_content}
                                </Text>
                            </HStack>
                            <Box align="start" ml="3rem">
                                <Text
                                    fontFamily="fontFamily"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="16px"
                                    letterSpacing="-0.01em"
                                    color="#969899"
                                >
                                    {moment(item.created_at).format("DD-MM-YYYY HH:mm A")}
                                </Text>
                            </Box>
                        </Box>
                    ))}
                </VStack>
            </Box>

            <Box bg="#FFFFFF" borderTop="1px solid #E0E5E0" paddingY={2} textAlign="center">
                <Text
                    fontFamily="fontFamily"
                    fontSize="14px"
                    fontWeight="600"
                    cursor="pointer"
                    color="#6CC35E"
                    onClick={handle_Show_All_Notifiocation}
                >
                    View All
                </Text>
            </Box>
        </Box>
    );
};
