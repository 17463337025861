import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    FormLabel,
    Image,
    Input,
    FormControl,
    FormErrorMessage,
} from "@chakra-ui/react";
import React from "react";
import CustomButton from "../../components/CustomButton";
import plusIcon from "../../assets/Plus_Icon.png";
import editPasword from "../assets/edit password.svg";
import { useFormik } from "formik";
import * as Yup from "yup";

// Input and label styles
const inputStyle = {
    borderRadius: "4px",
    border: "1px solid #81868180",
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    textAlign: "left",
    color: "#818681",
    _focus: { border: "1px solid #81868180", boxShadow: "none" },
};

const labelStyle = {
    fontFamily: "fontFamily",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "-0.01em",
};

const buttonTextStyle = {
    _hover: { bg: "#57BA47" },
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    color: "#FFFFFF",
};

// Validation schema using Yup
const validationSchema = Yup.object({
    currentPassword: Yup.string().required("Current password is required."),
    newPassword: Yup.string().required("New password is required."),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm password is required."),
});

const EditPassword = ({ editIsOpen, editonClose }) => {
    const btnRef = React.useRef();

    // Formik for form management
    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema,
        onSubmit: (values) => {
         
            editonClose();
        },
    });

    return (
        <Drawer isOpen={editIsOpen} placement="right" onClose={editonClose} finalFocusRef={btnRef}>
            <DrawerOverlay />
            <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} borderRadius="10px" m="12px">
                <DrawerCloseButton mt="39px" bg="#F6F6F6" color="#11250E" h="1.2rem" sx={{ mr: "1rem", pt: "1rem", pb: "1rem" }} />
                <DrawerHeader mt="22px" fontFamily="fontFamily" fontWeight="700" fontSize="24px" lineHeight="36px" letterSpacing="-0.01em">
                    Update Password
                </DrawerHeader>

                <DrawerBody
                    overflowY="auto"
                    sx={{
                        "::-webkit-scrollbar": { display: "none" },
                        scrollbarWidth: "none",
                    }}
                >
                    <Box>
                        <Image src={editPasword} alt="edit-password" />
                    </Box>

                    <form onSubmit={formik.handleSubmit}>
                        <Box mb={4}>
                            <FormControl isInvalid={formik.touched.currentPassword && formik.errors.currentPassword}>
                                <FormLabel htmlFor="currentPassword" sx={labelStyle}>
                                    Current Password
                                </FormLabel>
                                <Input
                                    id="currentPassword"
                                    placeholder="Type here"
                                    type="password"
                                    sx={inputStyle}
                                    {...formik.getFieldProps("currentPassword")}
                                />
                                <FormErrorMessage>{formik.errors.currentPassword}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb={4}>
                            <FormControl isInvalid={formik.touched.newPassword && formik.errors.newPassword}>
                                <FormLabel htmlFor="newPassword" sx={labelStyle}>
                                    New Password
                                </FormLabel>
                                <Input
                                    id="newPassword"
                                    placeholder="Type here"
                                    type="password"
                                    sx={inputStyle}
                                    {...formik.getFieldProps("newPassword")}
                                />
                                <FormErrorMessage>{formik.errors.newPassword}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb={4}>
                            <FormControl isInvalid={formik.touched.confirmPassword && formik.errors.confirmPassword}>
                                <FormLabel htmlFor="confirmPassword" sx={labelStyle}>
                                    Confirm Password
                                </FormLabel>
                                <Input
                                    id="confirmPassword"
                                    placeholder="Type here"
                                    type="password"
                                    sx={inputStyle}
                                    {...formik.getFieldProps("confirmPassword")}
                                />
                                <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <CustomButton mt="1rem" w="27rem" mb="0.5rem" h="2.5rem" bg="#57BA47" sx={buttonTextStyle} type="submit">
                            <Image src={plusIcon} alt="sortIcon" border="1.2px" h="16px" w="16px" color="#FFFFFF" mr="3px" />
                            Edit Password
                        </CustomButton>
                    </form>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default EditPassword;
