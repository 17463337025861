import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

// Create the Impersonation Context
const ImpersonationContext = createContext();

// Custom hook to use the Impersonation Context
export function useImpersonation() {
    return useContext(ImpersonationContext);
}

// Impersonation Provider Component
export function ImpersonationProvider({ children }) {
    const queryClient = useQueryClient();
    const [isImpersonating, setIsImpersonating] = useState(false);
    const navigate = useNavigate();

    // Function to check if impersonation is active
    function checkImpersonation() {
        const impersonationToken = sessionStorage.getItem("impersonation_token");

        const impersonating = sessionStorage.getItem("impersonating");

        setIsImpersonating(impersonating && !!impersonationToken); // Set to true if token exists
    }

    // Function to stop impersonation
    function stopImpersonation() {
        clearSessionStorage();
        clearQueryCache();
        navigateToAdmin();
    }

    function navigateToAdmin() {
        clearQueryCache();
        navigate("/admin/dashboard");
    }

    function clearSessionStorage() {
        sessionStorage.clear();
        clearQueryCache();
        checkImpersonation();
    }
    function clearQueryCache() {
        queryClient.clear();
    }

    // Initially check impersonation status when the provider mounts
    useEffect(() => {
        checkImpersonation();
    }, []);

    // Value provided by the context to its consumers
    const value = {
        isImpersonating,
        checkImpersonation,
        stopImpersonation,
        navigateToAdmin,
        clearSessionStorage,
    };

    return <ImpersonationContext.Provider value={value}>{children}</ImpersonationContext.Provider>;
}
