import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Image,
    Input,
    Text,
    Textarea,
} from "@chakra-ui/react";
import React from "react";
import CustomButton from "./CustomButton";
import plusIcon from "../assets/Plus_Icon.png";
import ImageUpload from "./ImageUpload";
import Step2AddRoom from "./Step2AddRoom";
import { useParams } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SEARCH_PARAMS } from "../constants";
import { ROOM_QUERY_PARAMS } from "../lib/queriesParams";
import { useQueryParams } from "use-query-params";

const inputStyle = {
    borderRadius: "4px",
    border: "1px solid #81868180",
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    color: "#000000",
    _focus: {
        border: "1.5px solid #57BA47",
        boxShadow: "none",
    },
    "::placeholder": {
        color: "#818681",
        opacity: 0.4,
    },
};

const labelStyle = {
    fontFamily: "fontFamily",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "-0.01em",
    marginBottom: "4px",
    color: "#11250E",
};

const AddRoom = () => {
    const queryClient = useQueryClient();
    const { space } = useParams();
    const [query, setQuery] = useQueryParams(ROOM_QUERY_PARAMS);
    const isOpen = query[SEARCH_PARAMS.ROOM.ADD.key] !== "";

    const clearQuery = () => {
        setQuery({
            [SEARCH_PARAMS.ROOM.ADD.key]: SEARCH_PARAMS.ROOM.ADD.default_value,
            [SEARCH_PARAMS.ROOM.ADD_STATUS.key]: SEARCH_PARAMS.ROOM.ADD_STATUS.default_value,
        });
    };

    const validation = useFormik({
        initialValues: {
            name: "",
            description: "",
            image: "",
            space,
        },
        validationSchema: Yup.object({
            name: Yup.string().min(3, "Name is too short.").required("Name is required."),
            description: Yup.string().min(10, "Description is too short.").required("Description is required."),
        }),
        onSubmit: function (values) {
            newRoomMutation.mutate(values);
        },
        enableReinitialize: true,
    });

    const newRoomMutation = useMutation({
        mutationFn: async function ({ name, description, space, image }) {
            const formData = new FormData();
            formData.append("name", name);
            formData.append("description", description);
            formData.append("space", space);
            formData.append("image", image);

            const response = await axios.post(`/room`, formData);

            return response;
        },
        onSuccess: function (response) {
            validation.resetForm();
            queryClient.refetchQueries({
                queryKey: ["fetchMembers", space],
                exact: true
            });
            setQuery({ [SEARCH_PARAMS.ROOM.ADD.key]: response?.data?.data?.id });
            setQuery({ [SEARCH_PARAMS.ROOM.ADD_STATUS.key]: "success" });
        },
        onError: function (error) {
            if (error?.response?.data) {
                console.log(error?.response);
            }
        },
    });

    return (
        <>
            <Drawer placement="right" isOpen={isOpen} onClose={clearQuery}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <Box mt={{ base: "0px", sm: "0px", md: "5px", lg: "10px" }} mr="10px">
                        <DrawerCloseButton sx={{ color: "#11250E", p: "8px", bg: "#F6F6F6" }} />
                        <DrawerHeader
                            fontFamily="fontFamily"
                            fontSize="28px"
                            fontWeight="800"
                            lineHeight="42px"
                            letterSpacing="-0.04em"
                            textAlign="left"
                            color="#11250E"
                        >
                            Add Room
                        </DrawerHeader>
                    </Box>

                    <DrawerBody>
                        <Box mb={4}>
                            <FormControl isInvalid={validation.errors.name && validation.touched.name} isRequired>
                                <FormLabel htmlFor="name" sx={labelStyle}>
                                     Room Name
                                </FormLabel>
                                <Input
                                    id="name"
                                    placeholder="e.g., Kitchen"
                                    type="text"
                                    isInvalid={validation.errors.name && validation.touched.name}
                                    name="name"
                                    sx={inputStyle}
                                    value={validation.values.name}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.name}</FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box mb={4}>
                            <FormControl isInvalid={validation.errors.description && validation.touched.description} isRequired>
                                <FormLabel htmlFor="description" sx={labelStyle}>
                                  Room Description
                                </FormLabel>
                                <Textarea
                                    id="description"
                                    placeholder="Start typing..."
                                    sx={inputStyle}
                                    name="description"
                                    value={validation.values.description}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.description}</FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box mb={4}>
                            <FormLabel htmlFor="image" sx={labelStyle}>
                                Add Image
                            </FormLabel>
                            <Text sx={{ fontFamily: "fontFamily", color: "#818681" }} mb="2rem">
                                Upload an image to enhance record accuracy and keep your information complete.
                            </Text>
                            <ImageUpload
                                setImage={(file) => validation.setFieldValue("image", file)}
                                src={validation.values.image}
                                name="image"
                                label="Browse Photo"
                                alt="Uploaded Image"
                            />
                        </Box>

                        <Text fontFamily="fontFamily" fontWeight="400" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#818681">
                            NOTE: Fields marked with * are compulsory.
                        </Text>
                    </DrawerBody>

                    <DrawerFooter>
                        <Flex direction="column" justifyContent="center" gap={2} width="100%">
                            <CustomButton
                                width="100%"
                                h="40px"
                                bg="#57BA47"
                                sx={{
                                    _hover: { bg: "#57BA47" },
                                    fontFamily: "fontFamily",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                }}
                                marginInline="auto"
                                onClick={validation.handleSubmit}
                                disabled={!validation.isValid || newRoomMutation.isPending}
                            >
                                <Image src={plusIcon} alt="Add Device" h="16px" w="16px" mr="3px" />
                                Add Room
                            </CustomButton>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <Step2AddRoom />
        </>
    );
};

export default AddRoom;
