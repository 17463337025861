import { Box, Button, CloseButton, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { CheckCircleIcon, WarningTwoIcon } from "@chakra-ui/icons";

const CustomToast = ({ onClose, onClick, text, status = "success", showViewDetails = false }) => {
    const STATUS = {
        error: {
            icon: WarningTwoIcon,
            color: "#EA5D5D",
        },
        success: {
            icon: CheckCircleIcon,
            color: "#57BA47",
        },
    };

    return (
        <Box
            p={2}
            bg="white"
            boxShadow="md"
            rounded="md"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            columnGap={3}
            width="800px"
        >
            <Flex align="center" gap={3} ml={1} w="100%">
                <div
                    style={{
                        minHeight: 52,
                        width: 12,
                        borderRadius: 100,
                        backgroundColor: STATUS[status].color,
                    }}
                ></div>
                <Icon as={STATUS[status].icon} color={STATUS[status].color} w="32px" h="32px" />
                <Text fontFamily="fontFamily" fontWeight="600" fontSize="16px" lineHeight="30px" letterSpacing="-0.01em">
                    {text}
                </Text>
                {showViewDetails && (
                    <Button onClick={onClick} marginInlineStart={"auto"}>
                        View Details
                    </Button>
                )}
            </Flex>
            <CloseButton onClick={onClose} />
        </Box>
    );
};

export default CustomToast;

/* Use this to get toast in another componnet*/

/* 
const handleDeleteRoom = () => {
    // Logic for deleting the room goes here
    // Assuming the deletion was successful:
    const message = 'Room 01 has been deleted successfully';
    toast({
      position: 'top-right',
      render: () => <CustomToast id={id} message={message} onClose={() => toast.close(id)}/>,
    });
  };
*/
