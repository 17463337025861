import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, VStack, Text, Image, HStack, Flex } from "@chakra-ui/react";
import { RiRadioButtonLine } from "react-icons/ri";
import temp from "../assets/temsvg.svg";
import humidity from "../assets/drop.svg";
import Battery from "../assets/fullBattery.svg";
import TemperatureChart from "./chart";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { formatUnitForSensorType } from "../utils/sensorUnitUtils";

const fetchLogs = async (device) => {
    const res = await axios.get(`/log`, {
        params: { device_id: device },
    });
    //console.log(res.data);
    return res.data;
};

export const DashboardDevice = ({ devices }) => {
    const { space } = useParams();
    const navigate = useNavigate();
    const [selectedDevice, setSelectedDevice] = useState(devices?.data?.[0]);
    const [selectedGraph, setSelectedGraph] = useState("sensor1");
    const [selectedGraphType, setSelectedGraphType] = useState("temperature");
    const [selectedOverview, setSelectedOverview] = useState("recent");
    const HumidityHumidity = undefined;
    useEffect(() => {
        if (selectedDevice) {
            const sensorType = selectedDevice?.sensor_one?.sensor_type === HumidityHumidity ? "humidity" : "temperature";
            setSelectedGraphType(sensorType);
        }
    }, [selectedDevice]);

    const { data: logs, ...logsQuery } = useQuery({
        queryKey: ["fetchLogs", { device: selectedDevice?.id }],
        queryFn: () => fetchLogs(selectedDevice?.id),
        enabled: !!selectedDevice,
    });

    const filteredDevices = devices?.data?.filter((device) => {
        if (selectedOverview === "disconnected") {
            return !device?.active;
        }

        if (selectedOverview === "lowBattery") {
            return device?.battery < 30;
        }

        return true;
    });

    return (
        <Box w="full" h="100%" marginTop="16px" borderRadius="8px" bg="white" border="2px solid #EDEDEDCC" overflow="hidden">
            <Flex direction="column" h="full">
                <Flex w="full" h="110px" paddingY={4} paddingX={8} borderBottom="1px solid #E0E5E0">
                    <VStack align="start" justify="start" spacing={6}>
                        <HStack>
                            <Text
                                fontFamily="fontFamily"
                                fontSize="24px"
                                fontWeight="700"
                                letterSpacing="-0.04em"
                                textAlign="start"
                                color="#11250E"
                                h="24px"
                            >
                                Device Status
                            </Text>
                        </HStack>
                        <HStack>
                            <Text
                                fontFamily="fontFamily"
                                fontSize="12px"
                                fontWeight="600"
                                letterSpacing="0.02em"
                                borderRadius="24px"
                                padding="8px 12px"
                                bgColor={selectedOverview === "recent" ? "#6CC35E" : "#F8F8F8"}
                                color={selectedOverview === "recent" ? "#FFFFFF" : "#818681"}
                                onClick={() => setSelectedOverview("recent")}
                            >
                                Recent
                            </Text>
                            <Text
                                fontFamily="fontFamily"
                                fontSize="12px"
                                fontWeight="600"
                                letterSpacing="0.02em"
                                borderRadius="24px"
                                padding="8px 12px"
                                bgColor={selectedOverview === "lowBattery" ? "#6CC35E" : "#F8F8F8"}
                                color={selectedOverview === "lowBattery" ? "#FFFFFF" : "#818681"}
                                onClick={() => setSelectedOverview("lowBattery")}
                            >
                                Low Battery
                            </Text>
                            <Text
                                fontFamily="fontFamily"
                                fontSize="12px"
                                fontWeight="600"
                                letterSpacing="0.02em"
                                borderRadius="24px"
                                padding="8px 12px"
                                bgColor={selectedOverview === "disconnected" ? "#6CC35E" : "#F8F8F8"}
                                color={selectedOverview === "disconnected" ? "#FFFFFF" : "#818681"}
                                onClick={() => setSelectedOverview("disconnected")}
                            >
                                Offline
                            </Text>
                        </HStack>
                    </VStack>
                </Flex>

                <Flex flex="1" overflow="hidden">
                    {/* Sensor Map Section */}
                    <Box
                        w="30%"
                        h="100%"
                        p={4}
                        overflowY="auto"
                        bg="#EFEFEF5E"
                        borderRight="1px solid #E0E5E0"
                        overflow="auto"
                        sx={{
                            "&::-webkit-scrollbar": {
                                width: "8px",
                            },
                            "&::-webkit-scrollbar-track": {
                                background: "transparent",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                background: "transparent",
                                borderRadius: "10px",
                            },
                            "&:hover::-webkit-scrollbar-thumb": {
                                background: "rgba(104, 110, 103, 0.3)",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                background: "rgba(104, 110, 103, 0.3)",
                            },
                        }}
                    >
                        <VStack spacing={4} align="stretch">
                            {filteredDevices?.map((sensor, index) => (
                                <Box
                                    key={index}
                                    bgColor="#ffffff"
                                    p="20px"
                                    borderRadius="8px"
                                    border={selectedDevice === sensor ? "2px solid #57BA47" : "1px solid #81868180"}
                                    _hover={{ cursor: "pointer" }}
                                    boxShadow="0px 4px 30px 0px #0000000D"
                                    onClick={() => setSelectedDevice(sensor)}
                                    width="100%"
                                >
                                    <VStack borderBottom="2px solid #E0E5E0" spacing={0} align="flex-start" paddingBottom="10px">
                                        <HStack alignItems="center" justifyContent="space-between" w="100%">
                                            <Text
                                                fontFamily="fontFamily"
                                                fontSize="14px"
                                                fontWeight="1000"
                                                letterSpacing="-0.01em"
                                                textAlign="start"
                                                color="#11250E"
                                                h="24px"
                                            >
                                                {sensor.name}
                                            </Text>
                                            <HStack
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}
                                                borderRadius="40px"
                                                border="2px solid #E0E5E0"
                                                bg="#FFFFFF"
                                                padding="2px 4px"
                                            >
                                                <RiRadioButtonLine color={sensor.active ? "#00b300" : "#b30000"} />
                                                <Text
                                                    fontFamily="fontFamily"
                                                    fontSize="12px"
                                                    fontWeight="600"
                                                    lineHeight="14.52px"
                                                    textAlign="start"
                                                    color="#11250E"
                                                >
                                                    {sensor?.active ? "Online" : "Offline"}
                                                </Text>
                                            </HStack>
                                        </HStack>

                                        <Text fontFamily="fontFamily" fontSize="12px" letterSpacing="0.02em" textAlign="start" color="#11250E">
                                            {sensor?.roomDevice?.name}
                                        </Text>
                                    </VStack>

                                    <HStack justify="space-between" mt="24px">
                                        <VStack spacing={0} align="center">
                                            <Image
                                                src={sensor?.sensor_one?.sensor_type === HumidityHumidity ? humidity : temp}
                                                alt=""
                                                w="21.31px"
                                                h="21.46px"
                                            />
                                            <Text
                                                fontFamily="fontFamily"
                                                fontSize="20px"
                                                fontWeight="600"
                                                lineHeight="36px"
                                                textAlign="center"
                                                color="#11250E"
                                            >
                                                {formatUnitForSensorType({
                                                    value: sensor?.sensor_one?.last_value,
                                                    sensorType: sensor?.sensor_one?.sensor_type,
                                                })}
                                            </Text>
                                            <Text
                                                fontFamily="fontFamily"
                                                fontSize="12px"
                                                fontWeight="600"
                                                lineHeight="16px"
                                                textAlign="center"
                                                color="#818681"
                                            >
                                                Sensor 1
                                            </Text>
                                        </VStack>

                                        <VStack spacing={0} align="center">
                                            <Image
                                                src={sensor?.sensor_two?.sensor_type === HumidityHumidity ? humidity : temp}
                                                alt=""
                                                w="21.31px"
                                                h="21.46px"
                                            />
                                            <Text
                                                fontFamily="fontFamily"
                                                fontSize="20px"
                                                fontWeight="600"
                                                lineHeight="36px"
                                                textAlign="center"
                                                color="#11250E"
                                            >
                                                {formatUnitForSensorType({
                                                    value: sensor?.sensor_two?.last_value,
                                                    sensorType: sensor?.sensor_two?.sensor_type,
                                                })}
                                            </Text>
                                            <Text
                                                fontFamily="fontFamily"
                                                fontSize="12px"
                                                fontWeight="600"
                                                lineHeight="16px"
                                                textAlign="center"
                                                color="#818681"
                                            >
                                                Sensor 2
                                            </Text>
                                        </VStack>

                                        <VStack spacing={0} align="center">
                                            <Image src={Battery} alt="" w="24px" h="24px" />
                                            <Text
                                                fontFamily="fontFamily"
                                                fontSize="20px"
                                                fontWeight="600"
                                                lineHeight="36px"
                                                textAlign="center"
                                                color="#11250E"
                                            >
                                                {formatUnitForSensorType({
                                                    value: sensor?.battery,
                                                    sensorType: "Battery",
                                                })}
                                            </Text>
                                            <Text
                                                fontFamily="fontFamily"
                                                fontSize="12px"
                                                fontWeight="600"
                                                lineHeight="16px"
                                                textAlign="center"
                                                color="#818681"
                                            >
                                                Battery
                                            </Text>
                                        </VStack>
                                    </HStack>
                                </Box>
                            ))}
                        </VStack>
                    </Box>

                    {/* Temperature Chart Section */}
                    <Box w="75%" h="100%" overflow="hidden">
                        <VStack spacing={0} align="flex-start" h="100%">
                            <HStack spacing={0} justifyContent="space-between" width="100%" paddingBottom={2} paddingTop={6} paddingX={6}>
                                <VStack spacing={1.5} align="flex-start">
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="20px"
                                        fontWeight="1000"
                                        letterSpacing="-0.02em"
                                        textAlign="start"
                                        color="#11250E"
                                        h="24px"
                                    >
                                        {selectedDevice?.name}
                                    </Text>
                                    <Text
                                        fontFamily="fontFamily"
                                        fontWeight="700"
                                        fontSize="14px"
                                        letterSpacing="-0.02em"
                                        lineHeight="20px"
                                        textAlign="start"
                                        color="#00000091"
                                    >
                                        {selectedDevice?.roomDevice?.name}
                                    </Text>
                                </VStack>

                                <HStack border="1px solid #D7D7D7" borderRadius="8px" padding="2px">
                                    <HStack
                                        bgColor={selectedGraph === "sensor1" ? "#e9ffdb" : "transparent"}
                                        border={selectedGraph === "sensor1" ? "2px solid #6CC35E" : ""}
                                        color={selectedGraph === "sensor1" ? "#11250E" : "#818681"}
                                        borderRadius={4}
                                        paddingX="8px"
                                        _hover={{ border: "1px solid #81868180", cursor: "pointer" }}
                                        onClick={() => {
                                            setSelectedGraph("sensor1");
                                            setSelectedGraphType(
                                                selectedDevice?.sensor_one?.sensor_type === HumidityHumidity ? "humidity" : "temperature",
                                            );
                                        }}
                                        gap="6px"
                                        padding="2px 8px"
                                    >
                                        <Image
                                            src={selectedDevice?.sensor_one?.sensor_type === HumidityHumidity ? humidity : temp}
                                            alt=""
                                            w="12px"
                                            h="12px"
                                        />
                                        <Text fontFamily="fontFamily" fontSize="12px" fontWeight="500" letterSpacing="0.01em">
                                            Sensor 1
                                        </Text>
                                    </HStack>

                                    <HStack
                                        bgColor={selectedGraph === "sensor2" ? "#e9ffdb" : "transparent"}
                                        border={selectedGraph === "sensor2" ? "2px solid #6CC35E" : ""}
                                        color={selectedGraph === "sensor2" ? "#11250E" : "#818681"}
                                        borderRadius={4}
                                        paddingX="8px"
                                        _hover={{ border: "1px solid #81868180", cursor: "pointer" }}
                                        onClick={() => {
                                            setSelectedGraph("sensor2");
                                            setSelectedGraphType(
                                                selectedDevice?.sensor_two?.sensor_type === HumidityHumidity ? "humidity" : "temperature",
                                            );
                                        }}
                                        gap="6px"
                                        padding="2px 8px"
                                    >
                                        <Image
                                            src={selectedDevice?.sensor_two?.sensor_type === HumidityHumidity ? humidity : temp}
                                            alt=""
                                            w="12px"
                                            h="12px"
                                        />
                                        <Text fontFamily="fontFamily" fontSize="12px" fontWeight="500" letterSpacing="0.01em">
                                            Sensor 2
                                        </Text>
                                    </HStack>
                                </HStack>
                            </HStack>

                            <HStack
                                spacing={0}
                                width="100%"
                                marginY={2}
                                paddingY={1}
                                paddingX={6}
                                backgroundColor="#FAFAFA"
                                align="flex-start"
                                justifyContent="space-between"
                            >
                                <Text
                                    fontFamily="fontFamily"
                                    fontSize="14px"
                                    fontWeight="500"
                                    letterSpacing="0.02em"
                                    textAlign="start"
                                    color="#2E2B2B91"
                                >
                                    Last Reading at{" "}
                                    {new Date(selectedDevice?.last_fetched).toLocaleTimeString("en-US", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                    })}
                                </Text>

                                <Text
                                    fontFamily="fontFamily"
                                    fontSize="14px"
                                    fontWeight="500"
                                    letterSpacing="0.01em"
                                    textAlign="start"
                                    color="#57BA47"
                                    textDecoration="underline"
                                    cursor="pointer"
                                    onClick={() => navigate(`/dashboard/${space}/devices/${selectedDevice?.id}`)}
                                >
                                    View All
                                </Text>
                            </HStack>

                            <VStack flex="1" width="100%" paddingY={1} paddingX={6}>
                                <TemperatureChart
                                    data={logs?.data}
                                    selectedGraph={selectedGraph}
                                    selectedType={selectedGraphType}
                                    deviceData={selectedDevice}
                                />
                            </VStack>
                        </VStack>
                    </Box>
                </Flex>
            </Flex>
        </Box>
    );
};
