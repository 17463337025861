import React, { useRef, useEffect, useState } from "react";
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Image, Input, Stack, Text, Tooltip, VStack } from "@chakra-ui/react";
import CustomButton from "../components/CustomButton";
import onboarding_pair_device from "../assets/configure_sensor.svg";
import { useDispatch, useSelector } from "react-redux";
import { Add_Device4_Toggle_Stepper_Function, Add_Device5_Toggle_Stepper_Function } from "../redux/action";
import { useNavigate, useParams } from "react-router-dom";
import temp from "../../src/assets/temsvg.svg";
import disable_info from "../../src/assets/disable_info_icon.svg";
import info from "../../src/assets/info_Icon.svg";
import { Switch } from "@chakra-ui/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { SetUpDevice6 } from "./SetUpDevice6";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import humidity from "../../src/assets/drop.svg";

const HumidityHumidity = undefined;

const fetchDeviceById = async (id) => {
    const response = await axios.get(`/device`, { params: { device_id: id } });
    console.log(response.data);
    return response.data;
};

export const SetUpDevice5 = ({ deviceId }) => {
    const Add_Device5_Stepper = useSelector((store) => store.ConditionalRenderingReducer.Add_Device5_Stepper);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { space, room } = useParams();
    const [loading, setLoading] = useState(false);
    const [prevDevice, setPrevDevice] = useState({});
    const inputRef = useRef(null);
    const [inputWidth, setInputWidth] = useState("auto");

    console.log(Add_Device5_Stepper);
    const Heading = {
        color: "#11250E",
        fontFamily: "fontFamily",
        fontSize: "32px",

        fontWeight: "800",
        lineHeight: "42px",
        letterSpacing: "-0.01em",
    };
    const noteStyle = {
        color: "#818681",
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "-0.14%",
    };
    const labelField = {
        color: "rgba(17, 37, 14, 1)",
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "16px",
        fontWeight: "800",
        lineHeight: "30px",
        letterSpacing: "-0.16%",
    };
    const InputField = {
        border: "1.5px solid rgba(129, 134, 129, 1)",
        _placeholder: {
            fontFamily: "fontFamily",
            color: "rgba(129, 134, 129, 1)",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "20px",
            letterSpacing: "-0.01em",
            alignContent: "start",
        },
        _focus: {
            border: "1.5px solid rgba(129, 134, 129, 1)",
            borderColor: "none",
            boxShadow: "none",
        },
        _hover: {
            border: "1.5px solid rgba(129, 134, 129, 1)",
        },
    };
    const buttonText = {
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.16%",
    };
    const min_limit = {
        // border: "1.5px solid rgba(129, 134, 129, 1)",
        // borderRadius: "4px 4px 4px 4px",
    };

    const previous_Handle_Pair_Device = () => {
        dispatch(Add_Device4_Toggle_Stepper_Function());
    };

    const validation = useFormik({
        initialValues: {
            minLimitOne: prevDevice?.data?.sensor_one?.min_value || 0,
            maxLimitOne: prevDevice?.data?.sensor_one?.max_value || 0,
            thresholdOne: prevDevice?.data?.sensor_one?.in_range_threshold || 0,
            sensorOneEnabled: prevDevice?.data?.sensor_one_enabled || false,
            minLimitTwo: prevDevice?.data?.sensor_two?.min_value || 0,
            maxLimitTwo: prevDevice?.data?.sensor_two?.max_value || 0,
            thresholdTwo: prevDevice?.data?.sensor_two?.in_range_threshold || 0,
            sensorTwoEnabled: prevDevice?.data?.sensor_two_enabled || false,
        },
        validationSchema: Yup.object({
            minLimitOne: Yup.number().required("min limit is required"),
            minLimitTwo: Yup.number().required("min limit is required"),
            maxLimitOne: Yup.number().required("max limit is required"),
            maxLimitTwo: Yup.number().required("max limit is required"),
            thresholdOne: Yup.number().min(1).required("threshold is required"),
            thresholdTwo: Yup.number().min(1).required("threshold is required"),
        }),
        onSubmit: (values) => {
            updateDeviceMutation.mutate(values);
            dispatch(Add_Device5_Toggle_Stepper_Function());
        },
        enableReinitialize: true,
    });

    // const Getting_Started = async () => {
    //     setLoading(true);
    //     // try {
    //     //     const response = await axios.post(`/device`, {
    //     //         room: roomId,
    //     //         serial_number: serialno,
    //     //         connection_key: connectionKey,
    //     //         pairing_key: paringKey,
    //     //         name: deviceName,
    //     //     });

    //     //     if (response.status === 200) {
    //     //         // navigate("/progressbar");
    //     //         return true;
    //     //     } else {
    //     //         console.error(`Error in Device Creation: ${response?.data?.error}`);
    //     //         return false;
    //     //     }
    //     // } catch (error) {
    //     //     console.error("Error in Device Creation:", error);
    //     //     return false;
    //     // } finally {
    //     //     setLoading(false);
    //     // }
    //     dispatch(Add_Device5_Toggle_Stepper_Function());
    // };

    const skipToTheDashboardFour = () => {
        window.location.href = "/dashboard";
    };
    // useEffect(() => {
    //     if (inputRef.current) {
    //         setInputWidth(`${inputRef.current.offsetWidth}px`);
    //     }
    // }, []); // Update width when input value changes\

    useEffect(() => {
        const res = fetchDeviceById(deviceId);
        res.then((data) => setPrevDevice(data));
    }, []);

    const updateDeviceMutation = useMutation({
        mutationFn: async (values) => {
            const response = await axios.patch(
                `/device`,
                {
                    name: prevDevice?.data?.name,
                    room: prevDevice?.data?.room || room,
                    sensor_one_data: { min_value: values.minLimitOne, max_value: values.maxLimitOne, in_range_threshold: values.thresholdOne },
                    sensor_two_data: { min_value: values.minLimitTwo, max_value: values.maxLimitTwo, in_range_threshold: values.thresholdTwo },
                    sensor_one_enabled: values.sensorOneEnabled,
                    sensor_two_enabled: values.sensorTwoEnabled,
                },
                {
                    params: { device_id: prevDevice?.data?.id },
                },
            );
            return response;
        },
    });

    const handleToggle = (field) => () => {
        validation.setFieldValue(field, !validation.values[field]);
    };
    return (
        <>
            {Add_Device5_Stepper ? (
                <SetUpDevice6 />
            ) : (
                <Grid gridTemplateColumns="55% auto" mt="1rem">
                    <VStack w="100%" align="start" ml="6rem">
                        <VStack w="100%" align="start" h="54vh" gap={4}>
                            <GridItem w="100%" h="5vh">
                                <Text sx={Heading}>Configure Sensor</Text>
                            </GridItem>

                            <GridItem mb="0.5rem" w="70%">
                                <Text sx={noteStyle}>Set minimum and maximum thresholds limits and receive alerts based on your preferences</Text>
                            </GridItem>
                            <Box w="90%">
                                <Flex w="100%" justifyContent="space-between">
                                    <Flex minW="50%" gap={2} align="center">
                                        <Image
                                            src={prevDevice?.data?.sensor_one?.sensor_type === HumidityHumidity ? humidity : temp}
                                            alt=""
                                            width="20px"
                                            height="20px"
                                        />
                                        <Text
                                            color="rgba(17, 37, 14, 1)"
                                            fontFamily="fontFamily"
                                            fontSize="18px"
                                            lineHeight="30px"
                                            letterSpacing="-0.01%"
                                            fontWeight={600}
                                        >
                                            Sensor 01{" "}
                                        </Text>
                                    </Flex>
                                    <FormControl display="flex" justifyContent="end" align="center">
                                        <Switch
                                            id="sensorOneEnabled"
                                            colorScheme="teal"
                                            isChecked={validation.values.sensorOneEnabled}
                                            onChange={handleToggle("sensorOneEnabled")}
                                        />
                                    </FormControl>
                                </Flex>

                                <Grid gridTemplateColumns="repeat(3,1fr)" w="87%">
                                    <GridItem width="200px">
                                        <FormControl isDisabled={!validation.values.sensorOneEnabled}>
                                            <FormLabel mb="0.5rem" sx={labelField} isDisabled={!validation.values.sensorOneEnabled}>
                                                Set Min Limit
                                            </FormLabel>
                                            <Box display="flex" alignItems="center" sx={min_limit} width={inputWidth} position="relative">
                                                <Input
                                                    minW="17px"
                                                    border="none"
                                                    placeholder="00"
                                                    isInvalid={validation.errors.minLimitOne && validation.touched.minLimitOne}
                                                    name="minLimitOne"
                                                    value={validation.values.minLimitOne}
                                                    onChange={validation.handleChange}
                                                    onFocus={validation.handleBlur}
                                                    type="text"
                                                    sx={InputField}
                                                    isDisabled={!validation.values.sensorOneEnabled}
                                                />
                                                <Box
                                                    borderLeft="1px solid rgba(155, 151, 151, 1)"
                                                    w="22px"
                                                    h="25px"
                                                    display="flex"
                                                    alignItems="center"
                                                    zIndex={1}
                                                    position="absolute"
                                                    left="80%"
                                                    top="5px"
                                                >
                                                    <Text ml="3px">
                                                        {prevDevice?.data?.sensor_one?.sensor_type === HumidityHumidity ? "%" : "°C"}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </FormControl>
                                    </GridItem>
                                    {/* Repeat for other GridItems */}
                                    <GridItem width="200px">
                                        <FormControl isRequired isDisabled={!validation.values.sensorOneEnabled}>
                                            <FormLabel mb="0.5rem" sx={labelField} isDisabled={!validation.values.sensorOneEnabled}>
                                                Set Max Limit
                                            </FormLabel>
                                            <Box display="flex" alignItems="center" sx={min_limit} width={inputWidth} position="relative">
                                                <Input
                                                    minW="17px"
                                                    isInvalid={validation.errors.maxLimitOne && validation.touched.maxLimitOne}
                                                    border="none"
                                                    placeholder="00"
                                                    type="text"
                                                    sx={InputField}
                                                    name="maxLimitOne"
                                                    value={validation.values.maxLimitOne}
                                                    onChange={validation.handleChange}
                                                    onFocus={validation.handleBlur}
                                                    isDisabled={!validation.values.sensorOneEnabled}
                                                />
                                                <Box
                                                    borderLeft="1px solid rgba(155, 151, 151, 1)"
                                                    w="22px"
                                                    h="25px"
                                                    display="flex"
                                                    alignItems="center"
                                                    zIndex={1}
                                                    position="absolute"
                                                    left="80%"
                                                    top="5px"
                                                >
                                                    <Text ml="3px">
                                                        {prevDevice?.data?.sensor_one?.sensor_type === HumidityHumidity ? "%" : "°C"}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem width="220px">
                                        <FormControl isRequired isDisabled={!validation.values.sensorOneEnabled}>
                                            <Flex align="center">
                                                <FormLabel mb="0.5rem" sx={labelField} isDisabled={!validation.values.sensorOneEnabled}>
                                                    Out-Range Threshold
                                                </FormLabel>
                                                <Tooltip
                                                    placement="auto"
                                                    hasArrow
                                                    bg="gray.500"
                                                    label="Out-Range Threshold refers to the limit beyond which a sensor's reading is considered irregular or abnormal"
                                                >
                                                    {!validation.values.sensorOneEnabled ? (
                                                        <Image src={disable_info} alt="" mb="0.5rem" />
                                                    ) : (
                                                        <Image src={info} alt="" mb="0.5rem" />
                                                    )}
                                                </Tooltip>
                                            </Flex>

                                            <Box display="flex" alignItems="center" sx={min_limit} width={inputWidth} position="relative">
                                                <Input
                                                    minW="17px"
                                                    border="none"
                                                    placeholder="00"
                                                    sx={InputField}
                                                    type="number"
                                                    name="thresholdOne"
                                                    value={validation.values.thresholdOne}
                                                    onChange={validation.handleChange}
                                                    onFocus={validation.handleBlur}
                                                    isDisabled={!validation.values.sensorOneEnabled}
                                                    // isDisabled={isDisabled}
                                                />
                                                <Box
                                                    borderLeft="1px solid rgba(155, 151, 151, 1)"
                                                    w="22px"
                                                    h="25px"
                                                    display="flex"
                                                    alignItems="center"
                                                    zIndex={1}
                                                    position="absolute"
                                                    left="80%"
                                                    top="5px"
                                                >
                                                    <Text ml="3px">Min</Text>
                                                </Box>
                                            </Box>
                                        </FormControl>
                                    </GridItem>
                                </Grid>
                            </Box>
                            <Box w="90%">
                                <Flex w="100%" justifyContent="space-between">
                                    <Flex minW="50%" gap={2} align="center">
                                        <Image
                                            w=" 20px"
                                            h="20px"
                                            src={prevDevice?.data?.sensor_two?.sensor_type === HumidityHumidity ? humidity : temp}
                                            alt=""
                                        />
                                        <Text
                                            color="rgba(17, 37, 14, 1)"
                                            fontFamily="fontFamily"
                                            fontSize="18px"
                                            lineHeight="30px"
                                            letterSpacing="-0.01%"
                                            fontWeight={600}
                                        >
                                            Sensor 02{" "}
                                        </Text>
                                    </Flex>
                                    <FormControl display="flex" justifyContent="end" align="center">
                                        <Switch
                                            colorScheme="teal"
                                            id="sensorTwoEnabled"
                                            isChecked={validation.values.sensorTwoEnabled}
                                            onChange={handleToggle("sensorTwoEnabled")}
                                        />
                                    </FormControl>
                                </Flex>

                                <Grid gridTemplateColumns="repeat(3,1fr)" w="87%">
                                    <GridItem width="200px">
                                        <FormControl isDisabled={!validation.values.sensorTwoEnabled}>
                                            <FormLabel mb="0.5rem" sx={labelField} isDisabled={!validation.values.sensorTwoEnabled}>
                                                Set Min Limit
                                            </FormLabel>
                                            <Box display="flex" alignItems="center" sx={min_limit} width={inputWidth} position="relative">
                                                <Input
                                                    minW="17px"
                                                    border="none"
                                                    placeholder="00"
                                                    type="text"
                                                    sx={InputField}
                                                    isInvalid={validation.errors.minLimitTwo && validation.touched.minLimitTwo}
                                                    name="minLimitTwo"
                                                    value={validation.values.minLimitTwo}
                                                    onChange={validation.handleChange}
                                                    onFocus={validation.handleBlur}
                                                    isDisabled={!validation.values.sensorTwoEnabled}
                                                />
                                                <Box
                                                    borderLeft="1px solid rgba(155, 151, 151, 1)"
                                                    w="22px"
                                                    h="25px"
                                                    display="flex"
                                                    alignItems="center"
                                                    zIndex={1}
                                                    position="absolute"
                                                    left="80%"
                                                    top="5px"
                                                >
                                                    <Text ml="3px">
                                                        {prevDevice?.data?.sensor_two?.sensor_type === HumidityHumidity ? "%" : "°C"}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </FormControl>
                                    </GridItem>
                                    {/* Repeat for other GridItems */}
                                    <GridItem width="200px">
                                        <FormControl
                                            isInvalid={validation.errors.maxLimitTwo && validation.touched.maxLimitTwo}
                                            isRequired
                                            isDisabled={!validation.values.sensorTwoEnabled}
                                        >
                                            <FormLabel mb="0.5rem" sx={labelField} isDisabled={!validation.values.sensorTwoEnabled}>
                                                Set Max Limit
                                            </FormLabel>
                                            <Box display="flex" alignItems="center" sx={min_limit} width={inputWidth} position="relative">
                                                <Input
                                                    minW="17px"
                                                    border="none"
                                                    placeholder="00"
                                                    type="text"
                                                    sx={InputField}
                                                    isInvalid={validation.errors.maxLimitTwo && validation.touched.maxLimitTwo}
                                                    name="maxLimitTwo"
                                                    value={validation.values.maxLimitTwo}
                                                    onChange={validation.handleChange}
                                                    onFocus={validation.handleBlur}
                                                    isDisabled={!validation.values.sensorTwoEnabled}
                                                    // isDisabled={isDisabledsensor2}
                                                />
                                                <Box
                                                    borderLeft="1px solid rgba(155, 151, 151, 1)"
                                                    w="22px"
                                                    h="25px"
                                                    display="flex"
                                                    alignItems="center"
                                                    zIndex={1}
                                                    position="absolute"
                                                    left="80%"
                                                    top="5px"
                                                >
                                                    <Text ml="3px">
                                                        {prevDevice?.data?.sensor_two?.sensor_type === HumidityHumidity ? "%" : "°C"}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem width="220px">
                                        <FormControl isRequired isDisabled={!validation.values.sensorTwoEnabled}>
                                            <Flex align="center">
                                                <FormLabel mb="0.5rem" sx={labelField} isDisabled={!validation.values.sensorTwoEnabled}>
                                                    Out-Range Threshold
                                                </FormLabel>
                                                {/* {isDisabledsensor2 ? (
                                                    <Image src={disable_info} alt="" mb="0.5rem" />
                                                ) : (
                                                    <Image src={info} alt="" mb="0.5rem" />
                                                )} */}

                                                <Tooltip
                                                    placement="auto"
                                                    hasArrow
                                                    bg="gray.500"
                                                    label="Out-Range Threshold refers to the limit beyond which a sensor's reading is considered irregular or abnormal"
                                                >
                                                    {!validation.values.sensorTwoEnabled ? (
                                                        <Image src={disable_info} alt="" mb="0.5rem" />
                                                    ) : (
                                                        <Image src={info} alt="" mb="0.5rem" />
                                                    )}
                                                </Tooltip>
                                            </Flex>

                                            <Box display="flex" alignItems="center" sx={min_limit} width={inputWidth} position="relative">
                                                <Input
                                                    minW="17px"
                                                    border="none"
                                                    placeholder="00"
                                                    type="text"
                                                    sx={InputField}
                                                    isInvalid={validation.errors.thresholdTwo && validation.touched.thresholdTwo}
                                                    name="thresholdTwo"
                                                    value={validation.values.thresholdTwo}
                                                    onChange={validation.handleChange}
                                                    onFocus={validation.handleBlur}
                                                    isDisabled={!validation.values.sensorTwoEnabled}
                                                />
                                                <Box
                                                    borderLeft="1px solid rgba(155, 151, 151, 1)"
                                                    w="22px"
                                                    h="25px"
                                                    display="flex"
                                                    alignItems="center"
                                                    zIndex={1}
                                                    position="absolute"
                                                    left="80%"
                                                    top="5px"
                                                >
                                                    <Text ml="3px">Min</Text>
                                                </Box>
                                            </Box>
                                        </FormControl>
                                    </GridItem>
                                </Grid>
                            </Box>
                        </VStack>

                        <GridItem display="flex" justifyContent="space-between" w="70%">
                            <Box w="30%" display="flex">
                                <CustomButton
                                    bg="rgba(255, 255, 255, 1)"
                                    color="rgba(87, 186, 71, 1)"
                                    border="2px solid"
                                    borderColor="rgba(87, 186, 71, 1)"
                                    onClick={previous_Handle_Pair_Device}
                                >
                                    Back
                                </CustomButton>
                            </Box>
                            <Box display="flex" justifyContent="flex-end" alignItems="center" w="90%" gap="1rem">
                                <CustomButton
                                    color="rgba(0, 0, 0, 1)"
                                    w="25%"
                                    bg="rgba(255, 255, 255, 1)"
                                    sx={buttonText}
                                    onClick={skipToTheDashboardFour}
                                >
                                    Skip
                                </CustomButton>
                                <CustomButton
                                    color="rgba(255, 255, 255, 1)"
                                    w="27%"
                                    h="40px"
                                    bg="rgba(175, 175, 175, 1)"
                                    sx={buttonText}
                                    onClick={validation.handleSubmit}
                                    disabled={
                                        !validation.isValid ||
                                        updateDeviceMutation.isLoading ||
                                        (!validation.values.sensorOneEnabled && !validation.values.sensorTwoEnabled)
                                    }
                                >
                                    Next
                                </CustomButton>
                            </Box>
                        </GridItem>
                    </VStack>

                    <GridItem display="flex" justifyContent="center" alignItems="center">
                        <Box mb="2rem">
                            <Image src={onboarding_pair_device} alt="deviceAdd" />
                        </Box>
                    </GridItem>
                </Grid>
            )}
        </>
    );
};
