import { Box, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerOverlay, Flex, Grid, Image, Text } from "@chakra-ui/react";
import React from "react";
import CustomButton from "./CustomButton";
import addGif from "../../src/assets/addGifDevice.gif";
import phase2_add_device from "../../src/assets/phase2_add_device.svg";
import { useQueryParams } from "use-query-params";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { DEVICE_QUERY_PARAMS } from "../lib/queriesParams";
import { SEARCH_PARAMS } from "../constants";

const Step2ManageDevice = () => {
    const { space } = useParams();
    const [query, setQuery] = useQueryParams(DEVICE_QUERY_PARAMS);
    const isOpen = query[SEARCH_PARAMS.DEVICE.MANAGE_STATUS.key] === "success";

    const clearQuery = () => {
        setQuery({
            [SEARCH_PARAMS.DEVICE.MANAGE.key]: SEARCH_PARAMS.DEVICE.MANAGE.default_value,
            [SEARCH_PARAMS.DEVICE.MANAGE_STATUS.key]: SEARCH_PARAMS.DEVICE.MANAGE_STATUS.default_value,
        });
    };

    const { data: rooms = { data: [] } } = useQuery({
        queryKey: ["fetchRooms", space],
        enabled: false,
    });

    const { data: device } = useQuery({
        queryKey: ["fetchDevices", space],
        enabled: false,
        select: function (data) {
            const device = data?.data?.find((item) => item?.id === query?.[SEARCH_PARAMS.DEVICE.MANAGE.key]);
            return device;
        },
    });

    return (
        <Drawer placement="right" isOpen={isOpen} onClose={clearQuery}>
            <DrawerOverlay />
            <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                <DrawerBody p={0} m={0} align="center" position="relative">
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="spa"
                        borderRadius="10px"
                        minWidth="310px"
                        flexGrow={1}
                        gap={30}
                    >
                        <Box w="100%" pl={7} ml="20px" mr="20px" mt="32px" alignItems="start" justifyContent="start">
                            <Text
                                fontFamily="fontFamily"
                                fontSize="30px"
                                fontWeight="700"
                                lineHeight="40px"
                                letterSpacing=" -0.01em"
                                align="start"
                                color="#11250E"
                            >
                                Congratulations!
                            </Text>
                        </Box>
                        <Grid templateColumns={{ base: "1fr", md: "1fr " }} gap={4} alignItems="center" justifyItems="center">
                            <Image src={addGif} alt="Placeholder Image" h="300px" />
                        </Grid>

                        <Box w="100%" pl={7} pb="2rem" display="flex" flexDirection="column" align="start" mt="2.5rem">
                            <Box>
                                <Image src={phase2_add_device} alt="phase2_add_device" w="27px" h="30px" mb="1rem" />
                            </Box>
                            <Flex align="start">
                                <Text
                                    fontFamily="fontFamily"
                                    fontSize="32px"
                                    fontWeight="700"
                                    lineHeight="40px"
                                    letterSpacing=" -0.01em"
                                    align="start"
                                    color="#11250E"
                                    marginBottom="8px"
                                >
                                    Managed Alerts{" "}
                                </Text>
                            </Flex>
                            <Flex alignItems="start">
                                <Text
                                    fontFamily="fontFamily"
                                    fontSize="14px"
                                    fontWeight="500"
                                    lineHeight="20px"
                                    letterSpacing="-0.01em"
                                    align="start"
                                    color="rgba(129, 134, 129, 1)"
                                    as="span"
                                >
                                    Your device alerts are now set up.{" "}
                                </Text>
                            </Flex>
                        </Box>
                    </Box>
                </DrawerBody>

                <DrawerFooter>
                    <CustomButton
                        width="100%"
                        h="40px"
                        bg="#57BA47"
                        sx={{
                            _hover: { bg: "#57BA47" },
                            fontFamily: "fontFamily",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#FFFFFF",
                        }}
                        marginInline="auto"
                        onClick={clearQuery}
                    >
                        Done
                    </CustomButton>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};

export default Step2ManageDevice;
