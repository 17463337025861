import React, { useEffect, useState } from "react";
import randomStroke from "../../assets/Vector 200 (Stroke).png";
import basicCheck from "../../assets/BasicListCheck.png";
import CustomButton from "../CustomButton";
import crown from "../../assets/Sub_yellow_crown.svg";
import premiumCheck from "../../assets/PremiumListCheck.png";
import { Button, Flex, Grid, GridItem, HStack, Modal, ModalBody, ModalContent, ModalOverlay, Text, Box, Image } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../checkout";
import { TiTick } from "react-icons/ti";
import axios from "axios";

const REACT_APP_STRIPE_CLIENT_ID = process.env.REACT_APP_STRIPE_CLIENT_ID;

// Fetching plans from the server
const fetchPlans = async () => {
    const res = await axios.get(`/plan`);
    return res.data;
};

const fetchLastSubscriptions = async () => {
    const res = await axios.get(`/subscription/user`);
    return res.data;
};

const Subscription = () => {
    const { data, ...dataQuery } = useQuery({
        queryKey: "fetchPlans",
        queryFn: fetchPlans,
    });

    const { data: lastSubscription, ...lastSubscriptionQuery } = useQuery({
        queryKey: "fetchLastSubscriptions",
        queryFn: fetchLastSubscriptions,
    });

    const currentSubscription = data?.data?.find((item) => item?.id === lastSubscription?.data?.plan)?.id;
    const monthlyPlan = data?.data?.filter((item) => item?.interval?.toLowerCase().includes("month"));
    const yearlyPlan = data?.data?.filter((item) => item?.interval?.toLowerCase().includes("year"));

    const [activeTab, setActiveTab] = useState("tab1");
    const [clientSecret, setClientSecret] = useState("");
    const [stripePromise, setStripePromise] = useState(null);
    const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
    const [paymentDone, setPaymentDone] = useState(false);

    // Load Stripe instance once
    useEffect(() => {
        setStripePromise(loadStripe(REACT_APP_STRIPE_CLIENT_ID));
    }, []);

    useEffect(() => {
        if (paymentDone) {
            setIsCheckoutModalOpen(false);
        }
    }, [paymentDone]);

    const handleSubscriptionDone = async (id) => {
        try {
            const response = await axios.post(`/subscription`, { plan_id: id });

            if (response.status === 200) {
                setClientSecret(response?.data?.data?.clientSecret);
                setIsCheckoutModalOpen(true);
            } else {
                console.error(`Error in subscription: ${response?.data?.error}`);
            }
        } catch (error) {
            console.error("Subscription error:", error);
        }
    };

    const renderPlans = (plans) => {
        return (
            plans &&
            plans.map((plan) => (
                <GridItem
                    key={plan.id}
                    w="100%"
                    h="440px"
                    borderRadius="16px"
                    padding="32px"
                    bg={
                        plan?.title?.toLowerCase().includes("premium")
                            ? "linear-gradient(325.55deg, #57BA47 -40.69%, #11250E 116.9%)"
                            : "rgba(255, 255, 255, 1)"
                    }
                    boxShadow="0px 4px 25px 0px rgba(0, 0, 0, 0.1)"
                >
                    <Box display="flex" flexDirection="column" gap="0.3rem">
                        <Box display="flex" alignItems="center" gap="0.5rem">
                            {plan?.title?.toLowerCase().includes("premium") && <Image src={crown} alt="crown" w="32px" h="32px" />}
                            <Text
                                color={plan?.title?.toLowerCase().includes("premium") ? "rgba(255, 255, 255, 1)" : "#11250E"}
                                fontSize="24px"
                                fontWeight="700"
                                lineHeight="32px"
                                textAlign="left"
                            >
                                {plan?.title}
                            </Text>
                        </Box>
                        <Text
                            color={plan?.title?.toLowerCase().includes("premium") ? "rgba(255, 255, 255, 0.6)" : "#818681"}
                            fontSize="14px"
                            fontWeight="500"
                            lineHeight="20px"
                            textAlign="left"
                        >
                            {plan?.description}
                        </Text>
                    </Box>

                    <Box>
                        <Text
                            color={plan?.title?.toLowerCase().includes("premium") ? "rgba(255, 255, 255, 1)" : "#11250E"}
                            fontSize="48px"
                            fontWeight="700"
                            lineHeight="72px"
                        >
                            ${plan?.amount}
                            <Text as="span" color="#818681" fontSize="24px" fontWeight="800" lineHeight="72px">
                                {activeTab === "tab1" ? "/mo" : "/yr"}
                            </Text>
                        </Text>
                        <CustomButton
                            border="1px solid"
                            borderColor={plan?.title?.toLowerCase().includes("premium") ? "rgba(255, 255, 255, 1)" : "rgba(129, 134, 129, 1)"}
                            w="100%"
                            boxShadow="rgba(0, 0, 0, 0.1)"
                            bg="#FFFFFF"
                            onClick={() => handleSubscriptionDone(plan?.id)}
                        >
                            {plan?.id === currentSubscription ? <>Current Plan {<TiTick color="#57BA47" size={24} />}</> : "Subscribe"}{" "}
                        </CustomButton>
                    </Box>

                    <Box mt="1rem" display="flex" flexDirection="column" gap="0.5rem">
                        <Text
                            color={plan?.title?.toLowerCase().includes("premium") ? "rgba(255, 255, 255, 1)" : "#11250E"}
                            fontFamily="fontFamily"
                            fontSize="16px"
                            fontWeight="500"
                            lineHeight="24px"
                            letterSpacing="-0.01em"
                        >
                            Features:
                        </Text>
                        {plan?.feature &&
                            plan?.feature?.map((feature, index) => (
                                <Box display="flex" alignItems="center" gap="0.5rem" key={index}>
                                    <Image
                                        src={plan?.title?.toLowerCase().includes("premium") ? premiumCheck : basicCheck}
                                        alt="check"
                                        w="16px"
                                        h="16px"
                                    />
                                    <Text
                                        color={plan?.title?.toLowerCase().includes("premium") ? "rgba(255, 255, 255, 0.6)" : "#818681"}
                                        fontSize="14px"
                                        fontWeight="500"
                                        lineHeight="20px"
                                        letterSpacing="-0.01em"
                                    >
                                        {feature}
                                    </Text>
                                </Box>
                            ))}
                    </Box>
                </GridItem>
            ))
        );
    };

    return (
        <Box
            minH={{
                base: "100vh",
                sm: "110vh",
                md: "110vh",
                lg: "110vh",
                xl: "110vh",
            }}
            w={{ base: "", sm: "100%", md: "100%", lg: "100%", xl: "100%" }}
        >
            <Box bg="#F8FBEE" h="450px" pt="72px" pl={{ base: "", sm: "", md: "", lg: "100px", xl: "100px" }}>
                <Text fontWeight="800" fontSize="40px" w="526px" fontFamily="fontFamily" letterSpacing="-0.01em">
                    Your perfect plan? We’ve
                </Text>
                <HStack>
                    <Text
                        bgGradient="linear(95.39deg, #FFA500 0.98%, #408B35 38.61%)"
                        bgClip="text"
                        fontSize="40px"
                        fontWeight="800"
                        lineHeight="60px"
                        fontFamily="fontFamily"
                        letterSpacing="-0.02em"
                    >
                        already
                    </Text>
                    <Text fontWeight="800" fontSize="40px" w="526px" fontFamily="fontFamily" letterSpacing="-0.01em">
                        got it lined up!
                    </Text>
                </HStack>
                <Image src={randomStroke} alt="random_stroke" />

                {/* Tab Buttons */}
                <Flex bg="white" w="190px" p="4px" maxWidth="fit-content" borderRadius="4px" mt="72px" boxShadow="0px 4px 30px 0px #0000001A">
                    <HStack spacing="7px">
                        <Button
                            onClick={() => setActiveTab("tab1")}
                            bg={activeTab === "tab1" ? "#57BA47" : "white"}
                            w={activeTab === "tab1" ? "95px" : "80px"}
                            fontFamily="fontFamily"
                            color={activeTab === "tab1" ? "rgba(255, 255, 255, 1)" : "rgba(17, 37, 14, 1)"}
                            fontWeight="700"
                            fontSize="16px"
                            lineHeight="24px"
                            h="32px"
                        >
                            Monthly
                        </Button>
                        <Button
                            onClick={() => setActiveTab("tab2")}
                            bg={activeTab === "tab2" ? "#57BA47" : "white"}
                            w={activeTab === "tab2" ? "95px" : "80px"}
                            h="32px"
                            fontFamily="fontFamily"
                            color={activeTab === "tab2" ? "rgba(255, 255, 255, 1)" : "rgba(17, 37, 14, 1)"}
                            fontWeight="700"
                            fontSize="16px"
                            lineHeight="24px"
                        >
                            Yearly
                        </Button>
                    </HStack>
                </Flex>
            </Box>

            {/* Subscription Cards */}
            <Box
                position="absolute"
                top={{ base: "350px", lg: "350px" }}
                w={{ base: "70%", sm: "70%", md: "50%", lg: "50%", xl: "50%" }}
                h={{ base: "85vh", sm: "70vh", md: "70vh", lg: "70vh" }}
                zIndex="2"
                pl={{ base: "", sm: "", md: "", lg: "100px", xl: "100px" }}
            >
                <Grid templateColumns="repeat(2, 1fr)" templateRows="repeat(1, 1fr)" gap={5} width="100%">
                    {activeTab === "tab1" ? renderPlans(monthlyPlan) : renderPlans(yearlyPlan)}
                </Grid>
            </Box>

            <Modal isOpen={isCheckoutModalOpen} onClose={() => setIsCheckoutModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        {clientSecret && stripePromise && (
                            <Elements stripe={stripePromise} options={{ clientSecret }}>
                                <CheckoutForm clientSecret={clientSecret} setPaymentDone={setPaymentDone} />
                            </Elements>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Subscription;
