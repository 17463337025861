import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormLabel,
    Input,
    Text,
    Textarea,
    FormControl,
    FormErrorMessage,
    Image,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomButton from "./CustomButton";
import plusIcon from "../assets/Plus_Icon.png";
import Step2AddSpace from "./step2AddSpace";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

// Input and label styles
const inputStyle = {
    borderRadius: "4px",
    border: "1px solid #81868180",
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    color: "#000000",
    _focus: { border: "1.5px solid #57BA47", boxShadow: "none" },
    "::placeholder": { color: "#818681", opacity: 0.4 },
};

const labelStyle = {
    fontFamily: "fontFamily",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "-0.01em",
    marginBottom: "4px",
    color: "#11250E",
};

const validationSchema = Yup.object({
    name: Yup.string().required("Space name is required."),
    country: Yup.string().required("Country is required."),
    state: Yup.string().required("State is required."),
    city: Yup.string().required("City is required."),
    address: Yup.string().required("Address is required."),
});

const AddSpace = ({ spaceIsOpen, spaceOnClose, step2spaceIsOpen, step2spaceOnClose, step2spaceOnOpen }) => {
    const [space, setSpace] = useState(null);

    const formik = useFormik({
        initialValues: {
            name: "",
            country: "",
            state: "",
            city: "",
            address: "",
        },
        validationSchema,
        onSubmit: (values) => {
            addSpaceMutation.mutate(values);
        },
    });

    const { data: company, ...userQuery } = useQuery({
        queryKey: "fetchProfile",
        enabled: false,
        select: function (data) {
            return data?.data?.company;
        },
    });

    // Mutation for adding space
    const addSpaceMutation = useMutation({
        mutationFn: async (values) => {
            const response = await axios.post(`/space`, {
                name: values.name,
                company_id: company?.id,
                location: { country: values.country, state: values.state, city: values.city, address: values.address },
            });
            return response;
        },
        onSuccess: (response) => {
            formik.resetForm();
            spaceOnClose();
            step2spaceOnOpen(response?.data?.data);
            setSpace(response?.data?.data);
        },
        onError: (error) => {
            formik.setFieldError("general", error.response?.data?.error || "Add Space failed. Please try again.");
        },
    });

    return (
        <>
            <Drawer isOpen={spaceIsOpen} placement="right" onClose={spaceOnClose}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <Box mt={{ base: "0px", sm: "0px", md: "5px", lg: "10px" }} mr="10px">
                        <DrawerCloseButton sx={{ color: "#11250E", p: "8px", bg: "#F6F6F6" }} />
                        <DrawerHeader
                            fontFamily="fontFamily"
                            fontSize="28px"
                            fontWeight="800"
                            lineHeight="42px"
                            letterSpacing="-0.04em"
                            textAlign="left"
                            color="#11250E"
                        >
                            Add Space
                        </DrawerHeader>
                    </Box>

                    <DrawerBody>
                        <form onSubmit={formik.handleSubmit}>
                            <Box mb={4}>
                                <Flex gap={2}>
                                    <Box flex="1">
                                        <FormControl isInvalid={formik.errors.name && formik.touched.name} isRequired>
                                            <FormLabel htmlFor="name" sx={labelStyle}>
                                                Space Name
                                            </FormLabel>
                                            <Input
                                                id="name"
                                                placeholder="ex: Space 01"
                                                type="text"
                                                sx={inputStyle}
                                                {...formik.getFieldProps("name")}
                                            />
                                            <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                                        </FormControl>
                                    </Box>
                                    <Box flex="1">
                                        <FormControl isInvalid={formik.errors.city && formik.touched.city} isRequired>
                                            <FormLabel htmlFor="city" sx={labelStyle}>
                                                City
                                            </FormLabel>
                                            <Input id="city" placeholder="ex: San Francisco" sx={inputStyle} {...formik.getFieldProps("city")} />
                                            <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
                                        </FormControl>
                                    </Box>
                                </Flex>
                            </Box>

                            <Box mb={4}>
                                <Flex gap={2}>
                                    <Box flex="1">
                                        <FormControl isInvalid={formik.errors.country && formik.touched.country} isRequired>
                                            <FormLabel htmlFor="country" sx={labelStyle}>
                                                Country
                                            </FormLabel>
                                            <Input id="country" placeholder="ex: USA" sx={inputStyle} {...formik.getFieldProps("country")} />
                                            <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
                                        </FormControl>
                                    </Box>

                                    <Box flex="1">
                                        <FormControl isInvalid={formik.errors.state && formik.touched.state} isRequired>
                                            <FormLabel htmlFor="state" sx={labelStyle}>
                                                State
                                            </FormLabel>
                                            <Input id="state" placeholder="ex: California" sx={inputStyle} {...formik.getFieldProps("state")} />
                                            <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
                                        </FormControl>
                                    </Box>
                                </Flex>
                            </Box>

                            <Box mb={4}>
                                <FormControl isInvalid={formik.errors.address && formik.touched.address} isRequired>
                                    <FormLabel htmlFor="address" sx={labelStyle}>
                                        Address
                                    </FormLabel>
                                    <Textarea id="address" placeholder="ex: 123 Market Street" sx={inputStyle} {...formik.getFieldProps("address")} />
                                    <FormErrorMessage>{formik.errors.address}</FormErrorMessage>
                                </FormControl>
                            </Box>
                        </form>
                    </DrawerBody>

                    <DrawerFooter>
                        <Flex direction="column" justifyContent="center" gap={2} width="100%">
                            <CustomButton
                                width="100%"
                                h="40px"
                                bg="#57BA47"
                                sx={{
                                    _hover: { bg: "#57BA47" },
                                    fontFamily: "fontFamily",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                }}
                                marginInline="auto"
                                onClick={formik.handleSubmit}
                                isLoading={addSpaceMutation.isLoading}
                            >
                                <Image src={plusIcon} alt="Add Space" h="16px" w="16px" mr="3px" />
                                Add Space
                            </CustomButton>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <Step2AddSpace step2spaceIsOpen={step2spaceIsOpen} step2spaceOnClose={step2spaceOnClose} space={space} />
        </>
    );
};

export default AddSpace;
