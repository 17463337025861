import React, { useEffect, useState } from "react";
import { Avatar, Box, Grid, HStack, Input, Link, Spinner, Text, useDisclosure, VStack } from "@chakra-ui/react";
import userimg from "../../assets/Ellipse.png";
import EditPassword from "./EditPassword";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PiCamera } from "react-icons/pi";

const fetchUserProfile = async () => {
    const res = await axios.get(`/auth/user`);
    return res?.data?.data;
};

export const AdminSettings = () => {
    const queryClient = useQueryClient();

    const { data: userProfile, ...userProfileQuery } = useQuery({
        queryKey: ["fetchUserProfile"],
        queryFn: () => fetchUserProfile(),
    });

    const { mutateAsync, isLoading: isMutating } = useMutation({
        mutationFn: async (file) => {
            const formData = new FormData();
            formData.append("image", file);
            formData.append("user_id", userProfile?.user?.id);

            const response = await axios.patch("/member", formData, {
                params: { member_id: userProfile?.user?.id },
            });

            return response;
        },
        onSuccess: (data) => {
            console.log("Avatar updated successfully:", data);
            queryClient.refetchQueries({
                queryKey: ["fetchUserProfile"],
                exact: true
            });
        },
        onError: (error) => {
            console.error("Error updating avatar:", error);
            alert("Failed to update avatar. Please try again.");
        },
    });

    const [avatar, setAvatar] = useState(userProfile?.user?.picture);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setAvatar(userProfile?.user?.picture);
    }, [avatar, userProfile]);

    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                setIsLoading(true);

                const imageUrl = URL.createObjectURL(file);
                await mutateAsync(file);

                setAvatar(imageUrl);
            } catch (error) {
                console.error("Error during avatar upload:", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const { isOpen: editIsOpen, onOpen: editonOpen, onClose: editonClose } = useDisclosure();

    const handleEditPassword = () => {
        editonOpen();
    };
    return (
        <>
            <HStack
                w="100%"
                h="110px"
                bg="#F8FBEE"
                justifyContent="space-between"
                position="fixed"
                top="0px"
                bottom="0px"
                zIndex="1000"
                boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
            >
                <Text
                    ml="3rem"
                    fontFamily="fontFamily"
                    fontSize="28px"
                    fontWeight="700"
                    lineHeight="42px"
                    letterSpacing="-0.01em"
                    textAlign="left"
                    color="rgba(17, 37, 14, 1)"
                >
                    Settings
                </Text>
            </HStack>

            <Box display="flex" flexDirection="column" alignItems="center" minW="100%" mt="7rem" minH="100vh">
                <Grid gridTemplateColumns="repeat(1,1fr)" w="100%">
                    <VStack w="100%" display="flex" flexDirection="column" mt="3rem" mb="3rem">
                        <Box position="relative" w="120px" h="120px" role="group">
                            <Avatar size="xl" name="John Doe" w="120px" h="120px" src={avatar ?? userimg} opacity={isLoading ? 0.5 : 1} />
                            {(isLoading || isMutating) && (
                                <Box
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    w="100%"
                                    h="100%"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    bg="rgba(0, 0, 0, 0.6)"
                                    borderRadius="full"
                                >
                                    <Spinner color="white" size="lg" />
                                </Box>
                            )}
                            {!isLoading && (
                                <Box
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    w="100%"
                                    h="100%"
                                    bg="rgba(0, 0, 0, 0.5)"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    opacity="0.7"
                                    transition="opacity 0.2s"
                                    _groupHover={{ opacity: 1 }}
                                    borderRadius="full"
                                    cursor="pointer"
                                    onClick={() => document.getElementById("avatarInput").click()}
                                >
                                    <PiCamera color="white" size={36} />
                                </Box>
                            )}
                            <Input id="avatarInput" type="file" accept="image/*" onChange={handleImageChange} style={{ display: "none" }} />
                        </Box>
                        <Box gap="5px" spacing="0.5rem" align="center">
                            <Text color="#11250E" fontWeight="600" lineHeight="24px" letterSpacing="-0.01em" fontSize="20px" fontFamily=" fontFamily">
                                {userProfile?.user?.name}
                            </Text>
                            <Text color="#818681" fontWeight="500" lineHeight="20px" letterSpacing="-0.01em" fontSize="14px" fontFamily=" fontFamily">
                                Admin
                            </Text>
                        </Box>
                    </VStack>
                </Grid>

                <Box
                    position="relative"
                    top="-2%"
                    w={{ base: "90%", sm: "90%", md: "90%", lg: "90%", xl: "90%", "2xl": "90%" }}
                    borderRadius="16px 16px 16px 16px"
                    boxShadow="0px 4px 25px 0px #0000000D"
                    background="#FFFFFF"
                    mt="2rem"
                    pb="2rem"
                    mb="2rem"
                    pt="1.5rem"
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        borderBottom="1px solid #E0E5E0"
                        w={{ base: "92%", sm: "92%", md: "92%", lg: "92%", xl: "93%", "2xl": "96%" }}
                        ml="2rem"
                    >
                        <Text
                            color="#11250E"
                            fontWeight="600"
                            lineHeight="36px"
                            letterSpacing="-0.01em"
                            fontSize="24px"
                            fontFamily=" fontFamily"
                            mt="16px"
                            mr="40px"
                            align="left"
                        >
                            Login Details
                        </Text>
                        <Link
                            isExternal
                            textDecoration="underline"
                            fontFamily=" fontFamily"
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="20px"
                            color="#57BA47"
                            onClick={handleEditPassword}
                        >
                            Edit
                        </Link>
                    </Box>

                    <VStack spacing={4} mt="40px" align="start" ml="40px">
                        <Box display="flex" align="start" gap="3rem">
                            <Text
                                w="70%"
                                color="rgba(129, 134, 129, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontFamily=" fontFamily"
                            >
                                Email Address
                            </Text>
                            <Text
                                color="rgba(17, 37, 14, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontFamily=" fontFamily"
                            >
                                {userProfile?.user?.email}
                            </Text>
                        </Box>

                        <Box display="flex" align="start" gap="5rem">
                            <Text
                                w="50%"
                                color="rgba(129, 134, 129, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontFamily=" fontFamily"
                            >
                                Password
                            </Text>
                            <Text
                                color="rgba(17, 37, 14, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontFamily=" fontFamily"
                            >
                                ************
                            </Text>
                        </Box>
                    </VStack>
                </Box>
            </Box>
            <EditPassword editIsOpen={editIsOpen} editonOpen={editonOpen} editonClose={editonClose} />
        </>
    );
};
