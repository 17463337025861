import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    FormLabel,
    Image,
    Input,
    Text,
    Flex,
    Select,
    FormControl,
    FormErrorMessage,
} from "@chakra-ui/react";
import React from "react";
import CustomButton from "./CustomButton";
import plusIcon from "../assets/Plus_Icon.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import Step2EditMember from "./Step2EditMember";
import member from "../assets/editMember.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useQueryParams } from "use-query-params";
import { MEMBER_QUERY_PARAMS } from "../lib/queriesParams";
import { SEARCH_PARAMS } from "../constants";

const inputStyle = {
    borderRadius: "4px",
    border: "1px solid #81868180",
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    color: "#000000",
    _focus: {
        border: "1.5px solid #57BA47",
        boxShadow: "none",
    },
    "::placeholder": {
        color: "#818681",
        opacity: 0.4,
    },
};

const labelStyle = {
    fontFamily: "fontFamily",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "-0.01em",
    marginBottom: "4px",
    color: "#11250E",
};

const ROLES = ["MANAGER", "OWNER", "EMPLOYEE", "FARMER", "CARETAKER", "CHEF"];

const EditMember = () => {
    const queryClient = useQueryClient();
    const { space } = useParams();
    const [query, setQuery] = useQueryParams(MEMBER_QUERY_PARAMS);
    const isOpen = query[SEARCH_PARAMS.MEMBER.EDIT.key] !== "";

    const clearQuery = () => {
        setQuery({
            [SEARCH_PARAMS.MEMBER.EDIT.key]: SEARCH_PARAMS.MEMBER.EDIT.default_value,
            [SEARCH_PARAMS.MEMBER.EDIT_STATUS.key]: SEARCH_PARAMS.MEMBER.EDIT_STATUS.default_value,
        });
    };

    const { data: prevMember, membersQuery } = useQuery({
        queryKey: ["fetchMembers", space],
        enabled: false,
        select: function (data) {
            const prevMember = data?.data?.find((item) => item?.id === query?.[SEARCH_PARAMS.MEMBER.EDIT.key]);
            return prevMember;
        },
    });

    const validation = useFormik({
        initialValues: {
            name: prevMember?.name,
            email: prevMember?.email,
            designation: prevMember?.role,
        },
        validationSchema: Yup.object({
            name: Yup.string().min(3, "Name is too short.").required("Name is required."),
            email: Yup.string().email("Enter a valid email.").required("Email is required."),
            designation: Yup.string().oneOf(ROLES, "Invalid designation.").required("Designation is required."),
        }),
        onSubmit: function (values) {
            updateMemberMutation.mutate(values);
        },
        enableReinitialize: true,
    });

    const updateMemberMutation = useMutation({
        mutationFn: async function ({ name, email, designation }) {
            const response = await axios.patch(
                `/member`,
                { name, email, role: designation, user_id: prevMember.id },
                {
                    params: { member_id: prevMember.id },
                },
            );
            return response;
        },
        onSuccess: function (response) {
            validation.resetForm();
            queryClient.refetchQueries(["fetchMembers", space]);
            setQuery({ [SEARCH_PARAMS.MEMBER.EDIT_STATUS.key]: "success" });
        },
        onError: function (error) {
            if (error?.response?.data) {
                console.log(error?.response);
            }
        },
    });

    return (
        <>
            <Drawer placement="right" isOpen={isOpen} onClose={clearQuery}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <Box mt={{ base: "0px", sm: "0px", md: "5px", lg: "10px" }} mr="10px">
                        <DrawerCloseButton sx={{ color: "#11250E", p: "8px", bg: "#F6F6F6" }} />
                        <DrawerHeader
                            fontFamily="fontFamily"
                            fontSize="28px"
                            fontWeight="800"
                            lineHeight="42px"
                            letterSpacing="-0.04em"
                            textAlign="left"
                            color="#11250E"
                        >
                            Edit Details
                        </DrawerHeader>
                    </Box>

                    <DrawerBody>
                        <Box display="flex" justifyContent="center" marginBottom={4}>
                            <Image src={member} alt="Image" w="180px" h="200px" />
                        </Box>

                        <Box mb={8}>
                            <FormControl isInvalid={validation.errors.name && validation.touched.name} isRequired>
                                <FormLabel htmlFor="name" sx={labelStyle}>
                                    Enter Full Name
                                </FormLabel>
                                <Input
                                    id="name"
                                    placeholder="e.g., Jon"
                                    type="text"
                                    sx={inputStyle}
                                    name="name"
                                    value={validation.values.name}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.name}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb={8}>
                            <FormControl isInvalid={validation.errors.email && validation.touched.email} isRequired>
                                <FormLabel htmlFor="email" sx={labelStyle}>
                                    Enter Email Address
                                </FormLabel>
                                <Input
                                    id="email"
                                    placeholder="e.g., Johnxyz@gmail.com"
                                    type="text"
                                    sx={inputStyle}
                                    name="email"
                                    value={validation.values.email}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                                <FormErrorMessage>{validation.errors.email}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb={8}>
                            <FormControl isInvalid={validation.errors.designation && validation.touched.designation} isRequired>
                                <FormLabel htmlFor="desiganation" sx={labelStyle}>
                                    Enter Designation
                                </FormLabel>
                                <Select
                                    id="designation"
                                    name="designation"
                                    value={validation.values.designation}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    sx={inputStyle}
                                >
                                    <option value="" disabled>
                                        Select designation
                                    </option>
                                    <option value="MANAGER">Manager</option>
                                    <option value="OWNER">Owner</option>
                                    <option value="EMPLOYEE">Employee</option>
                                    <option value="FARMER">Farmer</option>
                                    <option value="CARETAKER">Caretaker</option>
                                    <option value="CHEF">Chef</option>
                                </Select>
                                <FormErrorMessage>{validation.errors.designation}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Text fontFamily="fontFamily" fontWeight="400" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#818681">
                            NOTE: Fields marked with * are compulsory.
                        </Text>
                    </DrawerBody>

                    <DrawerFooter>
                        <Flex direction="column" justifyContent="center" gap={2} width="100%">
                            <CustomButton
                                width="100%"
                                h="40px"
                                bg="#57BA47"
                                sx={{
                                    _hover: { bg: "#57BA47" },
                                    fontFamily: "fontFamily",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                }}
                                marginInline="auto"
                                onClick={validation.handleSubmit}
                                disabled={!validation.isValid || updateMemberMutation.isPending}
                            >
                                <Image src={plusIcon} alt="Edit Details" h="16px" w="16px" mr="3px" />
                                Edit Details
                            </CustomButton>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <Step2EditMember />
        </>
    );
};

export default EditMember;
