import { Flex, HStack, Image, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import CustomButton from "../../components/CustomButton";
import plusIcon from "../../assets/Plus_Icon.png";
import AddClient from "../component/AddClient";

export const TopSection = () => {
    const { isOpen: deviceIsOpen, onOpen: deviceOnOpen, onClose: deviceOnClose } = useDisclosure();

    const ButtonTextStyle = {
        _hover: {
            bg: "#57BA47",
        },
        fontFamily: "fontFamily",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
        letterSpacing: "-0.01em",
        color: "#FFFFFF",
    };

    const handleAddClient = () => {
        deviceOnOpen();
    };

    return (
        <HStack w="80%" h="110px" bg="#F8FBEE" justifyContent="space-between" position="fixed" top="0" zIndex="1000" paddingX="3.5rem">
            <Text fontFamily="fontFamily" fontSize="28px" fontWeight="800" lineHeight="42px" letterSpacing="-0.04em" textAlign="left" color="#11250E">
                All Clients
            </Text>
            <Flex justifySelf="flex-end">
                <CustomButton w="185px" h="40px" bg="#57BA47" sx={ButtonTextStyle} onClick={handleAddClient}>
                    <Image src={plusIcon} alt="sortIcon" border="1.2px" h="16px" w="16px" color="#FFFFFF" mr="5px" /> Add Client
                </CustomButton>
            </Flex>
            {deviceIsOpen && <AddClient deviceIsOpen={deviceIsOpen} deviceOnClose={deviceOnClose} />}
        </HStack>
    );
};
