import React, { useState } from "react";
import { Box, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Image, Input, Text, VStack } from "@chakra-ui/react";

import CustomButton from "../components/CustomButton";
import onboarding_pair_device from "../assets/onboarding_pair_device.svg";
import { useDispatch } from "react-redux";
import { Add_Device4_Toggle_Stepper_Function } from "../redux/action";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
export const SetUpDevice5 = ({ serialno, deviceName, connectionKey, roomId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const Heading = {
        color: "#11250E",
        fontFamily: "fontFamily",
        fontSize: "32px",

        fontWeight: "800",
        lineHeight: "42px",
        letterSpacing: "-0.01em",
    };
    const noteStyle = {
        color: "#818681",
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "-0.14%",
    };
    const labelField = {
        color: "rgba(17, 37, 14, 1)",
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "16px",
        fontWeight: "800",
        lineHeight: "30px",
        letterSpacing: "-0.16%",
    };
    const InputField = {
        border: "1px solid",
        borderColor: "rgba(129, 134, 129, 1)",
        borderRadius: "4px 4px 4px 4px",
        _placeholder: {
            fontFamily: "fontFamily",
            color: "rgba(129, 134, 129, 1)",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "20px",
            letterSpacing: "-0.01em",
            alignContent: "start",
        },
        _focus: {
            border: "1.5px solid",
            borderColor: "rgba(87, 186, 71, 1)",
            boxShadow: "none",
        },
        _hover: {
            border: "1.5px solid",
            borderColor: "rgba(87, 186, 71, 1)",
            boxShadow: "none",
        },
    };
    const buttonText = {
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.16%",
    };

    const previous_Handle_Pair_Device = () => {
        dispatch(Add_Device4_Toggle_Stepper_Function());
    };

    const Getting_Started = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`/device`, {
                room: roomId,
                serial_number: serialno,
                connection_key: connectionKey,
                pairing_key: paringKey,
                name: deviceName,
            });

            if (response.status === 200) {
                navigate("/progressbar");
                return true;
            } else {
                console.error(`Error in Device Creation: ${response?.data?.error}`);
                return false;
            }
        } catch (error) {
            console.error("Error in Device Creation:", error);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const validation = useFormik({
        initialValues: {
            paringKey: "",
        },
        validationSchema: Yup.object({
            paringKey: Yup.string().required("paringKey Key is required"),
        }),
        enableReinitialize: true,
    });
    const { paringKey } = validation.values;

    const skipToTheDashboardFour=()=>{
        window.location.href = "/dashboard";

    }

    return (
        <Grid gridTemplateColumns="45% auto" mt="1rem">
            <VStack align="start" ml="6rem">
                <VStack align="start" h="50vh">
                    <GridItem w="100%" h="5vh" mb="1.5rem">
                        <Text sx={Heading}>Pair your device to get access</Text>
                    </GridItem>

                    <GridItem w="80%" mb="0.5rem">
                        <Text sx={noteStyle}>Step 1: Ensure the symbols are visible on your device as shown in Illustration 1.</Text>
                    </GridItem>
                    <GridItem w="80%">
                        <Text mb="0.5rem" sx={noteStyle}>
                            Step 2: Press the Start button 5 times in a row.
                        </Text>
                    </GridItem>
                    <GridItem w="80%">
                        <Text mb="0.5rem" sx={noteStyle}>
                            Step 3: Enter the key displayed on your device screen into the input field below.
                        </Text>
                    </GridItem>
                    <GridItem w="80%">
                        <FormControl isInvalid={validation.errors.paringKey && validation.touched.paringKey} isRequired>
                            <FormLabel mb="0.5rem" sx={labelField}>
                                Pairing key
                            </FormLabel>

                            <Input
                                isInvalid={validation.errors.paringKey && validation.touched.paringKey}
                                placeholder="Ex: abc123456"
                                type="text"
                                name="paringKey"
                                value={validation.values.paringKey}
                                onChange={validation.handleChange}
                                onFocus={validation.handleBlur}
                                sx={InputField}
                            />
                            <FormErrorMessage>{validation.errors.paringKey}</FormErrorMessage>
                        </FormControl>
                    </GridItem>
                </VStack>

                <GridItem display="flex" justifyContent="space-between" w="81%">
                    <Box w="30%" display="flex">
                        <CustomButton
                            bg="rgba(255, 255, 255, 1)"
                            color="rgba(87, 186, 71, 1)"
                            border="2px solid"
                            borderColor="rgba(87, 186, 71, 1)"
                            onClick={previous_Handle_Pair_Device}
                        >
                            Back
                        </CustomButton>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" alignItems="center" w="90%" gap="1rem">
                        <CustomButton color="rgba(0, 0, 0, 1)" w="30%" bg="rgba(255, 255, 255, 1)" sx={buttonText} onClick={skipToTheDashboardFour}>
                            Cancel
                        </CustomButton>
                        <CustomButton
                            color="rgba(255, 255, 255, 1)"
                            w="27%"
                            h="40px"
                            bg="rgba(87, 186, 71, 1)"
                            sx={buttonText}
                            onClick={Getting_Started}
                            disabled={!paringKey || loading}
                        >
                            Next
                        </CustomButton>
                    </Box>
                </GridItem>
            </VStack>

            <GridItem display="flex" justifyContent="center" alignItems="center">
                <Box mb="2rem"   >
                    <Image src={onboarding_pair_device} alt="deviceAdd" />
                </Box>
            </GridItem>
        </Grid>
    );
};
