import React from "react";
import { Box } from "@chakra-ui/react";
import { motion, useInView, useAnimation } from "framer-motion";

// Create a MotionBox component that wraps Chakra Box with framer-motion capabilities
const MotionBox = motion(Box);

export const Reveal = ({
  children,
  width = "auto",
  hidden,
  visible,
  delay = 1,
}) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  const slideControls = useAnimation();

  React.useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
      slideControls.start("visible");
    }
  }, [isInView,mainControls,slideControls]);

  return (
    <Box ref={ref} width={width} position="relative">
      <MotionBox
        variants={{
          hidden: hidden,
          visible: visible,
        }}
        initial="hidden"
        animate={mainControls}
        transition={{
          duration: 0.5,
          delay: delay,
        }}
      >
        {children}
      </MotionBox>
    </Box>
  );
};
