import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerOverlay, Image, Text } from "@chakra-ui/react";
import React from "react";
import CustomButton from "./CustomButton";
import group from "../assets/savePassword.svg";
import { useNavigate } from "react-router-dom";

const PasswordResetSuccess = ({ passwordSaveIsOpen, passwordSaveOnClose, email }) => {
    const navigation = useNavigate();
    const [placement] = React.useState("right");

    const handleDone = () => {
        passwordSaveOnClose();
        navigation("/login");
    };

    return (
        <>
            <Drawer placement={placement} onClose={passwordSaveOnClose} isOpen={passwordSaveIsOpen}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <DrawerCloseButton sx={{ color: "#11250E", p: "8px", bg: "#F6F6F6" }} />

                    <DrawerBody mt="80px" align="center" position="relative">
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="10px"
                            minWidth="310px"
                            fontFamily="fontFamily"
                            fontSize="19px"
                            fontWeight="600"
                            lineHeight="34px"
                            letterSpacing="-0.01em"
                            color="#11250E"
                        >
                            <Text textAlign="center" fontSize="20px" fontWeight="bold" color="#57BA47">
                                Congratulations
                            </Text>
                            <Text textAlign="center">Your password has been updated.</Text>
                        </Box>

                        <Image src={group} alt="Placeholder Image" w="100%" h="400px" />
                    </DrawerBody>

                    <DrawerFooter>
                        <CustomButton
                            width="100%"
                            h="40px"
                            bg="#57BA47"
                            sx={{
                                _hover: { bg: "#57BA47" },
                                fontFamily: "fontFamily",
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#FFFFFF",
                            }}
                            marginInline="auto"
                            onClick={handleDone}
                        >
                            Done
                        </CustomButton>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default PasswordResetSuccess;
