import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormLabel,
    Image,
    Input,
    Text,
    CircularProgress,
    FormControl,
    FormErrorMessage,
} from "@chakra-ui/react";
import React from "react";
import axios from "axios";
import CustomButton from "./CustomButton";
import plusIcon from "../assets/Plus_Icon.png";
import Step2EditDetail from "./Step2EditDetail";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";

const inputStyle = {
    borderRadius: "4px",
    border: "1px solid #81868180",
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    color: "#000000",
    _focus: {
        border: "1.5px solid #57BA47",
        boxShadow: "none",
    },
    "::placeholder": {
        color: "#818681",
        opacity: 0.4,
    },
};

const labelStyle = {
    fontFamily: "fontFamily",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "-0.01em",
    marginBottom: "4px",
    color: "#11250E",
};

const EditCompanyLocation = ({
    countryIsOpen,
    countryOnClose,
    step2countryIsOpen,
    step2countryOnClose,
    step2countryOnOpen,
    prevDetail,
    userProfileQuery,
}) => {
    const btnRef = React.useRef();

    const formik = useFormik({
        initialValues: {
            id: prevDetail?.company?.id || "",
            country: prevDetail?.company?.location?.country || "",
            state: prevDetail?.company?.location?.state || "",
            city: prevDetail?.company?.location?.city || "",
            address: prevDetail?.company?.location?.address || "",
        },
        validationSchema: Yup.object({
            country: Yup.string().min(3, "Country name is too short.").required("Country name is required."),
            state: Yup.string().min(3, "State name is too short.").required("State name is required"),
            city: Yup.string().min(3, "City name is too short.").required("City name is required"),
            address: Yup.string().min(3, "Address name is too short.").required("Address name is required"),
        }),
        enableReinitialize: true,
        onSubmit: (values) => {
            editCountryMutation.mutate(values);
        },
    });

    const editCountryMutation = useMutation({
        mutationFn: async (values) => {
            const response = await axios.patch(`/auth/update/company-detail`, {
                company_id: values.id,
                location: {
                    country: values.country,
                    state: values.state,
                    city: values.city,
                    address: values.address,
                },
            });
            return response;
        },
        onSuccess: (response) => {
            if (response.status === 200) {
                formik.resetForm();
                countryOnClose();
                step2countryOnOpen();
                userProfileQuery.refetch();
            } else {
                formik.setStatus({
                    error: response?.data?.error || "Edit Country Detail failed. Please try again.",
                });
            }
        },
        onError: (error) => {
            console.error("Error in Country Detail Updation:", error);
            const errorMessage = error?.response?.data?.error || "An unexpected error occurred.";
            formik.setStatus({ error: errorMessage });
        },
    });

    return (
        <>
            <Drawer isOpen={countryIsOpen} placement="right" onClose={countryOnClose} finalFocusRef={btnRef}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <Box mt={{ base: "0px", sm: "0px", md: "5px", lg: "10px" }} mr="10px">
                        <DrawerCloseButton sx={{ color: "#11250E", p: "8px", bg: "#F6F6F6" }} />
                        <DrawerHeader
                            fontFamily="fontFamily"
                            fontSize="28px"
                            fontWeight="800"
                            lineHeight="42px"
                            letterSpacing="-0.04em"
                            textAlign="left"
                            color="#11250E"
                        >
                            Edit Company Location
                        </DrawerHeader>
                    </Box>

                    <DrawerBody>
                        <Box mb={4}>
                            <FormControl isInvalid={formik.errors.address && formik.touched.address} isRequired>
                                <FormLabel htmlFor="address" sx={labelStyle}>
                                    Street
                                </FormLabel>
                                <Input
                                    id="address"
                                    placeholder="ex: Australia"
                                    type="text"
                                    sx={inputStyle}
                                    name="address"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage>{formik.errors.address}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb={4}>
                            <FormControl isInvalid={formik.errors.city && formik.touched.city} isRequired>
                                <FormLabel htmlFor="city" sx={labelStyle}>
                                    City
                                </FormLabel>
                                <Input
                                    id="city"
                                    placeholder="ex: Australia"
                                    type="text"
                                    name="city"
                                    sx={inputStyle}
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb={4}>
                            <FormControl isInvalid={formik.errors.state && formik.touched.state} isRequired>
                                <FormLabel htmlFor="state" sx={labelStyle}>
                                    State
                                </FormLabel>
                                <Input
                                    id="state"
                                    placeholder="ex: Australia"
                                    type="text"
                                    name="state"
                                    sx={inputStyle}
                                    value={formik.values.state}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb={4}>
                            <FormControl isInvalid={formik.errors.country && formik.touched.country} isRequired>
                                <FormLabel htmlFor="Country" sx={labelStyle}>
                                    Country
                                </FormLabel>
                                <Input
                                    id="Country"
                                    placeholder="ex: Australia"
                                    type="text"
                                    name="country"
                                    sx={inputStyle}
                                    value={formik.values.country}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Text fontFamily="fontFamily" fontWeight="400" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#818681">
                            NOTE: We need this address information for the mailing purpose.
                        </Text>
                    </DrawerBody>

                    <DrawerFooter>
                        <Flex direction="column" justifyContent="center" gap={2} width="100%">
                            <CustomButton
                                width="100%"
                                h="40px"
                                bg="#57BA47"
                                sx={{
                                    _hover: { bg: "#57BA47" },
                                    fontFamily: "fontFamily",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                }}
                                marginInline="auto"
                                onClick={formik.handleSubmit}
                                disabled={!formik.isValid || editCountryMutation.isPending}
                            >
                                Done
                            </CustomButton>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <Step2EditDetail step2DetailIsOpen={step2countryIsOpen} step2DetailOnClose={step2countryOnClose} prevDetail={prevDetail} />
        </>
    );
};

export default EditCompanyLocation;
