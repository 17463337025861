import React, { useState, useEffect } from "react";
import { Box, Flex, Image, Text, Divider, VStack } from "@chakra-ui/react";
import logo from "../assets/logo.svg";

import all_client_1 from "../assets/all_clients_1.svg";
import all_client_2 from "../assets/all_clients_2.svg";
import all_client_3 from "../assets/all_clients_3.svg";

import settings_1 from "../assets/settings_1.svg";
import settings_2 from "../assets/settings_2.svg";
import settings_3 from "../assets/settings_3.svg";

import logout_1 from "../assets/logout_1.svg";
import logout_2 from "../assets/logout_2.svg";
import logout_3 from "../assets/logout_3.svg";

import userImage from "../assets/userImage.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const fetchProfile = async () => {
    const res = await axios.get(`/auth/user`);
    return res?.data?.data;
};

const AdminNav = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [hoveredOption, setHoveredOption] = useState(null);

    const { data: profile, ...profileQuery } = useQuery({
        queryKey: "fetchProfile",
        queryFn: fetchProfile,
    });

    const handleNavigation = (option) => {
        navigate(`${option}`);
    };

    useEffect(() => {
        // clear session storage
        sessionStorage.clear();
    }, []);

    return (
        <Box
            position="fixed"
            top={0}
            left={0}
            bg="rgba(17, 37, 14, 1)"
            w="20%"
            h="100vh"
            p="2rem"
            overflow="auto"
            zIndex={9999}
            sx={{
                overflow: "auto",
                "&::-webkit-scrollbar": {
                    width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                    background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                    background: "transparent",
                    borderRadius: "10px",
                },
                "&:hover::-webkit-scrollbar-thumb": {
                    background: "rgba(104, 110, 103, 0.3)",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                    background: "rgba(104, 110, 103, 0.3)",
                },
            }}
        >
            {/* Top Section */}
            <Flex direction="column">
                <Flex direction="column" align="center" mb="2rem" mt="1rem">
                    <Image src={logo} alt="logo" boxSize="40px" />
                    <Text fontSize="lg" color="rgba(255, 255, 255, 1)">
                        Instantemp
                    </Text>
                </Flex>

                <Flex align="center" mb="1rem">
                    <Image src={userImage} alt="User" boxSize="50px" mr={3} />
                    <Box>
                        <Text fontSize="24px" fontWeight="600" color="rgba(255, 255, 255, 1)">
                            {profile?.user?.name}
                        </Text>
                        <Text fontSize="16px" fontWeight="400" color="rgba(221, 241, 218, 1)">
                            Admin
                        </Text>
                    </Box>
                </Flex>

                <VStack gap="0.5rem" align="flex-start" w="full">
                    <Flex
                        key="all-clients"
                        align="center"
                        w="full"
                        p={2}
                        borderRadius="md"
                        _hover={{ bg: "rgba(255, 255, 255, 0.12)" }}
                        cursor="pointer"
                        // bg={location.pathname.includes("all-clients") ? "rgba(255, 255, 255, 0.12)" : "transparent"}
                        bg={
                            location.pathname.includes("all-clients") ||
                            location.pathname === "/admin/dashboard" ||
                            location.pathname.includes("/admin/dashboard/client-profile/")
                                ? "rgba(255, 255, 255, 0.12)"
                                : "transparent"
                        }
                        onClick={() => handleNavigation("all-clients")}
                        onMouseEnter={() => setHoveredOption("all-clients")}
                        onMouseLeave={() => setHoveredOption(null)}
                    >
                        <Image
                            // src={
                            //     location.pathname.includes("all-clients")
                            //         ? all_client_3
                            //         : hoveredOption === "all-clients"
                            //         ? all_client_2
                            //         : all_client_1
                            // }
                            src={
                                location.pathname.includes("all-clients") ||
                                location.pathname === "/admin/dashboard" ||
                                location.pathname.includes("/admin/dashboard/client-profile/")
                                    ? all_client_3
                                    : hoveredOption === "all-clients"
                                    ? all_client_2
                                    : all_client_1
                            }
                            alt="all-clients"
                            boxSize="20px"
                            mr={3}
                            w="28px"
                            h="28px"
                        />
                        <Text
                            sx={{
                                fontFamily: "fontFamily",
                                fontSize: "18px",
                                fontWeight: "400",
                                lineHeight: "30px",
                                letterSpacing: "0.01em",
                                textAlign: "left",
                            }}
                            color="rgba(255, 255, 255, 1)"
                        >
                            All Clients
                        </Text>
                    </Flex>
                </VStack>

                <Divider color="rgba(221, 241, 218, 1)" marginY={8} />

                <VStack gap="0.5rem" align="flex-start" w="full">
                    <Flex
                        key="settings"
                        align="center"
                        w="full"
                        p={2}
                        borderRadius="md"
                        _hover={{ bg: "rgba(255, 255, 255, 0.12)" }}
                        cursor="pointer"
                        bg={location.pathname.includes("settings") ? "rgba(255, 255, 255, 0.12)" : "transparent"}
                        onClick={() => handleNavigation("settings")}
                        onMouseEnter={() => setHoveredOption("settings")}
                        onMouseLeave={() => setHoveredOption(null)}
                    >
                        <Image
                            src={location.pathname.includes("settings") ? settings_3 : hoveredOption === "settings" ? settings_2 : settings_1}
                            alt="settings"
                            boxSize="20px"
                            mr={3}
                            w="28px"
                            h="28px"
                        />
                        <Text
                            sx={{
                                fontFamily: "fontFamily",
                                fontSize: "18px",
                                fontWeight: "400",
                                lineHeight: "30px",
                                letterSpacing: "0.01em",
                                textAlign: "left",
                            }}
                            color="rgba(255, 255, 255, 1)"
                        >
                            Settings
                        </Text>
                    </Flex>

                    <Flex
                        key="logout"
                        align="center"
                        w="full"
                        p={2}
                        borderRadius="md"
                        _hover={{ bg: "rgba(255, 255, 255, 0.12)" }}
                        cursor="pointer"
                        bg={location.pathname.includes("logout") ? "rgba(255, 255, 255, 0.12)" : "transparent"}
                        onClick={() => {
                            localStorage.removeItem("token");
                            navigate("/login");
                        }}
                        onMouseEnter={() => setHoveredOption("logout")}
                        onMouseLeave={() => setHoveredOption(null)}
                    >
                        <Image
                            src={location.pathname.includes("logout") ? logout_3 : hoveredOption === "logout" ? logout_2 : logout_1}
                            alt="logout"
                            boxSize="20px"
                            mr={3}
                            w="28px"
                            h="28px"
                        />
                        <Text
                            sx={{
                                fontFamily: "fontFamily",
                                fontSize: "18px",
                                fontWeight: "400",
                                lineHeight: "30px",
                                letterSpacing: "0.01em",
                                textAlign: "left",
                            }}
                            color="rgba(255, 255, 255, 1)"
                        >
                            Logout
                        </Text>
                    </Flex>
                </VStack>
            </Flex>
        </Box>
    );
};

export default AdminNav;
