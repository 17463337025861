export function formatUnitForSensorType({ sensorType, value }) {
    const SENSOR_TYPES = {
        undefined: "%",
        HumidityHumidity: "%",
        HumidityTemperature: "°C",
        ThermocoupleK: "°C",
        Battery: "%",
    };

    return `${value ?? "NA"} ${SENSOR_TYPES[sensorType] ?? ""}`;
}
