import React from "react";
import { Bar, Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Box } from "@chakra-ui/react";
import Moment from "moment-timezone";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TemperatureLineChart = ({ data, selectedGraph, selectedType, deviceData, overview }) => {
    const distinctReadings = new Set();
    const firstTenData = [];

    if (data) {
        for (let point of data) {
            if (!distinctReadings.has(point.fetch_time)) {
                distinctReadings.add(point.fetch_time);
                firstTenData.push(point);
            }
        }
    }

    firstTenData.sort((a, b) => new Date(a.fetch_time) - new Date(b.fetch_time));

    const Max = deviceData?.[selectedGraph === "sensor1" ? "sensor_one" : "sensor_two"]?.max_value;
    const Min = deviceData?.[selectedGraph === "sensor1" ? "sensor_one" : "sensor_two"]?.min_value;

    const chartData = {
        labels: firstTenData.map((point) => new Date(point.fetch_time).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false })),
        labels: firstTenData.map((point) => Moment(point.fetch_time).format("MM/DD/YY HH:mm")),
        datasets: [
            {
                label: selectedType === "humidity" ? "Humidity" : "Temperature",
                data: firstTenData.map((point) => (selectedGraph === "sensor1" ? point.sensor_one : point.sensor_two)),
                // backgroundColor: selectedType === "humidity" ? "rgba(153, 233, 233, 0.5)" : "rgba(255, 204, 0, 0.5)",
                borderColor: selectedType === "humidity" ? "#99E9E9" : "#FFCC00",
                borderWidth: 3,
                tension: 0.4,
                pointRadius: 0,
            },
            {
                label: "Max",
                data: new Array(firstTenData?.length).fill(Max),
                borderDash: [10, 5], // Dashed line for upper range
                borderWidth: 2,
                pointRadius: 0,
                borderColor: "#EA5D5D77",
                type: "line",
            },
            {
                label: "Min",
                data: new Array(firstTenData?.length).fill(Min),
                borderDash: [10, 5], // Dashed line for lower range
                pointRadius: 0,
                borderWidth: 2,
                borderColor: "#EA5D5D77",
                type: "line",
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Time",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                title: {
                    display: true,
                    text: selectedType === "humidity" ? "Humidity" : "Temperature",
                },
                ticks: {
                    stepSize: 1,
                },
            },
        },
        plugins: {
            legend: false,
            tooltip: {
                enabled: true,
                mode: "index",
                intersect: false,
            },
        },
    };

    return (
        <Box width="100%" height="100%">
            <Line data={chartData} options={options} style={{ width: "100%", height: "100%" }} />
        </Box>
    );
};

export default TemperatureLineChart;
