import React, { useCallback, useState } from "react";
import {
    Table,
    Thead,
    Tbody,
    Icon,
    Tr,
    Th,
    Td,
    TableContainer,
    InputGroup,
    InputLeftElement,
    Input,
    Grid,
    GridItem,
    Box,
    Image,
    Text,
    Checkbox,
    Flex,
    Button,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { TopSection } from "../common/TopSection";
import totalClientImage from "../assets/total client.svg";
import activeSubscriptionImage from "../assets/Active Subscription.svg";
import inactiveSubscriptionImage from "../assets/Inactive Subscription.svg";
import searchIcon from "../../assets/Search_Icons.png";
import active from "../../assets/active.png";
import inactive from "../../assets/inactive.png";
import { CgSortAz } from "react-icons/cg";
import user from "../assets/user.png";
import left from "../assets/left_page_chnage.svg";
import right from "../assets/right_page_change.svg";
import DeleteClient from "./DeleteClient";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import CustomToast from "../../components/CustomToast";

const Description = {
    fontFamily: "fontFamily",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    textAlign: "left",
    color: "rgba(129, 134, 129, 1)",
};

const organisation_name = {
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    textAlign: "left",
    color: "rgba(17, 37, 14, 1)",
};

const other_name = {
    fontFamily: "fontFamily",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "16px",
    letterSpacing: "-0.01em",
    textAlign: "left",
    color: "rgba(17, 37, 14, 1)",
};

const fetchClients = async ({ page, sort, query, limit }) => {
    const res = await axios.get(`/admin/clients`, { params: { page, sort, query, limit: limit } });
    return res.data;
};

export const AllClients = () => {
    const { isOpen: step1IsOpen, onOpen: step1onOpen, onClose: step1onClose } = useDisclosure();
    const toast = useToast();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [sort, setSort] = useState("ASC");
    const [showCheckboxes, setShowCheckboxes] = useState(false);

    const [checkedItems, setCheckedItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchquery, setSearchQuery] = useState("");

    const { data: MemberData = { data: [] }, ...MemberDataQuery } = useQuery({
        queryKey: ["fetchClients", { currentPage, sort, searchquery }],
        queryFn: () => fetchClients({ page: currentPage, sort, query: searchquery, limit: MemberData?.data?.pagination?.limit }),
    });

    const deleteUserById = async (userId) => {
        console.log(userId, "userId");
        if (!checkedItems.includes(userId)) {
            toast({
                userId,
                description: "User is not selected.",
                status: "success",
                duration: 10000,
                isClosable: true,
                position: "top",
                render: () => <CustomToast text={`Client is not selected.`} onClose={() => toast.close(userId)} />,
            });
            return;
        }

        const user = MemberData?.data?.rows?.find((user) => user.id === userId);
        console.log(user);

        if (user) {
            try {
                await axios.delete(`/admin/client/deleted/${userId}`);
                queryClient.invalidateQueries("fetchClients");
                toast({
                    id: userId,
                    description: "Client deleted successfully.",
                    status: "success",
                    duration: 10000,
                    isClosable: true,
                    position: "top",
                    render: () => <CustomToast text={`Client deleted successfully.`} onClose={() => toast.close(userId)} />,
                });
                // console.log(`User ID ${userId} deleted successfully.`);
            } catch (error) {
                alert("An error occurred while deleting the user.");
            }
        } else {
            alert("You do not have permission to delete this user.");
        }
    };

    const deleteSelectedUsers = async (id) => {
        await Promise.all(checkedItems.map((id) => deleteUserById(id)));

        setCheckedItems([]);
    };

    const Total_Client = {
        fontFamily: "fontFamily",
        fontWeight: "600",
        letterSpacing: "-0.01em",
        textAlign: "left",
    };

    const imageMap = {
        "total client": totalClientImage,
        "active subscription": activeSubscriptionImage,
        "inactive subscription": inactiveSubscriptionImage,
    };

    const placeHolderCss = {
        fontFamily: "fontFamily",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        letterSpacing: "-0.01em",
        textAlign: "left",
        _focus: {
            border: "1px solid #81868180",
            boxShadow: "none",
        },
    };

    const totalPages = Math.ceil((MemberData?.data?.pagination?.count || 0) / MemberData?.data?.pagination?.limit || 1);

    const SearchIconComponent = () => <Image src={searchIcon} alt="Search icon" />;

    const handleRemoveClientsClick = () => {
        setShowCheckboxes((prev) => !prev);
    };

    const handleCheckboxChange = (userId) => {
        setCheckedItems((prev) => {
            const newCheckedItems = prev.includes(userId) ? prev.filter((id) => id !== userId) : [...prev, userId];

            return newCheckedItems;
        });
    };

    // const handleClientDelete = () => {
    //     step1onOpen();
    // };

    const counts = {
        "Total Client": MemberData?.data?.rows?.length,
        "Active Subscription": 0,
        "Inactive Subscription": 0,
    };

    const debouncedSearch = useCallback(
        debounce((query) => {
            setSearchQuery(query);
        }, 300),
        [],
    );

    const handleSearchChange = (e) => {
        debouncedSearch(e.target.value);
    };

    const ClientProfile = (id) => {
        navigate(`/admin/dashboard/client-profile/${id}`);
    };

    const handleSortChange = () => {
        setSort((prevSort) => (prevSort === "ASC" ? "DESC" : "ASC"));
    };

    return (
        <>
            <Grid w="100%" h="100vh" gap="0px">
                <TopSection />
                <GridItem mt="7rem" w="100%">
                    <Grid
                        templateColumns="repeat(3, 1fr)"
                        mt="2rem"
                        w="90%"
                        justifyContent="flex-end"
                        mx={{ lg: "auto", xl: "auto", "2xl": 0 }}
                        ml={{ "2xl": "3.5rem" }}
                        gap={{ lg: "1rem", xl: "2rem" }}
                    >
                        {["Total Client", "Active Subscription", "Inactive Subscription"].map((el) => (
                            <GridItem
                                key={el.toLowerCase()}
                                display="flex"
                                alignItems="center"
                                w="100%"
                                p={{ lg: "1.5rem", xl: "1.5rem", "2xl": "1.5rem" }}
                                justifyContent="center"
                                h={{ lg: "17vh", xl: "15vh", "2xl": "15vh" }}
                                borderRadius="8px"
                                border="1px solid"
                                borderColor="rgba(237, 237, 237, 0.8)"
                                cursor="pointer"
                            >
                                <Box w="80%">
                                    <Text
                                        alignItems="start"
                                        w="100%"
                                        sx={Total_Client}
                                        fontSize={{ lg: "30px", xl: "48px", "2xl": "48px" }}
                                        lineHeight={{ lg: "60px", xl: "72px", "2xl": "72px" }}
                                    >
                                        {counts[el]}
                                    </Text>
                                    <Text alignItems="start" w="100%" sx={Description}>
                                        {el}
                                    </Text>
                                </Box>
                                <Box>
                                    <Image src={imageMap[el.toLowerCase()]} alt={el.toLowerCase()} />
                                </Box>
                            </GridItem>
                        ))}
                    </Grid>
                    <Grid
                        gridTemplateRows="10vh auto"
                        border="1px solid"
                        borderColor="rgba(237, 237, 237, 0.8)"
                        w="90%"
                        ml={{ lg: "2.5rem", xl: "3.5rem", "2xl": "3.5rem" }}
                        mt="2rem"
                        pt="0.5rem"
                        borderRadius="8px"
                    >
                        <GridItem w="100%" h="10vh" pl="1rem" display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" gap="0.5rem">
                                <Box>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents="none">
                                            <SearchIconComponent />
                                        </InputLeftElement>
                                        <Input
                                            type="search"
                                            placeholder="Search by client name"
                                            sx={placeHolderCss}
                                            w="300px"
                                            h="40px"
                                            border="1px solid #81868180"
                                            onChange={handleSearchChange}
                                        />
                                    </InputGroup>
                                </Box>
                                <Box
                                    w="100%"
                                    h="40px"
                                    border="1px solid #81868180"
                                    display="flex"
                                    alignItems="center"
                                    borderRadius="4px"
                                    p="1rem"
                                    gap="0.5rem"
                                    onClick={handleSortChange}
                                >
                                    <Icon as={CgSortAz} h="24px" w="24px" />
                                    <Text>Sort: {sort === "DESC" ? "Last added" : "First added"}</Text>
                                </Box>
                            </Box>
                            <Box display="flex" gap="1rem" mr="1.5rem">
                                {!showCheckboxes ? (
                                    <Text
                                        color="rgba(87, 186, 71, 1)"
                                        fontFamily="fontFamily"
                                        fontSize="14px"
                                        fontWeight="600"
                                        lineHeight="20px"
                                        letterSpacing="-0.01em"
                                        textDecoration="underline"
                                        onClick={handleRemoveClientsClick}
                                        cursor="pointer"
                                    >
                                        Remove Client
                                    </Text>
                                ) : (
                                    <>
                                        <Button
                                            as="span"
                                            bg="none"
                                            _hover={{ bg: "none" }}
                                            textDecoration="underline"
                                            fontFamily="fontFamily"
                                            fontSize="14px"
                                            fontWeight="600"
                                            lineHeight="20px"
                                            letterSpacing="-0.01em"
                                            cursor="pointer"
                                            onClick={deleteSelectedUsers}
                                            isDisabled={checkedItems.length === 1}
                                            color={checkedItems.length > 1 ? "rgba(177, 9, 29, 1)" : "#57BA47"}
                                        >
                                            Delete All
                                        </Button>
                                        <Button
                                            as="span"
                                            bg="none"
                                            _hover={{ bg: "none" }}
                                            color="rgba(177, 9, 29, 1)"
                                            textDecoration="underline"
                                            fontFamily="fontFamily"
                                            fontSize="14px"
                                            fontWeight="600"
                                            lineHeight="20px"
                                            letterSpacing="-0.01em"
                                            onClick={handleRemoveClientsClick}
                                            cursor="pointer"
                                        >
                                            Cancel
                                        </Button>
                                    </>
                                )}
                            </Box>
                        </GridItem>

                        <GridItem w="100%" overflowY="auto">
                            <TableContainer>
                                <Table variant="simple">
                                    <Thead bg="rgba(246, 246, 246, 1)" h="7vh">
                                        <Tr>
                                            <Th textAlign="start">Organisation Name</Th>
                                            <Th textAlign="start">Location</Th>
                                            <Th textAlign="start">Member Since</Th>
                                            <Th textAlign="start">Owner Name</Th>
                                            <Th textAlign="start">Subscription</Th>
                                            <Th textAlign="end"></Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {MemberData?.data?.rows
                                            ?.filter((member) =>
                                                member.user_access[0]?.access_company?.name.toLowerCase().includes(searchquery.toLowerCase()),
                                            )
                                            .map((member) => (
                                                <Tr key={member.id}>
                                                    <Td display="flex" textAlign="start" gap="1.5rem" sx={other_name}>
                                                        {showCheckboxes && (
                                                            <Checkbox
                                                                isChecked={checkedItems.includes(member.id)}
                                                                onChange={() => {
                                                                    console.log("Checkbox changed for member ID:", member.id);
                                                                    handleCheckboxChange(member.id);
                                                                }}
                                                            />
                                                        )}
                                                        <Flex align="center">
                                                            <Image src={user} alt="User" mr="1rem" />
                                                            <Text ml={2} sx={organisation_name}>
                                                                {member?.user_access?.[0]?.access_company?.name || "N/A"}
                                                            </Text>
                                                        </Flex>
                                                    </Td>
                                                    <Td textAlign="start" sx={other_name}>
                                                        {[
                                                            member?.user_access?.[0]?.access_company?.location?.address,
                                                            member?.user_access?.[0]?.access_company?.location?.city,
                                                            member?.user_access?.[0]?.access_company?.location?.state,
                                                            member?.user_access?.[0]?.access_company?.location?.country,
                                                        ]
                                                            .filter(Boolean)
                                                            .join(", ") || "N/A"}
                                                    </Td>
                                                    <Td textAlign="start" sx={other_name}>
                                                        {member?.created_at ? new Date(member.created_at).toLocaleString() : "N/A"}
                                                    </Td>
                                                    <Td textAlign="start" sx={other_name}>
                                                        {member?.name || "N/A"}
                                                    </Td>
                                                    <Td textAlign="start" sx={other_name}>
                                                        <Flex alignItems="center" gap="0.3rem" justifyContent="center">
                                                            <Image
                                                                src={member?.Subscription === "Active" ? active : inactive}
                                                                alt="Subscription Status"
                                                                w="10px"
                                                                h="10px"
                                                            />
                                                            {member.Subscription || "N/A"}
                                                        </Flex>
                                                    </Td>

                                                    {showCheckboxes ? (
                                                        <Td
                                                            cursor="pointer"
                                                            onClick={() => deleteUserById(member.id)}
                                                            disabled={!checkedItems.includes(member.id)}
                                                            color={checkedItems.includes(member.id) ? "rgba(177, 9, 29, 1)" : "gray"}
                                                            textDecoration="underline"
                                                        >
                                                            Delete
                                                        </Td>
                                                    ) : (
                                                        <Td textAlign="end">
                                                            <Text
                                                                color="rgba(87, 186, 71, 1)"
                                                                textDecoration="underline"
                                                                onClick={() => ClientProfile(member?.id)}
                                                                cursor="pointer"
                                                            >
                                                                View Details
                                                            </Text>
                                                        </Td>
                                                    )}
                                                </Tr>
                                            ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </GridItem>
                    </Grid>
                    <Flex align="center" justify="center" mb="1rem" mt="2rem" gap="2">
                        <Box mr="3rem" onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}>
                            <Image src={left} alt="Previous page" />
                        </Box>
                        {[...Array(totalPages)].map((_, index) => {
                            const pageNumber = index + 1;
                            return (
                                <Button
                                    w="29px"
                                    h="29px"
                                    fontFamily="fontFamily"
                                    minWidth={0}
                                    fontSize="12px"
                                    fontWeight="400"
                                    _hover={{ color: "none" }}
                                    textAlign="center"
                                    borderRadius="50%"
                                    key={pageNumber}
                                    onClick={() => setCurrentPage(pageNumber)}
                                    bg={currentPage === pageNumber ? "rgba(87, 186, 71, 1)" : "none"}
                                    color={currentPage === pageNumber ? "white" : "rgba(101, 101, 101, 1)"}
                                >
                                    {pageNumber}
                                </Button>
                            );
                        })}
                        <Box ml="3rem" onClick={() => setCurrentPage(Math.min(currentPage + 1, totalPages))}>
                            <Image src={right} alt="Next page" />
                        </Box>
                    </Flex>
                </GridItem>
            </Grid>

            <DeleteClient step1IsOpen={step1IsOpen} step1onOpen={step1onOpen} step1onClose={step1onClose} />
        </>
    );
};
