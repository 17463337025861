import React from "react";
import * as ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import queryString from "query-string";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/store";
import theme from "./theme/theme";
import { ImpersonationProvider } from "./contexts/useImpersonationContext";

// Create root element
const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

// Initialize the Query Client for React Query
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <Provider store={store}>
                <BrowserRouter>
                    <QueryClientProvider client={queryClient}>
                        {/* TODO: to comment react query dev tools again before pushing */}
                        {/* <ReactQueryDevtools initialIsOpen={false} position="right" client={queryClient} buttonPosition="bottom-right" /> */}
                        <QueryParamProvider
                            adapter={ReactRouter6Adapter}
                            options={{
                                searchStringToObject: queryString.parse,
                                objectToSearchString: queryString.stringify,
                                skipUpdateWhenNoChange: true,
                                removeDefaultsFromUrl: true,
                                includeKnownParams: true,
                                updateType: "replaceIn",
                            }}
                        >
                            <ImpersonationProvider>
                                <App />
                            </ImpersonationProvider>
                        </QueryParamProvider>
                    </QueryClientProvider>
                </BrowserRouter>
            </Provider>
        </ChakraProvider>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// `serviceWorker.unregister()` to `serviceWorker.register()` below.
// Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
