import React, { useEffect, useState } from "react";
import {
    Box,
    Flex,
    Image,
    Text,
    Divider,
    Icon,
    VStack,
    HStack,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useDisclosure,
    Input,
    MenuDivider,
} from "@chakra-ui/react";
import { PiCaretDown } from "react-icons/pi";
import userImage from "../assets/userImage.png";
import logo from "../assets/logo.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import AddSpace from "../components/AddSpace";
import plusIcon from "../assets/green_plus.svg";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useImpersonation } from "../contexts/useImpersonationContext";
import LogoutImgHover from "../assets/logout_2.svg";
import LogoutImg from "../assets/logout_1.svg";
import { isCompanysDecisionMaker } from "../utils/roleUtils";

// Fetching spaces from the server
export const fetchSpaces = async () => {
    const res = await axios.get(`/space`);

    return res.data;
};

const fetchProfile = async () => {
    const res = await axios.get(`/auth/user`);
    return res.data;
};

const SideBar = () => {
    const { isImpersonating } = useImpersonation();
    const navigate = useNavigate();
    const location = useLocation();
    const { space: spaceID } = useParams();
    //console.log(spaceID);

    const { data: profile, ...profileQuery } = useQuery({
        queryKey: "fetchProfile",
        queryFn: fetchProfile,
    });

    const { data: spaces, ...spacesQuery } = useQuery({
        queryKey: "fetchSpaces",
        queryFn: fetchSpaces,
    });
    //console.log(spaces);
    const [selectedSpace, setSelectedSpace] = useState(null);

    const [searchTerm, setSearchTerm] = useState("");
    const filteredSpaces = spaces?.data?.filter((spaceItem) => spaceItem.name.toLowerCase().includes(searchTerm.toLowerCase()));
    const [hoveredOption, setHoveredOption] = useState(null);

    useEffect(() => {
        if (spaces?.data?.length) {
            if (!spaceID) {
                const defaultSpace = spaces.data[0];
                setSelectedSpace(defaultSpace);
                navigate(`/dashboard/${defaultSpace.id}/overview`);
            } else {
                const newSpace = spaces.data.find((space) => space.id === spaceID);
                setSelectedSpace(newSpace || spaces.data[0]);
            }
        }
    }, [spaces, spaceID, navigate]);

    useEffect(() => {
        if (profile?.data?.user && !profile?.data?.user?.onboarded) {
            navigate("/onboarding");
        }
    }, [profile]);

    const handleSpaceSelection = (space) => {
        setSelectedSpace(space);
        navigate(`/dashboard/${space?.id}/overview`);
    };

    const handleNavigation = (option) => {
        navigate(`/dashboard/${selectedSpace?.id}/${option}`);
    };

    const { isOpen: spaceIsOpen, onOpen: spaceOnOpen, onClose: spaceOnClose } = useDisclosure();
    const { isOpen: step2spaceIsOpen, onOpen: step2spaceOnOpen, onClose: step2spaceOnClose } = useDisclosure();

    return (
        <Box
            position="fixed"
            top={0}
            left={0}
            bg="rgba(17, 37, 14, 1)"
            w="20%"
            h="100vh"
            p="2rem"
            overflow="auto"
            zIndex={9999}
            sx={{
                overflow: "auto",
                "&::-webkit-scrollbar": {
                    width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                    background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                    background: "transparent",
                    borderRadius: "10px",
                },
                "&:hover::-webkit-scrollbar-thumb": {
                    background: "rgba(104, 110, 103, 0.3)",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                    background: "rgba(104, 110, 103, 0.3)",
                },
            }}
        >
            {/* Top Section */}
            <Flex direction="column">
                <Flex direction="column" align="center" mb="2rem" mt="1rem">
                    <Image src={logo} alt="logo" boxSize="40px" />
                    <Text fontSize="lg" color="rgba(255, 255, 255, 1)">
                        Instantemp
                    </Text>
                </Flex>

                <Flex align="center" mb="1rem">
                    <Image src={userImage} alt="User" boxSize="50px" mr={3} />
                    <Box>
                        <Text fontSize="24px" fontWeight="600" color="rgba(255, 255, 255, 1)">
                            {profile?.data?.user?.name}
                        </Text>
                        <Text fontSize="16px" fontWeight="400" color="rgba(221, 241, 218, 1)">
                            {profile?.data?.company?.company_access?.display_role
                                ? profile?.data?.company?.company_access?.display_role?.charAt(0).toUpperCase() +
                                  profile?.data?.company?.company_access?.display_role?.slice(1).toLowerCase()
                                : ""}
                        </Text>
                    </Box>
                </Flex>

                <Menu>
                    <MenuButton
                        as={HStack}
                        borderRadius="4px"
                        justifyContent="space-between"
                        mb={4}
                        padding={2}
                        border="1.5px solid rgba(129, 134, 129, 1)"
                        cursor="pointer"
                        color="rgba(255, 255, 255, 1)"
                        _hover={{
                            border: "1.5px solid #FFFFFF",
                        }}
                    >
                        <Flex justify="space-between" align="center" width="100%">
                            <Box>{selectedSpace ? selectedSpace.name : "Select a space"}</Box>
                            <Icon as={PiCaretDown} boxSize={4} />
                        </Flex>
                    </MenuButton>

                    <MenuList
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        zIndex="dropdown"
                        maxHeight="400px"
                        overflow="auto"
                        pt={2}
                        pb={4}
                        sx={{
                            overflow: "auto",
                            "&::-webkit-scrollbar": {
                                width: "8px",
                            },
                            "&::-webkit-scrollbar-track": {
                                background: "transparent",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                background: "rgba(104, 110, 103, 0.3)",
                                borderRadius: "10px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                background: "rgba(104, 110, 103, 0.3)",
                            },
                        }}
                    >
                        <div
                            style={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: "#FFFFFF",
                                zIndex: 1,
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <Input
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                w="180px"
                                h="40px"
                                marginTop={4}
                                sx={{
                                    _hover: {
                                        border: "1.5px solid #57BA47",
                                        boxShadow: "none",
                                    },
                                    _focus: {
                                        border: "1.5px solid #57BA47",
                                        boxShadow: "none",
                                    },
                                }}
                            />
                            <MenuDivider color="#000" my={2} />
                        </div>
                        {filteredSpaces?.map((spaceItem) => (
                            <MenuItem
                                key={spaceItem.id}
                                onClick={() => handleSpaceSelection(spaceItem)}
                                _hover={{
                                    bg: "#F2F2F2",
                                }}
                                w="100%"
                                textAlign="center"
                                fontSize="14px"
                                fontWeight="800"
                            >
                                {selectedSpace?.id === spaceItem.id ? (
                                    <Icon as={CheckCircleIcon} alt="tick" color="#57BA47" w="16px" h="16px" mr="3px" />
                                ) : (
                                    <div style={{ width: "16px", height: "16px", marginRight: "3px" }} />
                                )}
                                <Text
                                    color="#11250E"
                                    fontWeight="500"
                                    fontSize="14px"
                                    fontFamily="fontFamily"
                                    lineHeight="30px"
                                    letterSpacing="-0.01em"
                                    marginLeft="8px"
                                >
                                    {spaceItem.name}
                                </Text>
                            </MenuItem>
                        ))}
                        {isCompanysDecisionMaker(profile?.data?.company?.company_access?.company_role) && (
                            <>
                                <MenuDivider color="#000" my={2} />
                                <MenuItem
                                    onClick={spaceOnOpen}
                                    w="160px"
                                    h="40px"
                                    border="2px solid #57BA47"
                                    borderRadius="8px"
                                    fontWeight="600"
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    fontFamily="fontFamily"
                                    fontSize="14px"
                                    lineHeight="20px"
                                    letterSpacing="-0.01em"
                                    color="#57BA47"
                                    mb={2}
                                >
                                    <Image src={plusIcon} alt="add" h="16px" w="16px" mr="3px" />
                                    Add Space
                                </MenuItem>
                            </>
                        )}
                    </MenuList>
                </Menu>

                {/* Navigation Links */}
                <VStack gap="0.5rem" align="flex-start" w="full">
                    {["Overview", "Rooms", "Devices", isCompanysDecisionMaker(profile?.data?.company?.company_access?.company_role) && "Members"]
                        .filter(Boolean)
                        .map((option, index) => {
                            const isActive = location.pathname.includes(option?.toLowerCase());
                            const isHovered = hoveredOption === option.toLowerCase();
                            const imageSrc = isActive
                                ? require(`../assets/${option.toLowerCase()}_3.svg`)
                                : isHovered
                                ? require(`../assets/${option.toLowerCase()}_2.svg`)
                                : require(`../assets/${option.toLowerCase()}_1.svg`);

                            return (
                                <Flex
                                    key={option?.toLowerCase()}
                                    align="center"
                                    w="full"
                                    p={2}
                                    borderRadius="md"
                                    _hover={{ bg: "rgba(255, 255, 255, 0.12)" }}
                                    cursor="pointer"
                                    bg={isActive ? "rgba(255, 255, 255, 0.12)" : "transparent"}
                                    // bg={isActive ? "rgba(255, 255, 255, 0.12)" : otherActivetab ? "rgba(255, 255, 255, 0.12)" : "transparent"}
                                    onClick={() => handleNavigation(option?.toLowerCase())}
                                    onMouseEnter={() => setHoveredOption(option.toLowerCase())}
                                    onMouseLeave={() => setHoveredOption(null)}
                                >
                                    <Image src={imageSrc} alt={option?.toLowerCase()} boxSize="20px" mr={3} w="28px" h="28px" />
                                    <Text
                                        sx={{
                                            fontFamily: "fontFamily",
                                            fontSize: "18px",
                                            fontWeight: "400",
                                            lineHeight: "30px",
                                            letterSpacing: "0.01em",
                                            textAlign: "left",
                                        }}
                                        color="rgba(255, 255, 255, 1)"
                                    >
                                        {option}
                                    </Text>
                                </Flex>
                            );
                        })}
                </VStack>

                <Divider color="rgba(221, 241, 218, 1)" marginY={8} />

                <VStack gap="0.5rem" align="flex-start" w="full">
                    {[
                        // "Subscription",
                        "Settings",
                    ].map((option, index) => {
                        const isActive = location.pathname.includes(option?.toLowerCase());
                        const isHovered = hoveredOption === option.toLowerCase();
                        const imageSrc = isActive
                            ? require(`../assets/${option.toLowerCase()}_3.svg`)
                            : isHovered
                            ? require(`../assets/${option.toLowerCase()}_2.svg`)
                            : require(`../assets/${option.toLowerCase()}_1.svg`);

                        return (
                            <Flex
                                key={option?.toLowerCase()}
                                align="center"
                                w="full"
                                p={2}
                                borderRadius="md"
                                _hover={{ bg: "rgba(255, 255, 255, 0.12)" }}
                                cursor="pointer"
                                bg={isActive ? "rgba(255, 255, 255, 0.12)" : "transparent"}
                                onClick={() => handleNavigation(option?.toLowerCase())}
                                onMouseEnter={() => setHoveredOption(option.toLowerCase())}
                                onMouseLeave={() => setHoveredOption(null)}
                            >
                                <Image src={imageSrc} alt={option?.toLowerCase()} boxSize="20px" mr={3} w="28px" h="28px" />
                                <Text
                                    sx={{
                                        fontFamily: "fontFamily",
                                        fontSize: "18px",
                                        fontWeight: "400",
                                        lineHeight: "30px",
                                        letterSpacing: "0.01em",
                                        textAlign: "left",
                                    }}
                                    color="rgba(255, 255, 255, 1)"
                                >
                                    {option}
                                </Text>
                            </Flex>
                        );
                    })}

                    {!isImpersonating && (
                        <Flex
                            key="logout"
                            align="center"
                            w="full"
                            p={2}
                            borderRadius="md"
                            _hover={{ bg: "rgba(255, 255, 255, 0.12)" }}
                            cursor="pointer"
                            bg="transparent"
                            onClick={() => {
                                localStorage.removeItem("token");
                                navigate("/login");
                            }}
                            onMouseEnter={() => setHoveredOption("logout")}
                            onMouseLeave={() => setHoveredOption(null)}
                        >
                            <Image
                                src={hoveredOption === "logout" ? LogoutImgHover : LogoutImg}
                                alt="logout"
                                boxSize="20px"
                                mr={3}
                                w="32px"
                                h="32px"
                                style={{
                                    filter: hoveredOption === "logout" ? "invert(50%) sepia(100%) saturate(500%) hue-rotate(90deg)" : "none",
                                }}
                            />
                            <Text
                                sx={{
                                    fontFamily: "fontFamily",
                                    fontSize: "20px",
                                    fontWeight: "400",
                                    lineHeight: "30px",
                                    letterSpacing: "-0.01em",
                                    textAlign: "left",
                                }}
                                color="rgba(255, 255, 255, 1)"
                            >
                                Logout
                            </Text>
                        </Flex>
                    )}
                </VStack>
            </Flex>

            <AddSpace
                spaceIsOpen={spaceIsOpen}
                spaceOnClose={spaceOnClose}
                step2spaceIsOpen={step2spaceIsOpen}
                step2spaceOnClose={step2spaceOnClose}
                step2spaceOnOpen={step2spaceOnOpen}
            />
        </Box>
    );
};

export default SideBar;
