import { Box, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerOverlay, Grid, Image, Text } from "@chakra-ui/react";
import React from "react";
import CustomButton from "./CustomButton";
import group from "../assets/Group176.png";

const Step2EditDetail = ({ step2DetailIsOpen, step2DetailOnClose, prevDetail }) => {
    const [placement] = React.useState("right");

    const handleDone = () => {
        step2DetailOnClose();
    };

    return (
        <>
            <Drawer placement={placement} onClose={step2DetailOnClose} isOpen={step2DetailIsOpen}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <DrawerBody p={0} m={0} align="center" position="relative">
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="10px"
                            minWidth="310px"
                            bg="#F8FBEE"
                            flexGrow={1}
                            pb="60px"
                        >
                            <Grid templateColumns={{ base: "1fr", md: "1fr " }} gap={4} alignItems="center" justifyItems="center">
                                <Box
                                    w="300px"
                                    h="117px"
                                    ml="20px"
                                    mr="20px"
                                    mt="42px"
                                    fontFamily="fontFamily"
                                    fontSize="19px"
                                    fontWeight="600"
                                    lineHeight="34px"
                                    letterSpacing="-0.01em"
                                    color="#11250E"
                                >
                                    <Text textAlign="center">
                                        Your account{" "}
                                        <Text as="span" color="#57BA47">
                                            {prevDetail?.email}{" "}
                                        </Text>
                                        has been updated.
                                    </Text>
                                </Box>

                                <Image src={group} alt="Placeholder Image" w="140px" h="100px" mt="2rem" mb="2rem" />
                            </Grid>
                        </Box>
                    </DrawerBody>

                    <DrawerFooter>
                        <CustomButton
                            width="100%"
                            h="40px"
                            bg="#57BA47"
                            sx={{
                                _hover: { bg: "#57BA47" },
                                fontFamily: "fontFamily",
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#FFFFFF",
                            }}
                            marginInline="auto"
                            onClick={handleDone}
                        >
                            <Text fontFamily="fontFamily" color="#FFFFFF" fontWeight="600" fontSize="14px" letterSpacing="-0.01em" lineHeight="20px">
                                Done
                            </Text>
                        </CustomButton>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default Step2EditDetail;
