import React from "react";
import { Avatar, Box, Grid, HStack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useToast, VStack } from "@chakra-ui/react";
import { RiArrowLeftSLine } from "react-icons/ri";
import userimg from "../assets/Ellipse.png";
import CustomButton from "../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import axios from "axios";
import { useImpersonation } from "../../contexts/useImpersonationContext";
import CustomToast from "../../components/CustomToast";

const fetchClientDetail = async (client) => {
    const res = await axios.get(`/admin/client/details`, { params: { client_id: client } });
    return res?.data?.data;
};
export const Description = {
    fontFamily: "fontFamily",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    textAlign: "left",
    color: "rgba(129, 134, 129, 1)",
};

// const ButtonTextStyle1 = {
//     bg: "#FFF",
//     border: "1px solid",
//     borderColor: "#57BA47",
//     padding: "8px 24px 8px 24px",
//     _hover: {
//         bg: "#FFF",
//     },
//     color: "#57BA47",
//     fontFamily: "fontFamily",
//     fontSize: "16px",

//     fontWeight: "600",
//     lineHeight: "24px",
//     letterSpacing: "-0.16px",
// };
export const organisation_name = {
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    color: "rgba(17, 37, 14, 1)",
};
export const other_name = {
    fontFamily: "fontFamily",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "16px",
    letterSpacing: "-0.01em",
    color: "rgba(17, 37, 14, 1)",
};
export const ButtonTextStyle2 = {
    bg: "rgba(87, 186, 71, 1)",
    border: "1px solid",
    borderColor: "#57BA47",
    padding: "8px 24px 8px 24px",
    _hover: {
        bg: "rgba(87, 186, 71, 1)",
    },
    color: "#FFF",
    fontFamily: "fontFamily",
    fontSize: "16px",

    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "-0.16px",
};

export const smallTextStyle = {
    fontFamily: "fontFamily",
    fonSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    align: "left",
    color: "#818681",
};
export const boldTextStyle = {
    fontFamily: "fontFamily",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    align: "left",
    color: "#11250E",
};

export const ClientProfile = () => {
    const queryClient = useQueryClient();
    const { checkImpersonation } = useImpersonation();
    const navigate = useNavigate();

    const toast = useToast();
    const { client } = useParams();

    const { data: MemberData, ...MemberDataQuery } = useQuery({
        queryKey: ["fetchClientDetail", client],
        queryFn: () => fetchClientDetail(client),
        enabled: !!client,
    });

    const impersonateMutation = useMutation({
        mutationFn: async function () {
            const response = await axios.get("/admin/impersonation-token", { params: { user_id: client } });
            return response.data;
        },
        onSuccess: function (data) {
            const impersonationToken = data?.data?.user_impersonation_token;

            // set stuff in session storage to indicate the app that it is impersonating an user
            sessionStorage.setItem("impersonating", true);
            sessionStorage.setItem("impersonation_token", impersonationToken);

            checkImpersonation();

            navigate("/dashboard");
        },
        onError: function (error) {},
    });

    const removeIndividual = async (client) => {
        if (client) {
            try {
                await axios.delete(`/admin/client/deleted/${client}`);
                queryClient.invalidateQueries("fetchClientDetail");
                toast({
                    id: client,
                    description: "Client deleted successfully.",
                    status: "success",
                    duration: 10000,
                    isClosable: true,
                    position: "top",
                    render: () => <CustomToast text={`Client deleted successfully.`} onClose={() => toast.close(client)} />,
                });
                navigate("/admin/dashboard/all-clients");
                console.log(`User ID ${client} deleted successfully.`);
            } catch (error) {
                alert("An error occurred while deleting the user.");
            }
        } else {
            alert("You do not have permission to delete this user.");
        }
    };

    // const handleViewHistory = () => {
    //     navigate(`/admin/dashboard/client-profile/${client}/viewhistory`);
    // };

    const handleBackDashboard = () => {
        navigate("/admin/dashboard/all-clients");
    };

    return (
        <>
            <HStack
                w="80%"
                h="110px"
                bg="rgba(246, 246, 246, 1)"
                justifyContent="space-between"
                position="fixed"
                top="0px"
                bottom="0px"
                zIndex="1000"
                boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
                paddingX="0.7rem"
            >
                <Box display=" flex" align="center" alignItems="center" gap="0.5rem" ml="3rem">
                    <RiArrowLeftSLine style={{ fontSize: "35px", cursor: "pointer" }} onClick={handleBackDashboard} />
                    <Text
                        fontFamily="fontFamily"
                        fontSize="28px"
                        fontWeight="700"
                        lineHeight="42px"
                        letterSpacing="-0.01em"
                        textAlign="left"
                        color="rgba(17, 37, 14, 1)"
                    >
                        {MemberData?.user_access?.[0]?.access_company?.name}
                    </Text>
                </Box>
                <HStack gap="1rem">
                    <Box>
                        <Text color="rgba(87, 186, 71, 1)" textDecoration="underline" cursor="pointer" onClick={() => removeIndividual(client)}>
                            Remove Client
                        </Text>
                    </Box>
                    <Box onClick={() => impersonateMutation.mutate()}>
                        <CustomButton w="80%" h="6vh" mr="2rem" sx={ButtonTextStyle2}>
                            Login to Dashboard
                        </CustomButton>
                    </Box>
                </HStack>
            </HStack>

            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minW="100%" mt="7rem">
                <Box
                    position="relative"
                    top="-4%"
                    w="90%"
                    pb="0.5rem"
                    mt="3rem"
                    borderRadius="16px 16px 16px 16px"
                    boxShadow="0px 4px 25px 0px #0000000D"
                    background="#FFFFFF"
                >
                    <Text
                        borderBottom="1px solid #E0E5E0"
                        color="#11250E"
                        fontWeight="600"
                        lineHeight="36px"
                        letterSpacing="-0.01em"
                        fontSize="24px"
                        fontFamily=" fontFamily"
                        ml="40px"
                        mt="16px"
                        mr="40px"
                        align="start"
                    >
                        Client Details
                    </Text>
                    <Grid gridTemplateColumns="repeat(2,1fr)" w="100%">
                        <VStack spacing={2} mt="40px" align="center" w="90%" ml="40px">
                            <HStack display="flex" w="100%" gap="2rem">
                                <Text
                                    w="30%"
                                    color="rgba(129, 134, 129, 1)
"
                                    fontWeight="500"
                                    lineHeight="24px"
                                    letterSpacing="-0.01em"
                                    fontSize="16px"
                                    fontFamily=" fontFamily"
                                >
                                    Company Name
                                </Text>
                                <Text
                                    as="span"
                                    fontFamily=" fontFamily"
                                    color="rgba(17, 37, 14, 1)"
                                    fontWeight="500"
                                    fontSize="16px"
                                    lineHeight="24px"
                                >
                                    {MemberData?.user_access?.[0]?.access_company?.name}
                                </Text>
                            </HStack>

                            <HStack w="100%" gap="2rem">
                                <Text
                                    w="30%"
                                    color="rgba(129, 134, 129, 1)
                                    "
                                    fontWeight="500"
                                    lineHeight="24px"
                                    letterSpacing="-0.01em"
                                    fontSize="16px"
                                    fontFamily=" fontFamily"
                                >
                                    Owner Name
                                </Text>
                                <Text
                                    as="span"
                                    fontFamily=" fontFamily"
                                    color="rgba(17, 37, 14, 1)"
                                    fontWeight="500"
                                    fontSize="16px"
                                    lineHeight="24px"
                                >
                                    {MemberData?.name}
                                </Text>
                            </HStack>

                            <HStack display="flex" w="100%" gap="2rem">
                                <Text
                                    w="30%"
                                    color="rgba(129, 134, 129, 1)
                                    "
                                    fontWeight="500"
                                    lineHeight="24px"
                                    letterSpacing="-0.01em"
                                    fontSize="16px"
                                    fontFamily=" fontFamily"
                                >
                                    Company Size
                                </Text>
                                <Text as="span">{MemberData?.user_access?.[0]?.access_company?.size}</Text>
                            </HStack>

                            <HStack display="flex" w="100%" gap="2rem">
                                <Text sx={smallTextStyle} minW="30%" mb="1.5rem">
                                    Street
                                </Text>
                                <Text sx={{ ...boldTextStyle }}>
                                    {`${MemberData?.user_access?.[0]?.access_company?.location?.address} , ${MemberData?.user_access?.[0]?.access_company?.location?.city} ,${MemberData?.user_access?.[0]?.access_company?.location?.state}, ${MemberData?.user_access?.[0]?.access_company?.location?.country}` ||
                                        "NA"}
                                </Text>
                            </HStack>

                            <HStack display="flex" justifyContent="space-between" w="100%" gap="2rem">
                                <Text
                                    minW="30%"
                                    color="rgba(129, 134, 129, 1)"
                                    fontWeight="500"
                                    lineHeight="24px"
                                    letterSpacing="-0.01em"
                                    fontSize="16px"
                                    fontFamily=" fontFamily"
                                    mb="1.5rem"
                                >
                                    Time Zone
                                </Text>
                                <Text sx={{ ...boldTextStyle }}> Canberra, Melbourne, Sydney ACT (GMT+10)</Text>
                            </HStack>
                        </VStack>
                        <VStack w="70%" display="flex" flexDirection="column" align="center" justify="center">
                            <Avatar size="xl" name="John Doe" w="120px" h="120px" src={userimg} />
                            <Box gap="5px" spacing="0.5rem" align="center">
                                <Text
                                    color="#11250E"
                                    fontWeight="600"
                                    lineHeight="24px"
                                    letterSpacing="-0.01em"
                                    fontSize="20px"
                                    fontFamily=" fontFamily"
                                >
                                    {MemberData?.name}
                                </Text>
                                <Text
                                    color="#818681"
                                    fontWeight="500"
                                    lineHeight="20px"
                                    letterSpacing="-0.01em"
                                    fontSize="14px"
                                    fontFamily=" fontFamily"
                                >
                                    {MemberData?.user_access?.[0]?.display_role
                                        ? MemberData?.user_access?.[0]?.display_role?.charAt(0).toUpperCase() +
                                          MemberData?.user_access?.[0]?.display_role?.slice(1).toLowerCase()
                                        : ""}
                                </Text>
                            </Box>
                        </VStack>
                    </Grid>
                </Box>

                <Box
                    position="relative"
                    mt="2rem"
                    pb="1.5rem"
                    w={{ base: "90%", sm: "90%", md: "90%", lg: "90%", xl: "90%", "2xl": "90%" }}
                    h={{ base: "", sm: "", md: "", lg: "", xl: "" }}
                    borderRadius="16px 16px 16px 16px"
                    boxShadow="0px 4px 25px 0px #0000000D"
                    background="#FFFFFF"
                >
                    <Text
                        borderBottom="1px solid #E0E5E0"
                        color="#11250E"
                        fontWeight="600"
                        lineHeight="36px"
                        letterSpacing="-0.01em"
                        fontSize="24px"
                        fontFamily=" fontFamily"
                        ml="40px"
                        mt="16px"
                        mr="40px"
                        align="start"
                    >
                        Login Details
                    </Text>

                    <VStack spacing={4} mt="15px" align="start" ml="40px">
                        <Box display="flex" align="start" gap="3rem">
                            <Text
                                color="rgba(129, 134, 129, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontFamily=" fontFamily"
                            >
                                Email Address
                            </Text>
                            <Text
                                color="rgba(17, 37, 14, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontFamily=" fontFamily"
                            >
                                {MemberData?.email}
                            </Text>
                        </Box>
                    </VStack>
                </Box>

                <Box
                    position="relative"
                    w={{ base: "90%", sm: "90%", md: "90%", lg: "90%", xl: "90%", "2xl": "90%" }}
                    borderRadius="16px 16px 16px 16px"
                    boxShadow="0px 4px 25px 0px #0000000D"
                    background="#FFFFFF"
                    mt="2rem"
                    pb="1.5rem"
                    mb="2rem"
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        w={{ base: "92%", sm: "92%", md: "92%", lg: "92%", xl: "93%", "2xl": "96%" }}
                        ml="2rem"
                    >
                        <Text
                            color="#11250E"
                            fontWeight="600"
                            lineHeight="36px"
                            letterSpacing="-0.01em"
                            fontSize="24px"
                            fontFamily=" fontFamily"
                            mt="16px"
                            mr="40px"
                            align="left"
                        >
                            Subscription Details
                        </Text>
                        {/* <Link
                            isExternal
                            textDecoration="underline"
                            fontFamily=" fontFamily"
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="20px"
                            color="#57BA47"
                            onClick={handleViewHistory}
                            mr={{ "2xl": "2rem" }}
                        >
                            View History
                        </Link> */}
                    </Box>

                    <VStack mt="40px" align="start" ml="40px">
                        <Box display="flex" align="start" gap="1rem" w="90%">
                            <Text
                                w="15%"
                                color="rgba(129, 134, 129, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontFamily=" fontFamily"
                            >
                                Subscription Plan
                            </Text>
                            <Text
                                color="rgba(17, 37, 14, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontFamily=" fontFamily"
                            >
                                Exclusive Access
                            </Text>
                        </Box>

                        {/* <Box display="flex" align="start" gap="1rem" w="90%">
                            <Text
                                w="15%"
                                color="rgba(129, 134, 129, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontFamily=" fontFamily"
                            >
                                Amount Paid
                            </Text>
                            <Text
                                color="rgba(17, 37, 14, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontFamily=" fontFamily"
                            >
                                $80
                            </Text>
                        </Box> */}
                        {/* <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            w={{ base: "92%", sm: "96%", md: "96%", lg: "96%", xl: "96%", "2xl": "96%" }}
                        >
                            <Box display="flex" align="start" gap="1rem" w="90%">
                                <Text
                                    w="15%"
                                    color="rgba(129, 134, 129, 1)"
                                    fontWeight="500"
                                    lineHeight="24px"
                                    letterSpacing="-0.01em"
                                    fontSize="16px"
                                    fontFamily=" fontFamily"
                                >
                                    Amount Paid
                                </Text>
                                <Text
                                    color="rgba(17, 37, 14, 1)"
                                    fontWeight="500"
                                    lineHeight="24px"
                                    letterSpacing="-0.01em"
                                    fontSize="16px"
                                    fontFamily=" fontFamily"
                                >
                                    12 July 2024
                                </Text>
                            </Box>

                            <CustomButton w={{ lg: "13%", xl: "9%", "2xl": "9%" }} h="4.5vh" sx={ButtonTextStyle1}>
                                Pay Now
                            </CustomButton>
                        </Box> */}
                    </VStack>
                </Box>

                {/*  */}

                <Box position="relative" w="90%" borderRadius="16px 16px 16px 16px" boxShadow="0px 4px 25px 0px #0000000D" background="#FFFFFF">
                    <Text
                        color="#11250E"
                        fontWeight="600"
                        lineHeight="36px"
                        letterSpacing="-0.01em"
                        fontSize="24px"
                        fontFamily=" fontFamily"
                        ml="40px"
                        mt="16px"
                        mr="40px"
                        align="start"
                    >
                        Space Details
                    </Text>

                    <TableContainer w="100%" mt="1rem">
                        <Table variant="simple">
                            <Thead bg="rgba(246, 246, 246, 1)" h="7vh">
                                <Tr>
                                    <Th w="10%" textAlign="center">
                                        Space Name
                                    </Th>
                                    <Th w="10%" textAlign="start">
                                        Location
                                    </Th>
                                    <Th w="10%" textAlign="start">
                                        Rooms
                                    </Th>
                                    <Th w="10%" textAlign="start">
                                        Devices
                                    </Th>
                                    <Th w="10%" textAlign="center">
                                        Team size
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody w="100%">
                                {MemberData?.user_space_access &&
                                    MemberData.user_space_access.map((space, index) => {
                                        return (
                                            <Tr key={index}>
                                                <Td textAlign="center" sx={organisation_name}>
                                                    {space?.space_access_space?.name}
                                                </Td>
                                                <Td textAlign="start" sx={other_name}>
                                                    {[
                                                        space?.space_access_space?.location?.address,
                                                        space?.space_access_space?.location?.city,
                                                        space?.space_access_space?.location?.state,
                                                        space?.space_access_space?.location?.country,
                                                    ]
                                                        .filter(Boolean)
                                                        .join(", ") || "N/A"}
                                                </Td>
                                                <Td textAlign="start" sx={other_name}>
                                                    {space?.space_access_space?.name}
                                                </Td>
                                                <Td textAlign="start" sx={other_name}>
                                                    {space?.space_access_space?.name}
                                                </Td>
                                                <Td textAlign="center" sx={other_name}>
                                                    {space?.space_access_space?.space_access_company?.size}
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </>
    );
};
