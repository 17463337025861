import {
    Box,
    CircularProgress,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Text,
    useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomButton from "./CustomButton";
import axios from "axios";
import CustomToast from "./CustomToast";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useQueryParams } from "use-query-params";
import { DEVICE_QUERY_PARAMS } from "../lib/queriesParams";
import { SEARCH_PARAMS } from "../constants";
import { useParams } from "react-router-dom";

const DeleteDevice = () => {
    const queryClient = useQueryClient();
    const { space } = useParams();
    const toast = useToast();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useQueryParams(DEVICE_QUERY_PARAMS);
    const isOpen = query[SEARCH_PARAMS.DEVICE.DELETE.key] !== "";

    const clearQuery = () => {
        setQuery({
            [SEARCH_PARAMS.DEVICE.DELETE.key]: SEARCH_PARAMS.DEVICE.DELETE.default_value,
            [SEARCH_PARAMS.DEVICE.DELETE_STATUS.key]: SEARCH_PARAMS.DEVICE.DELETE_STATUS.default_value,
        });
    };

    const { data: deviceToDelete } = useQuery({
        queryKey: ["fetchDevices", space],
        enabled: false,
        enabled: !!space,
        select: (data) => data.data?.find((item) => item.id === query[SEARCH_PARAMS.DEVICE.DELETE.key]),
    });

    const handleDone = async () => {
        setLoading(true);
        setError("");
        try {
            const res = await axios.delete(`/device`, {
                params: { device_id: deviceToDelete.id },
            });
            if (res.status >= 200 && res.status < 300) {
                const id = `_${deviceToDelete.id}`;
                toast({
                    id,
                    description: "Device deleted successfully.",
                    status: "success",
                    duration: 10000,
                    isClosable: true,
                    position: "top",
                    render: () => <CustomToast text={`Device deleted successfully.`} onClose={() => toast.close(id)} />,
                });
                clearQuery();
                queryClient.refetchQueries(["fetchDevices", space]);
            } else {
                setError(res?.data?.error || "Error in deleting device.");
            }
        } catch (error) {
            console.error("Error in deleting device:", error);
            setError("An unexpected error occurred.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Drawer placement="right" isOpen={isOpen} onClose={clearQuery}>
            <DrawerOverlay />
            <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                <Box mt={{ base: "0px", sm: "0px", md: "5px", lg: "10px" }} mr="10px">
                    <DrawerCloseButton sx={{ color: "#11250E", p: "8px", bg: "#F6F6F6" }} />
                    <DrawerHeader
                        fontFamily="fontFamily"
                        fontSize="28px"
                        fontWeight="800"
                        lineHeight="42px"
                        letterSpacing="-0.04em"
                        textAlign="left"
                        color="#11250E"
                    >
                        Delete Device
                    </DrawerHeader>
                </Box>

                <DrawerBody p={0} align="center">
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="10px"
                        minWidth="310px"
                        fontFamily="fontFamily"
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="30px"
                        letterSpacing="-0.01em"
                        color="#11250E"
                    >
                        <Text textAlign="center" m="20px">
                            Are you sure you want to delete the{" "}
                            <Text as="span" color="#57BA47">
                                {deviceToDelete?.name}{" "}
                            </Text>
                            from room{" "}
                            <Text as="span" color="#57BA47">
                                {deviceToDelete?.roomDevice?.name}?{" "}
                            </Text>
                            All of your data related to this room will be automatically deleted.
                        </Text>
                    </Box>
                </DrawerBody>

                <DrawerFooter>
                    <Flex direction="column" justifyContent="center" gap={2} width="100%">
                        {error && (
                            <Text color="red.500" display="flex" justifyContent="center">
                                {error}
                            </Text>
                        )}
                        <CustomButton
                            width="100%"
                            h="40px"
                            bg="#57BA47"
                            sx={{
                                _hover: { bg: "#57BA47" },
                                fontFamily: "fontFamily",
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#FFFFFF",
                            }}
                            marginInline="auto"
                            onClick={handleDone}
                        >
                            {loading ? <CircularProgress isIndeterminate size="24px" color="#FFFFFF" /> : "Yes, Delete Device"}
                        </CustomButton>
                    </Flex>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};

export default DeleteDevice;
