import { Button } from "@chakra-ui/react";
import React from "react";

const CustomButton = ({ children, variant, size, bg, color, boxShadow, padding, disabled, isLoading, borderRadius, ...props }) => {
    return (
        <Button
            variant={variant}
            size={size}
            bg={bg}
            boxShadow={boxShadow}
            padding={padding}
            color={color}
            borderRadius={borderRadius}
            isLoading={isLoading}
            isDisabled={disabled}
            _hover={{
                bg: bg || "transparent", // Ensure default hover background color
                color: color || "inherit", // Ensure default hover text color
                // Add any other hover styles here if needed
            }}
            {...props}
        >
            {children}
        </Button>
    );
};

export default CustomButton;
