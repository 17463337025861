import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Grid,
    Image,
    Text,
} from "@chakra-ui/react";
import React from "react";
import CustomButton from "../../components/CustomButton";
import addclientsucess from "../assets/addclient sucess.svg";

const Step2AddClient = ({ step2IsOpen, step2onClose }) => {
    const btnRef = React.useRef();

    return (
        <>
            <Drawer isOpen={step2IsOpen} placement="right" onClose={step2onClose} finalFocusRef={btnRef} size={"xs"}>
                <DrawerOverlay />

                <DrawerContent borderRadius="10px" m="12px" minWidth={"380px"}>
                    <DrawerCloseButton
                        sx={{ color: "#11250E", p: "1px", bg: "#F6F6F6" }}
                        mt={{ base: 0, sm: 0, md: 0, lg: "29px" }}
                        mr={{ base: 0, sm: 0, md: 0, lg: "10px" }}
                    />
                    <DrawerHeader
                        fontFamily="fontFamily"
                        fontSize="28px"
                        fontWeight="700"
                        lineHeight="42px"
                        letterSpacing="-0.01em"
                        textAlign="left"
                        color="#11250E"
                        mt={{ base: 0, sm: 0, md: 0, lg: "22px" }}
                        ml={{ base: 0, sm: 0, md: 0, lg: "2px" }}
                    ></DrawerHeader>
                    <DrawerBody borderRadius="10px">
                        {/*  */}
                        <Box
                            w="300px"
                            mt="45px"
                            fontFamily="fontFamily"
                            fontSize="19px"
                            fontWeight="600"
                            lineHeight="34px"
                            letterSpacing="-0.01em"
                            color="#11250E"
                        >
                            <Text textAlign="center">
                                Congratulations,
                                <Text as="span" color="#57BA47">
                                    XYZ, Client
                                </Text>{" "}
                                has been added as a new member
                            </Text>
                        </Box>

                        {/*  */}
                        <Box w="220.35px" h="20%" margin="auto" mt="2rem">
                            <Image src={addclientsucess} alt="addclient-sucess" w="100%" />
                        </Box>

                        <Grid column={1} justifyContent="center" mt="7rem">
                            <Text mb="0.5rem" ml="1.5rem">
                                User can login using Username & Password.
                            </Text>

                            <Box w="379.7px" h="100px" mb="1.5rem" backgroundColor="#F8F8F8">
                                <Box ml="32px" mr="32px" mt="20px">
                                    <Box display="flex" mb="16px">
                                        <Text
                                            fontFamily="fontFamily"
                                            fontSize="18px"
                                            fontWeight="500"
                                            lineHeight="20px"
                                            letterSpacing=" -0.01em"
                                            color="rgba(0, 0, 0, 1)"
                                        >
                                            Email-
                                        </Text>{" "}
                                        <Text
                                            fontFamily="fontFamily"
                                            color="rgba(0, 0, 0, 1)"
                                            fontSize="18px"
                                            fontWeight="500"
                                            lineHeight="20px"
                                            letterSpacing=" -0.01em"
                                        >
                                            xyaz@gmail.com
                                        </Text>
                                    </Box>

                                    <Box display="flex">
                                        <Text
                                            fontFamily="fontFamily"
                                            fontSize="18px"
                                            fontWeight="500"
                                            lineHeight="20px"
                                            letterSpacing=" -0.01em"
                                            color="rgba(0, 0, 0, 1)"
                                        >
                                            Password-{" "}
                                        </Text>{" "}
                                        <Text
                                            fontFamily="fontFamily"
                                            color="rgba(0, 0, 0, 1)"
                                            fontSize="18px"
                                            fontWeight="500"
                                            lineHeight="20px"
                                            letterSpacing=" -0.01em"
                                        >
                                            qwerty12345
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                            <CustomButton
                                w="20rem"
                                h="2.5rem"
                                bg="#57BA47"
                                ml="32.3px"
                                mt="1.5rem"
                                sx={{
                                    _hover: {
                                        bg: "#57BA47",
                                    },
                                }}
                                onClick={step2onClose}
                            >
                                <Text
                                    fontFamily="fontFamily"
                                    fontSize="14px"
                                    fontWeight="600"
                                    lineHeight="20px"
                                    letterSpacing=" -0.01em"
                                    color="#FFFFFF"
                                >
                                    Done
                                </Text>
                            </CustomButton>
                        </Grid>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default Step2AddClient;
