import { Box, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerOverlay, Grid, Image, Text } from "@chakra-ui/react";
import React from "react";
import CustomButton from "./CustomButton";
import group from "../assets/Group176.png";
import { useQueryParams } from "use-query-params";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ROOM_QUERY_PARAMS } from "../lib/queriesParams";
import { SEARCH_PARAMS } from "../constants";

const Step2AddRoom = () => {
    const { space } = useParams();
    const [query, setQuery] = useQueryParams(ROOM_QUERY_PARAMS);
    const isOpen = query[SEARCH_PARAMS.ROOM.ADD_STATUS.key] === "success";

    const clearQuery = () => {
        setQuery({
            [SEARCH_PARAMS.ROOM.ADD.key]: SEARCH_PARAMS.ROOM.ADD.default_value,
            [SEARCH_PARAMS.ROOM.ADD_STATUS.key]: SEARCH_PARAMS.ROOM.ADD_STATUS.default_value,
        });
    };

    const { data: newMemeber, roomsQuery } = useQuery({
        queryKey: ["fetchRooms", space],
        enabled: false,
        select: function (data) {
            const newMemeber = data?.data?.find((item) => item?.id === query?.[SEARCH_PARAMS.ROOM.ADD.key]);
            return newMemeber;
        },
    });

    return (
        <>
            <Drawer placement="right" isOpen={isOpen} onClose={clearQuery}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <DrawerBody p={0} m={0} align="center" position="relative">
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="10px"
                            minWidth="310px"
                            bg="#F8FBEE"
                            flexGrow={1}
                            pb="60px"
                        >
                            <Grid templateColumns={{ base: "1fr", md: "1fr " }} gap={4} alignItems="center" justifyItems="center">
                                <Box
                                    w="300px"
                                    h="117px"
                                    ml="20px"
                                    mr="20px"
                                    mt="42px"
                                    fontFamily="fontFamily"
                                    fontSize="19px"
                                    fontWeight="600"
                                    lineHeight="34px"
                                    letterSpacing="-0.01em"
                                    color="#11250E"
                                >
                                    <Text textAlign="center">
                                        Congratulations,
                                        <Text as="span" color="#57BA47">
                                            Room {newMemeber?.name}
                                        </Text>{" "}
                                        has been added.
                                    </Text>
                                </Box>

                                <Image src={group} alt="Placeholder Image" w="140px" h="100px" mt="2rem" mb="2rem" />
                            </Grid>
                        </Box>
                    </DrawerBody>

                    <DrawerFooter>
                        <CustomButton
                            width="100%"
                            h="40px"
                            bg="#57BA47"
                            sx={{
                                _hover: { bg: "#57BA47" },
                                fontFamily: "fontFamily",
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#FFFFFF",
                            }}
                            marginInline="auto"
                            onClick={clearQuery}
                        >
                            <Text fontFamily="fontFamily" color="#FFFFFF" fontWeight="600" fontSize="14px" letterSpacing="-0.01em" lineHeight="20px">
                                Done
                            </Text>
                        </CustomButton>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default Step2AddRoom;
