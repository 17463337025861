import React, { createContext, useContext, useState } from 'react';

const ActiveUsersContext = createContext();

export const ActiveUsersProvider = ({ children }) => {
  const [activeUsers, setActiveUsers] = useState([]);

  return (
    <ActiveUsersContext.Provider value={{ activeUsers, setActiveUsers }}>
      {children}
    </ActiveUsersContext.Provider>
  );
};

export const useActiveUsers = () => useContext(ActiveUsersContext);