import { Box, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerOverlay, Image, Text } from "@chakra-ui/react";
import React from "react";
import CustomButton from "./CustomButton";
import addMemberimage from "../assets/addMemberimage.png";
import { useQueryParams } from "use-query-params";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { MEMBER_QUERY_PARAMS } from "../lib/queriesParams";
import { SEARCH_PARAMS } from "../constants";
import memberupdated from "../assets/details_updated.svg";

const Step2EditMember = () => {
    const { space } = useParams();
    const [query, setQuery] = useQueryParams(MEMBER_QUERY_PARAMS);
    const isOpen = query[SEARCH_PARAMS.MEMBER.EDIT_STATUS.key] === "success";

    const clearQuery = () => {
        setQuery({
            [SEARCH_PARAMS.MEMBER.EDIT.key]: SEARCH_PARAMS.MEMBER.EDIT.default_value,
            [SEARCH_PARAMS.MEMBER.EDIT_STATUS.key]: SEARCH_PARAMS.MEMBER.EDIT_STATUS.default_value,
        });
    };

    const { data: prevMember, membersQuery } = useQuery({
        queryKey: ["fetchMembers", space],
        enabled: false,
        select: function (data) {
            const prevMember = data?.data?.find((item) => item?.id === query?.[SEARCH_PARAMS.MEMBER.EDIT.key]);
            return prevMember;
        },
    });

    return (
        <Drawer placement="right" isOpen={isOpen} onClose={clearQuery}>
            <DrawerOverlay />

            <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                <DrawerBody p={0} m={0} align="center" position="relative">
                    <Box bg="#F8FBEE">
                        <Box
                            h="170px"
                            width="220px"
                            display="flex"
                            alignItems="left"
                            justifyContent="center"
                            py="22px"
                            color="#11250E"
                            fontFamily="fontFamily"
                            fontSize="20px"
                            fontWeight="600"
                            lineHeight="25px"
                            letterSpacing=" -0.01em"
                            ml="0.5rem"
                            align="center"
                        >
                            <Text textAlign="center">
                                Member
                                <Text as="span" color="#57BA47" ml="0.4rem">
                                    {prevMember?.name}'s
                                </Text>
                                {"  "}
                                information has been updated.
                            </Text>
                        </Box>

                        <Box w="220px" h="170px" mt="40px" ml="40px">
                            <Image src={memberupdated} alt="" w="100%" />
                        </Box>
                    </Box>

                    <Box ml="32px" mr="32px" marginY="20px">
                        <Text
                            fontFamily="fontFamily"
                            fontSize="13px"
                            fontWeight="600"
                            lineHeight="20px"
                            letterSpacing=" -0.01%"
                            align="center"
                            color="#11250E"
                        >
                            The details associated with the member profile have been successfully edited.
                        </Text>
                    </Box>
                </DrawerBody>

                <DrawerFooter>
                    <CustomButton
                        width="100%"
                        h="40px"
                        bg="#57BA47"
                        sx={{
                            _hover: { bg: "#57BA47" },
                            fontFamily: "fontFamily",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#FFFFFF",
                        }}
                        marginInline="auto"
                        onClick={clearQuery}
                    >
                        <Text fontFamily="fontFamily" color="#FFFFFF" fontWeight="600" fontSize="14px" letterSpacing="-0.01em" lineHeight="20px">
                            Done
                        </Text>
                    </CustomButton>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};

export default Step2EditMember;
