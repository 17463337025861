import React, { useEffect, useState } from "react";
import { Box, Flex, Grid, HStack } from "@chakra-ui/react";
import { VStack, Text, Input, Image, GridItem } from "@chakra-ui/react";
import CustomButton from "../components/CustomButton";
import userIcon from "../assets/user.png";
import owner from "../assets/owner.svg";
import logo from "../assets/logo.svg";
import Manager from "../assets/Manager.svg";
import Employee from "../assets/optionEmp.svg";
import Farmer from "../assets/Farmer.svg";
import Caretaker from "../assets/caretaker.svg";
import chef from "../assets/chef.svg";
import { Onboarding3 } from "./Onboarding3";
import { Reveal } from "../common/Reveal";
import graphSkeleton from "../assets/graphSkeleton.svg";
import { useDispatch, useSelector } from "react-redux";
import { Toggle_FrstCircleCheckFunction, Toggle_Step2Function } from "../redux/action.js";
import { useQuery } from "@tanstack/react-query";

const RoleBox = ({ src, alt, label, width, bg, handleClick }) => {
    return (
        <Box
            border="1px solid"
            borderColor="#DDE2DD"
            display="flex"
            alignItems="center"
            justifyContent="center"
            w={width}
            gap="8px"
            borderRadius="24px"
            px={{ base: "16px", sm: "16px", lg: "16px", xl: "0px" }}
            bg={bg}
            onClick={() => handleClick(label)}
            h="7vh"
            cursor="pointer"
        >
            <Image src={src} alt={alt} minW={{ base: "25px", sm: "25px", lg: "25px", xl: "0px" }} />
            <Text
                fontFamily="fontFamily"
                fontSize={{ base: "14px", sm: "14px", lg: "14px", xl: "18px" }}
                fontWeight="600"
                lineHeight="20px"
                letterSpacing="-0.01em"
                textAlign="left"
            >
                {label}
            </Text>
        </Box>
    );
};

export const Onboarding2 = ({ yourname }) => {
    const [designation, setDesignation] = useState("");
    const Step2 = useSelector((store) => store.ConditionalRenderingReducer.step2);
    const dispatch = useDispatch();

    const { data: companyAccess, ...userQuery } = useQuery({
        queryKey: "fetchProfile",
        enabled: false,
        select: function (data) {
            return data?.data?.company?.company_access;
        },
    });

    useEffect(() => {
        if (companyAccess) {
            setDesignation(companyAccess?.display_role);
        }
    }, [companyAccess]);

    const ButtonTextStyle1 = {
        bg: "#FFF",
        border: "1px solid",
        borderColor: "#57BA47",
        padding: "8px 24px 8px 24px",
        _hover: {
            bg: "#FFF",
        },
        color: "#57BA47",
        fontFamily: "fontFamily",
        fontSize: "16px",

        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };
    const ButtonTextStyle2 = {
        bg: "#57BA47",
        padding: "8px 24px 8px 24px",
        _hover: {
            bg: "#57BA47",
        },
        color: " #FFF",
        fontFamily: "fontFamily",
        fontSize: "16px",

        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };

    const handleRoleClick = (label) => {
        setDesignation(label);
    };

    const handleNextPage = () => {
        dispatch(Toggle_Step2Function());
    };

    const handlePrevious1 = () => {
        dispatch(Toggle_FrstCircleCheckFunction());
    };
    return (
        <>
            {Step2 ? (
                <Onboarding3 designation={designation} yourname={yourname} />
            ) : (
                <Grid w="100%" h="100vh" bg="#F2F2F266" m={0} p={0} gridTemplateColumns="repeat(2,1fr)">
                    <VStack w="90%" m={0} p={0} display="flex" flexDirection="column" ml="5rem" align="start">
                        <Box w="100%" display="flex" flexDirection="column" gap="1rem" mt="4rem">
                            <Box>
                                <Text color="#11250E" fontFamily="fontFamily" fontSize="28px" fontWeight="700" lineHeight="42px" ml="1rem">
                                    What describes you best?
                                </Text>
                            </Box>

                            <Box>
                                <Input
                                    placeholder="type here"
                                    type="text"
                                    border="none"
                                    size="lg"
                                    pt="2rem"
                                    pb="2rem"
                                    color="rgba(129, 134, 129, 1)"
                                    fontFamily="fontFamily"
                                    fontSize="40px"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    lineHeight="60px"
                                    letterSpacing="-0.4px"
                                    textAlign="left"
                                    value={designation}
                                    _focus={{ border: "none", boxShadow: "none" }}
                                    _placeholder={{
                                        color: "rgba(129, 134, 129, 0.50)",
                                        fontFamily: "fontFamily",
                                        fontSize: "40px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "60px",
                                        letterSpacing: "-0.4px",
                                        textAlign: "left",
                                    }}
                                />
                            </Box>
                            <Reveal hidden={{ opacity: 0, y: 40 }} visible={{ opacity: 1, y: 0 }} delay={0.1}>
                                <Flex flexDirection="column" gap="0.5rem">
                                    <HStack spacing="1rem" ml="1rem">
                                        <RoleBox
                                            src={owner}
                                            alt="owner"
                                            bg={designation === "OWNER" ? "rgba(221, 241, 218, 1)" : "transparent"}
                                            borderColor={designation === "OWNER" ? "none" : "#DDE2DD"}
                                            label="Owner"
                                            width="23%"
                                            handleClick={handleRoleClick}
                                        />
                                        <RoleBox
                                            src={Manager}
                                            bg={designation === "MANAGER" ? "rgba(221, 241, 218, 1)" : "transparent"}
                                            alt="manager"
                                            label="Manager"
                                            width="25%"
                                            handleClick={handleRoleClick}
                                        />
                                        <RoleBox
                                            src={Employee}
                                            bg={designation === "EMPLOYEE" ? "rgba(221, 241, 218, 1)" : "transparent"}
                                            alt="employee"
                                            label="Employee"
                                            width="29%"
                                            handleClick={handleRoleClick}
                                        />
                                        <RoleBox
                                            src={Farmer}
                                            bg={designation === "FARMER" ? "rgba(221, 241, 218, 1)" : "transparent"}
                                            alt="farmer"
                                            label="Farmer"
                                            width="24%"
                                            handleClick={handleRoleClick}
                                        />
                                    </HStack>

                                    <HStack spacing="1rem" ml="1rem">
                                        <RoleBox
                                            src={Caretaker}
                                            bg={designation === "CARETAKER" ? "rgba(221, 241, 218, 1)" : "transparent"}
                                            alt="caretaker"
                                            label="Caretaker"
                                            width="24%"
                                            handleClick={handleRoleClick}
                                        />
                                        <RoleBox
                                            src={chef}
                                            bg={designation === "CHEF" ? "rgba(221, 241, 218, 1)" : "transparent"}
                                            alt="chef"
                                            label="Chef"
                                            width="19%"
                                            handleClick={handleRoleClick}
                                        />
                                    </HStack>
                                </Flex>
                            </Reveal>
                            <Box display="flex" justifyContent="space-between" ml="1rem" w="97%" mt="1rem">
                                <CustomButton w="15%" sx={ButtonTextStyle1} onClick={handlePrevious1}>
                                    Previous
                                </CustomButton>

                                <CustomButton w="15%" sx={ButtonTextStyle2} onClick={handleNextPage} disabled={!designation}>
                                    Next
                                </CustomButton>
                            </Box>
                        </Box>
                    </VStack>

                    <VStack display="flex" alignItems="start">
                        <Grid
                            w={{ base: "80%", sm: "80%", md: "80%", lg: "80%", xl: "80%" }}
                            minH={{ base: "44vh", sm: "44vh", md: "44vh", lg: "44vh", xl: "44vh" }}
                            ml="3rem"
                            borderRadius="16px 16px 0px 0px"
                            background="#F2F2F2"
                            pt="2rem"
                            pl="2rem"
                            pr="2rem"
                            gridTemplateColumns="30% auto"
                            mt="4rem"
                        >
                            <GridItem background="#11250E" borderRadius="8px 0px 0px 0px" align="center">
                                <Image src={logo} alt="" w="19px" h="15px" mt="15%" />
                                <Box
                                    borderRadius="50%"
                                    overflow="hidden"
                                    width="50px"
                                    height="50px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    background="#F2F2F2"
                                    mt="30%"
                                >
                                    <Image src={userIcon} alt="" />
                                </Box>
                                <Text
                                    mt="0.5rem"
                                    color="rgba(251, 251, 251, 1)"
                                    fontFamily="fontFamily"
                                    fontSize="12px"
                                    fontWeight="600"
                                    lineHeight="16px"
                                    letterSpacing="-0.01em"
                                >
                                    {yourname ? yourname : "Your Name"}
                                </Text>
                                <Text
                                    mt="0.3rem"
                                    color="rgba(242, 242, 242, 0.50)"
                                    fontFamily="fontFamily"
                                    fontSize="10px"
                                    fontWeight="600"
                                    lineHeight="16px"
                                    letterSpacing="-0.01em"
                                >
                                    {designation}
                                </Text>
                            </GridItem>
                            <GridItem borderRadius="0px 8px 0px 0px" bg="#FFFFFF" pl="1.5rem" pt="1.5rem" pr="1.5rem">
                                <Reveal hidden={{ opacity: 0, y: -60 }} visible={{ opacity: 1, y: 0 }} delay={0.1}>
                                    <Grid gridTemplateColumns="auto auto auto" columnGap="1rem" ml="0.5rem">
                                        <GridItem bg="rgba(242, 242, 242, 1)" h="44px" borderRadius="4px 4px 4px"></GridItem>
                                        <GridItem bg="rgba(242, 242, 242, 1)" h="44px" borderRadius="4px 4px 4px"></GridItem>
                                        <GridItem bg="rgba(242, 242, 242, 1)" h="44px" borderRadius="4px 4px 4px"></GridItem>
                                    </Grid>
                                    <Grid
                                        gridTemplateColumns="40% 60%"
                                        ml="0.5rem"
                                        bg="rgba(242, 242, 242, 1)"
                                        mt="2rem"
                                        borderRadius="8px 8px 0px 0px"
                                    >
                                        <GridItem h="26.4vh" pt="2rem">
                                            <VStack
                                                pl="0.5rem"
                                                pr="0.5rem"
                                                spacing="1rem" // Add this line for spacing between VStack items
                                            >
                                                <VStack
                                                    w="90%"
                                                    h={{ base: "35px", sm: "35px", md: "35px", lg: "30px", xl: "39px" }}
                                                    bg="rgba(255, 255, 255, 1)"
                                                    borderRadius="4px 4px 4px"
                                                ></VStack>
                                                <VStack
                                                    w="90%"
                                                    h={{ base: "35px", sm: "35px", md: "35px", lg: "30px", xl: "39px" }}
                                                    bg="rgba(255, 255, 255, 1)"
                                                    borderRadius="4px 4px 4px"
                                                ></VStack>
                                                <VStack
                                                    w="90%"
                                                    h={{ base: "35px", sm: "35px", md: "35px", lg: "30px", xl: "39px" }}
                                                    bg="rgba(255, 255, 255, 1)"
                                                    borderRadius="4px 4px 4px"
                                                ></VStack>
                                            </VStack>
                                        </GridItem>
                                        <GridItem gridTemplateColumns="100%">
                                            <VStack
                                                w="90%"
                                                h={{ base: "120px", sm: "120px", md: "120px", lg: "120px", xl: "150px" }}
                                                bg="rgba(255, 255, 255, 1)"
                                                mt="2rem"
                                                borderRadius="4px 4px 4px"
                                            >
                                                <Image
                                                    src={graphSkeleton}
                                                    alt=""
                                                    w="90%"
                                                    position="relative"
                                                    top={{ base: "50%", sm: "50%", md: "50%", lg: "50%", xl: "15%" }}
                                                />
                                            </VStack>
                                        </GridItem>
                                    </Grid>
                                </Reveal>
                            </GridItem>
                        </Grid>
                    </VStack>
                </Grid>
            )}
        </>
    );
};
