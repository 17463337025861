import React, { useRef, useEffect, useState } from "react";
import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Image,
    Input,
    Text,
    useDisclosure,
    Tooltip,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import temp from "../../src/assets/temsvg.svg";
import humidity from "../../src/assets/drop.svg";
import { Switch } from "@chakra-ui/react";
import onboarding_pair_device from "../assets/configure_sensor.svg";
import CustomButton from "../../src/components/CustomButton";
import disable_info from "../../src/assets/disable_info_icon.svg";
import info from "../../src/assets/info_Icon.svg";
import Step2AddDevice from "./step2AddDevice";
import { useMutation } from "@tanstack/react-query";
import Axios from "axios";
import { useParams } from "react-router-dom";

const HumidityHumidity = undefined;

const fetchDeviceById = async (id) => {
    const response = await Axios.get(`/device`, { params: { device_id: id } });
    return response.data;
};

export const Step3AddDevice = ({ step2IsOpen, step2onClose, deviceId }) => {
    const inputRef = useRef(null);
    const btnRef = React.useRef();
    const [prevDevice, setPrevDevice] = useState({});
    const { space, room } = useParams();
    const [inputWidth, setInputWidth] = useState("auto");
    const { isOpen: step3IsOpen, onOpen: step3onOpen, onClose: step3onClose } = useDisclosure();

    const labelField = {
        color: "rgba(17, 37, 14, 1)",
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "30px",
        letterSpacing: "-0.1%",
    };
    const InputField = {
        border: "1.5px solid rgba(129, 134, 129, 1)",
        _placeholder: {
            fontFamily: "fontFamily",
            color: "rgba(129, 134, 129, 1)",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "20px",
            letterSpacing: "-0.01em",
            alignContent: "start",
        },
        _focus: {
            border: "1.5px solid rgba(129, 134, 129, 1)",
            borderColor: "none",
            boxShadow: "none",
        },
        _hover: {
            border: "1.5px solid rgba(129, 134, 129, 1)",
        },
    };
    const validation = useFormik({
        initialValues: {
            minLimitOne: prevDevice?.data?.sensor_one?.min_value || 0,
            maxLimitOne: prevDevice?.data?.sensor_one?.max_value || 0,
            thresholdOne: prevDevice?.data?.sensor_one?.in_range_threshold || 0,
            sensorOneEnabled: prevDevice?.data?.sensor_one_enabled || false,
            minLimitTwo: prevDevice?.data?.sensor_two?.min_value || 0,
            maxLimitTwo: prevDevice?.data?.sensor_two?.max_value || 0,
            thresholdTwo: prevDevice?.data?.sensor_two?.in_range_threshold || 0,
            sensorTwoEnabled: prevDevice?.data?.sensor_two_enabled || false,
        },
        validationSchema: Yup.object({
            minLimitOne: Yup.number().required("min limit is required"),
            minLimitTwo: Yup.number().required("min limit is required"),
            maxLimitOne: Yup.number().required("max limit is required"),
            maxLimitTwo: Yup.number().required("max limit is required"),
            thresholdOne: Yup.number().min(1).required("threshold is required"),
            thresholdTwo: Yup.number().min(1).required("threshold is required"),
        }),
        onSubmit: (values) => {
            updateDeviceMutation.mutate(values);
            handle_add_device_nextpage();
        },
        enableReinitialize: true,
    });

    useEffect(() => {
        const res = fetchDeviceById(deviceId);
        res.then((data) => setPrevDevice(data));
    }, []);

    const handleToggle = (field) => () => {
        validation.setFieldValue(field, !validation.values[field]);
    };

    const handle_add_device_nextpage = () => {
        step2onClose();
        step3onOpen();
    };

    const updateDeviceMutation = useMutation({
        mutationFn: async (values) => {
            const response = await Axios.patch(
                `/device`,
                {
                    name: prevDevice?.data?.name,
                    room: prevDevice?.data?.room || room,
                    sensor_one_data: { min_value: values.minLimitOne, max_value: values.maxLimitOne, in_range_threshold: values.thresholdOne },
                    sensor_two_data: { min_value: values.minLimitTwo, max_value: values.maxLimitTwo, in_range_threshold: values.thresholdTwo },
                    sensor_one_enabled: values.sensorOneEnabled,
                    sensor_two_enabled: values.sensorTwoEnabled,
                },
                {
                    params: { device_id: prevDevice?.data?.id },
                },
            );
            return response;
        },
    });

    return (
        <>
            <Drawer isOpen={step2IsOpen} placement="right" onClose={step2onClose} finalFocusRef={btnRef} size={"xs"}>
                <DrawerOverlay />

                <DrawerContent minWidth={{ base: 380, md: 480, lg: 500 }} my="5px" marginRight="1rem" borderRadius="10px" overflow="auto">
                    <Box mt={{ base: "0px", sm: "0px", md: "5px", lg: "10px" }} mr="20px">
                        <DrawerHeader
                            fontFamily="fontFamily"
                            fontSize="28px"
                            fontWeight="800"
                            lineHeight="42px"
                            letterSpacing="-0.04em"
                            textAlign="left"
                            color="#11250E"
                            pt="2rem"
                        >
                            Configure Sensors
                        </DrawerHeader>
                    </Box>

                    <DrawerBody>
                        <GridItem display="flex" justifyContent="center" alignItems="center">
                            <Box mb="1.5rem" mt="1.5rem">
                                <Image src={onboarding_pair_device} alt="deviceAdd" w="160.28px" h="214px" />
                            </Box>
                        </GridItem>

                        <Box w="100%" mb="1.5rem">
                            <Flex w="100%" justifyContent="space-between">
                                <Flex minW="90%" gap={2} align="center">
                                    <Image
                                        src={prevDevice?.data?.sensor_one?.sensor_type === HumidityHumidity ? humidity : temp}
                                        alt=""
                                        w=" 20px"
                                        h="20px"
                                    />
                                    <Text
                                        color="rgba(17, 37, 14, 1)"
                                        fontFamily="fontFamily"
                                        fontSize="16px"
                                        lineHeight="30px"
                                        letterSpacing="-0.01%"
                                        fontWeight={600}
                                    >
                                        Sensor 01{" "}
                                        {prevDevice?.data?.sensor_one?.sensor_type === HumidityHumidity ? "Humidity Range" : "Temperature Range"}
                                    </Text>
                                </Flex>
                                <FormControl display="flex" justifyContent="end" align="center">
                                    <Switch
                                        id="sensorOneEnabled"
                                        colorScheme="teal"
                                        isChecked={validation.values.sensorOneEnabled}
                                        onChange={handleToggle("sensorOneEnabled")}
                                    />
                                </FormControl>
                            </Flex>

                            <Grid gridTemplateColumns="repeat(3,1fr)" w="100%">
                                <GridItem width="120px">
                                    <FormControl isDisabled={!validation.values.sensorOneEnabled}>
                                        <FormLabel mb="0.5rem" sx={labelField} isDisabled={!validation.values.sensorOneEnabled}>
                                            Set Min Limit
                                        </FormLabel>
                                        <Box display="flex" alignItems="center" width={inputWidth} position="relative">
                                            <Input
                                                minW="17px"
                                                border="none"
                                                isInvalid={validation.errors.minLimitOne && validation.touched.minLimitOne}
                                                placeholder="00"
                                                type="text"
                                                name="minLimitOne"
                                                value={validation.values.minLimitOne}
                                                onChange={validation.handleChange}
                                                onFocus={validation.handleBlur}
                                                sx={InputField}
                                                isDisabled={!validation.values.sensorOneEnabled}
                                            />
                                            <Box
                                                borderLeft="1px solid rgba(155, 151, 151, 1)"
                                                w="22px"
                                                h="25px"
                                                display="flex"
                                                alignItems="center"
                                                zIndex={1}
                                                position="absolute"
                                                left="70%"
                                                top="5px"
                                            >
                                                <Text ml="3px">{prevDevice?.data?.sensor_one?.sensor_type === HumidityHumidity ? "%" : "°C"}</Text>
                                            </Box>
                                        </Box>
                                        {/* <FormErrorMessage>{validation.errors.paringKey}</FormErrorMessage> */}
                                    </FormControl>
                                </GridItem>
                                {/* Repeat for other GridItems */}
                                <GridItem width="120px">
                                    <FormControl isRequired isDisabled={!validation.values.sensorOneEnabled}>
                                        <FormLabel mb="0.5rem" sx={labelField} isDisabled={!validation.values.sensorOneEnabled}>
                                            Set Max Limit
                                        </FormLabel>
                                        <Box display="flex" alignItems="center" width={inputWidth} position="relative">
                                            <Input
                                                minW="17px"
                                                // border="none"
                                                isInvalid={validation.errors.maxLimitOne && validation.touched.maxLimitOne}
                                                placeholder="00"
                                                type="text"
                                                name="maxLimitOne"
                                                value={validation.values.maxLimitOne}
                                                onChange={validation.handleChange}
                                                onFocus={validation.handleBlur}
                                                sx={InputField}
                                                isDisabled={!validation.values.sensorOneEnabled}
                                            />
                                            <Box
                                                borderLeft="1px solid rgba(155, 151, 151, 1)"
                                                w="22px"
                                                h="25px"
                                                display="flex"
                                                alignItems="center"
                                                zIndex={1}
                                                position="absolute"
                                                left="80%"
                                                top="5px"
                                            >
                                                <Text ml="3px">{prevDevice?.data?.sensor_one?.sensor_type === HumidityHumidity ? "%" : "°C"}</Text>
                                            </Box>
                                        </Box>
                                        {/* <FormErrorMessage>{validation.errors.paringKey}</FormErrorMessage> */}
                                    </FormControl>
                                </GridItem>
                                <GridItem width="180px">
                                    <FormControl isRequired isDisabled={!validation.values.sensorOneEnabled}>
                                        <Flex align="center">
                                            <FormLabel mb="0.5rem" sx={labelField} isDisabled={!validation.values.sensorOneEnabled}>
                                                Out-Range Threshold
                                            </FormLabel>
                                            {/* {!validation.values.sensorOneEnabled ? <Image src={disable_info} alt="" mb="0.5rem" /> : <Image src={info} alt="" mb="0.5rem" />} */}
                                            <Tooltip
                                                placement="auto"
                                                hasArrow
                                                bg="gray.500"
                                                label="Out-Range Threshold refers to the limit beyond which a sensor's reading is considered irregular or abnormal"
                                            >
                                                {!validation.values.sensorTwoEnabled ? (
                                                    <Image src={disable_info} alt="" mb="0.5rem" />
                                                ) : (
                                                    <Image src={info} alt="" mb="0.5rem" />
                                                )}
                                            </Tooltip>
                                        </Flex>

                                        <Box display="flex" alignItems="center" width={inputWidth} position="relative">
                                            <Input
                                                minW="17px"
                                                border="none"
                                                isInvalid={validation.errors.thresholdOne && validation.touched.thresholdOne}
                                                placeholder="00"
                                                type="number"
                                                name="thresholdOne"
                                                value={validation.values.thresholdOne}
                                                onChange={validation.handleChange}
                                                onFocus={validation.handleBlur}
                                                sx={InputField}
                                                isDisabled={!validation.values.sensorOneEnabled}
                                            />
                                            <Box
                                                borderLeft="1px solid rgba(155, 151, 151, 1)"
                                                w="22px"
                                                h="25px"
                                                display="flex"
                                                alignItems="center"
                                                zIndex={1}
                                                position="absolute"
                                                left="80%"
                                                top="5px"
                                            >
                                                <Text ml="3px">Min</Text>
                                            </Box>
                                        </Box>
                                        {/* <FormErrorMessage>{validation.errors.paringKey}</FormErrorMessage> */}
                                    </FormControl>
                                </GridItem>
                            </Grid>
                        </Box>

                        {/*  */}
                        <Box w="100%">
                            <Flex w="100%" justifyContent="space-between">
                                <Flex minW="90%" gap={2} align="center">
                                    <Image
                                        w=" 20px"
                                        h="20px"
                                        src={prevDevice?.data?.sensor_two?.sensor_type === HumidityHumidity ? humidity : temp}
                                        alt=""
                                    />
                                    <Text
                                        color="rgba(17, 37, 14, 1)"
                                        fontFamily="fontFamily"
                                        fontSize="16px"
                                        lineHeight="30px"
                                        letterSpacing="-0.01%"
                                        fontWeight={600}
                                    >
                                        Sensor 02{" "}
                                        {prevDevice?.data?.sensor_two?.sensor_type === HumidityHumidity ? "Humidity Range" : "Temperature Range"}
                                    </Text>
                                </Flex>
                                <FormControl display="flex" justifyContent="end" align="center">
                                    <Switch
                                        colorScheme="teal"
                                        id="sensorTwoEnabled"
                                        isChecked={validation.values.sensorTwoEnabled}
                                        onChange={handleToggle("sensorTwoEnabled")}
                                    />
                                </FormControl>
                            </Flex>

                            <Grid gridTemplateColumns="repeat(3,1fr)" w="100%">
                                <GridItem width="120px">
                                    <FormControl isDisabled={!validation.values.sensorTwoEnabled}>
                                        <FormLabel mb="0.5rem" sx={labelField} isDisabled={!validation.values.sensorTwoEnabled}>
                                            Set Min Limit
                                        </FormLabel>
                                        <Box display="flex" alignItems="center" width={inputWidth} position="relative">
                                            <Input
                                                minW="17px"
                                                border="none"
                                                isInvalid={validation.errors.minLimitTwo && validation.touched.minLimitTwo}
                                                placeholder="00"
                                                type="text"
                                                name="minLimitTwo"
                                                value={validation.values.minLimitTwo}
                                                onChange={validation.handleChange}
                                                onFocus={validation.handleBlur}
                                                sx={InputField}
                                                isDisabled={!validation.values.sensorTwoEnabled}
                                            />
                                            <Box
                                                borderLeft="1px solid rgba(155, 151, 151, 1)"
                                                w="22px"
                                                h="25px"
                                                display="flex"
                                                alignItems="center"
                                                zIndex={1}
                                                position="absolute"
                                                left="70%"
                                                top="5px"
                                            >
                                                <Text ml="3px">{prevDevice?.data?.sensor_two?.sensor_type === HumidityHumidity ? "%" : "°C"}</Text>
                                            </Box>
                                        </Box>
                                        {/* <FormErrorMessage>{validation.errors.paringKey}</FormErrorMessage> */}
                                    </FormControl>
                                </GridItem>
                                {/* Repeat for other GridItems */}
                                <GridItem width="120px">
                                    <FormControl
                                        isInvalid={validation.errors.maxLimitTwo && validation.touched.maxLimitTwo}
                                        isRequired
                                        isDisabled={!validation.values.sensorTwoEnabled}
                                    >
                                        <FormLabel mb="0.5rem" sx={labelField} isDisabled={!validation.values.sensorTwoEnabled}>
                                            Set Max Limit
                                        </FormLabel>
                                        <Box display="flex" alignItems="center" width={inputWidth} position="relative">
                                            <Input
                                                minW="17px"
                                                // border="none"
                                                isInvalid={validation.errors.maxLimitTwo && validation.touched.maxLimitTwo}
                                                placeholder="00"
                                                type="text"
                                                name="maxLimitTwo"
                                                value={validation.values.maxLimitTwo}
                                                onChange={validation.handleChange}
                                                onFocus={validation.handleBlur}
                                                sx={InputField}
                                                isDisabled={!validation.values.sensorTwoEnabled}
                                            />
                                            <Box
                                                borderLeft="1px solid rgba(155, 151, 151, 1)"
                                                w="22px"
                                                h="25px"
                                                display="flex"
                                                alignItems="center"
                                                zIndex={1}
                                                position="absolute"
                                                left="80%"
                                                top="5px"
                                            >
                                                <Text ml="3px">{prevDevice?.data?.sensor_two?.sensor_type === HumidityHumidity ? "%" : "°C"}</Text>
                                            </Box>
                                        </Box>
                                        {/* <FormErrorMessage>{validation.errors.paringKey}</FormErrorMessage> */}
                                    </FormControl>
                                </GridItem>
                                <GridItem width="180px">
                                    <FormControl
                                        // isInvalid={validation.errors.paringKey && validation.touched.paringKey}
                                        isRequired
                                        isDisabled={!validation.values.sensorTwoEnabled}
                                    >
                                        <Flex align="center">
                                            <FormLabel mb="0.5rem" sx={labelField} isDisabled={!validation.values.sensorTwoEnabled}>
                                                Out-Range Threshold
                                            </FormLabel>
                                            {/* {!validation.values.sensorTwoEnabled ? (
                                                <Image src={disable_info} alt="" mb="0.5rem" />
                                            ) : (
                                                <Image src={info} alt="" mb="0.5rem" />
                                            )} */}
                                            <Tooltip
                                                placement="auto"
                                                hasArrow
                                                bg="gray.500"
                                                label="Out-Range Threshold refers to the limit beyond which a sensor's reading is considered irregular or abnormal"
                                            >
                                                {!validation.values.sensorTwoEnabled ? (
                                                    <Image src={disable_info} alt="" mb="0.5rem" />
                                                ) : (
                                                    <Image src={info} alt="" mb="0.5rem" />
                                                )}
                                            </Tooltip>
                                        </Flex>

                                        <Box display="flex" alignItems="center" width={inputWidth} position="relative">
                                            <Input
                                                minW="17px"
                                                border="none"
                                                placeholder="00"
                                                type="text"
                                                isInvalid={validation.errors.thresholdTwo && validation.touched.thresholdTwo} 
                                                name="thresholdTwo"
                                                value={validation.values.thresholdTwo}
                                                onChange={validation.handleChange}
                                                onFocus={validation.handleBlur}
                                                sx={InputField}
                                                isDisabled={!validation.values.sensorTwoEnabled}
                                            />
                                            <Box
                                                borderLeft="1px solid rgba(155, 151, 151, 1)"
                                                w="22px"
                                                h="25px"
                                                display="flex"
                                                alignItems="center"
                                                zIndex={1}
                                                position="absolute"
                                                left="80%"
                                                top="5px"
                                            >
                                                <Text ml="3px">Min</Text>
                                            </Box>
                                        </Box>
                                        {/* <FormErrorMessage>{validation.errors.paringKey}</FormErrorMessage> */}
                                    </FormControl>
                                </GridItem>
                            </Grid>
                        </Box>
                    </DrawerBody>

                    <DrawerFooter>
                        <Flex direction="column" justifyContent="center" gap={2} width="100%">
                            <CustomButton
                                width="100%"
                                h="40px"
                                bg="#57BA47"
                                sx={{
                                    _hover: { bg: "#57BA47" },
                                    fontFamily: "fontFamily",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                }}
                                onClick={validation.handleSubmit}
                                disabled={
                                    !validation.isValid ||
                                    updateDeviceMutation.isLoading ||
                                    (!validation.values.sensorOneEnabled && !validation.values.sensorTwoEnabled)
                                }
                            >
                                Next
                            </CustomButton>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <Step2AddDevice step3IsOpen={step3IsOpen} step3onClose={step3onClose} />
        </>
    );
};
