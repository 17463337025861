import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Image,
    Text,
    FormControl,
    FormLabel,
    Switch,
    Input,
    VStack,
    FormErrorMessage,
    HStack,
} from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CustomButton from "./CustomButton";
import plusIcon from "../assets/Plus_Icon.png";
import Step2ManageDevice from "./Step2ManageDevice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useQueryParams } from "use-query-params";
import { DEVICE_QUERY_PARAMS } from "../lib/queriesParams";
import { SEARCH_PARAMS } from "../constants";

const labelStyle = {
    fontFamily: "fontFamily",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "-0.01em",
    color: "#11250E",
};

const fetchDevicesBySpace = async (space) => {
    const response = await axios.get(`/device`, { params: { space_id: space } });
    return response.data;
};

const ManageDevice = () => {
    const queryClient = useQueryClient();
    const { space } = useParams();
    const [query, setQuery] = useQueryParams(DEVICE_QUERY_PARAMS);
    const isOpen = query[SEARCH_PARAMS.DEVICE.MANAGE.key] !== "";

    const { data: prevDevice } = useQuery({
        queryKey: ["fetchDevices", space],
        queryFn: () => fetchDevicesBySpace(space),
        enabled: !!space,
        select: (data) => data.data?.find((item) => item.id === query[SEARCH_PARAMS.DEVICE.MANAGE.key]),
    });

    const clearQuery = () => {
        setQuery({
            [SEARCH_PARAMS.DEVICE.MANAGE.key]: SEARCH_PARAMS.DEVICE.MANAGE.default_value,
            [SEARCH_PARAMS.DEVICE.EDIT_STATUS.key]: SEARCH_PARAMS.DEVICE.EDIT_STATUS.default_value,
        });
    };

    const formik = useFormik({
        initialValues: {
            snoozePermanent: prevDevice?.snooze_permanent || false,
            snoozeCustom: !!prevDevice?.snoozed_from || !!prevDevice?.snoozed_to,
            startDateTime: prevDevice?.snoozed_from || "",
            endDateTime: prevDevice?.snoozed_to || "",
        },
        validationSchema: Yup.object({
            snoozePermanent: Yup.boolean(),
            snoozeCustom: Yup.boolean(),
            startDateTime: Yup.date().when("snoozeCustom", {
                is: true,
                then: () => Yup.date().required("Start date is required"),
                otherwise: () => Yup.date().nullable(),
            }),
            endDateTime: Yup.date().when("snoozeCustom", {
                is: true,
                then: () => Yup.date().required("End date is required").min(Yup.ref("startDateTime"), "End date must be after start date"),
                otherwise: () => Yup.date().nullable(),
            }),
        }),
        onSubmit: (values) => {
            manageDeviceMutation.mutate(values);
        },
        enableReinitialize: true,
    });

    const manageDeviceMutation = useMutation({
        mutationFn: async ({ snoozePermanent, snoozeCustom, startDateTime, endDateTime }) => {
            if (snoozePermanent) {
                await axios.patch(`/device/${prevDevice?.id}/toggle-permanent-snooze`, { flag: true });
            }
            if (snoozeCustom) {
                await axios.patch(`/device/${prevDevice?.id}/custom-snooze`, {
                    snoozed_from: startDateTime ? new Date(startDateTime) : null,
                    snoozed_to: endDateTime ? new Date(endDateTime) : null,
                });
            }
        },
        onSuccess: () => {
            formik.resetForm();
            queryClient.refetchQueries({
                queryKey: ["fetchDevices", space],
                exact: true
            });
            setQuery({ [SEARCH_PARAMS.DEVICE.MANAGE_STATUS.key]: "success" });
        },
        onError: (error) => {
            console.error(error);
        },
    });

    const handleSwitchChange = (field) => (e) => {
        const value = e.target.checked;
        formik.setFieldValue(field, value);
        if (field === "snoozePermanent" && value) {
            formik.setFieldValue("snoozeCustom", false);
            formik.setFieldValue("startDateTime", "");
            formik.setFieldValue("endDateTime", "");
        }
        if (field === "snoozeCustom" && value) {
            formik.setFieldValue("snoozePermanent", false);
        }
    };

    return (
        <>
            <Drawer placement="right" isOpen={isOpen} onClose={clearQuery}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <Box mt={{ base: "0px", md: "5px", lg: "10px" }} mr="10px">
                        <DrawerCloseButton sx={{ color: "#11250E", p: "8px", bg: "#F6F6F6" }} />
                        <DrawerHeader
                            fontFamily="fontFamily"
                            fontSize="28px"
                            fontWeight="800"
                            lineHeight="42px"
                            letterSpacing="-0.04em"
                            textAlign="left"
                            color="#11250E"
                        >
                            Manage Alerts
                        </DrawerHeader>
                    </Box>

                    <DrawerBody p={0} m={0} align="center" position="relative">
                        <Box mb={4} p={5} bg="#F8F8F8">
                            <Text
                                fontFamily="fontFamily"
                                align="left"
                                color="#818681"
                                letterSpacing="-0.01em"
                                fontSize="14px"
                                fontWeight="800"
                                alignItems="left"
                            >
                                You are managing this device for{" "}
                                <Box as="span" color="#11250E">
                                    {prevDevice?.roomDevice?.name}.
                                </Box>
                            </Text>
                            <Text align="left" color="#818681" fontSize="14px" fontWeight="500">
                                You can snooze the notifications of this device or set a custom notification for your ease.
                            </Text>
                        </Box>

                        <Box m={4}>
                            <Flex sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                <Text sx={labelStyle}>Device Name</Text>
                                <Text sx={labelStyle}>{prevDevice?.name}</Text>
                            </Flex>
                        </Box>

                        <Box m={4}>
                            <FormControl display="flex" alignItems="center">
                                <FormLabel htmlFor="snooze-permanent" mb="0">
                                    Snooze Permanent
                                </FormLabel>
                                <Switch
                                    id="snooze-permanent"
                                    isChecked={formik.values.snoozePermanent}
                                    onChange={handleSwitchChange("snoozePermanent")}
                                />
                            </FormControl>
                        </Box>

                        <Box m={4}>
                            <FormControl display="flex" alignItems="center">
                                <FormLabel htmlFor="snooze-custom" mb="0">
                                    Snooze Custom
                                </FormLabel>
                                <Switch id="snooze-custom" isChecked={formik.values.snoozeCustom} onChange={handleSwitchChange("snoozeCustom")} />
                            </FormControl>
                        </Box>

                        {formik.values.snoozeCustom && (
                            <>
                                <Box m={4}>
                                    <HStack spacing="2" justify="space-between" width="100%">
                                        <VStack align="flex-start" spacing={0} width="100%">
                                            <FormControl isInvalid={formik.errors.startDateTime && formik.touched.startDateTime} mt={4}>
                                                <FormLabel htmlFor="startDate">Start Date & Time</FormLabel>
                                                <Input
                                                    id="startDate"
                                                    type="datetime-local"
                                                    value={formik.values.startDateTime}
                                                    onChange={(e) => formik.setFieldValue("startDateTime", e.target.value)}
                                                />
                                                <FormErrorMessage>{formik.errors.startDateTime}</FormErrorMessage>
                                            </FormControl>
                                        </VStack>
                                    </HStack>
                                </Box>

                                <Box m={4}>
                                    <HStack spacing="2" justify="space-between" width="100%">
                                        <VStack align="flex-start" spacing={0} width="100%">
                                            <FormControl isInvalid={formik.errors.endDateTime && formik.touched.endDateTime} mt={4}>
                                                <FormLabel htmlFor="endDate">End Date & Time</FormLabel>
                                                <Input
                                                    id="endDate"
                                                    type="datetime-local"
                                                    value={formik.values.endDateTime}
                                                    onChange={(e) => formik.setFieldValue("endDateTime", e.target.value)}
                                                />
                                                <FormErrorMessage>{formik.errors.endDateTime}</FormErrorMessage>
                                            </FormControl>
                                        </VStack>
                                    </HStack>
                                </Box>
                            </>
                        )}
                    </DrawerBody>

                    <DrawerFooter>
                        <Flex direction="column" justifyContent="center" gap={2} width="100%">
                            <CustomButton
                                width="100%"
                                h="40px"
                                bg="#57BA47"
                                sx={{
                                    _hover: { bg: "#57BA47" },
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                }}
                                onClick={formik.handleSubmit}
                                disabled={!formik.isValid || manageDeviceMutation.isLoading}
                            >
                                <Image src={plusIcon} alt="Manage Device" h="16px" w="16px" mr="3px" />
                                Manage Device
                            </CustomButton>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <Step2ManageDevice />
        </>
    );
};

export default ManageDevice;
