import { Divider, Circle, Grid, Box, VStack, Text, Input, Image, GridItem, useEditable } from "@chakra-ui/react";
import activeIcon from "../assets/onboardActiveicon.svg";
import StepperCheck from "../assets/stepper-check.svg";
import CustomButton from "../components/CustomButton";
import userIcon from "../assets/user.png";
import logo from "../assets/logo.svg";
import { useEffect, useState } from "react";
import { Onboarding2 } from "./Onboarding2";
import { TopBar } from "./TopBar";
import graphSkeleton from "../assets/graphSkeleton.svg";
import { Reveal } from "../common/Reveal";
import { useDispatch, useSelector } from "react-redux";
import { Toggle_FrstCircleCheckFunction } from "../redux/action";
import { useQuery } from "@tanstack/react-query";

const SteppweText = {
    color: "#000",
    fontFamily: "fontFamily",
    fontSize: "12px",

    fontWeight: "600",
    lineHeight: "16px",
    letterSpacing: "-0.4px",
};

const ButtonTextStyle = {
    bg: "#57BA47",
    _hover: {
        bg: "#57BA47",
    },
    color: "#FFF",
    fontFamily: "fontFamily",
    fontSize: "16px",

    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "-0.16px",
};

const Onboarding1 = () => {
    const [yourname, setYourName] = useState("");
    const dispatch = useDispatch();
    const firstCircle = useSelector((store) => store.ConditionalRenderingReducer.firstCircleCheck);
    const Step3 = useSelector((store) => store.ConditionalRenderingReducer.step3);

    const { data: userData, ...userQuery } = useQuery({
        queryKey: "fetchProfile",
        enabled: false,
        select: function (data) {
            return data?.data?.user;
        },
    });

    useEffect(() => {
        if (userData) {
            setYourName(userData?.name);
        }
    }, [userData]);

    // console.log(userData);

    const handleType = (e) => {
        setYourName(e.target.value);
    };

    const handleNextPage = () => {
        dispatch(Toggle_FrstCircleCheckFunction());
    };

    return (
        <Grid w="100%" bg="#F2F2F266" minH="100vh" m={0} p={0} gap={0} gridTemplateRows="10vh 23.9vh auto">
            <TopBar />
            <Box h="20vh" display="flex" bg="#F2F2F266" alignItems="center" position="relative" top="16%" p={0}>
                {firstCircle ? (
                    <Box
                        position="absolute"
                        top="50%"
                        left="0"
                        width="100%"
                        height="1px"
                        background={Step3 === true ? "#11250E" : "linear-gradient(270deg, #DDE2DD 0%, #11250E 100%)"}
                    />
                ) : (
                    <Divider borderColor="rgba(129, 134, 129, 0.80)" borderWidth="0.5px" w="100%" />
                )}

                {/* Circles for Stepper */}
                {!firstCircle ? (
                    <>
                        <Circle
                            size="40px"
                            bg="#DDF1DA"
                            position="absolute"
                            top="36%"
                            left="7%"
                            backgroundImage={activeIcon}
                            backgroundPosition="center"
                            backgroundRepeat="no-repeat"
                            cursor="pointer"
                        />

                        <Text position="absolute" sx={SteppweText} top="70%" left="6.7%" zIndex="1">
                            Personal
                        </Text>
                    </>
                ) : (
                    <>
                        <Circle
                            size="40px"
                            position="absolute"
                            top="36%"
                            left="6.7%"
                            backgroundImage={StepperCheck}
                            backgroundPosition="center"
                            backgroundRepeat="no-repeat"
                            zIndex="1"
                            cursor="pointer"
                        />
                        <Text position="absolute" sx={SteppweText} top="70%" left="6.5%" zIndex="1">
                            Personal
                        </Text>
                    </>
                )}
                {Step3 === true ? (
                    <>
                        <Circle
                            size="40px"
                            position="absolute"
                            top="36%"
                            left="50%"
                            backgroundImage={StepperCheck}
                            backgroundPosition="center"
                            backgroundRepeat="no-repeat"
                            zIndex="1"
                            cursor="pointer"
                        />

                        <Text position="absolute" sx={SteppweText} top="70%" left="49%" zIndex="1">
                            Professional
                        </Text>
                    </>
                ) : (
                    <>
                        <Circle
                            size="40px"
                            bg="#DDF1DA"
                            position="absolute"
                            top="36%"
                            backgroundImage={firstCircle === true ? activeIcon : "none"}
                            backgroundPosition="center"
                            backgroundRepeat="no-repeat"
                            left="50%"
                            zIndex="1"
                            cursor="pointer"
                        />
                        <Text position="absolute" sx={SteppweText} top="70%" left="49%" zIndex="1">
                            Professional
                        </Text>
                    </>
                )}

                <Circle
                    size="40px"
                    bg="#DDF1DA"
                    position="absolute"
                    backgroundImage={Step3 === true ? activeIcon : "none"}
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    top="36%"
                    left="90%"
                    zIndex="1"
                    cursor="pointer"
                />
                <Text position="absolute" sx={SteppweText} top="70%" left="89%" zIndex="1">
                    Setup Space
                </Text>
            </Box>

            {firstCircle === false ? (
                <Grid w="100%" bg="#F2F2F266" m={0} p={0} gridTemplateColumns="repeat(2,1fr)">
                    <VStack m={0} p={0} display="flex" flexDirection="column" mt="4rem">
                        <Box w="79%" display="flex" flexDirection="column">
                            <Reveal hidden={{ opacity: 1, x: 5 }} visible={{ opacity: 1, x: 0 }} delay={0.1}>
                                <Box m={0}>
                                    <Text color="#11250E" fontFamily="fontFamily" fontSize="28px" fontWeight="700" lineHeight="42px" ml="1rem">
                                        What should we call you?
                                    </Text>
                                </Box>
                            </Reveal>
                            <Reveal hidden={{ opacity: 1, x: 5 }} visible={{ opacity: 1, x: 0 }} delay={0.1}>
                                <Box>
                                    <Input
                                        placeholder="type here"
                                        onChange={handleType}
                                        value={yourname}
                                        type="text"
                                        border="none"
                                        size="lg"
                                        pt="2rem"
                                        pb="2rem"
                                        color="rgba(129, 134, 129, 1)"
                                        fontFamily="fontFamily"
                                        fontSize="40px"
                                        fontStyle="normal"
                                        fontWeight="400"
                                        lineHeight="60px"
                                        letterSpacing="-0.4px"
                                        textAlign="left"
                                        _focus={{ border: "none", boxShadow: "none" }}
                                        _placeholder={{
                                            color: "rgba(129, 134, 129, 0.50)",
                                            fontFamily: "fontFamily",
                                            fontSize: "40px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "60px",
                                            letterSpacing: "-0.4px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>
                            </Reveal>
                            <Reveal hidden={{ opacity: 0, y: 50 }} visible={{ opacity: 1, y: 0 }} delay={0.1}>
                                <Box mt="2rem">
                                    <CustomButton
                                        ml="1rem"
                                        w={{ base: "17%", sm: "17%", md: "17%", lg: "17%", xl: "15%" }}
                                        sx={ButtonTextStyle}
                                        onClick={handleNextPage}
                                        disabled={!yourname}
                                    >
                                        Next
                                    </CustomButton>
                                </Box>
                            </Reveal>
                        </Box>
                    </VStack>
                    <VStack display="flex" alignItems="start">
                        <Grid
                            w={{ base: "80%", sm: "80%", md: "80%", lg: "80%", xl: "80%" }}
                            minH={{ base: "44vh", sm: "44vh", md: "44vh", lg: "44vh", xl: "44vh" }}
                            ml="3rem"
                            borderRadius="16px 16px 0px 0px"
                            background="#F2F2F2"
                            pt="2rem"
                            pl="2rem"
                            pr="2rem"
                            gridTemplateColumns="30% auto"
                            mt="4rem"
                        >
                            <GridItem background="#11250E" borderRadius="8px 0px 0px 0px" align="center">
                                <Image src={logo} alt="" w="19px" h="15px" mt="15%" />
                                <Box
                                    borderRadius="50%"
                                    overflow="hidden"
                                    width="50px"
                                    height="50px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    background="#F2F2F2"
                                    mt="30%"
                                >
                                    <Image src={userIcon} alt="" />
                                </Box>
                                <Text
                                    mt="0.5rem"
                                    color="rgba(251, 251, 251, 1)"
                                    fontFamily="fontFamily"
                                    fontSize="12px"
                                    fontWeight="600"
                                    lineHeight="16px"
                                    letterSpacing="-0.01em"
                                >
                                    {yourname ? yourname : "Your Name"}
                                </Text>
                            </GridItem>
                            <GridItem borderRadius="0px 8px 0px 0px" bg="#FFFFFF" pl="1.5rem" pt="1.5rem" pr="1.5rem">
                                <Reveal hidden={{ opacity: 0, y: -60 }} visible={{ opacity: 1, y: 0 }} delay={0.1}>
                                    <Grid gridTemplateColumns="auto auto auto" columnGap="1rem" ml="0.5rem">
                                        <GridItem bg="rgba(242, 242, 242, 1)" h="44px" borderRadius="4px 4px 4px"></GridItem>
                                        <GridItem bg="rgba(242, 242, 242, 1)" h="44px" borderRadius="4px 4px 4px"></GridItem>
                                        <GridItem bg="rgba(242, 242, 242, 1)" h="44px" borderRadius="4px 4px 4px"></GridItem>
                                    </Grid>
                                    <Grid
                                        gridTemplateColumns="40% 60%"
                                        ml="0.5rem"
                                        bg="rgba(242, 242, 242, 1)"
                                        mt="2rem"
                                        borderRadius="8px 8px 0px 0px"
                                    >
                                        <GridItem h="26.4vh" pt="2rem">
                                            <VStack
                                                pl="0.5rem"
                                                pr="0.5rem"
                                                spacing="1rem" // Add this line for spacing between VStack items
                                            >
                                                <VStack
                                                    w="90%"
                                                    h={{ base: "35px", sm: "35px", md: "35px", lg: "30px", xl: "39px" }}
                                                    bg="rgba(255, 255, 255, 1)"
                                                    borderRadius="4px 4px 4px"
                                                ></VStack>
                                                <VStack
                                                    w="90%"
                                                    h={{ base: "35px", sm: "35px", md: "35px", lg: "30px", xl: "39px" }}
                                                    bg="rgba(255, 255, 255, 1)"
                                                    borderRadius="4px 4px 4px"
                                                ></VStack>
                                                <VStack
                                                    w="90%"
                                                    h={{ base: "35px", sm: "35px", md: "35px", lg: "30px", xl: "39px" }}
                                                    bg="rgba(255, 255, 255, 1)"
                                                    borderRadius="4px 4px 4px"
                                                ></VStack>
                                            </VStack>
                                        </GridItem>
                                        <GridItem gridTemplateColumns="100%">
                                            <VStack
                                                w="90%"
                                                h={{ base: "120px", sm: "120px", md: "120px", lg: "120px", xl: "150px" }}
                                                bg="rgba(255, 255, 255, 1)"
                                                mt="2rem"
                                                borderRadius="4px 4px 4px"
                                            >
                                                <Image
                                                    src={graphSkeleton}
                                                    alt=""
                                                    w="90%"
                                                    position="relative"
                                                    top={{ base: "30%", sm: "30%", md: "30%", lg: "30%", xl: "15%" }}
                                                />
                                            </VStack>
                                        </GridItem>
                                    </Grid>
                                </Reveal>
                            </GridItem>
                        </Grid>
                    </VStack>
                </Grid>
            ) : (
                <Onboarding2 yourname={yourname} />
            )}
        </Grid>
    );
};
export default Onboarding1;
