import React, { useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Text,
    VStack,
    color,
    useDisclosure,
} from "@chakra-ui/react";
import EmailIcon from "../assets/email-icon.svg";
import logo from "../assets/logo.svg";
import axios from "axios";
import EmailSended from "../components/emailSended";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomButton from "../components/CustomButton";
import { useNavigate } from "react-router-dom";

const placeHolderCss = {
    border: "1.5px solid",
    bg: "#FFFFF",
    borderColor: "#81868180",
    _focus: {
        boxShadow: "none",
        borderRadius: "4px",
        border: "1.5px solid #57BA47",
        background: "#FFF",
    },
    borderRadius: "4px",
    border: "1px solid #818681",
    background: "#FFF",
    _placeholder: {
        color: "#11250E",
        opacity: 0.4,
        fontFamily: "fontFamily",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
        letterSpacing: "-0.14px",
        _hover: {
            boxShadow: "none",
            borderRadius: "4px",
            border: "1.5px solid #57BA47",
            background: "#FFF",
        },
        _focus: {
            boxShadow: "none",
            borderRadius: "4px",
            border: "1.5px solid #57BA47",
            background: "#FFF",
        },
    },
};

const ResetPasswordScreen = () => {
    const { isOpen: emailSendIsOpen, onOpen: emailSendOnOpen, onClose: emailSendOnClose } = useDisclosure();
    const navigate = useNavigate();
    // const handleSubmit = async () => {
    //     setLoading(true);
    //     setError("");

    //     try {
    //         const response = await axios.post(`/auth/password-reset/request`, {
    //             email,
    //         });

    //         if (response.status === 200) {
    //             emailSendOnOpen();
    //         } else {
    //             setError(response?.data?.error || "Password reset failed. Please try again.");
    //         }
    //     } catch (err) {
    //         console.error(err);
    //         setError(err?.response?.data?.error || "An unexpected error occurred.");
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const resetPasswordMutation = useMutation({
        mutationFn: async function (email) {
            const response = await axios.post(`/auth/password-reset/request`, { email });
            return response;
        },

        onSuccess: function (response) {
            if (response.status === 200) {
                emailSendOnOpen();
            } else {
                formik.setFieldError("general", response?.data?.error || "Password reset failed. Please try again.");
            }
        },
        onError: function (error) {
            formik.setFieldError("general", error?.response?.data?.error || "An unexpected error occurred.");
        },
    });

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Enter a valid email.").required("Email is required."),
        }),
        onSubmit: function (values) {
            resetPasswordMutation.mutate(values.email);
        },
    });

    const handleNavigateLogin = () => {
        navigate("/login");
    };
    const EmailPassword = {
        color: "#11250E",
        fontSize: "16px",
        fontWeight: "600",
    };

    return (
        <>
            <VStack width={{ base: "100%", md: "50%" }} justifyContent="center" align={{ base: "center", lg: "flex-start" }} height="100%">
                <VStack
                    w={{ base: "100%", sm: "70%", md: "70%", lg: "70%", xl: "70%", "2xl": "80%" }}
                    ml={{ base: "", sm: "20%", md: "10%", lg: "10%", xl: "10%" }}
                    align="start"
                >
                    <Box w="100%" mb="20px">
                        <Image src={logo} alt="logo" />
                        <Text w="100%" fontSize="28px" fontWeight="700" lineHeight="42px" color="#11250E">
                            Reset Password
                        </Text>
                    </Box>

                    <Stack w="100%" spacing={4}>
                        <Box w="100%">
                            <Text
                                color="#818681"
                                fontSize="14px"
                                mb="20px"
                                fontWeight="400"
                                lineHeight="20px"
                                letterSpacing="0.01em"
                                fontFamily="fontFamily"
                            >
                                Forgot your password? Check your email for the reset link to update your password.
                            </Text>
                        </Box>

                        <Box w="100%">
                            <FormControl isInvalid={formik.errors.email && formik.touched.email} isRequired>
                                <FormLabel htmlFor="desc" sx={EmailPassword}>
                                    Email
                                </FormLabel>
                                <InputGroup size="sm" w="100%">
                                    <InputLeftElement>
                                        <Image src={EmailIcon} alt="email icon" />
                                    </InputLeftElement>
                                    <Input
                                        placeholder="Enter your registered email address"
                                        sx={placeHolderCss}
                                        type="email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isInvalid={formik.errors.email && formik.touched.email}
                                    />
                                </InputGroup>
                                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <CustomButton
                            isLoading={resetPasswordMutation.isLoading}
                            onClick={formik.handleSubmit}
                            disabled={!formik.isValid || resetPasswordMutation.isPending}
                            sx={{
                                _hover: { bg: "#57BA47" },
                                color: "#FFFFFF",
                                bg: "#57BA47",
                            }}
                        >
                            {resetPasswordMutation.isLoading ? <CircularProgress isIndeterminate color="#FFFFFF" /> : "Send Reset Link"}
                        </CustomButton>

                        <CustomButton
                            onClick={handleNavigateLogin}
                            sx={{
                                _hover: { bg: "#FFFFFF" },
                                bg: "#FFFFFF",
                                color: "#57BA47",
                                border: "1px solid #57BA47",
                            }}
                        >
                            {resetPasswordMutation.isLoading ? <CircularProgress isIndeterminate color="#FFFFFF" /> : "Cancel"}
                        </CustomButton>
                    </Stack>
                </VStack>
            </VStack>
            <EmailSended emailSendIsOpen={emailSendIsOpen} emailSendOnClose={emailSendOnClose} email={formik.values.email} />
        </>
    );
};

export default ResetPasswordScreen;
