import React, { useEffect, useState } from "react";
import { Box, VStack, Text, HStack } from "@chakra-ui/react";
import axios from "axios";
import { useQueries, useQuery } from "@tanstack/react-query";
import { DashboardCount } from "../dashboardCount";
import { useParams } from "react-router-dom";
import { DashboardNoDevice } from "../dashboardNoDevice";
import { DashboardDevice } from "../dashboardDevice";
import notification from "../../assets/Notification.svg";
import { Notification } from "../notification";

const fetchDevices = async (space) => {
    const res = await axios.get(`/device`, {
        params: { space_id: space },
    });
    return res?.data;
};

// const fetchNotifications = async () => {
//     const res = await axios.get(`/notification`);
//     return res?.data;
// };

const fetchRoomsForSpace = async (spaceId) => {
    const res = await axios.get(`/room`, {
        params: { space_id: spaceId },
    });
    return res?.data;
};

const fetchDevicesForRoom = async (roomId) => {
    const res = await axios.get(`/device`, {
        params: { room_id: roomId },
    });
    return res?.data;
};

const DashboardScreen = () => {
    const { space } = useParams();

    const { data: devices = { data: [] }, ...devicesQuery } = useQuery({
        queryKey: ["fetchDevices", space],
        queryFn: () => fetchDevices(space),
        enabled: !!space,
    });

    const { data: notifications = { data: [] }, refetch } = useQuery({
        queryKey: ["fetchNotifications"],
        // queryFn: fetchNotifications,
        enabled: true,
    });

    const { data: spaces, ...spacesQuery } = useQuery({
        queryKey: "fetchSpaces",
        enabled: false,
        select: function (data) {
            return data?.data?.filter((item) => item.id === space) || [];
        },
    });

    // Fetch rooms for the selected space
    const { data: roomsForSelectedSpace = { data: [] } } = useQuery({
        queryKey: ["fetchRoomsForSpace", space],
        queryFn: () => fetchRoomsForSpace(space),
        enabled: !!space,
    });

    useEffect(() => {}, [notifications])

    // Fetch devices for each room in the selected space using useQueries
    const devicesQueries = useQueries({
        queries:
            roomsForSelectedSpace?.data?.map((room) => ({
                queryKey: ["fetchDevicesForRoom", room.id],
                queryFn: () => fetchDevicesForRoom(room.id),
                enabled: !!room.id,
            })) || [],
    });

    // Combine all devices from all rooms
    const devicesForSelectedSpace = devicesQueries.flatMap((query) => query.data?.data || []);

    // Function to filter notifications by devices in the current space
    const filterNotificationsBySpace = (spaceId) => {
        const deviceIds = devicesForSelectedSpace.map((device) => device.id);
        return notifications.data.filter((notification) => deviceIds.includes(notification.device));
    };

    const filteredNotifications = filterNotificationsBySpace(space);
    const unreadCount = filteredNotifications.filter((notification) => !notification.is_read).length;

    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setDrawerOpen((prev) => !prev);
    };

    return (
        <Box bg="#FFFFFF" margin={0} width="100%" height="100vh" overflow="hidden">
            <HStack w="80%" h="110px" bg="#F8FBEE" justifyContent="space-between" position="fixed" top="0" zIndex="1000" paddingX="3rem">
                <Text
                    fontFamily="fontFamily"
                    fontSize="28px"
                    fontWeight="800"
                    lineHeight="42px"
                    letterSpacing="-0.04em"
                    textAlign="left"
                    color="#11250E"
                >
                    {spaces?.[0]?.name} / Dashboard
                </Text>

                <HStack position="relative" onClick={toggleDrawer} cursor="pointer">
                    <img src={notification} alt="notification" width="48px" height="48px" />
                    {unreadCount > 0 && (
                        <Box
                            position="absolute"
                            top="0"
                            right="0"
                            bg="red.500"
                            color="white"
                            borderRadius="full"
                            width="24px"
                            height="24px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            fontSize="12px"
                        >
                            {unreadCount}
                        </Box>
                    )}
                </HStack>
                {isDrawerOpen && <Notification notifications={filteredNotifications} refetch={refetch} />}
            </HStack>

            <VStack marginX="3.5%" marginTop="130px" height="calc(100vh - 130px)" overflowY="auto">
                <DashboardCount space={space} />
                {devices?.data?.length > 0 ? <DashboardDevice devices={devices} /> : <DashboardNoDevice />}
            </VStack>
        </Box>
    );
};

export default DashboardScreen;
