import { Avatar, Box, HStack, IconButton, Input, Spinner, Text, useDisclosure, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import userimg from "../../assets/Ellipse.png";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import EditContactDetail from "../EditContactDetail";
import EditPassword from "../EditPassword";
import EditCompany from "../EditCompany";
import EditCompanyLocation from "../EditCompanyLocation";
import { PiCamera } from "react-icons/pi";

const fetchUserProfile = async () => {
    const res = await axios.get(`/auth/user`);
    return res?.data;
};

const Settings = () => {
    const queryClient = useQueryClient();

    const { data: userProfile, ...userProfileQuery } = useQuery({
        queryKey: ["fetchUserProfile"],
        queryFn: () => fetchUserProfile(),
    });

    const { mutateAsync, isLoading: isMutating } = useMutation({
        mutationFn: async (file) => {
            const formData = new FormData();
            formData.append("image", file);
            formData.append("user_id", userProfile?.data?.user?.id);

            const response = await axios.patch("/member", formData, {
                params: { member_id: userProfile?.data?.user?.id },
            });

            return response;
        },
        onSuccess: (data) => {
            console.log("Avatar updated successfully:", data);
            queryClient.refetchQueries({
                queryKey: ["fetchUserProfile"],
                exact: true
            });
        },
        onError: (error) => {
            console.error("Error updating avatar:", error);
            alert("Failed to update avatar. Please try again.");
        },
    });

    const [avatar, setAvatar] = useState(userProfile?.data?.user?.picture);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setAvatar(userProfile?.data?.user?.picture);
    }, [avatar, userProfile])

    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                setIsLoading(true);

                const imageUrl = URL.createObjectURL(file);
                await mutateAsync(file);

                setAvatar(imageUrl);
            } catch (error) {
                console.error("Error during avatar upload:", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const smallTextStyle = {
        fontFamily: "fontFamily",
        fonSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "-0.01em",
        align: "left",
        color: "#818681",
    };
    const boldTextStyle = {
        fontFamily: "fontFamily",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "-0.01em",
        align: "left",
        color: "#11250E",
    };

    const { isOpen: companyIsOpen, onOpen: companyOnOpen, onClose: companyOnClose } = useDisclosure();
    const { isOpen: step2companyIsOpen, onOpen: step2companyOnOpen, onClose: step2companyOnClose } = useDisclosure();

    const { isOpen: countryIsOpen, onOpen: countryOnOpen, onClose: countryOnClose } = useDisclosure();
    const { isOpen: step2countryIsOpen, onOpen: step2countryOnOpen, onClose: step2countryOnClose } = useDisclosure();

    const { isOpen: passwordIsOpen, onOpen: passwordOnOpen, onClose: passwordOnClose } = useDisclosure();
    const { isOpen: step2passwordIsOpen, onOpen: step2passwordOnOpen, onClose: step2passwordOnClose } = useDisclosure();

    const { isOpen: contactDetailIsOpen, onOpen: contactDetailOnOpen, onClose: contactDetailOnClose } = useDisclosure();
    const { isOpen: step2contactDetailIsOpen, onOpen: step2contactDetailOnOpen, onClose: step2contactDetailOnClose } = useDisclosure();

    return (
        <>
            <HStack
                w="100%"
                h="110px"
                bg="#F8FBEE"
                justifyContent="space-between"
                position="fixed"
                top="0px"
                bottom="0px"
                zIndex="1000"
                boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
            >
                <Text
                    ml="3rem"
                    fontFamily="fontFamily"
                    fontSize="28px"
                    fontWeight="700"
                    lineHeight="42px"
                    letterSpacing="-0.01em"
                    textAlign="left"
                    color="rgba(17, 37, 14, 1)"
                >
                    Settings
                </Text>
            </HStack>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minW="100%" minH="100vh">
                <Box
                    position="relative"
                    mt={{ base: "4rem", md: "12rem", xl: "12rem", "2xl": "12%" }}
                    w="90%"
                    h="100%"
                    borderRadius="16px 16px 16px 16px"
                    boxShadow="0px 4px 25px 0px #0000000D"
                    background="#FFFFFF"
                >
                    <Text
                        borderBottom="1px solid #E0E5E0"
                        color="#11250E"
                        fontWeight="600"
                        lineHeight="36px"
                        letterSpacing="-0.01em"
                        fontSize="24px"
                        fontStyle=" fontFamily"
                        ml="40px"
                        mt="16px"
                        mr="40px"
                        align="start"
                    >
                        Profile
                    </Text>

                    <VStack spacing={2} mt="40px" align="left" ml="40px">
                        <Box position="relative" w="120px" h="120px" role="group">
                            <Avatar size="xl" name="John Doe" w="120px" h="120px" src={avatar ?? userimg} opacity={isLoading ? 0.5 : 1} />
                            {(isLoading || isMutating) && (
                                <Box
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    w="100%"
                                    h="100%"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    bg="rgba(0, 0, 0, 0.6)"
                                    borderRadius="full"
                                >
                                    <Spinner color="white" size="lg" />
                                </Box>
                            )}
                            {!isLoading && (
                                <Box
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    w="100%"
                                    h="100%"
                                    bg="rgba(0, 0, 0, 0.5)"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    opacity="0.7"
                                    transition="opacity 0.2s"
                                    _groupHover={{ opacity: 1 }}
                                    borderRadius="full"
                                    cursor="pointer"
                                    onClick={() => document.getElementById("avatarInput").click()}
                                >
                                    <PiCamera color="white" size={36} />
                                </Box>
                            )}
                            <Input id="avatarInput" type="file" accept="image/*" onChange={handleImageChange} style={{ display: "none" }} />
                        </Box>
                        <Text color="#11250E" fontWeight="600" lineHeight="24px" letterSpacing="-0.01em" fontSize="20px" fontStyle=" fontFamily">
                            {userProfile?.data?.user?.name}
                        </Text>
                        <Text color="#818681" fontWeight="500" lineHeight="20px" letterSpacing="-0.01em" fontSize="14px" fontStyle=" fontFamily">
                            {userProfile?.data?.company?.company_access?.display_role}
                        </Text>
                    </VStack>

                    <Box mt="40px" background="#FFFFFF" py="24px" px="40px" pb="24px" borderTop="1px solid #E0E5E0" borderBottom="1px solid #E0E5E0">
                        <Box w="100%">
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box mb="16px" sx={{ display: "flex", alignItems: "center" }} w="100%">
                                    <Text sx={smallTextStyle} minW="15%">
                                        Company
                                    </Text>
                                    <Text sx={{ ...boldTextStyle }}>{userProfile?.data?.company?.name}</Text>
                                </Box>
                                <Box mb="16px">
                                    <Text
                                        isExternal
                                        textDecoration="underline"
                                        fontStyle="fontFamily"
                                        fontWeight="500"
                                        fontSize="14px"
                                        lineHeight="20px"
                                        color="#57BA47"
                                        cursor="pointer"
                                        onClick={() => companyOnOpen()}
                                    >
                                        Edit
                                    </Text>
                                    <EditCompany
                                        companyIsOpen={companyIsOpen}
                                        companyOnClose={companyOnClose}
                                        step2companyIsOpen={step2companyIsOpen}
                                        step2companyOnClose={step2companyOnClose}
                                        step2companyOnOpen={step2companyOnOpen}
                                        prevDetail={userProfile?.data}
                                        userProfileQuery={userProfileQuery}
                                    />
                                </Box>
                            </Box>

                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box mb="16px" sx={{ display: "flex", alignItems: "center" }} w="100%">
                                    <Text sx={smallTextStyle} minW="15%">
                                        Address
                                    </Text>
                                    <Text sx={{ ...boldTextStyle }}>
                                        {`${userProfile?.data?.company?.location?.address} , ${userProfile?.data?.company?.location?.city} ,${userProfile?.data?.company?.location?.state}, ${userProfile?.data?.company?.location?.country}`}
                                    </Text>
                                </Box>
                                <Box mb="16px">
                                    <Text
                                        isExternal
                                        textDecoration="underline"
                                        fontStyle="fontFamily"
                                        fontWeight="500"
                                        fontSize="14px"
                                        lineHeight="20px"
                                        color="#57BA47"
                                        cursor="pointer"
                                        onClick={() => countryOnOpen()}
                                    >
                                        Edit
                                    </Text>

                                    <EditCompanyLocation
                                        countryIsOpen={countryIsOpen}
                                        countryOnClose={countryOnClose}
                                        step2countryIsOpen={step2countryIsOpen}
                                        step2countryOnClose={step2countryOnClose}
                                        step2countryOnOpen={step2countryOnOpen}
                                        prevDetail={userProfile?.data}
                                        userProfileQuery={userProfileQuery}
                                    />
                                </Box>
                            </Box>

                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box mb="16px" sx={{ display: "flex", alignItems: "center" }} w="100%">
                                    <Text sx={smallTextStyle} minW="15%">
                                        Time Zone
                                    </Text>
                                    <Text sx={{ ...boldTextStyle }}> Canberra, Melbourne, Sydney ACT (GMT+10)</Text>
                                </Box>

                                {/* <Box mb="16px">
                                    <Text
                                        isExternal
                                        textDecoration="underline"
                                        fontStyle="fontFamily"
                                        fontWeight="500"
                                        fontSize="14px"
                                        lineHeight="20px"
                                        color="#57BA47"
                                        cursor="pointer"
                                    >
                                        Edit
                                    </Text>
                                </Box> */}
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box
                    position="relative"
                    top="-2%"
                    pb="1.5rem"
                    mt="2rem"
                    w="90%"
                    h="100%"
                    borderRadius="16px 16px 16px 16px"
                    boxShadow="0px 4px 25px 0px #0000000D"
                    background="#FFFFFF"
                >
                    <Text
                        borderBottom="1px solid #E0E5E0"
                        color="#11250E"
                        fontWeight="600"
                        lineHeight="36px"
                        letterSpacing="-0.01em"
                        fontSize="24px"
                        fontStyle=" fontFamily"
                        ml="40px"
                        mt="16px"
                        mr="40px"
                        align="start"
                    >
                        Sign in Method
                    </Text>

                    <VStack spacing={4} mt="40px" align="start" ml="40px">
                        <Box display="flex" align="start" gap="3rem">
                            <Text
                                color="rgba(129, 134, 129, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontStyle=" fontFamily"
                            >
                                Email Address
                            </Text>
                            <Text
                                color="rgba(17, 37, 14, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontStyle=" fontFamily"
                            >
                                {userProfile?.data?.user?.email}
                            </Text>
                        </Box>

                        <Box display="flex" align="start" w="100%">
                            <HStack gap="5rem" w="50%">
                                <Text
                                    color="rgba(129, 134, 129, 1)"
                                    fontWeight="500"
                                    lineHeight="24px"
                                    letterSpacing="-0.01em"
                                    fontSize="16px"
                                    fontStyle=" fontFamily"
                                >
                                    Password
                                </Text>
                                <Text
                                    color="rgba(17, 37, 14, 1)"
                                    fontWeight="500"
                                    lineHeight="24px"
                                    letterSpacing="-0.01em"
                                    fontSize="16px"
                                    fontStyle=" fontFamily"
                                >
                                    ************
                                </Text>
                            </HStack>
                            <HStack align="end" justifyContent="end" w={{ base: "0px", sm: "", md: "", lg: "46%", xl: "46%", "2xl": "48%" }}>
                                <Text
                                    isExternal
                                    textDecoration="underline"
                                    fontStyle=" fontFamily"
                                    fontWeight="500"
                                    fontSize="14px"
                                    lineHeight="20px"
                                    color="#57BA47"
                                    cursor="pointer"
                                    onClick={() => passwordOnOpen()}
                                >
                                    Edit
                                </Text>
                            </HStack>
                        </Box>
                    </VStack>

                    <EditPassword
                        passwordIsOpen={passwordIsOpen}
                        passwordOnClose={passwordOnClose}
                        step2passwordIsOpen={step2passwordIsOpen}
                        step2passwordOnClose={step2passwordOnClose}
                        step2passwordOnOpen={step2passwordOnOpen}
                        prevDetail={userProfile?.data}
                        userProfileQuery={userProfileQuery}
                    />
                </Box>

                <Box
                    position="relative"
                    top="-2%"
                    w="90%"
                    borderRadius="16px 16px 16px 16px"
                    boxShadow="0px 4px 25px 0px #0000000D"
                    background="#FFFFFF"
                    mt="2rem"
                    pb="1.5rem"
                    mb="2rem"
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        borderBottom="1px solid #E0E5E0"
                        w={{ base: "92%", sm: "92%", md: "92%", lg: "92%", xl: "93%", "2xl": "96%" }}
                        ml="2rem"
                    >
                        <Text
                            color="#11250E"
                            fontWeight="600"
                            lineHeight="36px"
                            letterSpacing="-0.01em"
                            fontSize="24px"
                            fontStyle=" fontFamily"
                            mt="16px"
                            mr="40px"
                            align="left"
                        >
                            Contact Information
                        </Text>
                        <Text
                            isExternal
                            textDecoration="underline"
                            fontStyle=" fontFamily"
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="20px"
                            color="#57BA47"
                            cursor="pointer"
                            onClick={() => contactDetailOnOpen()}
                        >
                            Edit
                        </Text>
                    </Box>

                    <VStack spacing={4} mt="40px" align="start" ml="40px">
                        <Box display="flex" align="start" gap="3rem">
                            <Text
                                color="rgba(129, 134, 129, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontStyle=" fontFamily"
                            >
                                Phone Number
                            </Text>
                            <Text
                                color="rgba(17, 37, 14, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontStyle=" fontFamily"
                            >
                                {userProfile?.data?.user?.phone}
                            </Text>
                        </Box>

                        <Box display="flex" align="start" gap="5rem">
                            <Text
                                color="rgba(129, 134, 129, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontStyle=" fontFamily"
                            >
                                Address
                            </Text>
                            <Text
                                color="rgba(17, 37, 14, 1)"
                                fontWeight="500"
                                lineHeight="24px"
                                letterSpacing="-0.01em"
                                fontSize="16px"
                                fontStyle=" fontFamily"
                                ml="1.5rem"
                            >
                                {[
                                    userProfile?.data?.user?.location?.address,
                                    userProfile?.data?.user?.location?.city,
                                    userProfile?.data?.user?.location?.state,
                                    userProfile?.data?.user?.location?.country,
                                ]
                                    .filter(Boolean)
                                    .join(", ")}
                            </Text>
                        </Box>
                    </VStack>

                    <EditContactDetail
                        contactDetailIsOpen={contactDetailIsOpen}
                        contactDetailOnClose={contactDetailOnClose}
                        step2contactDetailIsOpen={step2contactDetailIsOpen}
                        step2contactDetailOnClose={step2contactDetailOnClose}
                        step2contactDetailOnOpen={step2contactDetailOnOpen}
                        prevDetail={userProfile?.data?.user}
                        userProfileQuery={userProfileQuery}
                    />
                </Box>
            </Box>
        </>
    );
};

export default Settings;
