import React from "react";
import { Box, Grid, GridItem, Image, Text, VStack } from "@chakra-ui/react";

import CustomButton from "../components/CustomButton";
import onboarding_connect_wifi from "../assets/onboarding_connect_wifi.svg";
import { useDispatch, useSelector } from "react-redux";
import { Add_Device2_Toggle_Stepper_Function, Add_Device3_Toggle_Stepper_Function } from "../redux/action";
import { SetUpDevice4 } from "./SetUpDevice4";

export const SetUpDevice3 = ({ serialno, deviceName, connectionKey, roomId }) => {
    const dispatch = useDispatch();
    const Add_Device3_Stepper = useSelector((store) => store.ConditionalRenderingReducer.Add_Device3_Stepper);
    const Heading = {
        color: "#11250E",
        fontFamily: "fontFamily",
        fontSize: "32px",

        fontWeight: "800",
        lineHeight: "42px",
        letterSpacing: "-0.01em",
    };
    const noteStyle = {
        color: "#818681",
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "0.1%",
    };
    const buttonText = {
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.16%",
    };

    const ConnectWifi = () => {
        dispatch(Add_Device3_Toggle_Stepper_Function());
    };
    const PreviousConnectWifi = () => {
        dispatch(Add_Device2_Toggle_Stepper_Function());
    };

    const skipToTheDashboardTwo = () => {
        window.location.href = "/dashboard";
    };
    return (
        <>
            {Add_Device3_Stepper ? (
                <SetUpDevice4 serialno={serialno} deviceName={deviceName} connectionKey={connectionKey} roomId={roomId} />
            ) : (
                <Grid gridTemplateColumns="45% auto" mt="1rem">
                    <VStack ml="110px" align="start">
                        <VStack align="start">
                            <GridItem w="75%" h="5vh" mb="1rem">
                                <Text sx={Heading}>Connect to Wifi</Text>
                            </GridItem>
                            <GridItem w="75%">
                                <Text
                                    color="#000D00"
                                    fontFamily="fontFamily"
                                    fontSize="16px"
                                    fontWeight="700"
                                    lineHeight="30px"
                                    letterSpacing="-0.16%"
                                >
                                    Please follow the step to set wifi
                                </Text>
                            </GridItem>
                            <GridItem w="75%">
                                <Text mb="0.5rem" sx={noteStyle}>
                                    Step 1: When your device screen shows "Need Connection," open the Wi-Fi settings on your host device, such as a
                                    laptop or mobile phone.
                                </Text>
                            </GridItem>
                            <GridItem w="75%">
                                <Text mb="0.5rem" sx={noteStyle}>
                                    Step 2: In the Wi-Fi settings, look for the Wi-Fi SSID that says 'Thermadata {"\u003C"}your device serial number
                                    {"\u003E"}'.
                                </Text>
                            </GridItem>
                            <GridItem w="75%">
                                <Text mb="0.5rem" sx={noteStyle}>
                                    Step 3: Select and connect to that Wi-Fi network (You can refer to the screenshot for guidance).
                                </Text>
                            </GridItem>
                        </VStack>
                        <GridItem display="flex" justifyContent="space-between" w="75%" mt="5.5rem">
                            <Box w="30%" display="flex">
                                <CustomButton
                                    bg="rgba(255, 255, 255, 1)"
                                    color="rgba(87, 186, 71, 1)"
                                    border="2px solid"
                                    borderColor="rgba(87, 186, 71, 1)"
                                    onClick={PreviousConnectWifi}
                                >
                                    Back
                                </CustomButton>
                            </Box>
                            <Box display="flex" justifyContent="flex-end" alignItems="center" w="90%" gap="1rem">
                                <CustomButton
                                    color="rgba(0, 0, 0, 1)"
                                    w="30%"
                                    bg="rgba(255, 255, 255, 1)"
                                    sx={buttonText}
                                    onClick={skipToTheDashboardTwo}
                                >
                                    Cancel
                                </CustomButton>
                                <CustomButton
                                    color="rgba(255, 255, 255, 1)"
                                    w="27%"
                                    h="40px"
                                    bg="rgba(87, 186, 71, 1)"
                                    sx={buttonText}
                                    onClick={ConnectWifi}
                                >
                                    Next
                                </CustomButton>
                            </Box>
                        </GridItem>
                    </VStack>

                    <GridItem display="flex" justifyContent="center" alignItems="center">
                        <Box mb="3rem">
                            <Image src={onboarding_connect_wifi} alt="deviceAdd" />
                        </Box>
                    </GridItem>
                </Grid>
            )}
        </>
    );
};
