import { StringParam } from "use-query-params";

export const SEARCH_PARAMS = {
    MEMBER: {
        EDIT: {
            key: "edit_member",
            type: StringParam,
            default_value: "",
        },
        EDIT_STATUS: {
            key: "edit_member_status",
            type: StringParam,
            default_value: "",
        },
        ADD: {
            key: "add_member",
            type: StringParam,
            default_value: "",
        },
        ADD_STATUS: {
            key: "add_member_status",
            type: StringParam,
            default_value: "",
        },
        DELETE: {
            key: "delete_member",
            type: StringParam,
            default_value: "",
        },
        DELETE_STATUS: {
            key: "delete_member_status",
            type: StringParam,
            default_value: "",
        },
    },
    ROOM: {
        ADD: {
            key: "add_room",
            type: StringParam,
            default_value: "",
        },
        ADD_STATUS: {
            key: "add_room_status",
            type: StringParam,
            default_value: "",
        },
        EDIT: {
            key: "edit_room",
            type: StringParam,
            default_value: "",
        },
        EDIT_STATUS: {
            key: "edit_room_status",
            type: StringParam,
            default_value: "",
        },
        DELETE: {
            key: "delete_room",
            type: StringParam,
            default_value: "",
        },
        DELETE_STATUS: {
            key: "delete_room_status",
            type: StringParam,
            default_value: "",
        },
    },
    DEVICE: {
        ADD: {
            key: "add_device",
            type: StringParam,
            default_value: "",
        },
        ADD_STATUS: {
            key: "add_device_status",
            type: StringParam,
            default_value: "",
        },
        EDIT: {
            key: "edit_device",
            type: StringParam,
            default_value: "",
        },
        EDIT_STATUS: {
            key: "edit_device_status",
            type: StringParam,
            default_value: "",
        },
        DELETE: {
            key: "delete_device",
            type: StringParam,
            default_value: "",
        },
        DELETE_STATUS: {
            key: "delete_device_status",
            type: StringParam,
            default_value: "",
        },
        MANAGE: {
            key: "manange_device",
            type: StringParam,
            default_value: "",
        },
        MANAGE_STATUS: {
            key: "manage_device_status",
            type: StringParam,
            default_value: "",
        },
    },
};
