import {
    Add_Device2_Toggle_Stepper,
    Add_Device3_Toggle_Stepper,
    Add_Device4_Toggle_Stepper,
    Add_Device5_Toggle_Stepper,
    Add_Device_Toggle_Stepper,
    OnboardDevice_Toggle_Stepper,
    Toggle_iFrstCircleCheck,
    Toggle_Step2,
    Toggle_Step3,
    Toggle_Step4,
    Toggle_Step5,
    Toggle_Step6,
    Toggle_Step7,
    Toggle_Step8,
    Toggle_Step9,
    Toggle_StepTopBarSettings,
} from "./actionType";

export const Toggle_FrstCircleCheckFunction = () => (dispatch) => {
    // console.log("Dispatching action: Toggle_iFrstCircleCheck");
    dispatch({ type: Toggle_iFrstCircleCheck });
};

export const Toggle_Step2Function = () => (dispatch) => {
    dispatch({ type: Toggle_Step2 });
};
export const Toggle_Step3Function = () => (dispatch) => {
    dispatch({ type: Toggle_Step3 });
};

export const Toggle_Step4Function = () => (dispatch) => {
    // console.log("Dispatching action: Toggle_iFrstCircleCheck");
    dispatch({ type: Toggle_Step4 });
};

export const Toggle_Step5Function = () => (dispatch) => {
    // console.log("Dispatching action: Toggle_iFrstCircleCheck");
    dispatch({ type: Toggle_Step5 });
};
export const Toggle_Step6Function = () => (dispatch) => {
    // console.log("Dispatching action: Toggle_iFrstCircleCheck");
    dispatch({ type: Toggle_Step6 });
};

export const Toggle_Step7Function = () => (dispatch) => {
    // console.log("Dispatching action: Toggle_iFrstCircleCheck");
    dispatch({ type: Toggle_Step7 });
};
export const Toggle_Step8Function = () => (dispatch) => {
    // console.log("Dispatching action: Toggle_Step8Function");
    dispatch({ type: Toggle_Step8 });
};
export const Toggle_Step9Function = () => (dispatch) => {
    // console.log("Dispatching action: Toggle_iFrstCircleCheck");
    dispatch({ type: Toggle_Step9 });
};

export const Toggle_StepTopBarSettingsFunction = () => (dispatch) => {
    // console.log("Dispatching action: Toggle_iFrstCircleCheck");
    dispatch({ type: Toggle_StepTopBarSettings });
};

export const Device_Add_Toggle_Stepper_Function = () => (dispatch) => {
    // console.log("Dispatching action: Toggle_iFrstCircleCheck");
    dispatch({ type: Add_Device_Toggle_Stepper });
};

export const Add_Device2_Toggle_Stepper_Function = () => (dispatch) => {
    // console.log("Dispatching action: Add_Device2_Toggle_Stepper");
    dispatch({ type: Add_Device2_Toggle_Stepper });
};

export const Add_Device3_Toggle_Stepper_Function = () => (dispatch) => {
    // console.log("Dispatching action: Add_Device3_Toggle_Stepper");
    dispatch({ type: Add_Device3_Toggle_Stepper });
};

export const Add_Device4_Toggle_Stepper_Function = () => (dispatch) => {
    // console.log("Dispatching action: Add_Device4_Toggle_Stepper");
    dispatch({ type: Add_Device4_Toggle_Stepper });
};
export const Add_Device5_Toggle_Stepper_Function = () => (dispatch) => {
    // console.log("Dispatching action: Add_Device4_Toggle_Stepper");
    dispatch({ type: Add_Device5_Toggle_Stepper });
};

export const OnboardDevice_Toggle_Stepper_Function = () => (dispatch) => {
    // console.log("Dispatching action: OnboardDevice_Toggle_Stepper");
    dispatch({ type: OnboardDevice_Toggle_Stepper });
};
