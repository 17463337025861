import {
    Box,
    Divider,
    Flex,
    HStack,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    SimpleGrid,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import plusIcon from "../../assets/Plus_Icon.png";
import memberImage from "../../assets/Ellipse.png";
import AddMember from "../AddMember";
import { IoIosSearch } from "react-icons/io";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { useQueries, useQuery } from "@tanstack/react-query";
import axios from "axios";
import EditMember from "../EditMember";
import DeleteMember from "../DeleteMember";
import notification from "../../assets/Notification.svg";
import offlineIcon from "../../assets/offlineIcon.png";
import onlineIcon from "../../assets/onlineIcon.png";
import deleteIcon from "../../assets/delete.svg";
import editIcon from "../../assets/edit.svg";
import { Notification } from "../notification";
import { useQueryParams } from "use-query-params";
import { SEARCH_PARAMS } from "../../constants";
import { MEMBER_QUERY_PARAMS } from "../../lib/queriesParams";
import { useActiveUsers } from "../../contexts/useActiveUsers";
import fallback from "../../assets/fallback.svg";

const OnlineIcon = () => {
    return <Image src={onlineIcon} alt="onlineIcon" w="13px" h="13px" />;
};

const OfflineIcon = () => {
    return <Image src={offlineIcon} alt="offlineIcon" w="13px" h="13px" />;
};

const DeleteIcon = () => {
    return <Image src={deleteIcon} alt="onlineIcon" w="20px" h="20px" />;
};

const EditIcon = () => {
    return <Image src={editIcon} alt="onlineIcon" w="20px" h="20px" />;
};

const fetchMembers = async (space) => {
    const res = await axios.get(`/member`, {
        params: { space_id: space },
    });
    return res?.data;
};

const fetchDevices = async (space) => {
    const res = await axios.get(`/device`, {
        params: { space_id: space },
    });
    return res?.data;
};

// const fetchNotifications = async () => {
//     const res = await axios.get(`/notification`);
//     return res?.data;
// };

const fetchRoomsForSpace = async (spaceId) => {
    const res = await axios.get(`/room`, {
        params: { space_id: spaceId },
    });
    return res?.data;
};

const fetchDevicesForRoom = async (roomId) => {
    const res = await axios.get(`/device`, {
        params: { room_id: roomId },
    });
    return res?.data;
};

const FilterBox = ({ filterState, setFilterState, searchText, setSearchText, setQuery }) => {
    const ButtonTextStyle = {
        _hover: {
            bg: "#57BA47",
        },
        fontFamily: "fontFamily",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
        letterSpacing: "-0.01em",
        color: "#FFFFFF",
    };

    return (
        <Flex w="95%" h="42px" mt="32px" display="flex" justifyContent="space-between" alignItems="center">
            <HStack spacing={4} alignItems="center">
                <InputGroup>
                    <InputLeftElement pointerEvents="none">
                        <IoIosSearch fontSize={24} color="#81868180" />
                    </InputLeftElement>
                    <Input
                        type="text"
                        placeholder="Search by member's name"
                        w="300px"
                        h="40px"
                        border="1px solid #81868180"
                        _focus={{ boxShadow: "none", border: "1.5px solid", borderColor: "rgba(87, 186, 71, 1)" }}
                        _hover={{ boxShadow: "none", border: "1.5px solid", borderColor: "rgba(87, 186, 71, 1)", cursor: "pointer" }}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </InputGroup>

                <Box
                    h="40px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderRadius="4px"
                    background="#FFFFFF"
                    padding="4px"
                    flexShrink={0}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        padding="2px 8px"
                        gap="1"
                        bgColor={filterState === "all" ? "#e9ffdb" : "transparent"}
                        border={filterState === "all" ? "2px solid #6CC35E" : ""}
                        color={filterState === "all" ? "#11250E" : "#818681"}
                        borderRadius={4}
                        cursor="pointer"
                        onClick={() => setFilterState("all")}
                    >
                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#11250E">
                            All
                        </Text>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        padding="2px 8px"
                        gap="1"
                        bgColor={filterState === "active" ? "#e9ffdb" : "transparent"}
                        border={filterState === "active" ? "2px solid #6CC35E" : ""}
                        color={filterState === "active" ? "#11250E" : "#818681"}
                        borderRadius={4}
                        cursor="pointer"
                        onClick={() => setFilterState("active")}
                    >
                        <OnlineIcon />
                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#11250E">
                            Active
                        </Text>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        padding="2px 8px"
                        gap="1"
                        bgColor={filterState === "inactive" ? "#e9ffdb" : "transparent"}
                        border={filterState === "inactive" ? "2px solid #6CC35E" : ""}
                        color={filterState === "inactive" ? "#11250E" : "#818681"}
                        borderRadius={4}
                        cursor="pointer"
                        onClick={() => setFilterState("inactive")}
                    >
                        <OfflineIcon />
                        <Text fontFamily="fontFamily" fontWeight="600" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#11250E">
                            Inactive
                        </Text>
                    </Box>
                </Box>
            </HStack>

            <HStack>
                <CustomButton
                    w="192px"
                    h="40px"
                    bg="#57BA47"
                    sx={ButtonTextStyle}
                    aria-label="Add Member"
                    onClick={() => setQuery({ [SEARCH_PARAMS.MEMBER.ADD.key]: "success" })}
                >
                    <Image src={plusIcon} alt="add" h="16px" w="16px" mr="3px" />
                    Add Member
                </CustomButton>
            </HStack>

            <AddMember />
        </Flex>
    );
};

const Members = () => {
    const { space } = useParams();
    const [filterState, setFilterState] = useState("all");
    const [searchText, setSearchText] = useState("");
    const [query, setQuery] = useQueryParams(MEMBER_QUERY_PARAMS);
    const { activeUsers } = useActiveUsers();

    const { data: spaces, ...spacesQuery } = useQuery({
        queryKey: "fetchSpaces",
        enabled: false,
        select: function (data) {
            return data?.data?.filter((item) => item.id === space) || [];
        },
    });

    const { data: members = { data: [] }, ...membersQuery } = useQuery({
        queryKey: ["fetchMembers", space],
        queryFn: () => fetchMembers(space),
        enabled: !!space,
    });

    const { data: devices = { data: [] }, ...devicesQuery } = useQuery({
        queryKey: ["fetchDevices", space],
        queryFn: () => fetchDevices(space),
        enabled: !!space,
    });

    const { data: notifications = { data: [] }, refetch } = useQuery({
        queryKey: ["fetchNotifications"],
        // queryFn: fetchNotifications,
        enabled: true,
    });

    // Fetch rooms for the selected space
    const { data: roomsForSelectedSpace = { data: [] } } = useQuery({
        queryKey: ["fetchRoomsForSpace", space],
        queryFn: () => fetchRoomsForSpace(space),
        enabled: !!space,
    });

    // Fetch devices for each room in the selected space using useQueries
    const devicesQueries = useQueries({
        queries:
            roomsForSelectedSpace?.data?.map((room) => ({
                queryKey: ["fetchDevicesForRoom", room.id],
                queryFn: () => fetchDevicesForRoom(room.id),
                enabled: !!room.id,
            })) || [],
    });

    // Combine all devices from all rooms
    const devicesForSelectedSpace = devicesQueries.flatMap((query) => query.data?.data || []);

    // Function to filter notifications by devices in the current space
    const filterNotificationsBySpace = (spaceId) => {
        const deviceIds = devicesForSelectedSpace.map((device) => device.id);
        return notifications.data.filter((notification) => deviceIds.includes(notification.device));
    };

    const filteredNotifications = filterNotificationsBySpace(space);
    const unreadCount = filteredNotifications.filter((notification) => !notification.is_read).length;

    useEffect(() => {}, [notifications]);

    const filteredMembers = members?.data?.filter((member) => {
        const isActive = activeUsers.includes(member.id);
        const name = member?.name?.toLowerCase() || "";

        if (filterState === "all") {
            return name.includes(searchText.toLowerCase());
        }

        if (filterState === "active") {
            return isActive && name.includes(searchText.toLowerCase());
        }

        if (filterState === "inactive") {
            return !isActive && name.includes(searchText.toLowerCase());
        }

        return false;
    });
    const renderMenuItems = (member) => {
        const menuItems = [
            {
                label: "Edit Member",
                icon: <EditIcon />,
                action: () => {
                    setQuery({ [SEARCH_PARAMS.MEMBER.EDIT.key]: member?.id });
                },
            },
        ];

        if (member?.user_access?.[0]?.company_role !== "OWNER") {
            menuItems.push({
                label: "Delete Member",
                icon: <DeleteIcon />,
                action: () => {
                    setQuery({ [SEARCH_PARAMS.MEMBER.DELETE.key]: member?.id });
                },
            });
        }

        return menuItems.map((item, idx) => (
            <MenuItem
                key={idx}
                icon={item.icon}
                onClick={item.action}
                fontFamily="fontFamily"
                fontSize="14px"
                fontWeight="600"
                lineHeight="26px"
                letterSpacing="-0.01em"
                color="rgba(17, 37, 14, 1)"
                bg="rgba(255, 255, 255, 1)"
                _hover={{ bg: "none" }}
                iconSpacing="14px"
                paddingLeft="18px"
            >
                {item.label}
            </MenuItem>
        ));
    };

    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setDrawerOpen((prev) => !prev);
    };

    return (
        <>
            <Box bg="#F9F9F9" margin={0} width="100%" overflow="hidden">
                <HStack w="80%" h="110px" bg="#F8FBEE" justifyContent="space-between" position="fixed" top="0" zIndex="1000" paddingX="3rem">
                    <Text
                        fontFamily="fontFamily"
                        fontSize="28px"
                        fontWeight="800"
                        lineHeight="42px"
                        letterSpacing="-0.04em"
                        textAlign="left"
                        color="#11250E"
                    >
                        {spaces?.[0]?.name} / Members
                    </Text>

                    <HStack position="relative" onClick={toggleDrawer} cursor="pointer">
                        <img src={notification} alt="notification" width="48px" height="48px" />
                        {unreadCount > 0 && (
                            <Box
                                position="absolute"
                                top="0"
                                right="0"
                                bg="red.500"
                                color="white"
                                borderRadius="full"
                                width="24px"
                                height="24px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                fontSize="12px"
                            >
                                {unreadCount}
                            </Box>
                        )}
                    </HStack>
                    {isDrawerOpen && <Notification notifications={filteredNotifications} refetch={refetch} />}
                </HStack>

                <Box w="100%" h="100vh" mt="7rem">
                    <Box bg="#FBFBFB" h="full" w="full">
                        <VStack spacing="24px">
                            <Box w="100%" paddingLeft="3rem">
                                <FilterBox
                                    filterState={filterState}
                                    setFilterState={setFilterState}
                                    searchText={searchText}
                                    setSearchText={setSearchText}
                                    setQuery={setQuery}
                                />
                            </Box>

                            <SimpleGrid h="full" width="full" mt="40px">
                                {filteredMembers?.length ? (
                                    <TableContainer>
                                        <Table>
                                            <Thead
                                                fontSize="12px"
                                                backgroundColor="#FFFFFF"
                                                borderBottom="8px solid #FFFFFF"
                                                borderTop="8px solid #FFFFFF"
                                            >
                                                <Tr>
                                                    <Th
                                                        pl="52px"
                                                        key="Name"
                                                        textTransform="capitalize"
                                                        color="#818681"
                                                        fontWeight="500"
                                                        textAlign="left"
                                                        fontFamily="fontFamily"
                                                        letterSpacing="-0.01em"
                                                    >
                                                        Member Name
                                                    </Th>
                                                    <Th
                                                        pl="52px"
                                                        key="Designation"
                                                        textTransform="capitalize"
                                                        color="#818681"
                                                        fontWeight="500"
                                                        textAlign="left"
                                                        fontFamily="fontFamily"
                                                        letterSpacing="-0.01em"
                                                    >
                                                        Designation
                                                    </Th>
                                                    <Th
                                                        pl="52px"
                                                        key="Status"
                                                        textTransform="capitalize"
                                                        color="#818681"
                                                        fontWeight="500"
                                                        textAlign="left"
                                                        fontFamily="fontFamily"
                                                        letterSpacing="-0.01em"
                                                    >
                                                        Status
                                                    </Th>
                                                    <Th
                                                        pl="52px"
                                                        key="Actions"
                                                        textTransform="capitalize"
                                                        color="#818681"
                                                        fontWeight="500"
                                                        textAlign="left"
                                                        fontFamily="fontFamily"
                                                        letterSpacing="-0.01em"
                                                    >
                                                        Actions
                                                    </Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody
                                                variant="striped"
                                                sx={{
                                                    "tr:nth-of-type(odd)": { backgroundColor: "#F6F6F6", border: "1px solid #E0E5E0" },
                                                    "tr:nth-of-type(even)": { backgroundColor: "#FFFFFF" },
                                                }}
                                                fontSize="12px"
                                                mt={0}
                                                pt={0}
                                            >
                                                {filteredMembers.map((member, index) => (
                                                    <Tr key={member?.id}>
                                                        <Td pl="52px">
                                                            <Box display="flex" alignItems="center">
                                                                <Image
                                                                    src={member.picture || memberImage}
                                                                    alt=""
                                                                    w="32px"
                                                                    h="32px"
                                                                    borderRadius="50%"
                                                                />
                                                                <Box ml="8px">
                                                                    <Text
                                                                        fontFamily="fontFamily"
                                                                        fontWeight="500"
                                                                        fontSize="14px"
                                                                        lineHeight="20px"
                                                                        letterSpacing="-0.01em"
                                                                        color="#11250E"
                                                                    >
                                                                        {member?.name}
                                                                    </Text>
                                                                    <Text
                                                                        fontFamily="fontFamily"
                                                                        fontWeight="500"
                                                                        fontSize="12px"
                                                                        lineHeight="16px"
                                                                        letterSpacing="-0.01em"
                                                                        color="#818681"
                                                                    >
                                                                        {member?.email}
                                                                    </Text>
                                                                </Box>
                                                            </Box>
                                                        </Td>
                                                        <Td pl="52px">
                                                            <Text
                                                                fontFamily="fontFamily"
                                                                fontWeight="600"
                                                                fontSize="12px"
                                                                lineHeight="16px"
                                                                letterSpacing="-0.01em"
                                                                color="#11250E"
                                                            >
                                                                {member?.user_access?.[0]?.company_role
                                                                    ? member?.user_access?.[0]?.company_role?.charAt(0).toUpperCase() +
                                                                      member?.user_access?.[0]?.company_role?.slice(1).toLowerCase()
                                                                    : ""}
                                                            </Text>
                                                        </Td>
                                                        <Td pl="52px">
                                                            <Box display="flex" alignItems="center" gap="7px">
                                                                {activeUsers?.includes(member?.id) ? <OnlineIcon /> : <OfflineIcon />}
                                                                <Text
                                                                    fontFamily="fontFamily"
                                                                    fontWeight="600"
                                                                    fontSize="12px"
                                                                    lineHeight="16px"
                                                                    letterSpacing="-0.01em"
                                                                    color="#11250E"
                                                                >
                                                                    {activeUsers?.includes(member?.id) ? "Active" : "Inactive"}
                                                                </Text>
                                                            </Box>
                                                        </Td>
                                                        <Td pl="52px">
                                                            <Menu>
                                                                <MenuButton>
                                                                    <Icon as={BsThreeDotsVertical} w="20px" h="20px" />
                                                                </MenuButton>
                                                                <MenuList>{renderMenuItems(member)}</MenuList>
                                                            </Menu>
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Box display="flex" h="100%" flexDir="column" justifyContent="center" alignItems="center">
                                        <Image src={fallback} p={8} />
                                        <Divider w="25%" borderColor="#DBDBDB" />
                                        <Text p={2} fontFamily="fontFamily" fontSize="28px" fontWeight={700} lineHeight="42px" letterSpacing="-1%">
                                            No members to show!
                                        </Text>
                                    </Box>
                                )}
                            </SimpleGrid>
                        </VStack>
                    </Box>
                </Box>
            </Box>

            <EditMember />
            <DeleteMember />
        </>
    );
};

export default Members;
