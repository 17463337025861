import React, { useEffect, useState } from "react";
import {
    border,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    FormLabel,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Link,
    space,
    Stack,
    Text,
    useQuery,
    VStack,
} from "@chakra-ui/react";
import CustomButton from "../components/CustomButton";
import EmailIcon from "../assets/email-icon.svg";
import PasswordIcon from "../assets/lock.svg";
import logo from "../assets/logo.svg";
import googleIcon from "../assets/googleIcon.svg";
import AppleIcon from "../assets/AppleIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useImpersonation } from "../contexts/useImpersonationContext";
import { fetchSpaces } from "../common/SideBar";

const googleIconComponent = () => {
    return <Image src={googleIcon} alt="google-icon" />;
};

const AppleIconComponent = () => {
    return <Image src={AppleIcon} alt="apple-icon" />;
};

const PROVIDERS = {
    GOOGLE: {
        baseURL: "https://accounts.google.com/o/oauth2/v2/auth?",
        params: new URLSearchParams([
            ["client_id", process.env.REACT_APP_GOOGLE_CLIENT_ID],
            ["redirect_uri", `${window.location.origin}/oauth-redirect`],
            ["scope", "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid"],
            ["state", "google"],
            ["response_type", "code"],
            ["prompt", "consent"],
            ["include_granted_scopes", true],
        ]),
    },
};

const LoginScreen = () => {
    const navigate = useNavigate();
    const { clearSessionStorage } = useImpersonation();

    const loginMutation = useMutation({
        mutationFn: async function ({ email, password }) {
            const response = await axios.post("/auth/login", { email, password });
            return response;
        },
        onSuccess: function (response) {
            const token = response?.data?.data?.access_token;
            localStorage.setItem("token", token);
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;

            if (response?.data?.data?.company?.company_access?.global_role === "SUPER_ADMIN") {
                navigate("/admin/dashboard");
            } else {
                navigate("/dashboard");
            }
        },
        onError: function (error) {
            formik.setFieldError("general", error.response?.data?.error || "An unexpected error occurred.");
        },
    });

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Enter a valid email.").required("Email is required."),
            password: Yup.string().min(6, "Password is too short.").required("Password is required."),
        }),
        onSubmit: function (values) {
            loginMutation.mutate(values);
        },
    });

    function navigateToGoogle() {
        window.location.href = `${PROVIDERS.GOOGLE.baseURL}${PROVIDERS.GOOGLE.params}`;
    }

    useEffect(() => {
        clearSessionStorage();
    }, []);

    const placeHolderCss = {
        border: "1.5px solid",
        bg: "#FFFFF",
        borderColor: "#81868180",
        _focus: {
            boxShadow: "none",
            borderRadius: "4px",
            border: "1.5px solid #57BA47",
            background: "#FFF",
        },
        borderRadius: "4px",
        border: "1px solid #818681",
        background: "#FFF",
        _placeholder: {
            color: "#11250E",
            opacity: 0.4,
            fontFamily: "fontFamily",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "20px",
            letterSpacing: "-0.14px",
            _hover: {
                boxShadow: "none",
                borderRadius: "4px",
                border: "1.5px solid #57BA47",
                background: "#FFF",
            },
            _focus: {
                boxShadow: "none",
                borderRadius: "4px",
                border: "1.5px solid #57BA47",
                background: "#FFF",
            },
        },
    };
    const OpenwithGoogle_Apple = {
        _hover: {
            bg: "#FFFFFF",
        },
        color: "#11250E",
        fontFamily: "fontFamily",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px" /* 133.333% */,
        letterSpacing: "-0.12px",
    };
    const spantext = {
        color: "#57BA47",
        fontFamily: "fontFamily",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "20px",
        letterSpacing: "-0.14px",
    };
    const EmailPassword = {
        color: "#11250E",
        fontFamily: "fontFamily",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "30px" /* 187.5% */,
        letterSpacing: "-0.16px",
    };

    return (
        <VStack
            width={{
                base: "100%",
                md: "50%",
            }}
            display="flex"
            justifyContent="center"
            align={{
                base: "center",
                lg: "flex-start",
            }}
            height="100%"
        >
            <VStack
                w={{
                    base: "100%",
                    sm: "70%",
                    md: "70%",
                    lg: "70%",
                    xl: "70%",
                    "2xl": "80%",
                }}
                ml={{
                    base: "",
                    sm: "20%",
                    md: "10%",
                    lg: "10%",
                    xl: "10%",
                }}
                align="start"
            >
                <Box w="100%" mb="20px">
                    <Image src={logo} alt="logo" />
                    <Text
                        w="100%"
                        sx={{
                            color: "#11250E",
                            fontFamily: "fontFamily",
                            fontSize: "28px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "42px",
                            letterSpacing: "-0.28px",
                            display: "flex",
                            alignContent: "left",
                        }}
                    >
                        Login to Instantemp
                    </Text>
                </Box>
                <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
                <Stack w="100%" spacing={4}>
                    <Box w="100%">
                        <FormControl isInvalid={formik.errors.name && formik.touched.name} isRequired>
                            <FormLabel htmlFor="desc" sx={EmailPassword}>
                                Email
                            </FormLabel>
                            <InputGroup size="sm" w="100%">
                                <InputLeftElement>
                                    <Image src={EmailIcon} alt="email icon" />
                                </InputLeftElement>
                                <Input
                                    placeholder="Enter your registered email address"
                                    sx={placeHolderCss}
                                    type="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.errors.email && formik.touched.email}
                                />
                            </InputGroup>
                            {formik.errors.email && formik.touched.email && (
                                <Text color="red.500" fontSize="sm">
                                    {formik.errors.email}
                                </Text>
                            )}
                        </FormControl>
                    </Box>

                    <Box mb="5%">
                        <FormControl isInvalid={formik.errors.password && formik.touched.password} isRequired>
                            <FormLabel sx={EmailPassword}>Password</FormLabel>
                            <InputGroup size="sm" w="100%">
                                <InputLeftElement>
                                    <Image src={PasswordIcon} alt="password icon" />
                                </InputLeftElement>
                                <Input
                                    placeholder="*******"
                                    sx={placeHolderCss}
                                    type="password"
                                    name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.errors.password && formik.touched.password}
                                />
                            </InputGroup>
                            {formik.errors.password && formik.touched.password && (
                                <Text color="red.500" fontSize="sm">
                                    {formik.errors.password}
                                </Text>
                            )}

                            <Text
                                sx={{
                                    color: "#57BA47",
                                    fontSize: ["12px", "13px"],
                                    display: "flex",
                                    justifyContent: "right",
                                    lineHeight: "20px",
                                    letterSpacing: "-0.01em",
                                    fontWeight: "600",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#4BA03C",
                                    },
                                    "&:focus": {
                                        outline: "2px solid #57BA47",
                                    },
                                }}
                                onClick={() => navigate("/reset-password")}
                            >
                                Forgot Password
                            </Text>
                        </FormControl>
                    </Box>

                    {formik.errors.general && (
                        <Text color="red.500" fontSize="sm">
                            {formik.errors.general}
                        </Text>
                    )}

                    <Button
                        type="submit"
                        isLoading={loginMutation.isLoading}
                        disabled={!formik.isValid || loginMutation.isPending}
                        sx={{
                            _hover: {
                                bg: "#57BA47",
                            },
                            fontFamily: "fontFamily",
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterSpacing: "-0.01em",
                            color: "#FFFFFF",
                            bg: "#57BA47",
                        }}
                        loadingText="Logging in"
                        colorScheme="green"
                        variant="solid"
                        width="100%"
                    >
                        Login
                    </Button>

                    {/* <Text
                        mt="0.01em"
                        w="100%"
                        align="center"
                        color="#11250E"
                        fontFamily="fontFamily"
                        fontSize="14px"
                        fontStyle="normal"
                        fontWeight="500"
                        lineHeight="20px"
                        letterSpacing="-0.14px"
                    >
                        Don't have an account? <Link sx={spantext}>Sign up</Link>
                    </Text> */}
                </Stack>

                {/* <Box display="flex" alignItems="center" w="100%" mt="4">
                    <Divider background="rgba(129, 134, 129, 0.20)" borderWidth="1px" w="45%" />
                    <Text as="span" mx="5%">
                        Or
                    </Text>
                    <Divider background="rgba(129, 134, 129, 0.20)" borderWidth="1px" w="45%" />
                </Box> */}

                {/* <VStack w="100%" flexDirection="column" gap={4} mt="4">
                    <CustomButton
                        w="100%"
                        mb="3%"
                        border="1px solid"
                        borderColor="gray.100"
                        sx={OpenwithGoogle_Apple}
                        bg="#FFFFFF"
                        onClick={navigateToGoogle}
                    >
                        <Box as="span" mr="8px">
                            {googleIconComponent()}
                        </Box>
                        Login With Google
                    </CustomButton>

                    <CustomButton bg="#FFFFFF" w="100%" border="1px solid" borderColor="gray.100" sx={OpenwithGoogle_Apple}>
                        <Box as="span" mr="8px">
                            {AppleIconComponent()}
                        </Box>
                        Login With Apple
                    </CustomButton>
                </VStack> */}
                </form>
            </VStack>
        </VStack>
    );
};

export default LoginScreen;
