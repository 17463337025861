// used as a socket wrapper
import { useEffect } from "react";
import { io } from "socket.io-client";
import { useQuery } from "@tanstack/react-query";
import { Box, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import CustomToast from "../CustomToast";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const query = {
    user_id: null,
};

export const socket = io(process.env.REACT_APP_SOCKET_BASE_URL, {
    autoConnect: false,
    query,
});

const fetchNotifications = async () => {
    const res = await axios.get(`/notification`);
    return res?.data;
};

export default function DashboardWrapper(props) {
    const queryClient = useQueryClient();
    const toast = useToast();
    const navigate = useNavigate();

    const { data: userId, ...userQuery } = useQuery({
        queryKey: "fetchProfile",
        enabled: false,
        select: function (data) {
            return data?.data?.user?.id;
        },
    });

    const notifications = useQuery({
        queryKey: ["fetchNotifications"],
        queryFn: fetchNotifications,
    });

    // socket connection and connection handling
    useEffect(() => {
        if (userId) {
            query.user_id = userId;

            socket.connect();

            socket.once("disconnect", (reason) => {
                console.log("Disconnected due to:", reason); // Log the reason for disconnection
            });

            socket.once("connect_error", (error) => {
                console.error("Connection Error:", error.message);
            });

            return function () {
                socket.disconnect();
            };
        }
    }, [userId]);

    // socket events handling
    useEffect(() => {
        // alerts
        socket.on("alert", function (data) {
            const { space_id, device_id } = data;
            const id = `_${space_id}${device_id}`;

            const status = "error";

            toast({
                id,
                description: data.message,
                status,
                duration: 10000,
                isClosable: true,
                position: "top",
                render: () => (
                    <CustomToast
                        text={`${data.message} Click to view details`}
                        onClick={() => {
                            navigate(`/dashboard/${data.space_id}/devices/${data.device_id}`);
                            toast.close(id);
                        }}
                        status={status}
                        onClose={() => toast.close(id)}
                        showViewDetails={true}
                    />
                ),
            });
        });

        // live data update
        socket.on("data-update", function (incomingData) {
            // add the data to the end of the graph and remove one metric from the start
            queryClient.setQueriesData(
                { queryKey: ["fetchLogs", { device: incomingData?.device, reading_type: "MINUTES", start_date: null, end_date: null }] },
                function (previousData) {
                    console.log("found query", previousData);
                    if (previousData) return { ...previousData, data: [incomingData, ...previousData.data] };
                    return previousData;
                },
            );
        });

        // notification
        socket.on("notification", function (incomingData) {
            console.log("recieved data", incomingData);
            // add the data to the end of the graph and remove one metric from the start
            queryClient.setQueryData(["fetchNotifications"], function (previousData) {
                if (previousData) return { ...previousData, data: [incomingData, ...previousData.data] };
                return previousData;
            });
        });

        return () => {
            socket.off("alert");
            socket.off("data-update");
            socket.off("notification");
        };
    }, []);

    // window event handler
    useEffect(() => {
        const id = `_${Math.random()}`;
        function handler(event) {
            const message = event.detail.message;

            toast({
                id,
                description: message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
                render: () => <CustomToast text={message} status="error" onClose={() => toast.close(id)} />,
            });
        }
        window.addEventListener("validation-toast", handler);

        return function () {
            window.removeEventListener("validation-toast", handler);
        };
    }, []);

    return props.children;
}
