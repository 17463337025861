import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormLabel,
    Image,
    Input,
    Text,
    FormControl,
    FormErrorMessage,
} from "@chakra-ui/react";
import React from "react";
import axios from "axios";
import CustomButton from "./CustomButton";
import plusIcon from "../assets/Plus_Icon.png";
import Step2EditDetail from "./Step2EditDetail";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";

const inputStyle = {
    borderRadius: "4px",
    border: "1px solid #81868180",
    fontFamily: "fontFamily",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "-0.01em",
    color: "#000000",
    _focus: {
        border: "1.5px solid #57BA47",
        boxShadow: "none",
    },
    "::placeholder": {
        color: "#818681",
        opacity: 0.4,
    },
};

const labelStyle = {
    fontFamily: "fontFamily",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "-0.01em",
    marginBottom: "4px",
    color: "#11250E",
};

const EditCompany = ({
    companyIsOpen,
    companyOnClose,
    step2companyIsOpen,
    step2companyOnClose,
    step2companyOnOpen,
    prevDetail,
    userProfileQuery,
}) => {
    const btnRef = React.useRef();

    const formik = useFormik({
        initialValues: {
            id: prevDetail?.company?.id || "",
            name: prevDetail?.company?.name || "",
            size: prevDetail?.company?.size || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().min(3, "Company name is too short.").required("Company name is required."),
            size: Yup.string()
                .oneOf(["Less than 10", "10 - 50", "50 - 100", "100 - 200", "200 - 500", "500 - 1000", "More than 1000"], "Invalid company size.")
                .required("Company size is required."),
        }),
        enableReinitialize: true, // Reinitialize form when prevDetail changes
        onSubmit: (values) => {
            editCompanyMutation.mutate(values);
        },
    });

    const editCompanyMutation = useMutation({
        mutationFn: async (values) => {
            const response = await axios.patch(`/auth/update/company-detail`, {
                company_id: values.id,
                name: values.name,
                size: values.size,
            });
            return response;
        },
        onSuccess: (response) => {
            if (response.status === 200) {
                formik.resetForm();
                companyOnClose();
                step2companyOnOpen();
                userProfileQuery.refetch();
            } else {
                // Handle unexpected success response
                formik.setStatus({
                    error: response?.data?.error || "Edit Company Detail failed. Please try again.",
                });
            }
        },
        onError: (error) => {
            console.error("Error in Company Detail Updation:", error);
            const errorMessage = error?.response?.data?.error || "An unexpected error occurred.";
            formik.setStatus({ error: errorMessage });
        },
    });

    const handleCompanySizeClick = (size) => {
        formik.setFieldValue("size", size);
    };

    return (
        <>
            <Drawer isOpen={companyIsOpen} placement="right" onClose={companyOnClose} finalFocusRef={btnRef}>
                <DrawerOverlay />
                <DrawerContent minWidth={{ base: 380, md: 480, lg: 480 }} my="5px" marginRight="1rem" borderRadius="10px">
                    <Box mt={{ base: "0px", sm: "0px", md: "5px", lg: "10px" }} mr="10px">
                        <DrawerCloseButton sx={{ color: "#11250E", p: "8px", bg: "#F6F6F6" }} />
                        <DrawerHeader
                            fontFamily="fontFamily"
                            fontSize="28px"
                            fontWeight="800"
                            lineHeight="42px"
                            letterSpacing="-0.04em"
                            textAlign="left"
                            color="#11250E"
                        >
                            Edit Company Detail
                        </DrawerHeader>
                    </Box>

                    <DrawerBody>
                        <Box mb={4}>
                            <FormControl isInvalid={formik.errors.name && formik.touched.name} isRequired>
                                <FormLabel htmlFor="company" sx={labelStyle}>
                                    Company Name
                                </FormLabel>
                                <Input
                                    id="company"
                                    placeholder="ex: Instatemp"
                                    type="text"
                                    name="name"
                                    sx={inputStyle}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mt={8}>
                            <FormControl isInvalid={formik.errors.size && formik.touched.size} isRequired>
                                <FormLabel htmlFor="companySize" sx={labelStyle}>
                                    Company Size
                                </FormLabel>
                                <Box display="flex" gap="8px" flexWrap="wrap">
                                    {["Less than 10", "10 - 50", "50 - 100", "100 - 200", "200 - 500", "500 - 1000", "More than 1000"].map((item) => (
                                        <Box
                                            as="span"
                                            key={item}
                                            border="1px solid"
                                            borderColor="#DDE2DD"
                                            h="4.7vh"
                                            borderRadius="24px"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            cursor="pointer"
                                            bg={formik.values.size === item ? "#DDF1DA" : "none"}
                                            _hover={{
                                                borderColor: formik.values.size === item ? "none" : "#11250E",
                                            }}
                                            minWidth="120px" // Adjust minWidth as needed
                                            flex="1 1 auto" // Allow items to grow and shrink
                                            maxWidth="150px" // Set a max width for responsiveness
                                            onClick={() => handleCompanySizeClick(item)}
                                        >
                                            <Text
                                                color="#11250E"
                                                fontFamily="fontFamily"
                                                fontSize="14px"
                                                fontStyle="normal"
                                                fontWeight="500"
                                                lineHeight="20px"
                                                letterSpacing="-0.14px"
                                                textAlign="center" // Center text
                                            >
                                                {item}
                                            </Text>
                                        </Box>
                                    ))}
                                </Box>
                                <FormErrorMessage>{formik.errors.size}</FormErrorMessage>
                            </FormControl>
                        </Box>
                    </DrawerBody>

                    <DrawerFooter>
                        <Flex direction="column" justifyContent="center" gap={2} width="100%">
                            <CustomButton
                                width="100%"
                                h="40px"
                                bg="#57BA47"
                                sx={{
                                    _hover: { bg: "#57BA47" },
                                    fontFamily: "fontFamily",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                }}
                                marginInline="auto"
                                onClick={formik.handleSubmit}
                                disabled={!formik.isValid || editCompanyMutation.isPending}
                            >
                                <Image src={plusIcon} alt="Edit company" h="16px" w="16px" mr="3px" />
                                Edit Company Detail
                            </CustomButton>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <Step2EditDetail step2DetailIsOpen={step2companyIsOpen} step2DetailOnClose={step2companyOnClose} prevDetail={prevDetail} />
        </>
    );
};

export default EditCompany;
