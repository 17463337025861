import React, { useState } from "react";
import { VStack, Text, Image, GridItem, Grid, Box, Input } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { Onboarding7 } from "./Onboarding7";
import CustomButton from "../components/CustomButton";
import userIcon from "../assets/user.png";
import downArrow from "../assets/arrow-down.svg";
import cubeOnboarding from "../assets/3CubeOnboarding.svg";
import logo from "../assets/logo.svg";
import { Reveal } from "../common/Reveal";
import { useDispatch, useSelector } from "react-redux";
import { Toggle_Step6Function, Toggle_Step7Function } from "../redux/action";
import axios from "axios";

export const Onboarding6 = ({ spaceId, yourname, designation, space }) => {
    const [room, setRoom] = useState("");
    const [roomId, setRoomId] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const Step7 = useSelector((store) => store.ConditionalRenderingReducer.step7);

    const prevSetupRoom = () => {
        dispatch(Toggle_Step6Function());
    };

    const ButtonTextStyle1 = {
        bg: "#FFF",
        border: "1px solid",
        borderColor: "#57BA47",
        _hover: {
            bg: "#FFF",
        },
        color: "#57BA47",
        fontFamily: "fontFamily",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };
    const ButtonTextStyle2 = {
        bg: "#FFFFFF",
        _hover: { bg: "#FFFFFF" },
        color: "#11250E",
        fontFamily: "fontFamily",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };
    const ButtonTextStyle3 = {
        bg: "#57BA47",
        _hover: {
            bg: "#57BA47",
        },
        color: "#FFF",
        fontFamily: "fontFamily",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
    };

    function skipToDashboard() {
        window.location.href = "/dashboard";
    }

    const handleSetupDeviceInRoom = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`/room`, {
                name: room,
                space: spaceId,
            });

            if (response.status === 200) {
                setRoomId(response?.data?.data?.id);
                // console.log("User Room creation successfull.");
                dispatch(Toggle_Step7Function());
                return true;
            } else {
                console.error(`Error in Room Creation: ${response?.data?.error}`);
                return false;
            }
        } catch (error) {
            console.error("Error in Room Creation:", error);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
    const rotationVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
    };

    return (
        <>
            {Step7 ? (
                <Onboarding7 roomId={roomId} space={space} room={room} yourname={yourname} designation={designation} />
            ) : (
                <Grid bg="#F2F2F266" w="100%" h="100vh" gridTemplateColumns="repeat(2,1fr)">
                    <VStack w="100%" display="flex" flexDirection="column" h="100vh">
                        <Box w="90%" display="flex" flexDirection="column" h="69vh" gap="2rem" mt="4rem" ml="102px">
                            <VStack align="left">
                                <Box alignItems="flex-start">
                                    <Reveal hidden={rotationVariants.hidden} visible={rotationVariants.visible} delay={0.1}>
                                        <Image
                                            alignItems="left"
                                            src={cubeOnboarding}
                                            alt="cube"
                                            css={{
                                                animation: `${rotate360} 1s linear`,
                                            }}
                                        />
                                    </Reveal>
                                </Box>
                                <VStack display="flex" align="left">
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="32px"
                                        fontWeight="700"
                                        lineHeight="40px"
                                        letterSpacing="-0.01em"
                                        textAlign="left"
                                    >
                                        You are almost there!! Setup room now
                                    </Text>
                                    <Text
                                        color="#818681"
                                        fontFamily="fontFamily"
                                        fontSize="14px"
                                        fontWeight="500"
                                        lineHeight="20px"
                                        letterSpacing="-0.01em"
                                        textAlign="left"
                                    >
                                        Congratulations on setting up your space! Now, let's add your first room so you can effectively manage your
                                        devices.
                                    </Text>
                                </VStack>
                            </VStack>
                            <Grid gridTemplateColumns="90%" gridColumnGap="1rem">
                                <GridItem display="flex" flexDirection="column" gap="0.5rem">
                                    <Text
                                        fontFamily="fontFamily"
                                        color="rgba(17, 37, 14, 1)"
                                        fontSize="16px"
                                        fontStyle="normal"
                                        fontWeight="600"
                                        lineHeight="30px"
                                        letterSpacing="-0.01em"
                                    >
                                        Room Name *
                                    </Text>
                                    <Input
                                        type="text"
                                        _placeholder={{
                                            fontFamily: "fontFamily",
                                            color: "rgba(129, 134, 129, 1)",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "20px",
                                            letterSpacing: "-0.01em",
                                            alignContent: "start",
                                        }}
                                        placeholder="ex: Room 01"
                                        value={room}
                                        onChange={(e) => setRoom(e.target.value)}
                                        _hover={{
                                            border: "2px solid",
                                            borderColor: "#57BA47",
                                            boxShadow: "none",
                                        }}
                                        _focus={{
                                            border: "2px solid",
                                            borderColor: "#57BA47",
                                            boxShadow: "none",
                                        }}
                                    />
                                </GridItem>
                            </Grid>

                            <Box display="flex" justifyContent={"space-between"} w="90%" mt="1.5rem">
                                <CustomButton w="15%" sx={ButtonTextStyle1} onClick={prevSetupRoom}>
                                    Previous
                                </CustomButton>

                                <Box display="flex" justifyContent="space-between" w="35%">
                                    <CustomButton w="45%" sx={ButtonTextStyle2} onClick={skipToDashboard}>
                                        Skip
                                    </CustomButton>

                                    <CustomButton w="45%" sx={ButtonTextStyle3} disabled={!room || loading} onClick={handleSetupDeviceInRoom}>
                                        Next
                                    </CustomButton>
                                </Box>
                            </Box>
                        </Box>
                    </VStack>
                    <VStack display="flex" alignItems="start">
                        <Grid
                            w="80%"
                            h="40vh"
                            ml="3rem"
                            borderRadius="16px 16px 0px 0px"
                            background="#F2F2F2"
                            pt="2rem"
                            pl="2rem"
                            pr="2rem"
                            gridTemplateColumns="30% auto"
                            mt="8rem"
                        >
                            <GridItem background="#11250E" h="35.5vh" borderRadius="8px 0px 0px 0px" align="center">
                                <Image src={logo} alt="" w="19px" h="15px" mt="10%" />
                                <Box
                                    borderRadius="50%"
                                    overflow="hidden"
                                    width="50px"
                                    height="50px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    background="#F2F2F2"
                                    mt="15%"
                                >
                                    <Image src={userIcon} alt="useIcon" />
                                </Box>
                                <Text color="white">{yourname}</Text>
                                <Text color="rgba(242, 242, 242, 0.50)">{designation}</Text>
                                <Box
                                    border="1px solid"
                                    borderColor="#FBFBFB"
                                    w="90%"
                                    h="24px"
                                    mt="1rem"
                                    borderRadius="4px 4px 4px 4px"
                                    padding="4px 10px 4px 10px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Text
                                        color="#FBFBFB"
                                        fontFamily="fontFamily"
                                        fontSize="10px"
                                        fontStyle="normal"
                                        fontWeight="600"
                                        lineHeight="16px"
                                    >
                                        {space ? `${space}` : "Your space"}
                                    </Text>
                                    <Image src={downArrow} alt="arrow" />
                                </Box>
                            </GridItem>
                            <GridItem borderRadius="0px 8px 0px 0px" bg="#FFFFFF" h="35.7vh">
                                <Box bg="#F8FBEE" h="7vh" display="flex" alignItems="center">
                                    <Text
                                        color="#11250E"
                                        ml="2rem"
                                        fontFamily="fontFamily"
                                        fontSize="12px"
                                        fontWeight="600"
                                        lineHeight="16px"
                                        letterSpacing="-0.01em"
                                        textAlign="left"
                                    >
                                        {space ? `${space}` : "Your space"}
                                    </Text>
                                </Box>
                                <Reveal hidden={{ opacity: 0, y: 40 }} visible={{ opacity: 1, y: 0 }} delay={0.1}>
                                    <Grid gridTemplateColumns="50% 50%" w="100%" pt="2rem" pl="1.5rem" pr="4rem" columnGap="0.5rem" rowGap="0.5rem">
                                        <GridItem
                                            borderRadius="8px 8px 8px 8px"
                                            h="18vh"
                                            border="1px solid"
                                            bg="rgba(221, 241, 218, 1)"
                                            borderColor="rgba(17, 37, 14, 0.5)"
                                        >
                                            <Text
                                                fontFamily="fontFamily"
                                                color="rgba(17, 37, 14, 1)"
                                                fontSize="12px"
                                                fontWeight="700"
                                                lineHeight="16px"
                                                letterSpacing="-0.01em"
                                                textAlign="left"
                                                mt="5.2rem"
                                                ml="0.5rem"
                                            >
                                                {room ? `${room}` : "Room 01"}
                                            </Text>
                                            <Text
                                                fontFamily="fontFamily"
                                                color="rgba(129, 134, 129, 1)"
                                                fontSize="8px"
                                                fontWeight="400"
                                                lineHeight="12px"
                                                letterSpacing="-0.01em"
                                                textAlign="left"
                                                ml="0.5rem"
                                            >
                                                Details for better clarity.
                                            </Text>
                                        </GridItem>
                                        <GridItem borderRadius="8px 8px 8px 8px" h="18vh" bg="rgba(242, 242, 242, 1)"></GridItem>
                                        <GridItem borderRadius="8px 8px 0px 0px" h="5.3vh" bg="rgba(242, 242, 242, 1)"></GridItem>
                                        <GridItem borderRadius="8px 8px 0px 0px" h="5.3vh" bg="rgba(242, 242, 242, 1)"></GridItem>
                                    </Grid>
                                </Reveal>
                            </GridItem>
                        </Grid>
                    </VStack>
                </Grid>
            )}
        </>
    );
};
