import React, { useState } from "react";
import axios from "axios";
import { Box, Link, Grid, GridItem, Image, Text, VStack } from "@chakra-ui/react";

import CustomButton from "../components/CustomButton";
import onboarding_open_wifi from "../assets/onboarding_open_wifi.svg";
import { useDispatch, useSelector } from "react-redux";
import { SetUpDevice5 } from "./SetUpDevice5";
import { Add_Device3_Toggle_Stepper_Function, Add_Device4_Toggle_Stepper_Function } from "../redux/action";
import { useNavigate } from "react-router-dom";
// import { Link } from 'react-router-dom';
export const SetUpDevice4 = ({ serialno, deviceName, connectionKey, roomId }) => {
    const Add_Device4_Stepper = useSelector((store) => store.ConditionalRenderingReducer.Add_Device4_Stepper);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const Heading = {
        color: "#11250E",
        fontFamily: "fontFamily",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: "800",
        lineHeight: "42px",
        letterSpacing: "-0.01em",
    };

    const buttonText = {
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "24px",
        letterSpacing: "-0.16%",
    };
    const noteStyle = {
        color: "#818681",
        fontFamily: "fontFamily",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "0.1%",
    };
    const handle_link_Open_Wifi = async () => {
        // dispatch(Add_Device4_Toggle_Stepper_Function());
        setLoading(true);

        try {
            const response = await axios.post(`/device`, {
                room: roomId,
                serial_number: serialno,
                connection_key: connectionKey,
                pairing_key: "ABC000",
                name: deviceName,
            });

            if (response.status === 200) {
                navigate("/progressbar");
                return true;
            } else {
                console.error(`Error in Device Creation: ${response?.data?.error}`);
                return false;
            }
        } catch (error) {
            console.error("Error in Device Creation:", error);
            return false;
        } finally {
            setLoading(false);
        }
    };
    const Previous_handle_link_Open_Wifi = () => [dispatch(Add_Device3_Toggle_Stepper_Function())];

    const skipToTheDashboardThree = () => {
        window.location.href = "/dashboard";
    };

    return (
        <>
            <Grid gridTemplateColumns="45% auto" mt="1rem">
                <VStack align="start" ml="6.9rem">
                    <VStack align="start" h="50vh">
                        <GridItem w="100%" h="5vh" mb="1rem">
                            <Text sx={Heading}>Click on the link to open Wifi </Text>
                        </GridItem>
                        <GridItem w="75%">
                            <Text sx={noteStyle}>Step 1: After completing the SSID setup from the previous steps.</Text>
                        </GridItem>
                        <GridItem w="75%">
                            <Text mb="0.5rem" sx={noteStyle}>
                                Step 2: Click here or visit this link:
                                <Link
                                    href="http://192.168.1.1/"
                                    isExternal
                                    textDecoration="underline"
                                    fontFamily="fontFamily"
                                    color="rgba(84, 137, 236, 1)"
                                >
                                    {" "}
                                    http://192.168.1.1.
                                </Link>
                            </Text>
                        </GridItem>

                        <GridItem w="75%">
                            <Text sx={noteStyle}>Step 3: A page similar to the one shown in the screenshot will appear.</Text>
                        </GridItem>
                        <GridItem w="75%">
                            <Text sx={noteStyle}>
                                Step 4: From the list of available Wi-Fi networks, select the Wi-Fi you want to connect your Thermadata device to.
                            </Text>
                        </GridItem>
                        <GridItem w="75%">
                            <Text sx={noteStyle}>Step 5: Enter your Wi-Fi password in the designated field.</Text>
                        </GridItem>
                        <GridItem w="75%">
                            <Text sx={noteStyle}>Step 6: Finally, click on the "Save Configuration" button to complete the setup.</Text>
                        </GridItem>
                    </VStack>

                    <GridItem display="flex" justifyContent="space-between" w="75%">
                        <Box w="30%" display="flex">
                            <CustomButton
                                bg="rgba(255, 255, 255, 1)"
                                color="rgba(87, 186, 71, 1)"
                                border="2px solid"
                                borderColor="rgba(87, 186, 71, 1)"
                                onClick={Previous_handle_link_Open_Wifi}
                            >
                                Back
                            </CustomButton>
                        </Box>
                        <Box display="flex" justifyContent="flex-end" alignItems="center" w="90%" gap="1rem">
                            <CustomButton
                                color="rgba(0, 0, 0, 1)"
                                w="30%"
                                bg="rgba(255, 255, 255, 1)"
                                sx={buttonText}
                                onClick={skipToTheDashboardThree}
                            >
                                Cancel
                            </CustomButton>
                            <CustomButton
                                color="rgba(255, 255, 255, 1)"
                                w="27%"
                                h="40px"
                                bg="rgba(87, 186, 71, 1)"
                                sx={buttonText}
                                onClick={handle_link_Open_Wifi}
                            >
                                Next
                            </CustomButton>
                        </Box>
                    </GridItem>
                </VStack>

                <GridItem display="flex" justifyContent="center" alignItems="center">
                    <Box mb="2rem">
                        <Image src={onboarding_open_wifi} alt="deviceAdd" />
                    </Box>
                </GridItem>
            </Grid>
        </>
    );
};
