import React, { useState } from "react";
import {
    Flex,
    Text,
    HStack,
    IconButton,
    Box,
    Image,
    VStack,
    Grid,
    GridItem,
    useDisclosure,
    Menu,
    MenuButton,
    Icon,
    MenuList,
    MenuItem,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "@chakra-ui/react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import temp from "../../assets/temsvg.svg";
import humidity from "../../assets/drop.svg";
import Battery from "../../assets/fullBattery.svg";
import { BsThreeDotsVertical } from "react-icons/bs";

import editIcon from "../../assets/editIcon.svg";
import trash from "../../assets/trash.svg";
import { LuChevronLeft } from "react-icons/lu";

import AddDevice from "../AddDevice.jsx";
import EditDevice from "../EditDevice";
import CustomButton from "../../components/CustomButton";
import plusIcon from "../../assets/Plus_Icon.png";
import gridView from "../../assets/Grid.png";
import listView from "../../assets/List.png";
import bellIcon from "../../assets/bell.svg";
import offlineIcon from "../../assets/offlineIcon.png";
import onlineIcon from "../../assets/onlineIcon.png";
import { formatUnitForSensorType } from "../../utils/sensorUnitUtils.js";
import { useQueryParams } from "use-query-params";
import { SEARCH_PARAMS } from "../../constants";
import { DEVICE_QUERY_PARAMS } from "../../lib/queriesParams.jsx";
import DeleteDevice from "../DeleteDevice.jsx";
import ManageDevice from "../manageDevice.jsx";
import { isCompanysDecisionMaker } from "../../utils/roleUtils.js";
import useGetQueryData from "../../hooks/useGetQueryData.js";

const HumidityHumidity = undefined;

const GridView = () => {
    return <Image src={gridView} alt="GridIcon" />;
};

const ListView = () => {
    return <Image src={listView} alt="ListView" />;
};

const BellIcon = () => {
    return <Image src={bellIcon} alt="onlineIcon" w="20px" h="20px" />;
};

const EditIcon = () => {
    return <Image src={editIcon} alt="eidt" />;
};
const DeleteIcon = () => {
    return <Image src={trash} alt="eidt" />;
};

const OnlineIcon = () => {
    return <Image src={onlineIcon} alt="onlineIcon" w="13px" h="13px" />;
};

const OfflineIcon = () => {
    return <Image src={offlineIcon} alt="offlineIcon" w="13px" h="13px" />;
};

const Location = ({ roomName }) => {
    const { space } = useParams();
    const navigate = useNavigate();

    return (
        <Flex w="100%" mt="32px" display="flex" alignItems="center">
            <HStack>
                <LuChevronLeft size={24} onClick={() => navigate(`/dashboard/${space}/rooms`)} cursor="pointer" />
                <Text fontFamily="fontFamily" fontWeight="700" fontSize="28px" letterSpacing="-0.01em">
                    {roomName}
                </Text>
            </HStack>
        </Flex>
    );
};

const fetchDevices = async (room) => {
    const res = await axios.get(`/device`, {
        params: { room_id: room },
    });
    return res?.data;
};

const fetchRooms = async (space) => {
    const res = await axios.get(`/room`, {
        params: { space_id: space },
    });
    return res?.data;
};

const FilterSensors = ({ view, setView, filterState, setFilterState, lowBattery, setLowBattery, setQuery }) => {
    const { data: userRole } = useGetQueryData({ queryKey: "fetchProfile", select: "data.company.company_access.company_role" });

    const ButtonTextStyle = {
        _hover: {
            bg: "#57BA47",
        },
        fontFamily: "fontFamily",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
        letterSpacing: "-0.01em",
        color: "#FFFFFF",
    };

    const handleTabClick = (view) => {
        setView(view);
    };

    const handleToggle = () => {
        setLowBattery((prev) => !prev);
    };

    const [hoveredOption, setHoveredOption] = React.useState(false);
    const colorScheme = {
        border: "1.5px solid",
        borderColor: lowBattery || hoveredOption ? "rgba(87, 186, 71, 1)" : "rgba(129, 134, 129, 0.5)",
    };

    return (
        <Flex w="95%" h="42px" mt="32px" display="flex" justifyContent="space-between" alignItems="center">
            <HStack spacing="6%" justify="center">
                <HStack spacing="4%">
                    <Button
                        borderRadius="4px"
                        justifyContent="space-between"
                        padding={2}
                        width="180px"
                        minWidth="180px"
                        cursor="pointer"
                        color="#11250E"
                        backgroundColor="#FFFFFF"
                        border={colorScheme.border}
                        borderColor={colorScheme.borderColor}
                        onClick={handleToggle}
                        onMouseEnter={() => setHoveredOption(true)}
                        onMouseLeave={() => setHoveredOption(false)}
                    >
                        <Flex justify="center" align="center" width="100%">
                            <Text fontSize="12px" fontWeight="500" fontFamily="fontFamily" lineHeight="16px" letterSpacing="0.01em">
                                Filter By Low Battery
                            </Text>
                        </Flex>
                    </Button>

                    <Box
                        h="40px"
                        w="238px"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        borderRadius="4px"
                        position="relative"
                        background="#FFFFFF"
                        paddingX="4px"
                        marginLeft={4}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            padding="2px 8px"
                            bgColor={filterState === "all" ? "#e9ffdb" : "transparent"}
                            border={filterState === "all" ? "2px solid #6CC35E" : ""}
                            color={filterState === "all" ? "#11250E" : "#818681"}
                            borderRadius={4}
                            sx={{
                                "&::after": {
                                    content: '""',
                                    position: "absolute",
                                    right: "-4",
                                    height: "4vh",
                                    width: "1px",
                                    backgroundColor: "#E0E5E0",
                                },
                            }}
                            onClick={() => {
                                setFilterState("all");
                            }}
                        >
                            <Text fontFamily="fontFamily" fontWeight="600" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#11250E">
                                All
                            </Text>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            padding="2px 8px"
                            gap="1"
                            bgColor={filterState === "active" ? "#e9ffdb" : "transparent"}
                            border={filterState === "active" ? "2px solid #6CC35E" : ""}
                            color={filterState === "active" ? "#11250E" : "#818681"}
                            borderRadius={4}
                            onClick={() => {
                                setFilterState("active");
                            }}
                        >
                            <OnlineIcon />
                            <Text fontFamily="fontFamily" fontWeight="600" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#11250E">
                                Online
                            </Text>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            padding="2px 8px"
                            gap="1"
                            bgColor={filterState === "inactive" ? "#e9ffdb" : "transparent"}
                            border={filterState === "inactive" ? "2px solid #6CC35E" : ""}
                            color={filterState === "inactive" ? "#11250E" : "#818681"}
                            borderRadius={4}
                            onClick={() => {
                                setFilterState("inactive");
                            }}
                        >
                            <OfflineIcon />
                            <Text fontFamily="fontFamily" fontWeight="600" fontSize="12px" lineHeight="16px" letterSpacing="-0.01em" color="#11250E">
                                Offline
                            </Text>
                        </Box>
                    </Box>
                </HStack>

                <Flex p="2" gap={1} w="111px" h="32px" position="relative" bg="#FFFFFF" align="center">
                    <IconButton
                        onClick={() => handleTabClick("gridView")}
                        colorScheme={view === "gridView" ? "#57BA47" : "white"}
                        borderRadius="4px"
                        bg={view === "gridView" ? "#DDF1DA" : ""}
                        border={view === "gridView" ? "1px solid #57BA47" : ""}
                        w={view === "gridView" ? "48px" : "46px"}
                        h={view === "gridView" ? "24px" : "24px"}
                        icon={<GridView />}
                        _focus={{ background: "#DDF1DA", border: "1px solid #57BA47" }}
                    />

                    <IconButton
                        onClick={() => handleTabClick("listView")}
                        colorScheme={view === "listView" ? "#57BA47" : "white"}
                        borderRadius="4px"
                        bg={view === "listView" ? "#DDF1DA" : ""}
                        border={view === "listView" ? "1px solid #57BA47" : ""}
                        w={view === "listView" ? "48px" : "46px"}
                        h={view === "listView" ? "24px" : "24px"}
                        icon={<ListView />}
                        _focus={{ background: "#DDF1DA", border: "1px solid #57BA47" }}
                    />
                </Flex>
            </HStack>

            {isCompanysDecisionMaker(userRole) && (
                <HStack>
                    <CustomButton
                        w="192px"
                        h="40px"
                        bg="#57BA47"
                        sx={ButtonTextStyle}
                        aria-label="Add Device"
                        onClick={() => setQuery({ [SEARCH_PARAMS.DEVICE.ADD.key]: "success" })}
                    >
                        <Image src={plusIcon} alt="add" h="16px" w="16px" mr="3px" />
                        Add Device
                    </CustomButton>
                    <AddDevice />
                </HStack>
            )}
        </Flex>
    );
};

const RoomsListType = () => {
    const navigate = useNavigate();
    const { space, room, room_name } = useParams();
    const [filterState, setFilterState] = React.useState("all");
    const [lowBattery, setLowBattery] = React.useState(false);
    const [query, setQuery] = useQueryParams(DEVICE_QUERY_PARAMS);
    const { data: userRole } = useGetQueryData({ queryKey: "fetchProfile", select: "data.company.company_access.company_role" });

    const { data: roomDevices = { data: [] }, ...roomDevicesQuery } = useQuery({
        queryKey: ["fetchDevices", room],
        queryFn: () => fetchDevices(room),
        enabled: !!room,
    });

    const { data: rooms = { data: [] }, ...roomsQuery } = useQuery({
        queryKey: ["fetchRooms", space],
        queryFn: () => fetchRooms(space),
        enabled: !!space,
    });

    const filteredDevices = roomDevices?.data?.filter((device) => {
        const isActive = device?.active;
        const isLowBattery = device?.battery < 30;

        const baseCondition = lowBattery ? isLowBattery : true;

        if (filterState === "all") {
            return baseCondition;
        }

        if (filterState === "active") {
            return baseCondition && isActive;
        }

        if (filterState === "inactive") {
            return baseCondition && !isActive;
        }

        return false;
    });

    const renderMenuItems = (sensor) => {
        const menuItems = [
            {
                label: "Manage Device",
                icon: <BellIcon />,
                action: () => {
                    setQuery({ [SEARCH_PARAMS.DEVICE.MANAGE.key]: sensor?.id });
                },
            },
            {
                label: "Edit Device",
                icon: <EditIcon />,
                action: () => {
                    setQuery({ [SEARCH_PARAMS.DEVICE.EDIT.key]: sensor?.id });
                },
            },
            {
                label: "Delete Device",
                icon: <DeleteIcon />,
                action: () => {
                    setQuery({ [SEARCH_PARAMS.DEVICE.DELETE.key]: sensor?.id });
                },
            },
        ];

        return menuItems.map((item, idx) => (
            <MenuItem
                key={idx}
                icon={item.icon}
                onClick={item.action}
                fontFamily="fontFamily"
                fontSize="14px"
                fontWeight="600"
                lineHeight="20px"
                letterSpacing="-0.01em"
                color="rgba(17, 37, 14, 1)"
                bg="rgba(255, 255, 255, 1)"
                _hover={{ bg: "none" }}
            >
                {item.label}
            </MenuItem>
        ));
    };

    const [view, setView] = useState("gridView");

    return (
        <VStack w="100%" bg="#F9F9F9" minH="100vh" margin={0} padding={0}>
            <VStack w="100%" gap={0}>
                <Box w="100%" paddingLeft="2rem" bg="#F6F6F6">
                    <Location roomName={room_name} />
                </Box>

                <Box w="100%" paddingLeft="3rem" bg="#F6F6F6" pb="1.5rem">
                    <FilterSensors
                        view={view}
                        setView={setView}
                        filterState={filterState}
                        setFilterState={setFilterState}
                        lowBattery={lowBattery}
                        setLowBattery={setLowBattery}
                        setQuery={setQuery}
                    />
                </Box>
            </VStack>

            {view === "gridView" ? (
                <Grid
                    templateColumns={{
                        base: "repeat(2, 1fr)",
                        sm: "repeat(2, 1fr)",
                        md: "repeat(2, 1fr)",
                        lg: "repeat(2, 1fr)",
                        xl: "repeat(3, 1fr)",
                    }}
                    columnGap="0px"
                    rowGap="32px"
                    w="94%"
                    mt="24px"
                >
                    {filteredDevices?.map((sensor, index) => (
                        <GridItem
                            w={{ base: "", sm: "", md: "100%", lg: "85%", xl: "85%" }}
                            borderColor="81868180"
                            p="24px"
                            borderRadius="8px"
                            _hover={{ border: "1px solid #81868180", cursor: "pointer" }}
                            align="stretch"
                            bg="rgba(255, 255, 255, 1)"
                        >
                            <HStack
                                alignItems="center"
                                justifyContent="space-between"
                                borderBottom="2px solid #E0E5E0"
                                borderColor="#E0E5E0"
                                mb="16px"
                                pb="16px"
                            >
                                <Text
                                    fontFamily="fontFamily"
                                    fontSize="16px"
                                    fontWeight="800"
                                    lineHeight="24px"
                                    letterSpacing="-0.02em"
                                    textAlign="start"
                                    color="#11250E"
                                    h="24px"
                                    w="162px"
                                >
                                    {sensor.name}
                                </Text>
                                <HStack
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={3}
                                    w="82px"
                                    h="24px"
                                    border="1px solid rgba(212, 212, 212, 1)"
                                    borderRadius="40px"
                                    bg="#FFFFFF"
                                >
                                    {sensor.active ? <OnlineIcon /> : <OfflineIcon />}
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="12px"
                                        fontWeight="500"
                                        lineHeight="14.52px"
                                        letterSpacing="-0.01em"
                                        textAlign="start"
                                        color="#11250E"
                                    >
                                        {sensor?.active ? "Online" : "Offline"}
                                    </Text>
                                </HStack>
                            </HStack>

                            <HStack marginY={2}>
                                <Text
                                    fontFamily="fontFamily"
                                    fontSize="14px"
                                    fontWeight="500"
                                    lineheight="20px"
                                    letterSpacing="0.01em"
                                    textAlign="start"
                                    color="#818681"
                                >
                                    Last Recorded reading -
                                </Text>
                                <Text
                                    fontFamily="fontFamily"
                                    fontSize="14px"
                                    fontWeight="600"
                                    lineheight="20px"
                                    letterSpacing="-0.01em"
                                    textAlign="start"
                                    color="#11250E"
                                >
                                    {new Date(sensor?.last_fetched).toLocaleTimeString("en-US", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                    })}
                                </Text>
                            </HStack>

                            <HStack mb="24px" justifyContent="space-between" mt="24px" w="95%">
                                <VStack spacing={0}>
                                    <Image
                                        src={sensor?.sensor_one?.sensor_type === HumidityHumidity ? humidity : temp}
                                        alt=""
                                        w="21.31x"
                                        h="21.46px"
                                    />
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="24px"
                                        fontWeight="600"
                                        lineHeight="36px"
                                        letterSpacing="-0.04em"
                                        textAlign="center"
                                        color="#11250E"
                                    >
                                        {formatUnitForSensorType({
                                            sensorType: sensor?.sensor_one?.sensor_type,
                                            value: sensor?.sensor_one?.last_value,
                                        })}
                                    </Text>
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="12px"
                                        fontWeight="500"
                                        lineHeight="16px"
                                        letterSpacing="-0.01em"
                                        textAlign="center"
                                        color="#818681"
                                        p={0}
                                        m={0}
                                        w="100%"
                                    >
                                        Sensor 1
                                    </Text>
                                </VStack>

                                <VStack spacing={0}>
                                    <Image
                                        src={sensor?.sensor_two?.sensor_type === HumidityHumidity ? humidity : temp}
                                        alt=""
                                        w="21.31x"
                                        h="21.46px"
                                    />
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="24px"
                                        fontWeight="600"
                                        lineHeight="36px"
                                        letterSpacing="-0.04em"
                                        textAlign="center"
                                        color="#11250E"
                                    >
                                        {formatUnitForSensorType({
                                            sensorType: sensor?.sensor_two?.sensor_type,
                                            value: sensor?.sensor_two?.last_value,
                                        })}
                                    </Text>
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="12px"
                                        fontWeight="500"
                                        lineHeight="16px"
                                        letterSpacing="-0.01em"
                                        textAlign="center"
                                        color="#818681"
                                        p={0}
                                        m={0}
                                        w="100%"
                                    >
                                        Sensor 2
                                    </Text>
                                </VStack>

                                <VStack spacing={0}>
                                    <Image src={Battery} alt="" w="24px" h="24px" />
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="24px"
                                        fontWeight="600"
                                        lineHeight="36px"
                                        letterSpacing="-0.04em"
                                        textAlign="center"
                                        color="#11250E"
                                    >
                                        {sensor?.battery}
                                    </Text>
                                    <Text
                                        fontFamily="fontFamily"
                                        fontSize="12px"
                                        fontWeight="500"
                                        lineHeight="16px"
                                        letterSpacing="-0.01em"
                                        align="center"
                                        color="#818681"
                                        p={0}
                                        m={0}
                                        w="100%"
                                    >
                                        Battery
                                    </Text>
                                </VStack>
                            </HStack>

                            <HStack justifyContent="space-between" mt="24px" w="100%">
                                <Text
                                    fontFamily="fontFamily"
                                    textDecoration="underline"
                                    fontSize="14px"
                                    fontWeight="600"
                                    lineHeight="20px"
                                    letterSpacing="-0.01em"
                                    textAlign="center"
                                    color="#57BA47"
                                    _hover={{ cursor: "pointer", textDecoration: "underline" }}
                                    onClick={() => navigate(`/dashboard/${space}/devices/${sensor?.id}`)}
                                >
                                    <Link textDecoration="underline">View more Details</Link>
                                </Text>
                                {isCompanysDecisionMaker(userRole) && (
                                    <Menu>
                                        <MenuButton
                                            w="36px"
                                            h="36px"
                                            sx={{ _hover: { bg: "rgba(251, 251, 251, 1)" } }}
                                            borderRadius="4px"
                                            display="flex"
                                            align="center"
                                            justifyContent="center"
                                        >
                                            <Icon as={BsThreeDotsVertical} w="20px" h="20px" />
                                        </MenuButton>
                                        <MenuList>{renderMenuItems(sensor)}</MenuList>
                                    </Menu>
                                )}
                            </HStack>
                        </GridItem>
                    ))}
                </Grid>
            ) : (
                <Box w="94%" mt="24px">
                    <VStack spacing={4} mb="2%">
                        {filteredDevices?.map((sensor, index) => (
                            <HStack
                                bg="#FFFFFF"
                                key={index}
                                w={{ base: "", sm: "", md: "", lg: "", xl: "100%" }}
                                h={{ base: "", sm: "", md: "", lg: "", xl: "64px" }}
                                borderRadius={{ base: "", sm: "", md: "", lg: "", xl: "8px" }}
                                spacing={{ base: "", sm: "", md: "", lg: "", xl: "16px" }}
                                align="stretch"
                            >
                                <Grid
                                    templateColumns="repeat(7, 1fr)"
                                    bg="#FFFFFF"
                                    h={{ base: "", sm: "", md: "", lg: "100%", xl: "100%" }}
                                    w={{ base: "", sm: "", md: "", lg: "", xl: "100%" }}
                                    justifyContent="space-between"
                                >
                                    <GridItem align="center" justifyContent="center">
                                        <VStack align="left">
                                            <Box
                                                display="flex"
                                                w={{ base: "", sm: "", md: "", lg: "", xl: "110px" }}
                                                h={{ base: "", sm: "", md: "", lg: "", xl: "60px" }}
                                                bg="#FFFFFF"
                                                alignItems="center"
                                                justifyContent="center"
                                                borderRight="1px solid #DDE2DD"
                                            >
                                                <HStack align="center">
                                                    <Text fontFamily="fontFamily" fontSize="16px" fontWeight="600" lineHeight="24px">
                                                        {sensor.name}
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        </VStack>
                                    </GridItem>
                                    <GridItem display="flex" flexDirection="column" alignItems="start" justifyContent="center">
                                        <VStack h="32px" spacing={0} align="left">
                                            <Text
                                                fontFamily="fontFamily"
                                                fontSize="12px"
                                                fontWeight="700"
                                                lineHeight="16px"
                                                color="#818681"
                                                align="left"
                                            >
                                                Time
                                            </Text>
                                            <HStack alignItems="center">
                                                <Text fontFamily="fontFamily" fontSize="12px" fontWeight="700" lineHeight="16px">
                                                    {new Date(sensor.sensor_one?.last_value).toLocaleTimeString("en-US", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                        hour12: true,
                                                    })}
                                                </Text>
                                            </HStack>
                                        </VStack>
                                    </GridItem>
                                    <GridItem display="flex" flexDirection="column" alignItems="start" justifyContent="center">
                                        <VStack h="32px" spacing={0} align="left">
                                            <Text fontFamily="fontFamily" fontSize="12px" fontWeight="700" color="#818681" align="left">
                                                Sensor 1
                                            </Text>
                                            <HStack align="left" alignItems="center" spacing={1}>
                                                <Text fontFamily="fontFamily" fontSize="12px" fontWeight="700" lineHeight="16px">
                                                    {formatUnitForSensorType({
                                                        sensorType: sensor?.sensor_one?.sensor_type,
                                                        value: sensor?.sensor_one?.last_value,
                                                    })}
                                                </Text>
                                                <Image
                                                    src={sensor?.sensor_one?.sensor_type === HumidityHumidity ? humidity : temp}
                                                    alt="sensorTemperature"
                                                    w="10px"
                                                    h="10px"
                                                />
                                            </HStack>
                                        </VStack>
                                    </GridItem>
                                    <GridItem display="flex" flexDirection="column" alignItems="start" justifyContent="center">
                                        <VStack h="32px" spacing={0} align="left">
                                            <Text fontFamily="fontFamily" fontSize="12px" fontWeight="700" color="#818681" align="left">
                                                Sensor 2
                                            </Text>
                                            <HStack align="left" alignItems="center" spacing={1}>
                                                <Text fontFamily="fontFamily" fontSize="12px" fontWeight="700" lineHeight="16px">
                                                    {formatUnitForSensorType({
                                                        sensorType: sensor?.sensor_two?.sensor_type,
                                                        value: sensor?.sensor_two?.last_value,
                                                    })}
                                                </Text>
                                                <Image
                                                    src={sensor?.sensor_two?.sensor_type === HumidityHumidity ? humidity : temp}
                                                    alt="sensorTemperature"
                                                    w="10px"
                                                    h="10px"
                                                />
                                            </HStack>
                                        </VStack>
                                    </GridItem>
                                    <GridItem display="flex" flexDirection="column" alignItems="start" justifyContent="center">
                                        <VStack h="32px" spacing={0} align="left">
                                            <Text
                                                fontFamily="fontFamily"
                                                fontSize="12px"
                                                lineHeight="16px"
                                                fontWeight="700"
                                                color="#818681"
                                                align="left"
                                            >
                                                Status
                                            </Text>
                                            <HStack align="left" alignItems="center" spacing={1}>
                                                <Text fontFamily="fontFamily" fontSize="12px" lineHeight="16px" fontWeight="700">
                                                    {sensor?.active ? "Online" : "Offline"}
                                                </Text>
                                                {sensor.active ? <OnlineIcon /> : <OfflineIcon />}
                                            </HStack>
                                        </VStack>
                                    </GridItem>
                                    <GridItem display="flex" flexDirection="column" alignItems="start" justifyContent="center">
                                        <VStack h="32px" spacing={0} align="left">
                                            <Text fontFamily="fontFamily" fontSize="12px" fontWeight="700" color="#818681" align="left">
                                                Battery Life
                                            </Text>
                                            <HStack alignItems="center" spacing={1}>
                                                <Text fontFamily="fontFamily" fontSize="12px" fontWeight="500">
                                                    {sensor.battery}
                                                </Text>
                                                <Image src={Battery} alt="sensorBattery" w="20px" h="20px"></Image>
                                            </HStack>
                                        </VStack>
                                    </GridItem>
                                    <GridItem display="flex" flexDirection="column" alignItems="start" justifyContent="center">
                                        <Flex
                                            h="32px"
                                            w={{ base: "", sm: "", md: "", lg: "", xl: "150px" }}
                                            gap={{ base: "", sm: "", md: "", lg: "", xl: "27px" }}
                                            align="center"
                                        >
                                            <Text
                                                cursor="pointer"
                                                textDecoration="underline"
                                                fontFamily="fontFamily"
                                                fontSize="14px"
                                                fontWeight="600"
                                                lineHeight="20px"
                                                letterSpacing="0.04em"
                                                textAlign="center"
                                                color="#57BA47"
                                                _hover={{ cursor: "pointer", textDecoration: "underline" }}
                                                onClick={() => navigate(`/dashboard/${space}/devices/${sensor?.id}`)}
                                            >
                                                View Details
                                            </Text>
                                            {isCompanysDecisionMaker(userRole) && (
                                                <Menu>
                                                    <MenuButton>
                                                        <Icon as={BsThreeDotsVertical} w="20px" h="20px" />
                                                    </MenuButton>
                                                    <MenuList>{renderMenuItems(sensor)}</MenuList>
                                                </Menu>
                                            )}
                                        </Flex>
                                    </GridItem>
                                </Grid>
                            </HStack>
                        ))}
                    </VStack>
                </Box>
            )}

            <EditDevice />
            <ManageDevice />
            <DeleteDevice />
        </VStack>
    );
};

export default RoomsListType;
