import React, { useEffect, useState } from "react";
import { Box, VStack, Text, Image, HStack, Flex, TableContainer, Table, Thead, Tr, Th, Tbody, Td, useDisclosure } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import temp from "../../assets/temsvg.svg";
import humidity from "../../assets/drop.svg";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import TemperatureLineChart from "../chart2";
import { LuChevronLeft } from "react-icons/lu";
import { RiRadioButtonLine } from "react-icons/ri";
import CustomButton from "../CustomButton";
import { ExportReading } from "../ExportReading";
import ChartFilter from "../CustomChartFilter";
import moment from "moment-timezone";
import { formatUnitForSensorType } from "../../utils/sensorUnitUtils";

const HumidityHumidity = undefined;

const fetchLogs = async ({ device, startDate, endDate, readingType }) => {
    const res = await axios.get(`/log`, {
        params: { device_id: device, start_date: startDate, end_date: endDate, reading_type: readingType },
    });
    return res?.data;
};

const fetchDevices = async (device) => {
    const res = await axios.get(`/device`, {
        params: { device_id: device },
    });
    return res?.data?.data;
};

const SensorDevice = () => {
    const navigate = useNavigate();
    const { space, device } = useParams();
    const [selectedGraph, setSelectedGraph] = useState("sensor1");
    const [selectedGraphType, setSelectedGraphType] = useState("temperature");
    const [selectedOverview, setSeletedOverview] = useState("MINUTES");
    const { isOpen: exportisOpen, onOpen: exportonOpen, onClose: exportonClose } = useDisclosure();
    const { isOpen: filterIsOpen, onOpen: filterOnOpen, onClose: filterOnClose } = useDisclosure();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const customDateTimeEnabled = !!startDate && !!endDate;

    const { data: logs, ...logsQuery } = useQuery({
        queryKey: ["fetchLogs", { device, start_date: startDate, end_date: endDate, reading_type: selectedOverview }],
        queryFn: () => fetchLogs({ device, startDate, endDate, readingType: selectedOverview }),
        enabled: !!device,
    });

    const { data: deviceData, ...deviceDataQuery } = useQuery({
        queryKey: ["fetchDevices", device],
        queryFn: () => fetchDevices(device),
        enabled: !!device,
    });

    const Max = deviceData?.[selectedGraph === "sensor1" ? "sensor_one" : "sensor_two"]?.max_value;
    const Min = deviceData?.[selectedGraph === "sensor1" ? "sensor_one" : "sensor_two"]?.min_value;
    const handleOpen = () => {
        exportonOpen();
    };
    useEffect(() => {
        if (deviceData) {
            const sensorType = deviceData?.sensor_one?.sensor_type === HumidityHumidity ? "humidity" : "temperature";
            setSelectedGraphType(sensorType);
        }
    }, [deviceData]);

    return (
        <Box bg="#F9F9F9" margin={0} width="100%" overflow="hidden">
            <Box marginX={12} marginY={10}>
                <HStack width="100%" justifyContent="space-between">
                    <HStack display="flex" alignItems="center" justifyContent="center" spacing={2}>
                        <LuChevronLeft size={24} onClick={() => navigate(`/dashboard/${space}/devices`)} cursor="pointer" />
                        <Text color="#11250E" fontFamily="fontFamily" fontWeight="bold" fontSize="24px">
                            {deviceData?.name}
                        </Text>
                    </HStack>

                    <HStack display="flex" alignItems="center" justifyContent="center" spacing={2} w="82px" h="24px" borderRadius="40px" bg="#FFFFFF">
                        <RiRadioButtonLine color={deviceData?.active ? "#00b300" : "#b30000"} />
                        <Text
                            fontFamily="fontFamily"
                            fontSize="12px"
                            fontWeight="400"
                            lineHeight="14.52px"
                            letterSpacing="-0.01em"
                            textAlign="start"
                            color="#11250E"
                        >
                            {deviceData?.active ? "Online" : "Offline"}
                        </Text>
                    </HStack>
                </HStack>
            </Box>

            <Box h="full" borderRadius="8px 8px 0px 0px" bg="#FFFFFF" marginX={12} boxShadow="0px 4px 25px 0px #0000000D">
                <Box p={8}>
                    <HStack spacing={0} marginBottom={4} justifyContent="space-between" width="100%">
                        <Text fontFamily="fontFamily" fontSize="12px" borderRadius="24px" padding="4px 16px">
                            Location{" "}
                            <Text as="span" fontFamily="fontFamily" fontSize="15px" mr="0.3rem">
                                :
                            </Text>
                            <span style={{ fontWeight: "bold", fontSize: "14px", borderRadius: "24px" }}>{deviceData?.roomDevice?.name}</span>
                        </Text>
                        <Text fontFamily="fontFamily" fontSize="12px" borderRadius="24px" padding="4px 16px">
                            Last Reading{" "}
                            <Text as="span" fontFamily="fontFamily" fontSize="15px" mr="0.3rem">
                                :
                            </Text>
                            <span style={{ fontWeight: "bold", fontSize: "14px", borderRadius: "24px" }}>
                                {moment(deviceData?.last_fetched).format("MM-DD-YYYY HH:mm")}
                            </span>
                        </Text>
                        <Text fontFamily="fontFamily" fontSize="12px" borderRadius="24px" padding="4px 16px">
                            Sensor 1{" "}
                            <Text as="span" fontFamily="fontFamily" fontSize="15px" mr="0.3rem">
                                :
                            </Text>
                            <span style={{ fontWeight: "bold", fontSize: "14px", borderRadius: "24px" }}>
                                {formatUnitForSensorType({
                                    value: deviceData?.sensor_one?.last_value,
                                    sensorType: deviceData?.sensor_one?.sensor_type,
                                })}
                            </span>
                        </Text>
                        <Text fontFamily="fontFamily" fontSize="12px" borderRadius="24px" padding="4px 16px">
                            Sensor 2{" "}
                            <Text as="span" fontFamily="fontFamily" fontSize="15px" mr="0.3rem">
                                :
                            </Text>
                            <span style={{ fontWeight: "bold", fontSize: "14px", borderRadius: "24px" }}>
                                {formatUnitForSensorType({
                                    value: deviceData?.sensor_two?.last_value,
                                    sensorType: deviceData?.sensor_two?.sensor_type,
                                })}
                            </span>
                        </Text>
                        <Text fontFamily="fontFamily" fontSize="12px" borderRadius="24px" padding="4px 16px">
                            Battery{" "}
                            <Text as="span" fontFamily="fontFamily" fontSize="15px" mr="0.3rem">
                                :
                            </Text>
                            <span style={{ fontWeight: "bold", fontSize: "14px", borderRadius: "24px" }}>
                                {formatUnitForSensorType({ value: deviceData?.battery, sensorType: "Battery" })}
                            </span>
                        </Text>
                    </HStack>

                    <HStack spacing={0} justifyContent="space-between" width="100%">
                        <HStack spacing={4} align="flex-start" margin={4}>
                            <Text
                                fontFamily="fontFamily"
                                fontSize="12px"
                                borderRadius="24px"
                                padding="4px 16px"
                                bgColor={customDateTimeEnabled ? "#57BA47" : "transparent"}
                                color={customDateTimeEnabled ? "#FFFFFF" : "#818681"}
                                cursor="pointer"
                                onClick={() => {
                                    filterOnOpen();
                                }}
                            >
                                Custom Date & Time
                            </Text>
                            <Text
                                fontFamily="fontFamily"
                                fontSize="12px"
                                borderRadius="24px"
                                padding="4px 16px"
                                bgColor={selectedOverview === "DAILY" ? "#57BA47" : "transparent"}
                                cursor="pointer"
                                color={selectedOverview === "DAILY" ? "#FFFFFF" : "#818681"}
                                onClick={() => setSeletedOverview("DAILY")}
                            >
                                Daily
                            </Text>
                            <Text
                                fontFamily="fontFamily"
                                fontSize="12px"
                                borderRadius="24px"
                                padding="4px 16px"
                                bgColor={selectedOverview === "HOURLY" ? "#57BA47" : "transparent"}
                                color={selectedOverview === "HOURLY" ? "#FFFFFF" : "#818681"}
                                cursor="pointer"
                                onClick={() => setSeletedOverview("HOURLY")}
                            >
                                Hourly
                            </Text>
                            <Text
                                fontFamily="fontFamily"
                                fontSize="12px"
                                borderRadius="24px"
                                padding="4px 16px"
                                bgColor={selectedOverview === "MINUTES" ? "#57BA47" : "transparent"}
                                color={selectedOverview === "MINUTES" ? "#FFFFFF" : "#818681"}
                                cursor="pointer"
                                onClick={() => setSeletedOverview("MINUTES")}
                            >
                                Minutes
                            </Text>
                            <Text
                                padding="4px 16px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                fontFamily="fontFamily"
                                fontSize="12px"
                                color="#818681"
                            >
                                Max
                                <Text as="span" fontFamily="fontFamily" fontSize="15px" mr="0.3rem">
                                    :
                                </Text>
                                <Text style={{ fontWeight: "bold", fontSize: "14px", borderRadius: "24px" }}>{Max}</Text>
                            </Text>
                            <Text
                                padding="4px 16px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                fontFamily="fontFamily"
                                fontSize="12px"
                                color="#818681"
                            >
                                Min
                                <Text as="span" fontFamily="fontFamily" fontSize="15px" mr="0.3rem">
                                    :
                                </Text>
                                <Text style={{ fontWeight: "bold", fontSize: "14px", borderRadius: "24px" }}>{Min}</Text>
                            </Text>
                        </HStack>

                        <HStack border="1px solid #D7D7D7" borderRadius="8px" padding="2px">
                            <HStack
                                bgColor={selectedGraph === "sensor1" ? "#e9ffdb" : "transparent"}
                                border={selectedGraph === "sensor1" ? "2px solid #6CC35E" : ""}
                                color={selectedGraph === "sensor1" ? "#11250E" : "#818681"}
                                borderRadius={4}
                                paddingX="8px"
                                _hover={{ border: "1px solid #81868180", cursor: "pointer" }}
                                onClick={() => {
                                    setSelectedGraph("sensor1");
                                    setSelectedGraphType(deviceData?.sensor_one?.sensor_type === HumidityHumidity ? "humidity" : "temperature");
                                }}
                            >
                                <Image src={deviceData?.sensor_one?.sensor_type === HumidityHumidity ? humidity : temp} alt="" w="12px" h="12px" />
                                <Text fontFamily="fontFamily" fontSize="12px">
                                    Sensor 1
                                </Text>
                            </HStack>

                            <HStack
                                bgColor={selectedGraph === "sensor2" ? "#e9ffdb" : "transparent"}
                                border={selectedGraph === "sensor2" ? "2px solid #6CC35E" : ""}
                                color={selectedGraph === "sensor2" ? "#11250E" : "#818681"}
                                borderRadius={4}
                                paddingX="8px"
                                _hover={{ border: "1px solid #81868180", cursor: "pointer" }}
                                onClick={() => {
                                    setSelectedGraph("sensor2");
                                    setSelectedGraphType(deviceData?.sensor_two?.sensor_type === HumidityHumidity ? "humidity" : "temperature");
                                }}
                            >
                                <Image src={deviceData?.sensor_two?.sensor_type === HumidityHumidity ? humidity : temp} alt="" w="12px" h="12px" />
                                <Text fontFamily="fontFamily" fontSize="12px">
                                    Sensor 2
                                </Text>
                            </HStack>
                        </HStack>
                    </HStack>

                    <Flex w="full">
                        <TemperatureLineChart
                            data={logs?.data}
                            selectedGraph={selectedGraph}
                            selectedType={selectedGraphType}
                            deviceData={deviceData}
                            overview={selectedOverview}
                        />
                    </Flex>
                </Box>

                <Box p={8}>
                    <VStack>
                        <HStack width="100%" justifyContent="space-between">
                            <Text color="#11250E" fontFamily="fontFamily" fontSize="20px" fontWeight="bold" lineHeight="30px" letterSpacing="-0.01em">
                                Detailed Statistics
                            </Text>

                            {/* <HStack
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                                w="92px"
                                h="36px"
                                borderRadius="4px"
                                bg="#FFFFFF"
                                border="2px solid #818681"
                            >
                                <IoFilter />
                                <Text
                                    fontFamily="fontFamily"
                                    fontSize="14px"
                                    fontWeight="400"
                                    lineHeight="14.52px"
                                    letterSpacing="-0.01em"
                                    textAlign="start"
                                    color="#11250E"
                                >
                                    Filter
                                </Text>
                            </HStack> */}
                            <CustomButton
                                px="2.5rem"
                                bg="#57BA47"
                                fontFamily="fontFamily"
                                fontSize="14px"
                                fontWeight="700"
                                lineHeight="20px"
                                letterSpacing="-0.01em"
                                textAlign="left"
                                color="#FFFFFF"
                                onClick={handleOpen}
                            >
                                Export
                            </CustomButton>
                        </HStack>
                        <TableContainer w="100%">
                            <Table
                                variant="contained"
                                sx={{ "tr:nth-of-type(even)": { backgroundColor: "#F6F6F6" }, tableLayout: "fixed" }}
                                colorScheme="#F6F6F6"
                                fontSize="12px"
                            >
                                <Thead bg="#F6F6F6" borderBottom="16px solid #FFFFFF" h="48px">
                                    <Tr>
                                        <Th
                                            key="Name"
                                            textTransform="capitalize"
                                            fontWeight="bold"
                                            textAlign="center"
                                            fontFamily="fontFamily"
                                            fontSize="16px"
                                            lineHeight="30px"
                                            letterSpacing="-0.01em"
                                            alignContent="center"
                                        >
                                            Reading Time
                                        </Th>
                                        <Th
                                            key="sensor1"
                                            textTransform="capitalize"
                                            fontWeight="bold"
                                            textAlign="center"
                                            fontFamily="fontFamily"
                                            fontSize="16px"
                                            lineHeight="30px"
                                            letterSpacing="-0.01em"
                                            alignContent="center"
                                        >
                                            Sensor 1
                                        </Th>
                                        <Th
                                            key="sensor2"
                                            textTransform="capitalize"
                                            fontWeight="bold"
                                            textAlign="center"
                                            fontFamily="fontFamily"
                                            fontSize="16px"
                                            lineHeight="30px"
                                            letterSpacing="-0.01em"
                                            alignContent="center"
                                        >
                                            Sensor 2
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {logs?.data?.map((items, index) => (
                                        <Tr key={index}>
                                            <Td
                                                fontFamily="fontFamily"
                                                fontSize="14px"
                                                fontWeight="bold"
                                                lineHeight="30px"
                                                letterSpacing="-0.01em"
                                                textAlign="center"
                                            >
                                                {new Date(items?.fetch_time).toLocaleDateString("en-US", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "2-digit",
                                                })}{" "}
                                                {new Date(items?.fetch_time).toLocaleTimeString("en-US", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    hour12: true,
                                                })}
                                            </Td>
                                            <Td fontFamily="fontFamily" fontSize="14px" lineHeight="30px" letterSpacing="-0.01em" textAlign="center">
                                                {items?.sensor_one} {deviceData?.sensor_one?.sensor_type === HumidityHumidity ? "%" : "°C"}
                                            </Td>
                                            <Td fontFamily="fontFamily" fontSize="14px" lineHeight="30px" letterSpacing="-0.01em" textAlign="center">
                                                {items?.sensor_two} {deviceData?.sensor_two?.sensor_type === HumidityHumidity ? "%" : "°C"}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </VStack>
                </Box>
            </Box>
            {exportisOpen && (
                <ExportReading
                    exportisOpen={exportisOpen}
                    exportonClose={exportonClose}
                    device={deviceData}
                    endDate={endDate}
                    startDate={startDate}
                />
            )}
            {filterIsOpen && (
                <ChartFilter
                    endDate={endDate}
                    startDate={startDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    isOpen={filterIsOpen}
                    onClose={filterOnClose}
                />
            )}
        </Box>
    );
};

export default SensorDevice;
